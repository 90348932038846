import React, { useEffect, useState } from "react";
import { MenuProps, Tooltip } from "antd";
import { Avatar, Dropdown, Layout, Space } from "antd";
import {
  BellOutlined,
  DownOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  UpOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { usePermissions } from "../../PermissionsProvider";
import { CompanyService } from "../../services/CompanyService";
import { Company } from "../../model_gen/company";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom"; // Added import for useAuth0
import { OutlineButton } from "./button/Buttons";
import Search from "../Search/Search";
import useStore from "../../store/useStore";

const { Header } = Layout;

const HeaderComponent: React.FC = () => {
  const { apiKey } = usePermissions();
  const navigate = useNavigate();
  const { dataReadyTutorial } = useStore();
  // Company not being used, kept for future implementation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [company, setCompany] = useState<Company | null>(null);
  const { user, logout } = useAuth0(); // Destructure logout from useAuth0
  const [isHeaderDropDownMenuOpen, setIsHeaderDropDownMenuOpen] =
    useState<boolean>(false);
  const items: MenuProps["items"] = [
    {
      label: <a>Account Settings</a>,
      key: "0",
      disabled: true,
    },
    {
      label: <a onClick={() => navigate("/billing")}>Billing</a>,
      key: "1",
    },
    {
      label: <a>Support</a>,
      key: "2",
      disabled: true,
    },
    {
      type: "divider",
    },
    {
      label: (
        <a
          onClick={() =>
            logout({ logoutParams: { returnTo: `${window.location.origin}` } })
          }>
          Logout
        </a>
      ),
      key: "4",
    },
  ];

  useEffect(() => {
    if (apiKey) {
      CompanyService.getCompany(null, apiKey).subscribe(
        (result: Company) => {
          setCompany(result);
        },
        (error: any) => {
          console.error(error);
        }
      );
    }
  }, [apiKey]);

  const handleOpenChange = (open: boolean) => {
    setIsHeaderDropDownMenuOpen(open);
  };
  return (
    <Header className="Header main-header-wrap">
      <div className="leftSection">
        {/* <img src={MaitaiLogo} style={{ width: '125px', height: 'auto', marginRight: '80px' }} /> */}
        <Search disabled={!dataReadyTutorial} />
      </div>
      <div
        style={{
          background: "transparent",
          border: "none",
          width: "40%",
          justifyContent: "flex-end",
          display: "flex",
          gap: "20px",
        }}>
        <OutlineButton
          className="menu-icon-size"
          target="_blank"
          href="https://docs.trymaitai.ai"
          icon={<QuestionCircleOutlined />}
        />
        <OutlineButton
          className="menu-icon-size"
          href="/"
          icon={<BellOutlined />}
          disabled
        />
        <Tooltip
          title={
            !dataReadyTutorial
              ? "Make a request using the Maitai SDK to view this page"
              : ""
          }>
          <OutlineButton
            className="menu-icon-size"
            onClick={() => navigate("/settings")}
            icon={<SettingOutlined />}
            disabled={!dataReadyTutorial}
          />
        </Tooltip>
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          onOpenChange={handleOpenChange}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              {user?.picture ? (
                <Avatar size={32} src={user?.picture} />
              ) : (
                <UserOutlined />
              )}
              {isHeaderDropDownMenuOpen ? <UpOutlined /> : <DownOutlined />}
            </Space>
          </a>
        </Dropdown>
      </div>
      {/* <Menu mode="horizontal" theme="light" style={{ lineHeight: '64px', background: 'transparent', border: 'none', width: '40%', justifyContent: 'flex-end' }}>
                <Menu.Item key="developers" style={{ padding: 0, marginRight: "20px" }} icon={<LaptopOutlined className='menu-icon-size pl-wrap' />}>
                    <Link style={{ margin: 0 }} to="/developers" className='menu-item-font-size'>Developers</Link>
                </Menu.Item>
                <Menu.Item key="help" style={{ padding: 0, paddingRight: "10px" }} icon={<QuestionCircleOutlined className='menu-icon-size' />}>
                    <Link style={{ margin: 0 }} to="https://docs.trymaitai.ai"></Link>
                </Menu.Item>
                <Menu.Item key="alerts" style={{ padding: 0, paddingRight: "10px" }} icon={<BellOutlined className='menu-icon-size' />} disabled={true}>
                    <Link style={{ margin: 0 }} to="/"></Link>
                </Menu.Item>
                <Menu.Item key="settings" style={{ padding: 0, paddingRight: "0px" }} style={{ padding: 0, paddingRight: "0px" }} icon={<SettingOutlined className='menu-icon-size' className='menu-icon-size' />} disabled={true}>
                    <Link style={{ margin: 0 }} style={{ margin: 0 }} to="/settings"></Link>
                </Menu.Item>
                <Menu.Item key="logout">
                    <Dropdown menu={{ items }} trigger={['click']} onOpenChange={handleOpenChange}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                {user?.picture ?
                                    <Avatar size={32} src={user?.picture} /> :
                                    <UserOutlined />
                                }
                                {
                                    isHeaderDropDownMenuOpen ? <UpOutlined /> : <DownOutlined />
                                }
                            </Space>
                        </a>
                    </Dropdown>
                </Menu.Item>
            </Menu> */}
    </Header>
  );
};

export default HeaderComponent;
