import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Col,
  Descriptions,
  Divider,
  Input,
  InputNumber,
  Radio,
  Row,
  Slider,
  Spin,
  Tooltip,
  Typography,
  Select
} from "antd";
import {
  Config,
  InferenceLocations,
  ModelConfig,
} from "../../../model_gen/config";
import { ConfigService } from "../../../services/ConfigService";
import { usePermissions } from "../../../PermissionsProvider";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import CustomSwitch from "../../shared/switch/CustomSwitch";
import {
  ConfigurationPanelProps,
  ConfigurationPanelRef,
} from "../../../types/IApplication";
import { LinkButton } from "../../shared/button/Buttons";
import { InfoCircleOutlined } from "@ant-design/icons";

const ConfigurationPanel = forwardRef<
  ConfigurationPanelRef,
  ConfigurationPanelProps
>((props, ref) => {
  const { apiKey } = usePermissions();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [modelOptions, setModelOptions] = useState<string[]>([]);
  const [models, setModels] = useState<ModelConfig>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getModels = () => {
    setIsLoading(true);
    ConfigService.getModels(setIsLoading, apiKey).subscribe(
      (models: ModelConfig) => {
        // Sort the models in alphabetical order
        models.client_models.sort();
        models.server_models.sort();
        console.log(JSON.stringify(models, null, 2));
        setModels(models);
      },
      (error: any) => {
        setIsLoading(false);
        console.error(error);
      }
    );
  };

  const updateConfig = (new_config: Partial<Config>, save: boolean = true) => {
    if (save) {
      setIsLoading(true);
    }
    props.updateConfig(new_config, setIsLoading, save);
  };

  useEffect(() => {
    getModels();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTooltipOpen(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  useImperativeHandle(ref, () => ({
    closeToolTipEvent() {
      setTooltipOpen(false);
    },
    openToolTipEvent() {
      setTimeout(() => {
        setTooltipOpen(true);
      }, 100);
    },
  }));

  useEffect(() => {
    if (!models) return;
    if (props.config?.inference_location == InferenceLocations.SERVER) {
      setModelOptions(models.server_models);
    }
    if (props.config?.inference_location == InferenceLocations.CLIENT) {
      setModelOptions(models.client_models);
    }
    setIsLoading(false);
  }, [models, props.config?.inference_location]);
  const cleanedModel = modelOptions.map((model) => {
    return { value: model, label: model };
  });
  cleanedModel.splice(0, 0, { value: "", label: "None" });

  const renderTooltip = (
    title: string,
    content: string,
    isOnLeft: boolean = false
  ) => (
    <span
      style={{
        position: "relative",
        paddingLeft: isOnLeft ? "20px" : "0",
        paddingRight: isOnLeft ? "0" : "20px",
      }}
      className="configuration-panel-label">
      <Tooltip title={content}>
        <InfoCircleOutlined
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: isOnLeft ? "0" : "auto",
            right: isOnLeft ? "auto" : "0",
            color: "rgba(0, 0, 0, 0.45)",
            fontSize: "14px",
          }}
        />
      </Tooltip>
      {title}
    </span>
  );

  const groupModelsByProvider = (models: string[]) => {
    const grouped: { [provider: string]: string[] } = {};
    models.forEach((model) => {
      const [provider] = model.split(":");
      if (!grouped[provider]) {
        grouped[provider] = [];
      }
      grouped[provider].push(model);
    });
    return grouped;
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const renderModelSelect = (
    value: string | undefined,
    onChange: (value: string) => void,
    options: string[]
  ) => {
    const groupedModels = groupModelsByProvider(options);
    const sortedProviders = Object.keys(groupedModels).sort((a, b) => {
      if (a.toLowerCase() === "maitai") return -1;
      if (b.toLowerCase() === "maitai") return 1;
      return a.localeCompare(b);
    });

    return (
      <Select
        value={value}
        style={{ width: "100%" }}
        className="h-56"
        onChange={onChange}
        optionLabelProp="label"
        optionFilterProp="children"
      >
        <Select.Option key="" value="" label="None">
          None
        </Select.Option>
        {sortedProviders.map((provider) => (
          <Select.OptGroup 
            key={provider} 
            label={
              provider.toLowerCase() === "maitai" ? (
                <Typography.Text strong style={{ color: "#21b892" }}>
                  {capitalizeFirstLetter(provider)} (Recommended)
                </Typography.Text>
              ) : (
                capitalizeFirstLetter(provider)
              )
            }
          >
            {groupedModels[provider].map((model) => {
              const [, modelName] = model.split(":");
              return (
                <Select.Option 
                  key={model} 
                  value={model} 
                  label={model}
                >
                  {modelName}
                </Select.Option>
              );
            })}
          </Select.OptGroup>
        ))}
      </Select>
    );
  };

  return (
    <div
      className="overlay-container"
      style={{
        paddingTop: props.isModalOpen ? 16 : 0,
      }}>
      {(isLoading || !props.config) && (
        <div className="overlay">
          <Spin size="large" />
        </div>
      )}

      <div
        className={
          !props.isModalOpen
            ? "configuration-panel-wrapper"
            : "configuration-panel-wrapper-modal"
        }>
        <div>
          {!props.isModalOpen ? (
            <Typography.Title
              level={3}
              style={{ margin: "0 0 24px 0", fontWeight: 400 }}>
              General
            </Typography.Title>
          ) : (
            <></>
          )}
          <Col span={24}>
            <div className="configure-menu-list-item-wrap">
              <div>
                <span className="configuration-panel-label">
                  {renderTooltip(
                    "Inference Location:",
                    "Choose where the model inference will take place",
                    true
                  )}
                </span>
              </div>
              <div>
                <Tooltip
                  title={
                    props.config?.apply_corrections
                      ? "Disable corrections to change inference location"
                      : ""
                  }>
                  <Radio.Group
                    className="custom-radio-btn"
                    onChange={(e) => {
                      updateConfig({
                        inference_location: e.target.value,
                        model:
                          e.target.value == InferenceLocations.CLIENT
                            ? models?.default_client_model
                            : props.config?.model,
                        context_retrieval_enabled:
                          e.target.value == InferenceLocations.SERVER
                            ? props.config?.context_retrieval_enabled
                            : false,
                      });
                    }}
                    value={props.config?.inference_location}>
                    <Radio value={InferenceLocations.CLIENT}>Client</Radio>
                    <Radio value={InferenceLocations.SERVER}>Server</Radio>
                  </Radio.Group>
                </Tooltip>
              </div>
            </div>
          </Col>
          <Divider
            style={{
              borderColor: "#ECEDEC",
              margin: props.isModalOpen ? "16px 0" : "24px 0",
            }}
          />
          <Col span={24}>
            <div className="configure-menu-list-item-wrap">
              <div>
                <span className="configuration-panel-label">
                  {renderTooltip(
                    "Evaluations:",
                    "Enable or disable model evaluations",
                    true
                  )}
                </span>
              </div>
              <div className="switch-btn-wrap">
                <CustomSwitch
                  className="custom-switch"
                  checked={props.config?.evaluation_enabled}
                  onChange={(checked) => {
                    updateConfig({
                      evaluation_enabled: checked,
                      apply_corrections: !checked
                        ? false
                        : props.config?.apply_corrections,
                    });
                  }}
                />
              </div>
            </div>
          </Col>
          <Divider
            style={{
              borderColor: "#ECEDEC",
              margin: props.isModalOpen ? "16px 0" : "24px 0",
            }}
          />
          <Col span={24}>
            <div className="configure-menu-list-item-wrap">
              <div>
                <span className="configuration-panel-label">
                  {renderTooltip(
                    "Apply Corrections:",
                    "Automatically apply corrections based on evaluations",
                    true
                  )}
                </span>
              </div>
              <div className="switch-btn-wrap">
                <Tooltip
                  title={
                    props.config?.evaluation_enabled
                      ? ""
                      : "Enable evaluations to apply corrections"
                  }>
                  <CustomSwitch
                    className="custom-switch"
                    checked={props.config?.apply_corrections}
                    disabled={!props.config?.evaluation_enabled}
                    onChange={(checked) => {
                      updateConfig({
                        apply_corrections: checked,
                        inference_location: checked
                          ? InferenceLocations.SERVER
                          : props.config?.inference_location,
                        evaluation_enabled: checked
                          ? true
                          : props.config?.evaluation_enabled,
                      });
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </Col>
          <Divider
            style={{
              borderColor: "#ECEDEC",
              margin: props.isModalOpen ? "16px 0" : "24px 0",
            }}
          />
          {props.config?.apply_corrections && (
            <Col span={24} style={{ marginBottom: 20 }}>
              <div className="configure-menu-list-item-wrap">
                <div>
                  {renderTooltip(
                    "Safe Mode",
                    "Safe mode prioritizes accuracy over latency",
                    true
                  )}
                </div>
                <div className="switch-btn-wrap">
                  <CustomSwitch
                    className="custom-switch"
                    checked={props.config?.safe_mode}
                    onChange={(checked) => {
                      updateConfig({ safe_mode: checked });
                    }}
                  />
                </div>
              </div>
            </Col>
          )}
        </div>
        {!props.isModalOpen ? (
          <Divider
            type="vertical"
            style={{ borderColor: "#ECEDEC", height: "100%" }}
          />
        ) : (
          <></>
        )}
        <div style={{ width: "100%" }}>
          {!props.isModalOpen ? (
            <Typography.Title
              level={3}
              style={{ margin: "0 0 24px 0", fontWeight: 400 }}>
              Model settings
            </Typography.Title>
          ) : (
            <></>
          )}
          <Col>
            <Col style={{ marginBottom: props.isModalOpen ? 16 : 24 }}>
              <Descriptions
                colon={false}
                layout="vertical"
                className="config-panel-descriptions"
                labelStyle={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#616665",
                  marginBottom: 4,
                }}>
                <Descriptions.Item
                  label={renderTooltip(
                    "Model",
                    "The primary AI model to be used for inference",
                    true
                  )}>
                  {renderModelSelect(
                    props.config?.model,
                    (value) => {
                      updateConfig({ model: value });
                    },
                    modelOptions
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col style={{ marginBottom: props.isModalOpen ? 16 : 24 }}>
              <Descriptions
                colon={false}
                layout="vertical"
                className="config-panel-descriptions"
                labelStyle={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#616665",
                  marginBottom: 4,
                }}>
                <Descriptions.Item
                  label={renderTooltip(
                    "Secondary Model",
                    "The model to fallback to if the primary model is not available or has degraded performance",
                    true
                  )}>
                  {renderModelSelect(
                    props.config?.fallback_model,
                    (value) => {
                      updateConfig({ fallback_model: value });
                    },
                    modelOptions
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col style={{ marginBottom: props.isModalOpen ? 16 : 24 }}>
              <Descriptions
                colon={false}
                column={1}
                layout="vertical"
                className="custom_slider_wrapper"
                labelStyle={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#616665",
                  marginBottom: 4,
                }}>
                <Descriptions.Item
                  label={renderTooltip(
                    "Temperature",
                    "Controls randomness in the model's output (0 = deterministic, 2 = very random)",
                    true
                  )}>
                  <div style={{ width: "100%", position: "relative" }}>
                    <Slider
                      min={0}
                      max={2}
                      className="cus-slider-wrap"
                      tooltip={{ open: tooltipOpen, placement: "top" }}
                      value={props.config?.temperature}
                      step={0.01}
                      onChange={(value: number) => {
                        updateConfig({ temperature: value }, false);
                      }}
                      onChangeComplete={(value: number) => {
                        updateConfig({ temperature: value });
                      }}
                    />
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Col>
          {isExpanded && (
            <>
              <Typography.Title
                level={3}
                style={{
                  margin: props.isModalOpen ? "0 0 16px 0" : "0 0 32px 0",
                  fontWeight: 400,
                }}>
                Advanced settings
              </Typography.Title>
              <Col style={{ marginBottom: props.isModalOpen ? 16 : 24 }}>
                <Descriptions
                  colon={false}
                  column={1}
                  layout="vertical"
                  labelStyle={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#616665",
                    marginBottom: 4,
                  }}>
                  <Descriptions.Item
                    label={renderTooltip(
                      "Stop Sequence",
                      "Sequences where the API will stop generating further tokens",
                      true
                    )}>
                    <Input
                      className="h-56"
                      // eslint-disable-next-line quotes
                      placeholder={'["", ".", "..."]'}
                      onBlur={(e) => {
                        updateConfig({ stop: e.target.value });
                      }}
                    />
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Row
                gutter={24}
                style={{
                  marginBottom: props.isModalOpen ? 16 : 24,
                  flexDirection: props.isModalOpen ? "row-reverse" : "row",
                }}>
                {!props.isModalOpen ? (
                  <Col span={8} className="configure-menu-list-item-wrap">
                    <div className="configure-menu-list-item-wrap w-100">
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#616665",
                          marginBottom: 4,
                        }}>
                        {renderTooltip(
                          "Log Probs",
                          "Include the log probabilities of the output tokens",
                          true
                        )}
                      </div>
                      <div className="switch-btn-wrap">
                        <CustomSwitch
                          className="custom-switch"
                          checked={props.config?.logprobs}
                          onChange={(checked) => {
                            updateConfig({
                              logprobs: checked,
                              apply_corrections: !checked
                                ? false
                                : props.config?.apply_corrections,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                ) : (
                  <></>
                )}
                <Col span={props?.isModalOpen ? 12 : 8}>
                  <Descriptions
                    colon={false}
                    column={1}
                    layout="vertical"
                    labelStyle={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#616665",
                      marginBottom: 4,
                    }}>
                    <Descriptions.Item
                      label={renderTooltip(
                        "N",
                        "How many completions to generate for each prompt",
                        true
                      )}
                      span={props?.isModalOpen ? 6 : 2}>
                      <InputNumber
                        value={props.config?.n}
                        min={0}
                        style={{ width: "100%" }}
                        className="h-56"
                        placeholder="N"
                        onBlur={(e) => {
                          const value = Number(e.target.value);
                          if (isNaN(value)) {
                            return;
                          }
                          updateConfig({ n: value });
                        }}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={props?.isModalOpen ? 12 : 8}>
                  <Descriptions
                    colon={false}
                    column={1}
                    layout="vertical"
                    labelStyle={{
                      fontSize: "16px",
                      fontWeight: 500,
                      color: "#616665",
                      marginBottom: 4,
                    }}>
                    <Descriptions.Item
                      label={renderTooltip(
                        "Max Tokens",
                        "The maximum number of tokens to generate in the completion",
                        true
                      )}
                      span={props?.isModalOpen ? 6 : 2}>
                      <InputNumber
                        value={props.config?.max_tokens}
                        min={0}
                        style={{ width: "100%" }}
                        className="h-56 w-100"
                        placeholder="Tokens"
                        onBlur={(e) => {
                          const value = Number(e.target.value);
                          if (isNaN(value)) {
                            return;
                          }
                          updateConfig({ max_tokens: value });
                        }}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              {props.isModalOpen ? (
                <>
                  <Divider
                    style={{ borderColor: "#ECEDEC", margin: "24px 0" }}
                  />
                  <Col className="configure-menu-list-item-wrap">
                    <div className="configure-menu-list-item-wrap w-100">
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#616665",
                          marginBottom: 4,
                        }}>
                        {renderTooltip(
                          "Log Probs",
                          "Include the log probabilities of the output tokens",
                          true
                        )}
                      </div>
                      <div className="switch-btn-wrap">
                        <CustomSwitch
                          className="custom-switch"
                          checked={props.config?.logprobs}
                          onChange={(checked) => {
                            updateConfig({
                              logprobs: checked,
                              apply_corrections: !checked
                                ? false
                                : props.config?.apply_corrections,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Divider
                    style={{ borderColor: "#ECEDEC", margin: "24px 0" }}
                  />
                </>
              ) : (
                <></>
              )}

              <Col style={{ marginBottom: props.isModalOpen ? 16 : 24 }}>
                <Descriptions
                  colon={false}
                  column={1}
                  layout="vertical"
                  className="custom_slider_wrapper"
                  labelStyle={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#616665",
                    marginBottom: 4,
                  }}>
                  <Descriptions.Item
                    label={renderTooltip(
                      "Presence Penalty",
                      "Increases the model's likelihood to talk about new topics",
                      true
                    )}>
                    <div style={{ width: "100%" }}>
                      <Slider
                        min={-2}
                        max={2}
                        className="cus-slider-wrap"
                        tooltip={{
                          open: tooltipOpen,
                          placement: "top",
                        }}
                        value={props.config?.presence_penalty}
                        step={0.01}
                        onChange={(value: number) => {
                          updateConfig({ presence_penalty: value }, false);
                        }}
                        onChangeComplete={(value: number) => {
                          updateConfig({ presence_penalty: value });
                        }}
                      />
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              {props.isModalOpen ? (
                <Divider style={{ borderColor: "#ECEDEC", margin: "24px 0" }} />
              ) : (
                <></>
              )}
              <Col style={{ marginBottom: props.isModalOpen ? 16 : 24 }}>
                <Descriptions
                  colon={false}
                  column={1}
                  layout="vertical"
                  className="custom_slider_wrapper"
                  labelStyle={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#616665",
                    marginBottom: 4,
                  }}>
                  <Descriptions.Item
                    label={renderTooltip(
                      "Frequency Penalty",
                      "Decreases the model's likelihood to repeat the same line verbatim",
                      true
                    )}>
                    <div style={{ width: "100%" }}>
                      <Slider
                        min={-2}
                        max={2}
                        className="cus-slider-wrap"
                        tooltip={{ open: tooltipOpen, placement: "top" }}
                        value={props.config?.frequency_penalty}
                        step={0.01}
                        onChange={(value: number) => {
                          updateConfig({ frequency_penalty: value }, false);
                        }}
                        onChangeComplete={(value: number) => {
                          updateConfig({ frequency_penalty: value });
                        }}
                      />
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </>
          )}
          <LinkButton
            className="less-more-wrap"
            style={{ padding: 0 }}
            icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}>
            {isExpanded ? "Hide Advanced Settings" : "Show Advanced Settings"}
          </LinkButton>
        </div>
      </div>
    </div>
  );
});

export default ConfigurationPanel;
