// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-container {
  position: relative;
  width: 100%;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #dfe1e5;
  border-radius: 0 0 8px 8px;
  border-top: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: -16px;
  line-height: 32px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 0; /* Add this line */
  visibility: hidden; /* Add this line */
}

.suggestions-list.scrollable {
  max-height: 350px; /* Adjust this value as needed */
  overflow-y: auto;
}

.suggestions-list:not(:empty) {
  visibility: visible; /* Add this block */
}

.suggestion-item, .result-item, .result-item-no-hover {
  padding: 6px 16px;
  cursor: pointer;
  font-size: 14px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.suggestion-item:last-child, .result-item:last-child {
  border-bottom: none;
}

.suggestion-item:hover, .result-item:hover {
  background-color: #f1f3f4;
}

.category-label {
  color: #616665;
  font-size: 12px;
}

.search-input {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/Search.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,uBAAuB;EACvB,yBAAyB;EACzB,0BAA0B;EAC1B,gBAAgB;EAChB,qBAAqB;EACrB,SAAS;EACT,UAAU;EACV,iBAAiB;EACjB,iBAAiB;EACjB,wCAAwC;EACxC,aAAa,EAAE,kBAAkB;EACjC,kBAAkB,EAAE,kBAAkB;AACxC;;AAEA;EACE,iBAAiB,EAAE,gCAAgC;EACnD,gBAAgB;AAClB;;AAEA;EACE,mBAAmB,EAAE,mBAAmB;AAC1C;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,eAAe;EACf,gCAAgC;EAChC,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".search-container {\n  position: relative;\n  width: 100%;\n}\n\n.suggestions-list {\n  position: absolute;\n  top: 100%;\n  left: 0;\n  right: 0;\n  background-color: white;\n  border: 1px solid #dfe1e5;\n  border-radius: 0 0 8px 8px;\n  border-top: none;\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  margin-top: -16px;\n  line-height: 32px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  min-height: 0; /* Add this line */\n  visibility: hidden; /* Add this line */\n}\n\n.suggestions-list.scrollable {\n  max-height: 350px; /* Adjust this value as needed */\n  overflow-y: auto;\n}\n\n.suggestions-list:not(:empty) {\n  visibility: visible; /* Add this block */\n}\n\n.suggestion-item, .result-item, .result-item-no-hover {\n  padding: 6px 16px;\n  cursor: pointer;\n  font-size: 14px;\n  border-bottom: 1px solid #e8e8e8;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.suggestion-item:last-child, .result-item:last-child {\n  border-bottom: none;\n}\n\n.suggestion-item:hover, .result-item:hover {\n  background-color: #f1f3f4;\n}\n\n.category-label {\n  color: #616665;\n  font-size: 12px;\n}\n\n.search-input {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
