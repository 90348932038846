import { Button } from "antd";
import type { ButtonProps } from "antd";

export const PrimaryButton: React.FC<
  Omit<ButtonProps, "type"> & { isLeadingIcon?: boolean; children?: React.ReactNode | string }
> = ({ isLeadingIcon = true, title, children, ...props }) => {
  return (
    <Button
      {...props}
      iconPosition={isLeadingIcon ? "start" : "end"}
      type="primary">
      {children ? children : title}
    </Button>
  );
};
export const DashedButton: React.FC<
  Omit<ButtonProps, "type"> & { isLeadingIcon?: boolean; children?: React.ReactNode | string }
> = ({ isLeadingIcon = true, title, children, ...props }) => {
  return (
    <Button
      {...props}
      iconPosition={isLeadingIcon ? "start" : "end"}
      type="dashed">
      {children ? children : title}
    </Button>
  );
};
export const OutlineButton: React.FC<
  Omit<ButtonProps, "type"> & { isLeadingIcon?: boolean; children?: React.ReactNode | string }
> = ({ isLeadingIcon = true, title, children, ...props }) => {
  return (
    <Button
      {...props}
      iconPosition={isLeadingIcon ? "start" : "end"}
      type="default">
      {children ? children : title}
    </Button>
  );
};
export const GhostButton: React.FC<
  Omit<ButtonProps, "type"> & { isLeadingIcon?: boolean; children?: React.ReactNode | string }
> = ({ isLeadingIcon = true, title, children, ...props }) => {
  return (
    <Button
      {...props}
      iconPosition={isLeadingIcon ? "start" : "end"}
      type="text">
      {children ? children : title}
    </Button>
  );
};
export const LinkButton: React.FC<
  Omit<ButtonProps, "type"> & { isLeadingIcon?: boolean; children?: React.ReactNode | string }
> = ({ isLeadingIcon = true, title, children, ...props }) => {
  return (
    <Button
      {...props}
      iconPosition={isLeadingIcon ? "start" : "end"}
      type="link">
      {children ? children : title}
    </Button>
  );
};
