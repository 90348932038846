// @ts-nocheck
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: sentinel.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import Long from "long";

export const protobufPackage = "sentinel";

export enum SeverityLevel {
  INFO = 0,
  WARN = 1,
  ERROR = 2,
  FATAL = 3,
  UNRECOGNIZED = -1,
}

export function severityLevelFromJSON(object: any): SeverityLevel {
  switch (object) {
    case 0:
    case "INFO":
      return SeverityLevel.INFO;
    case 1:
    case "WARN":
      return SeverityLevel.WARN;
    case 2:
    case "ERROR":
      return SeverityLevel.ERROR;
    case 3:
    case "FATAL":
      return SeverityLevel.FATAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SeverityLevel.UNRECOGNIZED;
  }
}

export function severityLevelToJSON(object: SeverityLevel): string {
  switch (object) {
    case SeverityLevel.INFO:
      return "INFO";
    case SeverityLevel.WARN:
      return "WARN";
    case SeverityLevel.ERROR:
      return "ERROR";
    case SeverityLevel.FATAL:
      return "FATAL";
    case SeverityLevel.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Sentinel {
  id: number;
  application_id: number;
  sentinel_name: string;
  date_created: number;
  sentinel_directive: SentinelDirective | undefined;
  state: string;
  action_types: string[];
  severity: number;
  meta: { [key: string]: string };
  sentinel_generation_id: number;
}

export interface Sentinel_MetaEntry {
  key: string;
  value: string;
}

export interface SentinelDirective {
  id: number;
  sentinel_id: number;
  date_created: number;
  directive_body: DirectiveBody | undefined;
  examples: SentinelExample[];
}

export interface DirectiveBody {
  main: string;
  override: string;
}

export interface SentinelExample {
  example_type: string;
  example_body: string;
}

export interface SentinelGeneration {
  id: number;
  date_created: number;
  company_id: number;
  application_id: number;
  application_action_id: number;
  base_prompt: string;
  meta: { [key: string]: string };
  generation_successful: boolean;
}

export interface SentinelGeneration_MetaEntry {
  key: string;
  value: string;
}

function createBaseSentinel(): Sentinel {
  return {
    id: 0,
    application_id: 0,
    sentinel_name: "",
    date_created: 0,
    sentinel_directive: undefined,
    state: "",
    action_types: [],
    severity: 0,
    meta: {},
    sentinel_generation_id: 0,
  };
}

export const Sentinel = {
  encode(message: Sentinel, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.application_id !== 0) {
      writer.uint32(16).int64(message.application_id);
    }
    if (message.sentinel_name !== "") {
      writer.uint32(26).string(message.sentinel_name);
    }
    if (message.date_created !== 0) {
      writer.uint32(32).int64(message.date_created);
    }
    if (message.sentinel_directive !== undefined) {
      SentinelDirective.encode(message.sentinel_directive, writer.uint32(42).fork()).ldelim();
    }
    if (message.state !== "") {
      writer.uint32(50).string(message.state);
    }
    for (const v of message.action_types) {
      writer.uint32(58).string(v!);
    }
    if (message.severity !== 0) {
      writer.uint32(64).int64(message.severity);
    }
    Object.entries(message.meta).forEach(([key, value]) => {
      Sentinel_MetaEntry.encode({ key: key as any, value }, writer.uint32(74).fork()).ldelim();
    });
    if (message.sentinel_generation_id !== 0) {
      writer.uint32(80).int64(message.sentinel_generation_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Sentinel {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSentinel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.application_id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sentinel_name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sentinel_directive = SentinelDirective.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.state = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.action_types.push(reader.string());
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.severity = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          const entry9 = Sentinel_MetaEntry.decode(reader, reader.uint32());
          if (entry9.value !== undefined) {
            message.meta[entry9.key] = entry9.value;
          }
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.sentinel_generation_id = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Sentinel {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      application_id: isSet(object.application_id) ? globalThis.Number(object.application_id) : 0,
      sentinel_name: isSet(object.sentinel_name) ? globalThis.String(object.sentinel_name) : "",
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      sentinel_directive: isSet(object.sentinel_directive)
        ? SentinelDirective.fromJSON(object.sentinel_directive)
        : undefined,
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      action_types: globalThis.Array.isArray(object?.action_types)
        ? object.action_types.map((e: any) => globalThis.String(e))
        : [],
      severity: isSet(object.severity) ? globalThis.Number(object.severity) : 0,
      meta: isObject(object.meta)
        ? Object.entries(object.meta).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      sentinel_generation_id: isSet(object.sentinel_generation_id)
        ? globalThis.Number(object.sentinel_generation_id)
        : 0,
    };
  },

  toJSON(message: Sentinel): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.application_id !== 0) {
      obj.application_id = Math.round(message.application_id);
    }
    if (message.sentinel_name !== "") {
      obj.sentinel_name = message.sentinel_name;
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.sentinel_directive !== undefined) {
      obj.sentinel_directive = SentinelDirective.toJSON(message.sentinel_directive);
    }
    if (message.state !== "") {
      obj.state = message.state;
    }
    if (message.action_types?.length) {
      obj.action_types = message.action_types;
    }
    if (message.severity !== 0) {
      obj.severity = Math.round(message.severity);
    }
    if (message.meta) {
      const entries = Object.entries(message.meta);
      if (entries.length > 0) {
        obj.meta = {};
        entries.forEach(([k, v]) => {
          obj.meta[k] = v;
        });
      }
    }
    if (message.sentinel_generation_id !== 0) {
      obj.sentinel_generation_id = Math.round(message.sentinel_generation_id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Sentinel>, I>>(base?: I): Sentinel {
    return Sentinel.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Sentinel>, I>>(object: I): Sentinel {
    const message = createBaseSentinel();
    message.id = object.id ?? 0;
    message.application_id = object.application_id ?? 0;
    message.sentinel_name = object.sentinel_name ?? "";
    message.date_created = object.date_created ?? 0;
    message.sentinel_directive = (object.sentinel_directive !== undefined && object.sentinel_directive !== null)
      ? SentinelDirective.fromPartial(object.sentinel_directive)
      : undefined;
    message.state = object.state ?? "";
    message.action_types = object.action_types?.map((e) => e) || [];
    message.severity = object.severity ?? 0;
    message.meta = Object.entries(object.meta ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.sentinel_generation_id = object.sentinel_generation_id ?? 0;
    return message;
  },
};

function createBaseSentinel_MetaEntry(): Sentinel_MetaEntry {
  return { key: "", value: "" };
}

export const Sentinel_MetaEntry = {
  encode(message: Sentinel_MetaEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Sentinel_MetaEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSentinel_MetaEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Sentinel_MetaEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: Sentinel_MetaEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Sentinel_MetaEntry>, I>>(base?: I): Sentinel_MetaEntry {
    return Sentinel_MetaEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Sentinel_MetaEntry>, I>>(object: I): Sentinel_MetaEntry {
    const message = createBaseSentinel_MetaEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseSentinelDirective(): SentinelDirective {
  return { id: 0, sentinel_id: 0, date_created: 0, directive_body: undefined, examples: [] };
}

export const SentinelDirective = {
  encode(message: SentinelDirective, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.sentinel_id !== 0) {
      writer.uint32(16).int64(message.sentinel_id);
    }
    if (message.date_created !== 0) {
      writer.uint32(24).int64(message.date_created);
    }
    if (message.directive_body !== undefined) {
      DirectiveBody.encode(message.directive_body, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.examples) {
      SentinelExample.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SentinelDirective {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSentinelDirective();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sentinel_id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.directive_body = DirectiveBody.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.examples.push(SentinelExample.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SentinelDirective {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      sentinel_id: isSet(object.sentinel_id) ? globalThis.Number(object.sentinel_id) : 0,
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      directive_body: isSet(object.directive_body) ? DirectiveBody.fromJSON(object.directive_body) : undefined,
      examples: globalThis.Array.isArray(object?.examples)
        ? object.examples.map((e: any) => SentinelExample.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SentinelDirective): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.sentinel_id !== 0) {
      obj.sentinel_id = Math.round(message.sentinel_id);
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.directive_body !== undefined) {
      obj.directive_body = DirectiveBody.toJSON(message.directive_body);
    }
    if (message.examples?.length) {
      obj.examples = message.examples.map((e) => SentinelExample.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SentinelDirective>, I>>(base?: I): SentinelDirective {
    return SentinelDirective.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SentinelDirective>, I>>(object: I): SentinelDirective {
    const message = createBaseSentinelDirective();
    message.id = object.id ?? 0;
    message.sentinel_id = object.sentinel_id ?? 0;
    message.date_created = object.date_created ?? 0;
    message.directive_body = (object.directive_body !== undefined && object.directive_body !== null)
      ? DirectiveBody.fromPartial(object.directive_body)
      : undefined;
    message.examples = object.examples?.map((e) => SentinelExample.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDirectiveBody(): DirectiveBody {
  return { main: "", override: "" };
}

export const DirectiveBody = {
  encode(message: DirectiveBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.main !== "") {
      writer.uint32(10).string(message.main);
    }
    if (message.override !== "") {
      writer.uint32(18).string(message.override);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DirectiveBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDirectiveBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.main = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.override = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DirectiveBody {
    return {
      main: isSet(object.main) ? globalThis.String(object.main) : "",
      override: isSet(object.override) ? globalThis.String(object.override) : "",
    };
  },

  toJSON(message: DirectiveBody): unknown {
    const obj: any = {};
    if (message.main !== "") {
      obj.main = message.main;
    }
    if (message.override !== "") {
      obj.override = message.override;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DirectiveBody>, I>>(base?: I): DirectiveBody {
    return DirectiveBody.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DirectiveBody>, I>>(object: I): DirectiveBody {
    const message = createBaseDirectiveBody();
    message.main = object.main ?? "";
    message.override = object.override ?? "";
    return message;
  },
};

function createBaseSentinelExample(): SentinelExample {
  return { example_type: "", example_body: "" };
}

export const SentinelExample = {
  encode(message: SentinelExample, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.example_type !== "") {
      writer.uint32(10).string(message.example_type);
    }
    if (message.example_body !== "") {
      writer.uint32(18).string(message.example_body);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SentinelExample {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSentinelExample();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.example_type = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.example_body = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SentinelExample {
    return {
      example_type: isSet(object.example_type) ? globalThis.String(object.example_type) : "",
      example_body: isSet(object.example_body) ? globalThis.String(object.example_body) : "",
    };
  },

  toJSON(message: SentinelExample): unknown {
    const obj: any = {};
    if (message.example_type !== "") {
      obj.example_type = message.example_type;
    }
    if (message.example_body !== "") {
      obj.example_body = message.example_body;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SentinelExample>, I>>(base?: I): SentinelExample {
    return SentinelExample.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SentinelExample>, I>>(object: I): SentinelExample {
    const message = createBaseSentinelExample();
    message.example_type = object.example_type ?? "";
    message.example_body = object.example_body ?? "";
    return message;
  },
};

function createBaseSentinelGeneration(): SentinelGeneration {
  return {
    id: 0,
    date_created: 0,
    company_id: 0,
    application_id: 0,
    application_action_id: 0,
    base_prompt: "",
    meta: {},
    generation_successful: false,
  };
}

export const SentinelGeneration = {
  encode(message: SentinelGeneration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.date_created !== 0) {
      writer.uint32(16).int64(message.date_created);
    }
    if (message.company_id !== 0) {
      writer.uint32(24).int64(message.company_id);
    }
    if (message.application_id !== 0) {
      writer.uint32(32).int64(message.application_id);
    }
    if (message.application_action_id !== 0) {
      writer.uint32(40).int64(message.application_action_id);
    }
    if (message.base_prompt !== "") {
      writer.uint32(50).string(message.base_prompt);
    }
    Object.entries(message.meta).forEach(([key, value]) => {
      SentinelGeneration_MetaEntry.encode({ key: key as any, value }, writer.uint32(58).fork()).ldelim();
    });
    if (message.generation_successful !== false) {
      writer.uint32(64).bool(message.generation_successful);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SentinelGeneration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSentinelGeneration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.company_id = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.application_id = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.application_action_id = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.base_prompt = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          const entry7 = SentinelGeneration_MetaEntry.decode(reader, reader.uint32());
          if (entry7.value !== undefined) {
            message.meta[entry7.key] = entry7.value;
          }
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.generation_successful = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SentinelGeneration {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      company_id: isSet(object.company_id) ? globalThis.Number(object.company_id) : 0,
      application_id: isSet(object.application_id) ? globalThis.Number(object.application_id) : 0,
      application_action_id: isSet(object.application_action_id) ? globalThis.Number(object.application_action_id) : 0,
      base_prompt: isSet(object.base_prompt) ? globalThis.String(object.base_prompt) : "",
      meta: isObject(object.meta)
        ? Object.entries(object.meta).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      generation_successful: isSet(object.generation_successful)
        ? globalThis.Boolean(object.generation_successful)
        : false,
    };
  },

  toJSON(message: SentinelGeneration): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.company_id !== 0) {
      obj.company_id = Math.round(message.company_id);
    }
    if (message.application_id !== 0) {
      obj.application_id = Math.round(message.application_id);
    }
    if (message.application_action_id !== 0) {
      obj.application_action_id = Math.round(message.application_action_id);
    }
    if (message.base_prompt !== "") {
      obj.base_prompt = message.base_prompt;
    }
    if (message.meta) {
      const entries = Object.entries(message.meta);
      if (entries.length > 0) {
        obj.meta = {};
        entries.forEach(([k, v]) => {
          obj.meta[k] = v;
        });
      }
    }
    if (message.generation_successful !== false) {
      obj.generation_successful = message.generation_successful;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SentinelGeneration>, I>>(base?: I): SentinelGeneration {
    return SentinelGeneration.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SentinelGeneration>, I>>(object: I): SentinelGeneration {
    const message = createBaseSentinelGeneration();
    message.id = object.id ?? 0;
    message.date_created = object.date_created ?? 0;
    message.company_id = object.company_id ?? 0;
    message.application_id = object.application_id ?? 0;
    message.application_action_id = object.application_action_id ?? 0;
    message.base_prompt = object.base_prompt ?? "";
    message.meta = Object.entries(object.meta ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.generation_successful = object.generation_successful ?? false;
    return message;
  },
};

function createBaseSentinelGeneration_MetaEntry(): SentinelGeneration_MetaEntry {
  return { key: "", value: "" };
}

export const SentinelGeneration_MetaEntry = {
  encode(message: SentinelGeneration_MetaEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SentinelGeneration_MetaEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSentinelGeneration_MetaEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SentinelGeneration_MetaEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: SentinelGeneration_MetaEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SentinelGeneration_MetaEntry>, I>>(base?: I): SentinelGeneration_MetaEntry {
    return SentinelGeneration_MetaEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SentinelGeneration_MetaEntry>, I>>(object: I): SentinelGeneration_MetaEntry {
    const message = createBaseSentinelGeneration_MetaEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
