import React, { useEffect, useState } from "react";
import { Table, TableColumnsType, Typography } from "antd";
import { ReportsService } from "../../services/ReportsService";
import moment from "moment";
import { usePermissions } from "../../PermissionsProvider";
import { Session } from "../../model_gen/session";
import { Modal } from "antd";
import SessionOverview from "../Session/SessionOverview";
import { Turn } from "../../model_gen/chat";
import { Company } from "../../model_gen/company";
import { CompanyService } from "../../services/CompanyService";
import { useWebSocket } from "../../utils/Websockets";
import { TablePaginationConfig } from "antd/es/table";
import { CopyOutlined } from "@ant-design/icons";
import { Button, message } from "antd";

const { Text } = Typography;

interface FallbacksProps {
  intentId?: number;
}

const Fallbacks: React.FC<FallbacksProps> = ({ intentId }) => {
  const { apiKey } = usePermissions();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [requests, setRequests] = useState<Turn[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalFallbacks, setTotalFallbacks] = useState(0);
  const pageSize = 5;
  const [selectedSession, setSelectedSession] = useState<Session | null>(null);
  const [sessionModalVisible, setSessionModalVisible] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [company, setCompany] = useState<Company | null>(null);
  const [selectedRequestId, setSelectedRequestId] = useState<string | null>(
    null
  );

  useEffect(() => {
    fetchCompany();
  }, [apiKey]);

  const fetchCompany = async () => {
    setIsLoading(true);
    CompanyService.getCompany(setIsLoading, apiKey).subscribe(
      (company: Company) => {
        setCompany(company);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const fetchFallbacks = (page: number) => {
    const offset = (page - 1) * pageSize;
    if (intentId) {
      ReportsService.getFallbacksByIntent(
        intentId,
        offset,
        pageSize,
        setIsLoading,
        apiKey
      ).subscribe(
        (response: { fallbacks: Turn[]; total: number }) => {
          setRequests(response.fallbacks);
          setTotalFallbacks(response.total);
        },
        (error: any) => {
          console.error(error);
        }
      );
    } else {
      ReportsService.getFallbacks(
        offset,
        pageSize,
        setIsLoading,
        apiKey
      ).subscribe(
        (response: { fallbacks: Turn[]; total: number }) => {
          setRequests(response.fallbacks);
          setTotalFallbacks(response.total);
        },
        (error: any) => {
          console.error(error);
        }
      );
    }
  };

  useEffect(() => {
    fetchFallbacks(currentPage);
  }, [apiKey, currentPage, intentId]);

  const handleFallback = (data: any) => {
    const newFallback: Turn = JSON.parse(data.event_data);
    setRequests((prevRequests) => [
      newFallback,
      ...prevRequests.slice(0, pageSize - 1),
    ]);
    setTotalFallbacks((prevTotal) => prevTotal + 1);
  };

  useWebSocket({
    key: company?.id,
    eventType: "MODEL_FALLBACK",
    onMessage: handleFallback,
  });

  const handleViewFullSession = (session: Session) => {
    setSelectedSession(session);
    setSessionModalVisible(true);
    setRefreshTrigger((prev) => prev + 1);
  };

  const handleSessionModalClose = () => {
    setSelectedSession(null);
    setSessionModalVisible(false);
  };

  const handleRowClick = (turn: Turn) => {
    setSelectedRequestId(turn.request.request_id);
    handleViewFullSession(
      Session.fromPartial({
        application_id: turn.application.id,
        session_id: turn.request.session_id,
      })
    );
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }
  };

  const copyRequestId = (requestId: string) => {
    navigator.clipboard.writeText(requestId);
    message.success("Request ID copied to clipboard");
  };

  const requestColumns: TableColumnsType<Turn> = [
    {
      title: "Date",
      dataIndex: "date_created",
      key: "date_created",
      render: (_, request: Turn) =>
        moment(request.request.date_created).format("YYYY-MM-DD hh:mm:ss A"),
    },
    {
      title: "Application",
      render: (_, request: Turn) => (
        <Text>{request.application.application_name}</Text>
      ),
      key: "application",
    },
    {
      title: "Intent",
      render: (_, request: Turn) => <Text>{request.request.action_type}</Text>,
      key: "intent",
    },
    {
      title: "Session",
      render: (_, request: Turn) => <Text>{request.request.session_id}</Text>,
      key: "session",
    },
    {
      title: "Request ID",
      key: "request_id",
      render: (_, request: Turn) => {
        const shortId = request.request.request_id.split("-")[0];
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Text>{shortId}</Text>
            <Button
              type="text"
              icon={<CopyOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                copyRequestId(request.request.request_id);
              }}
            />
          </div>
        );
      },
      width: "135px",
    },
    {
      title: "Request Model",
      render: (_, request: Turn) => <Text>{request.request.params.model}</Text>,
      key: "request_model",
    },
    {
      title: "Response Model",
      render: (_, request: Turn) => <Text>{request.response.model}</Text>,
      key: "response_model",
    },
    {
      title: "Fallback Reason",
      key: "reason",
      render: (_, request: Turn) => (
        <Text>
          {request.response.fallback_reason?.includes("PROACTIVE")
            ? "PROACTIVE"
            : "ERROR"}
        </Text>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey={(request: Turn) => request.request.request_id}
        columns={requestColumns}
        dataSource={requests}
        pagination={
          totalFallbacks > pageSize
            ? {
                current: currentPage,
                pageSize: pageSize,
                total: totalFallbacks,
                showSizeChanger: false,
              }
            : false
        }
        loading={isLoading}
        onChange={handleTableChange}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
          style: { cursor: "pointer" },
        })}
      />
      <Modal
        title="Session Details"
        open={sessionModalVisible}
        onCancel={handleSessionModalClose}
        footer={null}
        width={1000}>
        {selectedSession && (
          <SessionOverview
            sessionId={selectedSession.session_id}
            applicationId={selectedSession.application_id.toString()}
            refreshTrigger={refreshTrigger}
            requestId={selectedRequestId || undefined}
          />
        )}
      </Modal>
    </>
  );
};
export default Fallbacks;
