import GeneralService from "./GeneralService";
import {Component} from "react";


export class ConfigService extends Component {
  public static getModels(loadingHandler: any, apiKey: string) {
    return GeneralService.get("/config/models/full", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
}

