// @ts-nocheck
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: chat.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Application } from "./application";
import { KeyMap } from "./key";
import { RequestTimingMetric } from "./metric";
import { Sentinel } from "./sentinel";
import Long from "long";

export const protobufPackage = "chat";

export enum EvaluationProvider {
  GROQ = 0,
  OPENAI = 1,
  UNRECOGNIZED = -1,
}

export function evaluationProviderFromJSON(object: any): EvaluationProvider {
  switch (object) {
    case 0:
    case "GROQ":
      return EvaluationProvider.GROQ;
    case 1:
    case "OPENAI":
      return EvaluationProvider.OPENAI;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EvaluationProvider.UNRECOGNIZED;
  }
}

export function evaluationProviderToJSON(object: EvaluationProvider): string {
  switch (object) {
    case EvaluationProvider.GROQ:
      return "GROQ";
    case EvaluationProvider.OPENAI:
      return "OPENAI";
    case EvaluationProvider.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EvaluationContentType {
  MESSAGE = 0,
  TEXT = 1,
  PARTIAL = 2,
  UNRECOGNIZED = -1,
}

export function evaluationContentTypeFromJSON(object: any): EvaluationContentType {
  switch (object) {
    case 0:
    case "MESSAGE":
      return EvaluationContentType.MESSAGE;
    case 1:
    case "TEXT":
      return EvaluationContentType.TEXT;
    case 2:
    case "PARTIAL":
      return EvaluationContentType.PARTIAL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EvaluationContentType.UNRECOGNIZED;
  }
}

export function evaluationContentTypeToJSON(object: EvaluationContentType): string {
  switch (object) {
    case EvaluationContentType.MESSAGE:
      return "MESSAGE";
    case EvaluationContentType.TEXT:
      return "TEXT";
    case EvaluationContentType.PARTIAL:
      return "PARTIAL";
    case EvaluationContentType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ChatCompletionRequest {
  params: ChatCompletionParams | undefined;
  application_ref_name: string;
  session_id: string;
  reference_id: string;
  action_type: string;
  company_id?: number | undefined;
  apply_corrections: boolean;
  id: number;
  date_created?: number | undefined;
  request_id?: string | undefined;
  evaluation_enabled: boolean;
  auth_info: ChatCompletionAuth | undefined;
  return_evaluation: boolean;
  error: string;
  auth_keys: KeyMap | undefined;
  inference_location: string;
  context_retrieval_enabled: boolean;
  context_query: string;
  context: string;
  return_request: boolean;
  fallback_model: string;
  user_id: string;
  assistant: boolean;
  client_params: ClientParams | undefined;
  metadata: { [key: string]: string };
  safe_mode: boolean;
}

export interface ChatCompletionRequest_MetadataEntry {
  key: string;
  value: string;
}

export interface ClientParams {
  base_url: string;
  default_headers: { [key: string]: string };
  default_query: { [key: string]: string };
}

export interface ClientParams_DefaultHeadersEntry {
  key: string;
  value: string;
}

export interface ClientParams_DefaultQueryEntry {
  key: string;
  value: string;
}

export interface ChatCompletionAuth {
  openai_api_key: string;
  groq_api_key: string;
  override_api_key: string;
  anthropic_api_key: string;
  cerebras_api_key: string;
}

export interface ChatCompletionParams {
  messages: ChatMessage[];
  model: string;
  max_tokens: number;
  response_format: ResponseFormat | undefined;
  temperature: number;
  top_p: number;
  stop: string;
  logprobs: boolean;
  top_logprobs: number;
  n: number;
  seed: number;
  stream: boolean;
  stream_options: StreamOptions | undefined;
  logit_bias: { [key: string]: number };
  presence_penalty: number;
  frequency_penalty: number;
  user: string;
  tools: Tool[];
  tool_choice: string;
  parallel_tool_calls: boolean;
}

export interface ChatCompletionParams_LogitBiasEntry {
  key: string;
  value: number;
}

export interface ResponseFormat {
  type: string;
  json_schema?: JSONSchema | undefined;
}

export interface StreamOptions {
  include_usage: boolean;
}

export interface Tool {
  type: string;
  function: Function | undefined;
}

export interface Function {
  name: string;
  description: string;
  parameters: FunctionParameters | undefined;
  strict: boolean;
}

export interface FunctionParameters {
  type: string;
  description: string;
  properties: { [key: string]: Property };
  required: string[];
  additional_properties: boolean;
}

export interface FunctionParameters_PropertiesEntry {
  key: string;
  value: Property | undefined;
}

export interface Property {
  type: string;
  description: string;
}

export interface JSONSchema {
  name: string;
  strict: boolean;
  schema: { [key: string]: string };
}

export interface JSONSchema_SchemaEntry {
  key: string;
  value: string;
}

export interface ChatCompletionResponse {
  id: string;
  response_id: number;
  object: string;
  created: number;
  model: string;
  system_fingerprint: string;
  choices: ChatCompletionChoice[];
  usage: ChatCompletionUsage | undefined;
  request_id: string;
  evaluate_response?: EvaluateResponse | undefined;
  correction_applied: boolean;
  first_token_time: number;
  response_time: number;
  /** TODO: remove */
  chat_completion_request?: ChatCompletionRequest | undefined;
  service_tier?: string | undefined;
  input_safety_score: number;
  fallback_reason: string;
}

export interface ChatCompletionChoice {
  index: number;
  message: ChatCompletionMessage | undefined;
  logprobs: LogProbs | undefined;
  finish_reason: string;
  is_correction: boolean;
}

export interface ChatMessage {
  role: string;
  content?: string | undefined;
  name?: string | undefined;
  tool_calls: ToolCall[];
  tool_call_id?: string | undefined;
}

export interface FunctionCall {
  arguments: string;
  name: string;
}

export interface ChatCompletionMessage {
  role: string;
  content: string;
  function_call?: FunctionCall | undefined;
  tool_calls: ToolCall[];
  refusal: string;
}

export interface ChatCompletionUsage {
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
  cost: number;
  model: string;
  completion_tokens_details: CompletionTokensDetails | undefined;
}

export interface CompletionTokensDetails {
  reasoning_tokens: number;
}

export interface ChatCompletionChunk {
  id: string;
  choices: ChunkChoice[];
  created: number;
  model: string;
  object: string;
  system_fingerprint: string;
  usage: ChatCompletionUsage | undefined;
  evaluate_response?: EvaluateResponse | undefined;
  correction_applied: boolean;
  service_tier?: string | undefined;
  input_safety_score: number;
  request_id: string;
  fallback_reason: string;
}

export interface ChunkChoice {
  delta: ChoiceDelta | undefined;
  finish_reason: string;
  index: number;
  logprobs: LogProbs | undefined;
  is_correction: boolean;
}

export interface ChoiceDelta {
  content: string;
  role: string;
  tool_calls: ToolCall[];
  function_call: FunctionCall | undefined;
  refusal: string;
}

export interface ToolCall {
  id: string;
  type: string;
  function: ToolCallFunction | undefined;
  index?: number | undefined;
}

export interface ToolCallFunction {
  name: string;
  arguments: string;
}

export interface LogProbs {
  content: LogPropsContent[];
  refusal: LogPropsContent[];
}

export interface LogPropsContent {
  token: string;
  logprob: number;
  bytes: number[];
  top_logprobs: TopLogProbs[];
}

export interface TopLogProbs {
  token: string;
  logprob: number;
  bytes: number[];
}

export interface ChatStorageRequest {
  chat_completion_request: ChatCompletionRequest | undefined;
  chat_completion_response: ChatCompletionResponse | undefined;
  evaluate_request: EvaluateRequest | undefined;
  timing_metrics: RequestTimingMetric | undefined;
}

export interface EvaluateRequest {
  id: number;
  date_created: number;
  application_id: number;
  application_ref_name: string;
  session_id: string;
  reference_id: string;
  action_type: string;
  evaluation_content_type: EvaluationContentType;
  /** TODO: remove */
  eval_results_set?: EvaluateResponse | undefined;
  company_id: number;
  evaluation_context: string;
  text_content: string;
  message_content: ChatMessage[];
  request_id: string;
  sentinel_id: number;
  fault_description: string;
  /** TODO: Make not optional */
  chat_completion_request?:
    | ChatCompletionRequest
    | undefined;
  /** TODO: Make not optional */
  chat_completion_response?: ChatCompletionResponse | undefined;
  timeout: number;
  partial: string;
  stream: boolean;
  apply_corrections: boolean;
  timing_metrics: RequestTimingMetric | undefined;
  tools: Tool[];
}

export interface EvaluateResult {
  id: number;
  status: string;
  description: string;
  confidence: number;
  meta: { [key: string]: string };
  sentinel_id: number;
  eval_time: number;
  date_created: number;
  usage: ChatCompletionUsage | undefined;
  sentinel_name: string;
  severity: number;
  correction: string;
}

export interface EvaluateResult_MetaEntry {
  key: string;
  value: string;
}

export interface EvaluateResponse {
  application_id: number;
  session_id: string;
  request_id: number;
  evaluation_results: EvaluateResult[];
  evaluation_request_id: string;
  evaluate_summary: string;
  evaluation_time: number;
  reference_id: string;
}

export interface Turn {
  request: ChatCompletionRequest | undefined;
  response: ChatCompletionResponse | undefined;
  eval_request: EvaluateRequest | undefined;
  application:
    | Application
    | undefined;
  /** TODO: add EvaluateResponse */
  first: boolean;
  last: boolean;
}

export interface SessionMessage {
  id: number;
  application_id: number;
  company_id: number;
  application_action_id: number;
  session_id: string;
  request_id: string;
  role: string;
  message: ChatMessage | undefined;
  instructions: string;
  date_created: number;
}

export interface BacktestEvaluateRequest {
  id: number;
  date_created: number;
  run_id: number;
  company_id: number;
  application_id: number;
  backtest_application_id: number;
  session_id: string;
  request_id: string;
  inference_request_id: string;
  action_type: string;
  request_messages: ChatMessage[];
  response_message: ChatCompletionMessage | undefined;
  sentinels: Sentinel[];
  tools: Tool[];
}

function createBaseChatCompletionRequest(): ChatCompletionRequest {
  return {
    params: undefined,
    application_ref_name: "",
    session_id: "",
    reference_id: "",
    action_type: "",
    company_id: undefined,
    apply_corrections: false,
    id: 0,
    date_created: undefined,
    request_id: undefined,
    evaluation_enabled: false,
    auth_info: undefined,
    return_evaluation: false,
    error: "",
    auth_keys: undefined,
    inference_location: "",
    context_retrieval_enabled: false,
    context_query: "",
    context: "",
    return_request: false,
    fallback_model: "",
    user_id: "",
    assistant: false,
    client_params: undefined,
    metadata: {},
    safe_mode: false,
  };
}

export const ChatCompletionRequest = {
  encode(message: ChatCompletionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.params !== undefined) {
      ChatCompletionParams.encode(message.params, writer.uint32(10).fork()).ldelim();
    }
    if (message.application_ref_name !== "") {
      writer.uint32(18).string(message.application_ref_name);
    }
    if (message.session_id !== "") {
      writer.uint32(26).string(message.session_id);
    }
    if (message.reference_id !== "") {
      writer.uint32(34).string(message.reference_id);
    }
    if (message.action_type !== "") {
      writer.uint32(42).string(message.action_type);
    }
    if (message.company_id !== undefined) {
      writer.uint32(48).int64(message.company_id);
    }
    if (message.apply_corrections !== false) {
      writer.uint32(56).bool(message.apply_corrections);
    }
    if (message.id !== 0) {
      writer.uint32(64).int64(message.id);
    }
    if (message.date_created !== undefined) {
      writer.uint32(72).int64(message.date_created);
    }
    if (message.request_id !== undefined) {
      writer.uint32(82).string(message.request_id);
    }
    if (message.evaluation_enabled !== false) {
      writer.uint32(88).bool(message.evaluation_enabled);
    }
    if (message.auth_info !== undefined) {
      ChatCompletionAuth.encode(message.auth_info, writer.uint32(98).fork()).ldelim();
    }
    if (message.return_evaluation !== false) {
      writer.uint32(104).bool(message.return_evaluation);
    }
    if (message.error !== "") {
      writer.uint32(114).string(message.error);
    }
    if (message.auth_keys !== undefined) {
      KeyMap.encode(message.auth_keys, writer.uint32(122).fork()).ldelim();
    }
    if (message.inference_location !== "") {
      writer.uint32(130).string(message.inference_location);
    }
    if (message.context_retrieval_enabled !== false) {
      writer.uint32(136).bool(message.context_retrieval_enabled);
    }
    if (message.context_query !== "") {
      writer.uint32(146).string(message.context_query);
    }
    if (message.context !== "") {
      writer.uint32(154).string(message.context);
    }
    if (message.return_request !== false) {
      writer.uint32(160).bool(message.return_request);
    }
    if (message.fallback_model !== "") {
      writer.uint32(170).string(message.fallback_model);
    }
    if (message.user_id !== "") {
      writer.uint32(178).string(message.user_id);
    }
    if (message.assistant !== false) {
      writer.uint32(184).bool(message.assistant);
    }
    if (message.client_params !== undefined) {
      ClientParams.encode(message.client_params, writer.uint32(194).fork()).ldelim();
    }
    Object.entries(message.metadata).forEach(([key, value]) => {
      ChatCompletionRequest_MetadataEntry.encode({ key: key as any, value }, writer.uint32(202).fork()).ldelim();
    });
    if (message.safe_mode !== false) {
      writer.uint32(208).bool(message.safe_mode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatCompletionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatCompletionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.params = ChatCompletionParams.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.application_ref_name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.session_id = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.reference_id = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.action_type = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.company_id = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.apply_corrections = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.request_id = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.evaluation_enabled = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.auth_info = ChatCompletionAuth.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.return_evaluation = reader.bool();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.error = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.auth_keys = KeyMap.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.inference_location = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.context_retrieval_enabled = reader.bool();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.context_query = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.context = reader.string();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.return_request = reader.bool();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.fallback_model = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.user_id = reader.string();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.assistant = reader.bool();
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.client_params = ClientParams.decode(reader, reader.uint32());
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          const entry25 = ChatCompletionRequest_MetadataEntry.decode(reader, reader.uint32());
          if (entry25.value !== undefined) {
            message.metadata[entry25.key] = entry25.value;
          }
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.safe_mode = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatCompletionRequest {
    return {
      params: isSet(object.params) ? ChatCompletionParams.fromJSON(object.params) : undefined,
      application_ref_name: isSet(object.application_ref_name) ? globalThis.String(object.application_ref_name) : "",
      session_id: isSet(object.session_id) ? globalThis.String(object.session_id) : "",
      reference_id: isSet(object.reference_id) ? globalThis.String(object.reference_id) : "",
      action_type: isSet(object.action_type) ? globalThis.String(object.action_type) : "",
      company_id: isSet(object.company_id) ? globalThis.Number(object.company_id) : undefined,
      apply_corrections: isSet(object.apply_corrections) ? globalThis.Boolean(object.apply_corrections) : false,
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : undefined,
      request_id: isSet(object.request_id) ? globalThis.String(object.request_id) : undefined,
      evaluation_enabled: isSet(object.evaluation_enabled) ? globalThis.Boolean(object.evaluation_enabled) : false,
      auth_info: isSet(object.auth_info) ? ChatCompletionAuth.fromJSON(object.auth_info) : undefined,
      return_evaluation: isSet(object.return_evaluation) ? globalThis.Boolean(object.return_evaluation) : false,
      error: isSet(object.error) ? globalThis.String(object.error) : "",
      auth_keys: isSet(object.auth_keys) ? KeyMap.fromJSON(object.auth_keys) : undefined,
      inference_location: isSet(object.inference_location) ? globalThis.String(object.inference_location) : "",
      context_retrieval_enabled: isSet(object.context_retrieval_enabled)
        ? globalThis.Boolean(object.context_retrieval_enabled)
        : false,
      context_query: isSet(object.context_query) ? globalThis.String(object.context_query) : "",
      context: isSet(object.context) ? globalThis.String(object.context) : "",
      return_request: isSet(object.return_request) ? globalThis.Boolean(object.return_request) : false,
      fallback_model: isSet(object.fallback_model) ? globalThis.String(object.fallback_model) : "",
      user_id: isSet(object.user_id) ? globalThis.String(object.user_id) : "",
      assistant: isSet(object.assistant) ? globalThis.Boolean(object.assistant) : false,
      client_params: isSet(object.client_params) ? ClientParams.fromJSON(object.client_params) : undefined,
      metadata: isObject(object.metadata)
        ? Object.entries(object.metadata).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      safe_mode: isSet(object.safe_mode) ? globalThis.Boolean(object.safe_mode) : false,
    };
  },

  toJSON(message: ChatCompletionRequest): unknown {
    const obj: any = {};
    if (message.params !== undefined) {
      obj.params = ChatCompletionParams.toJSON(message.params);
    }
    if (message.application_ref_name !== "") {
      obj.application_ref_name = message.application_ref_name;
    }
    if (message.session_id !== "") {
      obj.session_id = message.session_id;
    }
    if (message.reference_id !== "") {
      obj.reference_id = message.reference_id;
    }
    if (message.action_type !== "") {
      obj.action_type = message.action_type;
    }
    if (message.company_id !== undefined) {
      obj.company_id = Math.round(message.company_id);
    }
    if (message.apply_corrections !== false) {
      obj.apply_corrections = message.apply_corrections;
    }
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.date_created !== undefined) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.request_id !== undefined) {
      obj.request_id = message.request_id;
    }
    if (message.evaluation_enabled !== false) {
      obj.evaluation_enabled = message.evaluation_enabled;
    }
    if (message.auth_info !== undefined) {
      obj.auth_info = ChatCompletionAuth.toJSON(message.auth_info);
    }
    if (message.return_evaluation !== false) {
      obj.return_evaluation = message.return_evaluation;
    }
    if (message.error !== "") {
      obj.error = message.error;
    }
    if (message.auth_keys !== undefined) {
      obj.auth_keys = KeyMap.toJSON(message.auth_keys);
    }
    if (message.inference_location !== "") {
      obj.inference_location = message.inference_location;
    }
    if (message.context_retrieval_enabled !== false) {
      obj.context_retrieval_enabled = message.context_retrieval_enabled;
    }
    if (message.context_query !== "") {
      obj.context_query = message.context_query;
    }
    if (message.context !== "") {
      obj.context = message.context;
    }
    if (message.return_request !== false) {
      obj.return_request = message.return_request;
    }
    if (message.fallback_model !== "") {
      obj.fallback_model = message.fallback_model;
    }
    if (message.user_id !== "") {
      obj.user_id = message.user_id;
    }
    if (message.assistant !== false) {
      obj.assistant = message.assistant;
    }
    if (message.client_params !== undefined) {
      obj.client_params = ClientParams.toJSON(message.client_params);
    }
    if (message.metadata) {
      const entries = Object.entries(message.metadata);
      if (entries.length > 0) {
        obj.metadata = {};
        entries.forEach(([k, v]) => {
          obj.metadata[k] = v;
        });
      }
    }
    if (message.safe_mode !== false) {
      obj.safe_mode = message.safe_mode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatCompletionRequest>, I>>(base?: I): ChatCompletionRequest {
    return ChatCompletionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatCompletionRequest>, I>>(object: I): ChatCompletionRequest {
    const message = createBaseChatCompletionRequest();
    message.params = (object.params !== undefined && object.params !== null)
      ? ChatCompletionParams.fromPartial(object.params)
      : undefined;
    message.application_ref_name = object.application_ref_name ?? "";
    message.session_id = object.session_id ?? "";
    message.reference_id = object.reference_id ?? "";
    message.action_type = object.action_type ?? "";
    message.company_id = object.company_id ?? undefined;
    message.apply_corrections = object.apply_corrections ?? false;
    message.id = object.id ?? 0;
    message.date_created = object.date_created ?? undefined;
    message.request_id = object.request_id ?? undefined;
    message.evaluation_enabled = object.evaluation_enabled ?? false;
    message.auth_info = (object.auth_info !== undefined && object.auth_info !== null)
      ? ChatCompletionAuth.fromPartial(object.auth_info)
      : undefined;
    message.return_evaluation = object.return_evaluation ?? false;
    message.error = object.error ?? "";
    message.auth_keys = (object.auth_keys !== undefined && object.auth_keys !== null)
      ? KeyMap.fromPartial(object.auth_keys)
      : undefined;
    message.inference_location = object.inference_location ?? "";
    message.context_retrieval_enabled = object.context_retrieval_enabled ?? false;
    message.context_query = object.context_query ?? "";
    message.context = object.context ?? "";
    message.return_request = object.return_request ?? false;
    message.fallback_model = object.fallback_model ?? "";
    message.user_id = object.user_id ?? "";
    message.assistant = object.assistant ?? false;
    message.client_params = (object.client_params !== undefined && object.client_params !== null)
      ? ClientParams.fromPartial(object.client_params)
      : undefined;
    message.metadata = Object.entries(object.metadata ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.safe_mode = object.safe_mode ?? false;
    return message;
  },
};

function createBaseChatCompletionRequest_MetadataEntry(): ChatCompletionRequest_MetadataEntry {
  return { key: "", value: "" };
}

export const ChatCompletionRequest_MetadataEntry = {
  encode(message: ChatCompletionRequest_MetadataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatCompletionRequest_MetadataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatCompletionRequest_MetadataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatCompletionRequest_MetadataEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ChatCompletionRequest_MetadataEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatCompletionRequest_MetadataEntry>, I>>(
    base?: I,
  ): ChatCompletionRequest_MetadataEntry {
    return ChatCompletionRequest_MetadataEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatCompletionRequest_MetadataEntry>, I>>(
    object: I,
  ): ChatCompletionRequest_MetadataEntry {
    const message = createBaseChatCompletionRequest_MetadataEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseClientParams(): ClientParams {
  return { base_url: "", default_headers: {}, default_query: {} };
}

export const ClientParams = {
  encode(message: ClientParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.base_url !== "") {
      writer.uint32(10).string(message.base_url);
    }
    Object.entries(message.default_headers).forEach(([key, value]) => {
      ClientParams_DefaultHeadersEntry.encode({ key: key as any, value }, writer.uint32(18).fork()).ldelim();
    });
    Object.entries(message.default_query).forEach(([key, value]) => {
      ClientParams_DefaultQueryEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientParams {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.base_url = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          const entry2 = ClientParams_DefaultHeadersEntry.decode(reader, reader.uint32());
          if (entry2.value !== undefined) {
            message.default_headers[entry2.key] = entry2.value;
          }
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = ClientParams_DefaultQueryEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.default_query[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClientParams {
    return {
      base_url: isSet(object.base_url) ? globalThis.String(object.base_url) : "",
      default_headers: isObject(object.default_headers)
        ? Object.entries(object.default_headers).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      default_query: isObject(object.default_query)
        ? Object.entries(object.default_query).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ClientParams): unknown {
    const obj: any = {};
    if (message.base_url !== "") {
      obj.base_url = message.base_url;
    }
    if (message.default_headers) {
      const entries = Object.entries(message.default_headers);
      if (entries.length > 0) {
        obj.default_headers = {};
        entries.forEach(([k, v]) => {
          obj.default_headers[k] = v;
        });
      }
    }
    if (message.default_query) {
      const entries = Object.entries(message.default_query);
      if (entries.length > 0) {
        obj.default_query = {};
        entries.forEach(([k, v]) => {
          obj.default_query[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClientParams>, I>>(base?: I): ClientParams {
    return ClientParams.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClientParams>, I>>(object: I): ClientParams {
    const message = createBaseClientParams();
    message.base_url = object.base_url ?? "";
    message.default_headers = Object.entries(object.default_headers ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.default_query = Object.entries(object.default_query ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseClientParams_DefaultHeadersEntry(): ClientParams_DefaultHeadersEntry {
  return { key: "", value: "" };
}

export const ClientParams_DefaultHeadersEntry = {
  encode(message: ClientParams_DefaultHeadersEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientParams_DefaultHeadersEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientParams_DefaultHeadersEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClientParams_DefaultHeadersEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ClientParams_DefaultHeadersEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClientParams_DefaultHeadersEntry>, I>>(
    base?: I,
  ): ClientParams_DefaultHeadersEntry {
    return ClientParams_DefaultHeadersEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClientParams_DefaultHeadersEntry>, I>>(
    object: I,
  ): ClientParams_DefaultHeadersEntry {
    const message = createBaseClientParams_DefaultHeadersEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseClientParams_DefaultQueryEntry(): ClientParams_DefaultQueryEntry {
  return { key: "", value: "" };
}

export const ClientParams_DefaultQueryEntry = {
  encode(message: ClientParams_DefaultQueryEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientParams_DefaultQueryEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientParams_DefaultQueryEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ClientParams_DefaultQueryEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ClientParams_DefaultQueryEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ClientParams_DefaultQueryEntry>, I>>(base?: I): ClientParams_DefaultQueryEntry {
    return ClientParams_DefaultQueryEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ClientParams_DefaultQueryEntry>, I>>(
    object: I,
  ): ClientParams_DefaultQueryEntry {
    const message = createBaseClientParams_DefaultQueryEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseChatCompletionAuth(): ChatCompletionAuth {
  return { openai_api_key: "", groq_api_key: "", override_api_key: "", anthropic_api_key: "", cerebras_api_key: "" };
}

export const ChatCompletionAuth = {
  encode(message: ChatCompletionAuth, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.openai_api_key !== "") {
      writer.uint32(10).string(message.openai_api_key);
    }
    if (message.groq_api_key !== "") {
      writer.uint32(18).string(message.groq_api_key);
    }
    if (message.override_api_key !== "") {
      writer.uint32(26).string(message.override_api_key);
    }
    if (message.anthropic_api_key !== "") {
      writer.uint32(34).string(message.anthropic_api_key);
    }
    if (message.cerebras_api_key !== "") {
      writer.uint32(42).string(message.cerebras_api_key);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatCompletionAuth {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatCompletionAuth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.openai_api_key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.groq_api_key = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.override_api_key = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.anthropic_api_key = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.cerebras_api_key = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatCompletionAuth {
    return {
      openai_api_key: isSet(object.openai_api_key) ? globalThis.String(object.openai_api_key) : "",
      groq_api_key: isSet(object.groq_api_key) ? globalThis.String(object.groq_api_key) : "",
      override_api_key: isSet(object.override_api_key) ? globalThis.String(object.override_api_key) : "",
      anthropic_api_key: isSet(object.anthropic_api_key) ? globalThis.String(object.anthropic_api_key) : "",
      cerebras_api_key: isSet(object.cerebras_api_key) ? globalThis.String(object.cerebras_api_key) : "",
    };
  },

  toJSON(message: ChatCompletionAuth): unknown {
    const obj: any = {};
    if (message.openai_api_key !== "") {
      obj.openai_api_key = message.openai_api_key;
    }
    if (message.groq_api_key !== "") {
      obj.groq_api_key = message.groq_api_key;
    }
    if (message.override_api_key !== "") {
      obj.override_api_key = message.override_api_key;
    }
    if (message.anthropic_api_key !== "") {
      obj.anthropic_api_key = message.anthropic_api_key;
    }
    if (message.cerebras_api_key !== "") {
      obj.cerebras_api_key = message.cerebras_api_key;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatCompletionAuth>, I>>(base?: I): ChatCompletionAuth {
    return ChatCompletionAuth.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatCompletionAuth>, I>>(object: I): ChatCompletionAuth {
    const message = createBaseChatCompletionAuth();
    message.openai_api_key = object.openai_api_key ?? "";
    message.groq_api_key = object.groq_api_key ?? "";
    message.override_api_key = object.override_api_key ?? "";
    message.anthropic_api_key = object.anthropic_api_key ?? "";
    message.cerebras_api_key = object.cerebras_api_key ?? "";
    return message;
  },
};

function createBaseChatCompletionParams(): ChatCompletionParams {
  return {
    messages: [],
    model: "",
    max_tokens: 0,
    response_format: undefined,
    temperature: 0,
    top_p: 0,
    stop: "",
    logprobs: false,
    top_logprobs: 0,
    n: 0,
    seed: 0,
    stream: false,
    stream_options: undefined,
    logit_bias: {},
    presence_penalty: 0,
    frequency_penalty: 0,
    user: "",
    tools: [],
    tool_choice: "",
    parallel_tool_calls: false,
  };
}

export const ChatCompletionParams = {
  encode(message: ChatCompletionParams, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.messages) {
      ChatMessage.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.model !== "") {
      writer.uint32(18).string(message.model);
    }
    if (message.max_tokens !== 0) {
      writer.uint32(24).int32(message.max_tokens);
    }
    if (message.response_format !== undefined) {
      ResponseFormat.encode(message.response_format, writer.uint32(34).fork()).ldelim();
    }
    if (message.temperature !== 0) {
      writer.uint32(41).double(message.temperature);
    }
    if (message.top_p !== 0) {
      writer.uint32(49).double(message.top_p);
    }
    if (message.stop !== "") {
      writer.uint32(58).string(message.stop);
    }
    if (message.logprobs !== false) {
      writer.uint32(64).bool(message.logprobs);
    }
    if (message.top_logprobs !== 0) {
      writer.uint32(72).int32(message.top_logprobs);
    }
    if (message.n !== 0) {
      writer.uint32(80).int32(message.n);
    }
    if (message.seed !== 0) {
      writer.uint32(88).int32(message.seed);
    }
    if (message.stream !== false) {
      writer.uint32(96).bool(message.stream);
    }
    if (message.stream_options !== undefined) {
      StreamOptions.encode(message.stream_options, writer.uint32(106).fork()).ldelim();
    }
    Object.entries(message.logit_bias).forEach(([key, value]) => {
      ChatCompletionParams_LogitBiasEntry.encode({ key: key as any, value }, writer.uint32(114).fork()).ldelim();
    });
    if (message.presence_penalty !== 0) {
      writer.uint32(121).double(message.presence_penalty);
    }
    if (message.frequency_penalty !== 0) {
      writer.uint32(129).double(message.frequency_penalty);
    }
    if (message.user !== "") {
      writer.uint32(138).string(message.user);
    }
    for (const v of message.tools) {
      Tool.encode(v!, writer.uint32(146).fork()).ldelim();
    }
    if (message.tool_choice !== "") {
      writer.uint32(154).string(message.tool_choice);
    }
    if (message.parallel_tool_calls !== false) {
      writer.uint32(160).bool(message.parallel_tool_calls);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatCompletionParams {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatCompletionParams();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.messages.push(ChatMessage.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.model = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.max_tokens = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.response_format = ResponseFormat.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.temperature = reader.double();
          continue;
        case 6:
          if (tag !== 49) {
            break;
          }

          message.top_p = reader.double();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.stop = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.logprobs = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.top_logprobs = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.n = reader.int32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.seed = reader.int32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.stream = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.stream_options = StreamOptions.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          const entry14 = ChatCompletionParams_LogitBiasEntry.decode(reader, reader.uint32());
          if (entry14.value !== undefined) {
            message.logit_bias[entry14.key] = entry14.value;
          }
          continue;
        case 15:
          if (tag !== 121) {
            break;
          }

          message.presence_penalty = reader.double();
          continue;
        case 16:
          if (tag !== 129) {
            break;
          }

          message.frequency_penalty = reader.double();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.user = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.tools.push(Tool.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.tool_choice = reader.string();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.parallel_tool_calls = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatCompletionParams {
    return {
      messages: globalThis.Array.isArray(object?.messages)
        ? object.messages.map((e: any) => ChatMessage.fromJSON(e))
        : [],
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      max_tokens: isSet(object.max_tokens) ? globalThis.Number(object.max_tokens) : 0,
      response_format: isSet(object.response_format) ? ResponseFormat.fromJSON(object.response_format) : undefined,
      temperature: isSet(object.temperature) ? globalThis.Number(object.temperature) : 0,
      top_p: isSet(object.top_p) ? globalThis.Number(object.top_p) : 0,
      stop: isSet(object.stop) ? globalThis.String(object.stop) : "",
      logprobs: isSet(object.logprobs) ? globalThis.Boolean(object.logprobs) : false,
      top_logprobs: isSet(object.top_logprobs) ? globalThis.Number(object.top_logprobs) : 0,
      n: isSet(object.n) ? globalThis.Number(object.n) : 0,
      seed: isSet(object.seed) ? globalThis.Number(object.seed) : 0,
      stream: isSet(object.stream) ? globalThis.Boolean(object.stream) : false,
      stream_options: isSet(object.stream_options) ? StreamOptions.fromJSON(object.stream_options) : undefined,
      logit_bias: isObject(object.logit_bias)
        ? Object.entries(object.logit_bias).reduce<{ [key: string]: number }>((acc, [key, value]) => {
          acc[key] = Number(value);
          return acc;
        }, {})
        : {},
      presence_penalty: isSet(object.presence_penalty) ? globalThis.Number(object.presence_penalty) : 0,
      frequency_penalty: isSet(object.frequency_penalty) ? globalThis.Number(object.frequency_penalty) : 0,
      user: isSet(object.user) ? globalThis.String(object.user) : "",
      tools: globalThis.Array.isArray(object?.tools) ? object.tools.map((e: any) => Tool.fromJSON(e)) : [],
      tool_choice: isSet(object.tool_choice) ? globalThis.String(object.tool_choice) : "",
      parallel_tool_calls: isSet(object.parallel_tool_calls) ? globalThis.Boolean(object.parallel_tool_calls) : false,
    };
  },

  toJSON(message: ChatCompletionParams): unknown {
    const obj: any = {};
    if (message.messages?.length) {
      obj.messages = message.messages.map((e) => ChatMessage.toJSON(e));
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.max_tokens !== 0) {
      obj.max_tokens = Math.round(message.max_tokens);
    }
    if (message.response_format !== undefined) {
      obj.response_format = ResponseFormat.toJSON(message.response_format);
    }
    if (message.temperature !== 0) {
      obj.temperature = message.temperature;
    }
    if (message.top_p !== 0) {
      obj.top_p = message.top_p;
    }
    if (message.stop !== "") {
      obj.stop = message.stop;
    }
    if (message.logprobs !== false) {
      obj.logprobs = message.logprobs;
    }
    if (message.top_logprobs !== 0) {
      obj.top_logprobs = Math.round(message.top_logprobs);
    }
    if (message.n !== 0) {
      obj.n = Math.round(message.n);
    }
    if (message.seed !== 0) {
      obj.seed = Math.round(message.seed);
    }
    if (message.stream !== false) {
      obj.stream = message.stream;
    }
    if (message.stream_options !== undefined) {
      obj.stream_options = StreamOptions.toJSON(message.stream_options);
    }
    if (message.logit_bias) {
      const entries = Object.entries(message.logit_bias);
      if (entries.length > 0) {
        obj.logit_bias = {};
        entries.forEach(([k, v]) => {
          obj.logit_bias[k] = v;
        });
      }
    }
    if (message.presence_penalty !== 0) {
      obj.presence_penalty = message.presence_penalty;
    }
    if (message.frequency_penalty !== 0) {
      obj.frequency_penalty = message.frequency_penalty;
    }
    if (message.user !== "") {
      obj.user = message.user;
    }
    if (message.tools?.length) {
      obj.tools = message.tools.map((e) => Tool.toJSON(e));
    }
    if (message.tool_choice !== "") {
      obj.tool_choice = message.tool_choice;
    }
    if (message.parallel_tool_calls !== false) {
      obj.parallel_tool_calls = message.parallel_tool_calls;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatCompletionParams>, I>>(base?: I): ChatCompletionParams {
    return ChatCompletionParams.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatCompletionParams>, I>>(object: I): ChatCompletionParams {
    const message = createBaseChatCompletionParams();
    message.messages = object.messages?.map((e) => ChatMessage.fromPartial(e)) || [];
    message.model = object.model ?? "";
    message.max_tokens = object.max_tokens ?? 0;
    message.response_format = (object.response_format !== undefined && object.response_format !== null)
      ? ResponseFormat.fromPartial(object.response_format)
      : undefined;
    message.temperature = object.temperature ?? 0;
    message.top_p = object.top_p ?? 0;
    message.stop = object.stop ?? "";
    message.logprobs = object.logprobs ?? false;
    message.top_logprobs = object.top_logprobs ?? 0;
    message.n = object.n ?? 0;
    message.seed = object.seed ?? 0;
    message.stream = object.stream ?? false;
    message.stream_options = (object.stream_options !== undefined && object.stream_options !== null)
      ? StreamOptions.fromPartial(object.stream_options)
      : undefined;
    message.logit_bias = Object.entries(object.logit_bias ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.Number(value);
        }
        return acc;
      },
      {},
    );
    message.presence_penalty = object.presence_penalty ?? 0;
    message.frequency_penalty = object.frequency_penalty ?? 0;
    message.user = object.user ?? "";
    message.tools = object.tools?.map((e) => Tool.fromPartial(e)) || [];
    message.tool_choice = object.tool_choice ?? "";
    message.parallel_tool_calls = object.parallel_tool_calls ?? false;
    return message;
  },
};

function createBaseChatCompletionParams_LogitBiasEntry(): ChatCompletionParams_LogitBiasEntry {
  return { key: "", value: 0 };
}

export const ChatCompletionParams_LogitBiasEntry = {
  encode(message: ChatCompletionParams_LogitBiasEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(17).double(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatCompletionParams_LogitBiasEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatCompletionParams_LogitBiasEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.value = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatCompletionParams_LogitBiasEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.Number(object.value) : 0,
    };
  },

  toJSON(message: ChatCompletionParams_LogitBiasEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== 0) {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatCompletionParams_LogitBiasEntry>, I>>(
    base?: I,
  ): ChatCompletionParams_LogitBiasEntry {
    return ChatCompletionParams_LogitBiasEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatCompletionParams_LogitBiasEntry>, I>>(
    object: I,
  ): ChatCompletionParams_LogitBiasEntry {
    const message = createBaseChatCompletionParams_LogitBiasEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseResponseFormat(): ResponseFormat {
  return { type: "", json_schema: undefined };
}

export const ResponseFormat = {
  encode(message: ResponseFormat, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.json_schema !== undefined) {
      JSONSchema.encode(message.json_schema, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ResponseFormat {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseResponseFormat();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.json_schema = JSONSchema.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ResponseFormat {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      json_schema: isSet(object.json_schema) ? JSONSchema.fromJSON(object.json_schema) : undefined,
    };
  },

  toJSON(message: ResponseFormat): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.json_schema !== undefined) {
      obj.json_schema = JSONSchema.toJSON(message.json_schema);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ResponseFormat>, I>>(base?: I): ResponseFormat {
    return ResponseFormat.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ResponseFormat>, I>>(object: I): ResponseFormat {
    const message = createBaseResponseFormat();
    message.type = object.type ?? "";
    message.json_schema = (object.json_schema !== undefined && object.json_schema !== null)
      ? JSONSchema.fromPartial(object.json_schema)
      : undefined;
    return message;
  },
};

function createBaseStreamOptions(): StreamOptions {
  return { include_usage: false };
}

export const StreamOptions = {
  encode(message: StreamOptions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.include_usage !== false) {
      writer.uint32(8).bool(message.include_usage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StreamOptions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStreamOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.include_usage = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StreamOptions {
    return { include_usage: isSet(object.include_usage) ? globalThis.Boolean(object.include_usage) : false };
  },

  toJSON(message: StreamOptions): unknown {
    const obj: any = {};
    if (message.include_usage !== false) {
      obj.include_usage = message.include_usage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StreamOptions>, I>>(base?: I): StreamOptions {
    return StreamOptions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StreamOptions>, I>>(object: I): StreamOptions {
    const message = createBaseStreamOptions();
    message.include_usage = object.include_usage ?? false;
    return message;
  },
};

function createBaseTool(): Tool {
  return { type: "", function: undefined };
}

export const Tool = {
  encode(message: Tool, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.function !== undefined) {
      Function.encode(message.function, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Tool {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTool();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.function = Function.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Tool {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      function: isSet(object.function) ? Function.fromJSON(object.function) : undefined,
    };
  },

  toJSON(message: Tool): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.function !== undefined) {
      obj.function = Function.toJSON(message.function);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Tool>, I>>(base?: I): Tool {
    return Tool.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Tool>, I>>(object: I): Tool {
    const message = createBaseTool();
    message.type = object.type ?? "";
    message.function = (object.function !== undefined && object.function !== null)
      ? Function.fromPartial(object.function)
      : undefined;
    return message;
  },
};

function createBaseFunction(): Function {
  return { name: "", description: "", parameters: undefined, strict: false };
}

export const Function = {
  encode(message: Function, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.parameters !== undefined) {
      FunctionParameters.encode(message.parameters, writer.uint32(26).fork()).ldelim();
    }
    if (message.strict !== false) {
      writer.uint32(32).bool(message.strict);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Function {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFunction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.parameters = FunctionParameters.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.strict = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Function {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      parameters: isSet(object.parameters) ? FunctionParameters.fromJSON(object.parameters) : undefined,
      strict: isSet(object.strict) ? globalThis.Boolean(object.strict) : false,
    };
  },

  toJSON(message: Function): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.parameters !== undefined) {
      obj.parameters = FunctionParameters.toJSON(message.parameters);
    }
    if (message.strict !== false) {
      obj.strict = message.strict;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Function>, I>>(base?: I): Function {
    return Function.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Function>, I>>(object: I): Function {
    const message = createBaseFunction();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.parameters = (object.parameters !== undefined && object.parameters !== null)
      ? FunctionParameters.fromPartial(object.parameters)
      : undefined;
    message.strict = object.strict ?? false;
    return message;
  },
};

function createBaseFunctionParameters(): FunctionParameters {
  return { type: "", description: "", properties: {}, required: [], additional_properties: false };
}

export const FunctionParameters = {
  encode(message: FunctionParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    Object.entries(message.properties).forEach(([key, value]) => {
      FunctionParameters_PropertiesEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    for (const v of message.required) {
      writer.uint32(34).string(v!);
    }
    if (message.additional_properties !== false) {
      writer.uint32(40).bool(message.additional_properties);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FunctionParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFunctionParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = FunctionParameters_PropertiesEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.properties[entry3.key] = entry3.value;
          }
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.required.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.additional_properties = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FunctionParameters {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      properties: isObject(object.properties)
        ? Object.entries(object.properties).reduce<{ [key: string]: Property }>((acc, [key, value]) => {
          acc[key] = Property.fromJSON(value);
          return acc;
        }, {})
        : {},
      required: globalThis.Array.isArray(object?.required) ? object.required.map((e: any) => globalThis.String(e)) : [],
      additional_properties: isSet(object.additional_properties)
        ? globalThis.Boolean(object.additional_properties)
        : false,
    };
  },

  toJSON(message: FunctionParameters): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.properties) {
      const entries = Object.entries(message.properties);
      if (entries.length > 0) {
        obj.properties = {};
        entries.forEach(([k, v]) => {
          obj.properties[k] = Property.toJSON(v);
        });
      }
    }
    if (message.required?.length) {
      obj.required = message.required;
    }
    if (message.additional_properties !== false) {
      obj.additional_properties = message.additional_properties;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FunctionParameters>, I>>(base?: I): FunctionParameters {
    return FunctionParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FunctionParameters>, I>>(object: I): FunctionParameters {
    const message = createBaseFunctionParameters();
    message.type = object.type ?? "";
    message.description = object.description ?? "";
    message.properties = Object.entries(object.properties ?? {}).reduce<{ [key: string]: Property }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Property.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.required = object.required?.map((e) => e) || [];
    message.additional_properties = object.additional_properties ?? false;
    return message;
  },
};

function createBaseFunctionParameters_PropertiesEntry(): FunctionParameters_PropertiesEntry {
  return { key: "", value: undefined };
}

export const FunctionParameters_PropertiesEntry = {
  encode(message: FunctionParameters_PropertiesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Property.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FunctionParameters_PropertiesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFunctionParameters_PropertiesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = Property.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FunctionParameters_PropertiesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? Property.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: FunctionParameters_PropertiesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = Property.toJSON(message.value);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FunctionParameters_PropertiesEntry>, I>>(
    base?: I,
  ): FunctionParameters_PropertiesEntry {
    return FunctionParameters_PropertiesEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FunctionParameters_PropertiesEntry>, I>>(
    object: I,
  ): FunctionParameters_PropertiesEntry {
    const message = createBaseFunctionParameters_PropertiesEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? Property.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseProperty(): Property {
  return { type: "", description: "" };
}

export const Property = {
  encode(message: Property, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Property {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProperty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Property {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
    };
  },

  toJSON(message: Property): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Property>, I>>(base?: I): Property {
    return Property.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Property>, I>>(object: I): Property {
    const message = createBaseProperty();
    message.type = object.type ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseJSONSchema(): JSONSchema {
  return { name: "", strict: false, schema: {} };
}

export const JSONSchema = {
  encode(message: JSONSchema, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.strict !== false) {
      writer.uint32(16).bool(message.strict);
    }
    Object.entries(message.schema).forEach(([key, value]) => {
      JSONSchema_SchemaEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JSONSchema {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJSONSchema();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.strict = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          const entry3 = JSONSchema_SchemaEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.schema[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JSONSchema {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      strict: isSet(object.strict) ? globalThis.Boolean(object.strict) : false,
      schema: isObject(object.schema)
        ? Object.entries(object.schema).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: JSONSchema): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.strict !== false) {
      obj.strict = message.strict;
    }
    if (message.schema) {
      const entries = Object.entries(message.schema);
      if (entries.length > 0) {
        obj.schema = {};
        entries.forEach(([k, v]) => {
          obj.schema[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JSONSchema>, I>>(base?: I): JSONSchema {
    return JSONSchema.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JSONSchema>, I>>(object: I): JSONSchema {
    const message = createBaseJSONSchema();
    message.name = object.name ?? "";
    message.strict = object.strict ?? false;
    message.schema = Object.entries(object.schema ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseJSONSchema_SchemaEntry(): JSONSchema_SchemaEntry {
  return { key: "", value: "" };
}

export const JSONSchema_SchemaEntry = {
  encode(message: JSONSchema_SchemaEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JSONSchema_SchemaEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJSONSchema_SchemaEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JSONSchema_SchemaEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: JSONSchema_SchemaEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JSONSchema_SchemaEntry>, I>>(base?: I): JSONSchema_SchemaEntry {
    return JSONSchema_SchemaEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JSONSchema_SchemaEntry>, I>>(object: I): JSONSchema_SchemaEntry {
    const message = createBaseJSONSchema_SchemaEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseChatCompletionResponse(): ChatCompletionResponse {
  return {
    id: "",
    response_id: 0,
    object: "",
    created: 0,
    model: "",
    system_fingerprint: "",
    choices: [],
    usage: undefined,
    request_id: "",
    evaluate_response: undefined,
    correction_applied: false,
    first_token_time: 0,
    response_time: 0,
    chat_completion_request: undefined,
    service_tier: undefined,
    input_safety_score: 0,
    fallback_reason: "",
  };
}

export const ChatCompletionResponse = {
  encode(message: ChatCompletionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.response_id !== 0) {
      writer.uint32(16).int64(message.response_id);
    }
    if (message.object !== "") {
      writer.uint32(26).string(message.object);
    }
    if (message.created !== 0) {
      writer.uint32(32).int64(message.created);
    }
    if (message.model !== "") {
      writer.uint32(42).string(message.model);
    }
    if (message.system_fingerprint !== "") {
      writer.uint32(50).string(message.system_fingerprint);
    }
    for (const v of message.choices) {
      ChatCompletionChoice.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.usage !== undefined) {
      ChatCompletionUsage.encode(message.usage, writer.uint32(66).fork()).ldelim();
    }
    if (message.request_id !== "") {
      writer.uint32(74).string(message.request_id);
    }
    if (message.evaluate_response !== undefined) {
      EvaluateResponse.encode(message.evaluate_response, writer.uint32(82).fork()).ldelim();
    }
    if (message.correction_applied !== false) {
      writer.uint32(88).bool(message.correction_applied);
    }
    if (message.first_token_time !== 0) {
      writer.uint32(96).int64(message.first_token_time);
    }
    if (message.response_time !== 0) {
      writer.uint32(104).int64(message.response_time);
    }
    if (message.chat_completion_request !== undefined) {
      ChatCompletionRequest.encode(message.chat_completion_request, writer.uint32(114).fork()).ldelim();
    }
    if (message.service_tier !== undefined) {
      writer.uint32(122).string(message.service_tier);
    }
    if (message.input_safety_score !== 0) {
      writer.uint32(133).float(message.input_safety_score);
    }
    if (message.fallback_reason !== "") {
      writer.uint32(138).string(message.fallback_reason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatCompletionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatCompletionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.response_id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.object = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.created = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.model = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.system_fingerprint = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.choices.push(ChatCompletionChoice.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.usage = ChatCompletionUsage.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.request_id = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.evaluate_response = EvaluateResponse.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.correction_applied = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.first_token_time = longToNumber(reader.int64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.response_time = longToNumber(reader.int64() as Long);
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.chat_completion_request = ChatCompletionRequest.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.service_tier = reader.string();
          continue;
        case 16:
          if (tag !== 133) {
            break;
          }

          message.input_safety_score = reader.float();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.fallback_reason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatCompletionResponse {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      response_id: isSet(object.response_id) ? globalThis.Number(object.response_id) : 0,
      object: isSet(object.object) ? globalThis.String(object.object) : "",
      created: isSet(object.created) ? globalThis.Number(object.created) : 0,
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      system_fingerprint: isSet(object.system_fingerprint) ? globalThis.String(object.system_fingerprint) : "",
      choices: globalThis.Array.isArray(object?.choices)
        ? object.choices.map((e: any) => ChatCompletionChoice.fromJSON(e))
        : [],
      usage: isSet(object.usage) ? ChatCompletionUsage.fromJSON(object.usage) : undefined,
      request_id: isSet(object.request_id) ? globalThis.String(object.request_id) : "",
      evaluate_response: isSet(object.evaluate_response)
        ? EvaluateResponse.fromJSON(object.evaluate_response)
        : undefined,
      correction_applied: isSet(object.correction_applied) ? globalThis.Boolean(object.correction_applied) : false,
      first_token_time: isSet(object.first_token_time) ? globalThis.Number(object.first_token_time) : 0,
      response_time: isSet(object.response_time) ? globalThis.Number(object.response_time) : 0,
      chat_completion_request: isSet(object.chat_completion_request)
        ? ChatCompletionRequest.fromJSON(object.chat_completion_request)
        : undefined,
      service_tier: isSet(object.service_tier) ? globalThis.String(object.service_tier) : undefined,
      input_safety_score: isSet(object.input_safety_score) ? globalThis.Number(object.input_safety_score) : 0,
      fallback_reason: isSet(object.fallback_reason) ? globalThis.String(object.fallback_reason) : "",
    };
  },

  toJSON(message: ChatCompletionResponse): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.response_id !== 0) {
      obj.response_id = Math.round(message.response_id);
    }
    if (message.object !== "") {
      obj.object = message.object;
    }
    if (message.created !== 0) {
      obj.created = Math.round(message.created);
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.system_fingerprint !== "") {
      obj.system_fingerprint = message.system_fingerprint;
    }
    if (message.choices?.length) {
      obj.choices = message.choices.map((e) => ChatCompletionChoice.toJSON(e));
    }
    if (message.usage !== undefined) {
      obj.usage = ChatCompletionUsage.toJSON(message.usage);
    }
    if (message.request_id !== "") {
      obj.request_id = message.request_id;
    }
    if (message.evaluate_response !== undefined) {
      obj.evaluate_response = EvaluateResponse.toJSON(message.evaluate_response);
    }
    if (message.correction_applied !== false) {
      obj.correction_applied = message.correction_applied;
    }
    if (message.first_token_time !== 0) {
      obj.first_token_time = Math.round(message.first_token_time);
    }
    if (message.response_time !== 0) {
      obj.response_time = Math.round(message.response_time);
    }
    if (message.chat_completion_request !== undefined) {
      obj.chat_completion_request = ChatCompletionRequest.toJSON(message.chat_completion_request);
    }
    if (message.service_tier !== undefined) {
      obj.service_tier = message.service_tier;
    }
    if (message.input_safety_score !== 0) {
      obj.input_safety_score = message.input_safety_score;
    }
    if (message.fallback_reason !== "") {
      obj.fallback_reason = message.fallback_reason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatCompletionResponse>, I>>(base?: I): ChatCompletionResponse {
    return ChatCompletionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatCompletionResponse>, I>>(object: I): ChatCompletionResponse {
    const message = createBaseChatCompletionResponse();
    message.id = object.id ?? "";
    message.response_id = object.response_id ?? 0;
    message.object = object.object ?? "";
    message.created = object.created ?? 0;
    message.model = object.model ?? "";
    message.system_fingerprint = object.system_fingerprint ?? "";
    message.choices = object.choices?.map((e) => ChatCompletionChoice.fromPartial(e)) || [];
    message.usage = (object.usage !== undefined && object.usage !== null)
      ? ChatCompletionUsage.fromPartial(object.usage)
      : undefined;
    message.request_id = object.request_id ?? "";
    message.evaluate_response = (object.evaluate_response !== undefined && object.evaluate_response !== null)
      ? EvaluateResponse.fromPartial(object.evaluate_response)
      : undefined;
    message.correction_applied = object.correction_applied ?? false;
    message.first_token_time = object.first_token_time ?? 0;
    message.response_time = object.response_time ?? 0;
    message.chat_completion_request =
      (object.chat_completion_request !== undefined && object.chat_completion_request !== null)
        ? ChatCompletionRequest.fromPartial(object.chat_completion_request)
        : undefined;
    message.service_tier = object.service_tier ?? undefined;
    message.input_safety_score = object.input_safety_score ?? 0;
    message.fallback_reason = object.fallback_reason ?? "";
    return message;
  },
};

function createBaseChatCompletionChoice(): ChatCompletionChoice {
  return { index: 0, message: undefined, logprobs: undefined, finish_reason: "", is_correction: false };
}

export const ChatCompletionChoice = {
  encode(message: ChatCompletionChoice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.index !== 0) {
      writer.uint32(8).int32(message.index);
    }
    if (message.message !== undefined) {
      ChatCompletionMessage.encode(message.message, writer.uint32(18).fork()).ldelim();
    }
    if (message.logprobs !== undefined) {
      LogProbs.encode(message.logprobs, writer.uint32(26).fork()).ldelim();
    }
    if (message.finish_reason !== "") {
      writer.uint32(34).string(message.finish_reason);
    }
    if (message.is_correction !== false) {
      writer.uint32(40).bool(message.is_correction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatCompletionChoice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatCompletionChoice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.index = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.message = ChatCompletionMessage.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.logprobs = LogProbs.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.finish_reason = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.is_correction = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatCompletionChoice {
    return {
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
      message: isSet(object.message) ? ChatCompletionMessage.fromJSON(object.message) : undefined,
      logprobs: isSet(object.logprobs) ? LogProbs.fromJSON(object.logprobs) : undefined,
      finish_reason: isSet(object.finish_reason) ? globalThis.String(object.finish_reason) : "",
      is_correction: isSet(object.is_correction) ? globalThis.Boolean(object.is_correction) : false,
    };
  },

  toJSON(message: ChatCompletionChoice): unknown {
    const obj: any = {};
    if (message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    if (message.message !== undefined) {
      obj.message = ChatCompletionMessage.toJSON(message.message);
    }
    if (message.logprobs !== undefined) {
      obj.logprobs = LogProbs.toJSON(message.logprobs);
    }
    if (message.finish_reason !== "") {
      obj.finish_reason = message.finish_reason;
    }
    if (message.is_correction !== false) {
      obj.is_correction = message.is_correction;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatCompletionChoice>, I>>(base?: I): ChatCompletionChoice {
    return ChatCompletionChoice.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatCompletionChoice>, I>>(object: I): ChatCompletionChoice {
    const message = createBaseChatCompletionChoice();
    message.index = object.index ?? 0;
    message.message = (object.message !== undefined && object.message !== null)
      ? ChatCompletionMessage.fromPartial(object.message)
      : undefined;
    message.logprobs = (object.logprobs !== undefined && object.logprobs !== null)
      ? LogProbs.fromPartial(object.logprobs)
      : undefined;
    message.finish_reason = object.finish_reason ?? "";
    message.is_correction = object.is_correction ?? false;
    return message;
  },
};

function createBaseChatMessage(): ChatMessage {
  return { role: "", content: undefined, name: undefined, tool_calls: [], tool_call_id: undefined };
}

export const ChatMessage = {
  encode(message: ChatMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.role !== "") {
      writer.uint32(10).string(message.role);
    }
    if (message.content !== undefined) {
      writer.uint32(18).string(message.content);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    for (const v of message.tool_calls) {
      ToolCall.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.tool_call_id !== undefined) {
      writer.uint32(42).string(message.tool_call_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.role = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.content = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tool_calls.push(ToolCall.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tool_call_id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatMessage {
    return {
      role: isSet(object.role) ? globalThis.String(object.role) : "",
      content: isSet(object.content) ? globalThis.String(object.content) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      tool_calls: globalThis.Array.isArray(object?.tool_calls)
        ? object.tool_calls.map((e: any) => ToolCall.fromJSON(e))
        : [],
      tool_call_id: isSet(object.tool_call_id) ? globalThis.String(object.tool_call_id) : undefined,
    };
  },

  toJSON(message: ChatMessage): unknown {
    const obj: any = {};
    if (message.role !== "") {
      obj.role = message.role;
    }
    if (message.content !== undefined) {
      obj.content = message.content;
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.tool_calls?.length) {
      obj.tool_calls = message.tool_calls.map((e) => ToolCall.toJSON(e));
    }
    if (message.tool_call_id !== undefined) {
      obj.tool_call_id = message.tool_call_id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatMessage>, I>>(base?: I): ChatMessage {
    return ChatMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatMessage>, I>>(object: I): ChatMessage {
    const message = createBaseChatMessage();
    message.role = object.role ?? "";
    message.content = object.content ?? undefined;
    message.name = object.name ?? undefined;
    message.tool_calls = object.tool_calls?.map((e) => ToolCall.fromPartial(e)) || [];
    message.tool_call_id = object.tool_call_id ?? undefined;
    return message;
  },
};

function createBaseFunctionCall(): FunctionCall {
  return { arguments: "", name: "" };
}

export const FunctionCall = {
  encode(message: FunctionCall, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.arguments !== "") {
      writer.uint32(10).string(message.arguments);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FunctionCall {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFunctionCall();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.arguments = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FunctionCall {
    return {
      arguments: isSet(object.arguments) ? globalThis.String(object.arguments) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: FunctionCall): unknown {
    const obj: any = {};
    if (message.arguments !== "") {
      obj.arguments = message.arguments;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FunctionCall>, I>>(base?: I): FunctionCall {
    return FunctionCall.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FunctionCall>, I>>(object: I): FunctionCall {
    const message = createBaseFunctionCall();
    message.arguments = object.arguments ?? "";
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseChatCompletionMessage(): ChatCompletionMessage {
  return { role: "", content: "", function_call: undefined, tool_calls: [], refusal: "" };
}

export const ChatCompletionMessage = {
  encode(message: ChatCompletionMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.role !== "") {
      writer.uint32(10).string(message.role);
    }
    if (message.content !== "") {
      writer.uint32(18).string(message.content);
    }
    if (message.function_call !== undefined) {
      FunctionCall.encode(message.function_call, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.tool_calls) {
      ToolCall.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.refusal !== "") {
      writer.uint32(42).string(message.refusal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatCompletionMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatCompletionMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.role = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.content = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.function_call = FunctionCall.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tool_calls.push(ToolCall.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.refusal = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatCompletionMessage {
    return {
      role: isSet(object.role) ? globalThis.String(object.role) : "",
      content: isSet(object.content) ? globalThis.String(object.content) : "",
      function_call: isSet(object.function_call) ? FunctionCall.fromJSON(object.function_call) : undefined,
      tool_calls: globalThis.Array.isArray(object?.tool_calls)
        ? object.tool_calls.map((e: any) => ToolCall.fromJSON(e))
        : [],
      refusal: isSet(object.refusal) ? globalThis.String(object.refusal) : "",
    };
  },

  toJSON(message: ChatCompletionMessage): unknown {
    const obj: any = {};
    if (message.role !== "") {
      obj.role = message.role;
    }
    if (message.content !== "") {
      obj.content = message.content;
    }
    if (message.function_call !== undefined) {
      obj.function_call = FunctionCall.toJSON(message.function_call);
    }
    if (message.tool_calls?.length) {
      obj.tool_calls = message.tool_calls.map((e) => ToolCall.toJSON(e));
    }
    if (message.refusal !== "") {
      obj.refusal = message.refusal;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatCompletionMessage>, I>>(base?: I): ChatCompletionMessage {
    return ChatCompletionMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatCompletionMessage>, I>>(object: I): ChatCompletionMessage {
    const message = createBaseChatCompletionMessage();
    message.role = object.role ?? "";
    message.content = object.content ?? "";
    message.function_call = (object.function_call !== undefined && object.function_call !== null)
      ? FunctionCall.fromPartial(object.function_call)
      : undefined;
    message.tool_calls = object.tool_calls?.map((e) => ToolCall.fromPartial(e)) || [];
    message.refusal = object.refusal ?? "";
    return message;
  },
};

function createBaseChatCompletionUsage(): ChatCompletionUsage {
  return {
    prompt_tokens: 0,
    completion_tokens: 0,
    total_tokens: 0,
    cost: 0,
    model: "",
    completion_tokens_details: undefined,
  };
}

export const ChatCompletionUsage = {
  encode(message: ChatCompletionUsage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.prompt_tokens !== 0) {
      writer.uint32(8).int32(message.prompt_tokens);
    }
    if (message.completion_tokens !== 0) {
      writer.uint32(16).int32(message.completion_tokens);
    }
    if (message.total_tokens !== 0) {
      writer.uint32(24).int32(message.total_tokens);
    }
    if (message.cost !== 0) {
      writer.uint32(32).int64(message.cost);
    }
    if (message.model !== "") {
      writer.uint32(42).string(message.model);
    }
    if (message.completion_tokens_details !== undefined) {
      CompletionTokensDetails.encode(message.completion_tokens_details, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatCompletionUsage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatCompletionUsage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.prompt_tokens = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.completion_tokens = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.total_tokens = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.cost = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.model = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.completion_tokens_details = CompletionTokensDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatCompletionUsage {
    return {
      prompt_tokens: isSet(object.prompt_tokens) ? globalThis.Number(object.prompt_tokens) : 0,
      completion_tokens: isSet(object.completion_tokens) ? globalThis.Number(object.completion_tokens) : 0,
      total_tokens: isSet(object.total_tokens) ? globalThis.Number(object.total_tokens) : 0,
      cost: isSet(object.cost) ? globalThis.Number(object.cost) : 0,
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      completion_tokens_details: isSet(object.completion_tokens_details)
        ? CompletionTokensDetails.fromJSON(object.completion_tokens_details)
        : undefined,
    };
  },

  toJSON(message: ChatCompletionUsage): unknown {
    const obj: any = {};
    if (message.prompt_tokens !== 0) {
      obj.prompt_tokens = Math.round(message.prompt_tokens);
    }
    if (message.completion_tokens !== 0) {
      obj.completion_tokens = Math.round(message.completion_tokens);
    }
    if (message.total_tokens !== 0) {
      obj.total_tokens = Math.round(message.total_tokens);
    }
    if (message.cost !== 0) {
      obj.cost = Math.round(message.cost);
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.completion_tokens_details !== undefined) {
      obj.completion_tokens_details = CompletionTokensDetails.toJSON(message.completion_tokens_details);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatCompletionUsage>, I>>(base?: I): ChatCompletionUsage {
    return ChatCompletionUsage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatCompletionUsage>, I>>(object: I): ChatCompletionUsage {
    const message = createBaseChatCompletionUsage();
    message.prompt_tokens = object.prompt_tokens ?? 0;
    message.completion_tokens = object.completion_tokens ?? 0;
    message.total_tokens = object.total_tokens ?? 0;
    message.cost = object.cost ?? 0;
    message.model = object.model ?? "";
    message.completion_tokens_details =
      (object.completion_tokens_details !== undefined && object.completion_tokens_details !== null)
        ? CompletionTokensDetails.fromPartial(object.completion_tokens_details)
        : undefined;
    return message;
  },
};

function createBaseCompletionTokensDetails(): CompletionTokensDetails {
  return { reasoning_tokens: 0 };
}

export const CompletionTokensDetails = {
  encode(message: CompletionTokensDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.reasoning_tokens !== 0) {
      writer.uint32(8).int32(message.reasoning_tokens);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompletionTokensDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompletionTokensDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.reasoning_tokens = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompletionTokensDetails {
    return { reasoning_tokens: isSet(object.reasoning_tokens) ? globalThis.Number(object.reasoning_tokens) : 0 };
  },

  toJSON(message: CompletionTokensDetails): unknown {
    const obj: any = {};
    if (message.reasoning_tokens !== 0) {
      obj.reasoning_tokens = Math.round(message.reasoning_tokens);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompletionTokensDetails>, I>>(base?: I): CompletionTokensDetails {
    return CompletionTokensDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompletionTokensDetails>, I>>(object: I): CompletionTokensDetails {
    const message = createBaseCompletionTokensDetails();
    message.reasoning_tokens = object.reasoning_tokens ?? 0;
    return message;
  },
};

function createBaseChatCompletionChunk(): ChatCompletionChunk {
  return {
    id: "",
    choices: [],
    created: 0,
    model: "",
    object: "",
    system_fingerprint: "",
    usage: undefined,
    evaluate_response: undefined,
    correction_applied: false,
    service_tier: undefined,
    input_safety_score: 0,
    request_id: "",
    fallback_reason: "",
  };
}

export const ChatCompletionChunk = {
  encode(message: ChatCompletionChunk, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    for (const v of message.choices) {
      ChunkChoice.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.created !== 0) {
      writer.uint32(24).int64(message.created);
    }
    if (message.model !== "") {
      writer.uint32(34).string(message.model);
    }
    if (message.object !== "") {
      writer.uint32(42).string(message.object);
    }
    if (message.system_fingerprint !== "") {
      writer.uint32(50).string(message.system_fingerprint);
    }
    if (message.usage !== undefined) {
      ChatCompletionUsage.encode(message.usage, writer.uint32(58).fork()).ldelim();
    }
    if (message.evaluate_response !== undefined) {
      EvaluateResponse.encode(message.evaluate_response, writer.uint32(66).fork()).ldelim();
    }
    if (message.correction_applied !== false) {
      writer.uint32(72).bool(message.correction_applied);
    }
    if (message.service_tier !== undefined) {
      writer.uint32(82).string(message.service_tier);
    }
    if (message.input_safety_score !== 0) {
      writer.uint32(93).float(message.input_safety_score);
    }
    if (message.request_id !== "") {
      writer.uint32(98).string(message.request_id);
    }
    if (message.fallback_reason !== "") {
      writer.uint32(106).string(message.fallback_reason);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatCompletionChunk {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatCompletionChunk();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.choices.push(ChunkChoice.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.created = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.model = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.object = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.system_fingerprint = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.usage = ChatCompletionUsage.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.evaluate_response = EvaluateResponse.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.correction_applied = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.service_tier = reader.string();
          continue;
        case 11:
          if (tag !== 93) {
            break;
          }

          message.input_safety_score = reader.float();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.request_id = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.fallback_reason = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatCompletionChunk {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      choices: globalThis.Array.isArray(object?.choices) ? object.choices.map((e: any) => ChunkChoice.fromJSON(e)) : [],
      created: isSet(object.created) ? globalThis.Number(object.created) : 0,
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      object: isSet(object.object) ? globalThis.String(object.object) : "",
      system_fingerprint: isSet(object.system_fingerprint) ? globalThis.String(object.system_fingerprint) : "",
      usage: isSet(object.usage) ? ChatCompletionUsage.fromJSON(object.usage) : undefined,
      evaluate_response: isSet(object.evaluate_response)
        ? EvaluateResponse.fromJSON(object.evaluate_response)
        : undefined,
      correction_applied: isSet(object.correction_applied) ? globalThis.Boolean(object.correction_applied) : false,
      service_tier: isSet(object.service_tier) ? globalThis.String(object.service_tier) : undefined,
      input_safety_score: isSet(object.input_safety_score) ? globalThis.Number(object.input_safety_score) : 0,
      request_id: isSet(object.request_id) ? globalThis.String(object.request_id) : "",
      fallback_reason: isSet(object.fallback_reason) ? globalThis.String(object.fallback_reason) : "",
    };
  },

  toJSON(message: ChatCompletionChunk): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.choices?.length) {
      obj.choices = message.choices.map((e) => ChunkChoice.toJSON(e));
    }
    if (message.created !== 0) {
      obj.created = Math.round(message.created);
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.object !== "") {
      obj.object = message.object;
    }
    if (message.system_fingerprint !== "") {
      obj.system_fingerprint = message.system_fingerprint;
    }
    if (message.usage !== undefined) {
      obj.usage = ChatCompletionUsage.toJSON(message.usage);
    }
    if (message.evaluate_response !== undefined) {
      obj.evaluate_response = EvaluateResponse.toJSON(message.evaluate_response);
    }
    if (message.correction_applied !== false) {
      obj.correction_applied = message.correction_applied;
    }
    if (message.service_tier !== undefined) {
      obj.service_tier = message.service_tier;
    }
    if (message.input_safety_score !== 0) {
      obj.input_safety_score = message.input_safety_score;
    }
    if (message.request_id !== "") {
      obj.request_id = message.request_id;
    }
    if (message.fallback_reason !== "") {
      obj.fallback_reason = message.fallback_reason;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatCompletionChunk>, I>>(base?: I): ChatCompletionChunk {
    return ChatCompletionChunk.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatCompletionChunk>, I>>(object: I): ChatCompletionChunk {
    const message = createBaseChatCompletionChunk();
    message.id = object.id ?? "";
    message.choices = object.choices?.map((e) => ChunkChoice.fromPartial(e)) || [];
    message.created = object.created ?? 0;
    message.model = object.model ?? "";
    message.object = object.object ?? "";
    message.system_fingerprint = object.system_fingerprint ?? "";
    message.usage = (object.usage !== undefined && object.usage !== null)
      ? ChatCompletionUsage.fromPartial(object.usage)
      : undefined;
    message.evaluate_response = (object.evaluate_response !== undefined && object.evaluate_response !== null)
      ? EvaluateResponse.fromPartial(object.evaluate_response)
      : undefined;
    message.correction_applied = object.correction_applied ?? false;
    message.service_tier = object.service_tier ?? undefined;
    message.input_safety_score = object.input_safety_score ?? 0;
    message.request_id = object.request_id ?? "";
    message.fallback_reason = object.fallback_reason ?? "";
    return message;
  },
};

function createBaseChunkChoice(): ChunkChoice {
  return { delta: undefined, finish_reason: "", index: 0, logprobs: undefined, is_correction: false };
}

export const ChunkChoice = {
  encode(message: ChunkChoice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.delta !== undefined) {
      ChoiceDelta.encode(message.delta, writer.uint32(10).fork()).ldelim();
    }
    if (message.finish_reason !== "") {
      writer.uint32(18).string(message.finish_reason);
    }
    if (message.index !== 0) {
      writer.uint32(24).int32(message.index);
    }
    if (message.logprobs !== undefined) {
      LogProbs.encode(message.logprobs, writer.uint32(34).fork()).ldelim();
    }
    if (message.is_correction !== false) {
      writer.uint32(40).bool(message.is_correction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChunkChoice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChunkChoice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.delta = ChoiceDelta.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.finish_reason = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.index = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.logprobs = LogProbs.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.is_correction = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChunkChoice {
    return {
      delta: isSet(object.delta) ? ChoiceDelta.fromJSON(object.delta) : undefined,
      finish_reason: isSet(object.finish_reason) ? globalThis.String(object.finish_reason) : "",
      index: isSet(object.index) ? globalThis.Number(object.index) : 0,
      logprobs: isSet(object.logprobs) ? LogProbs.fromJSON(object.logprobs) : undefined,
      is_correction: isSet(object.is_correction) ? globalThis.Boolean(object.is_correction) : false,
    };
  },

  toJSON(message: ChunkChoice): unknown {
    const obj: any = {};
    if (message.delta !== undefined) {
      obj.delta = ChoiceDelta.toJSON(message.delta);
    }
    if (message.finish_reason !== "") {
      obj.finish_reason = message.finish_reason;
    }
    if (message.index !== 0) {
      obj.index = Math.round(message.index);
    }
    if (message.logprobs !== undefined) {
      obj.logprobs = LogProbs.toJSON(message.logprobs);
    }
    if (message.is_correction !== false) {
      obj.is_correction = message.is_correction;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChunkChoice>, I>>(base?: I): ChunkChoice {
    return ChunkChoice.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChunkChoice>, I>>(object: I): ChunkChoice {
    const message = createBaseChunkChoice();
    message.delta = (object.delta !== undefined && object.delta !== null)
      ? ChoiceDelta.fromPartial(object.delta)
      : undefined;
    message.finish_reason = object.finish_reason ?? "";
    message.index = object.index ?? 0;
    message.logprobs = (object.logprobs !== undefined && object.logprobs !== null)
      ? LogProbs.fromPartial(object.logprobs)
      : undefined;
    message.is_correction = object.is_correction ?? false;
    return message;
  },
};

function createBaseChoiceDelta(): ChoiceDelta {
  return { content: "", role: "", tool_calls: [], function_call: undefined, refusal: "" };
}

export const ChoiceDelta = {
  encode(message: ChoiceDelta, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.content !== "") {
      writer.uint32(10).string(message.content);
    }
    if (message.role !== "") {
      writer.uint32(18).string(message.role);
    }
    for (const v of message.tool_calls) {
      ToolCall.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.function_call !== undefined) {
      FunctionCall.encode(message.function_call, writer.uint32(34).fork()).ldelim();
    }
    if (message.refusal !== "") {
      writer.uint32(50).string(message.refusal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChoiceDelta {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChoiceDelta();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.content = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.role = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tool_calls.push(ToolCall.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.function_call = FunctionCall.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.refusal = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChoiceDelta {
    return {
      content: isSet(object.content) ? globalThis.String(object.content) : "",
      role: isSet(object.role) ? globalThis.String(object.role) : "",
      tool_calls: globalThis.Array.isArray(object?.tool_calls)
        ? object.tool_calls.map((e: any) => ToolCall.fromJSON(e))
        : [],
      function_call: isSet(object.function_call) ? FunctionCall.fromJSON(object.function_call) : undefined,
      refusal: isSet(object.refusal) ? globalThis.String(object.refusal) : "",
    };
  },

  toJSON(message: ChoiceDelta): unknown {
    const obj: any = {};
    if (message.content !== "") {
      obj.content = message.content;
    }
    if (message.role !== "") {
      obj.role = message.role;
    }
    if (message.tool_calls?.length) {
      obj.tool_calls = message.tool_calls.map((e) => ToolCall.toJSON(e));
    }
    if (message.function_call !== undefined) {
      obj.function_call = FunctionCall.toJSON(message.function_call);
    }
    if (message.refusal !== "") {
      obj.refusal = message.refusal;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChoiceDelta>, I>>(base?: I): ChoiceDelta {
    return ChoiceDelta.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChoiceDelta>, I>>(object: I): ChoiceDelta {
    const message = createBaseChoiceDelta();
    message.content = object.content ?? "";
    message.role = object.role ?? "";
    message.tool_calls = object.tool_calls?.map((e) => ToolCall.fromPartial(e)) || [];
    message.function_call = (object.function_call !== undefined && object.function_call !== null)
      ? FunctionCall.fromPartial(object.function_call)
      : undefined;
    message.refusal = object.refusal ?? "";
    return message;
  },
};

function createBaseToolCall(): ToolCall {
  return { id: "", type: "", function: undefined, index: undefined };
}

export const ToolCall = {
  encode(message: ToolCall, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.type !== "") {
      writer.uint32(18).string(message.type);
    }
    if (message.function !== undefined) {
      ToolCallFunction.encode(message.function, writer.uint32(26).fork()).ldelim();
    }
    if (message.index !== undefined) {
      writer.uint32(32).int64(message.index);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ToolCall {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToolCall();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.function = ToolCallFunction.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.index = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ToolCall {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      function: isSet(object.function) ? ToolCallFunction.fromJSON(object.function) : undefined,
      index: isSet(object.index) ? globalThis.Number(object.index) : undefined,
    };
  },

  toJSON(message: ToolCall): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.function !== undefined) {
      obj.function = ToolCallFunction.toJSON(message.function);
    }
    if (message.index !== undefined) {
      obj.index = Math.round(message.index);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ToolCall>, I>>(base?: I): ToolCall {
    return ToolCall.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ToolCall>, I>>(object: I): ToolCall {
    const message = createBaseToolCall();
    message.id = object.id ?? "";
    message.type = object.type ?? "";
    message.function = (object.function !== undefined && object.function !== null)
      ? ToolCallFunction.fromPartial(object.function)
      : undefined;
    message.index = object.index ?? undefined;
    return message;
  },
};

function createBaseToolCallFunction(): ToolCallFunction {
  return { name: "", arguments: "" };
}

export const ToolCallFunction = {
  encode(message: ToolCallFunction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.arguments !== "") {
      writer.uint32(18).string(message.arguments);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ToolCallFunction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToolCallFunction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.arguments = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ToolCallFunction {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      arguments: isSet(object.arguments) ? globalThis.String(object.arguments) : "",
    };
  },

  toJSON(message: ToolCallFunction): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.arguments !== "") {
      obj.arguments = message.arguments;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ToolCallFunction>, I>>(base?: I): ToolCallFunction {
    return ToolCallFunction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ToolCallFunction>, I>>(object: I): ToolCallFunction {
    const message = createBaseToolCallFunction();
    message.name = object.name ?? "";
    message.arguments = object.arguments ?? "";
    return message;
  },
};

function createBaseLogProbs(): LogProbs {
  return { content: [], refusal: [] };
}

export const LogProbs = {
  encode(message: LogProbs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.content) {
      LogPropsContent.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.refusal) {
      LogPropsContent.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogProbs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogProbs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.content.push(LogPropsContent.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.refusal.push(LogPropsContent.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogProbs {
    return {
      content: globalThis.Array.isArray(object?.content)
        ? object.content.map((e: any) => LogPropsContent.fromJSON(e))
        : [],
      refusal: globalThis.Array.isArray(object?.refusal)
        ? object.refusal.map((e: any) => LogPropsContent.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LogProbs): unknown {
    const obj: any = {};
    if (message.content?.length) {
      obj.content = message.content.map((e) => LogPropsContent.toJSON(e));
    }
    if (message.refusal?.length) {
      obj.refusal = message.refusal.map((e) => LogPropsContent.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LogProbs>, I>>(base?: I): LogProbs {
    return LogProbs.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogProbs>, I>>(object: I): LogProbs {
    const message = createBaseLogProbs();
    message.content = object.content?.map((e) => LogPropsContent.fromPartial(e)) || [];
    message.refusal = object.refusal?.map((e) => LogPropsContent.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLogPropsContent(): LogPropsContent {
  return { token: "", logprob: 0, bytes: [], top_logprobs: [] };
}

export const LogPropsContent = {
  encode(message: LogPropsContent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.logprob !== 0) {
      writer.uint32(17).double(message.logprob);
    }
    writer.uint32(26).fork();
    for (const v of message.bytes) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.top_logprobs) {
      TopLogProbs.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LogPropsContent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLogPropsContent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.logprob = reader.double();
          continue;
        case 3:
          if (tag === 24) {
            message.bytes.push(reader.int32());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.bytes.push(reader.int32());
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.top_logprobs.push(TopLogProbs.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LogPropsContent {
    return {
      token: isSet(object.token) ? globalThis.String(object.token) : "",
      logprob: isSet(object.logprob) ? globalThis.Number(object.logprob) : 0,
      bytes: globalThis.Array.isArray(object?.bytes) ? object.bytes.map((e: any) => globalThis.Number(e)) : [],
      top_logprobs: globalThis.Array.isArray(object?.top_logprobs)
        ? object.top_logprobs.map((e: any) => TopLogProbs.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LogPropsContent): unknown {
    const obj: any = {};
    if (message.token !== "") {
      obj.token = message.token;
    }
    if (message.logprob !== 0) {
      obj.logprob = message.logprob;
    }
    if (message.bytes?.length) {
      obj.bytes = message.bytes.map((e) => Math.round(e));
    }
    if (message.top_logprobs?.length) {
      obj.top_logprobs = message.top_logprobs.map((e) => TopLogProbs.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LogPropsContent>, I>>(base?: I): LogPropsContent {
    return LogPropsContent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LogPropsContent>, I>>(object: I): LogPropsContent {
    const message = createBaseLogPropsContent();
    message.token = object.token ?? "";
    message.logprob = object.logprob ?? 0;
    message.bytes = object.bytes?.map((e) => e) || [];
    message.top_logprobs = object.top_logprobs?.map((e) => TopLogProbs.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTopLogProbs(): TopLogProbs {
  return { token: "", logprob: 0, bytes: [] };
}

export const TopLogProbs = {
  encode(message: TopLogProbs, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.token !== "") {
      writer.uint32(10).string(message.token);
    }
    if (message.logprob !== 0) {
      writer.uint32(17).double(message.logprob);
    }
    writer.uint32(26).fork();
    for (const v of message.bytes) {
      writer.double(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TopLogProbs {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTopLogProbs();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.token = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.logprob = reader.double();
          continue;
        case 3:
          if (tag === 25) {
            message.bytes.push(reader.double());

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.bytes.push(reader.double());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TopLogProbs {
    return {
      token: isSet(object.token) ? globalThis.String(object.token) : "",
      logprob: isSet(object.logprob) ? globalThis.Number(object.logprob) : 0,
      bytes: globalThis.Array.isArray(object?.bytes) ? object.bytes.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: TopLogProbs): unknown {
    const obj: any = {};
    if (message.token !== "") {
      obj.token = message.token;
    }
    if (message.logprob !== 0) {
      obj.logprob = message.logprob;
    }
    if (message.bytes?.length) {
      obj.bytes = message.bytes;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TopLogProbs>, I>>(base?: I): TopLogProbs {
    return TopLogProbs.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TopLogProbs>, I>>(object: I): TopLogProbs {
    const message = createBaseTopLogProbs();
    message.token = object.token ?? "";
    message.logprob = object.logprob ?? 0;
    message.bytes = object.bytes?.map((e) => e) || [];
    return message;
  },
};

function createBaseChatStorageRequest(): ChatStorageRequest {
  return {
    chat_completion_request: undefined,
    chat_completion_response: undefined,
    evaluate_request: undefined,
    timing_metrics: undefined,
  };
}

export const ChatStorageRequest = {
  encode(message: ChatStorageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.chat_completion_request !== undefined) {
      ChatCompletionRequest.encode(message.chat_completion_request, writer.uint32(10).fork()).ldelim();
    }
    if (message.chat_completion_response !== undefined) {
      ChatCompletionResponse.encode(message.chat_completion_response, writer.uint32(18).fork()).ldelim();
    }
    if (message.evaluate_request !== undefined) {
      EvaluateRequest.encode(message.evaluate_request, writer.uint32(26).fork()).ldelim();
    }
    if (message.timing_metrics !== undefined) {
      RequestTimingMetric.encode(message.timing_metrics, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatStorageRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatStorageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.chat_completion_request = ChatCompletionRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.chat_completion_response = ChatCompletionResponse.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.evaluate_request = EvaluateRequest.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.timing_metrics = RequestTimingMetric.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatStorageRequest {
    return {
      chat_completion_request: isSet(object.chat_completion_request)
        ? ChatCompletionRequest.fromJSON(object.chat_completion_request)
        : undefined,
      chat_completion_response: isSet(object.chat_completion_response)
        ? ChatCompletionResponse.fromJSON(object.chat_completion_response)
        : undefined,
      evaluate_request: isSet(object.evaluate_request) ? EvaluateRequest.fromJSON(object.evaluate_request) : undefined,
      timing_metrics: isSet(object.timing_metrics) ? RequestTimingMetric.fromJSON(object.timing_metrics) : undefined,
    };
  },

  toJSON(message: ChatStorageRequest): unknown {
    const obj: any = {};
    if (message.chat_completion_request !== undefined) {
      obj.chat_completion_request = ChatCompletionRequest.toJSON(message.chat_completion_request);
    }
    if (message.chat_completion_response !== undefined) {
      obj.chat_completion_response = ChatCompletionResponse.toJSON(message.chat_completion_response);
    }
    if (message.evaluate_request !== undefined) {
      obj.evaluate_request = EvaluateRequest.toJSON(message.evaluate_request);
    }
    if (message.timing_metrics !== undefined) {
      obj.timing_metrics = RequestTimingMetric.toJSON(message.timing_metrics);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatStorageRequest>, I>>(base?: I): ChatStorageRequest {
    return ChatStorageRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatStorageRequest>, I>>(object: I): ChatStorageRequest {
    const message = createBaseChatStorageRequest();
    message.chat_completion_request =
      (object.chat_completion_request !== undefined && object.chat_completion_request !== null)
        ? ChatCompletionRequest.fromPartial(object.chat_completion_request)
        : undefined;
    message.chat_completion_response =
      (object.chat_completion_response !== undefined && object.chat_completion_response !== null)
        ? ChatCompletionResponse.fromPartial(object.chat_completion_response)
        : undefined;
    message.evaluate_request = (object.evaluate_request !== undefined && object.evaluate_request !== null)
      ? EvaluateRequest.fromPartial(object.evaluate_request)
      : undefined;
    message.timing_metrics = (object.timing_metrics !== undefined && object.timing_metrics !== null)
      ? RequestTimingMetric.fromPartial(object.timing_metrics)
      : undefined;
    return message;
  },
};

function createBaseEvaluateRequest(): EvaluateRequest {
  return {
    id: 0,
    date_created: 0,
    application_id: 0,
    application_ref_name: "",
    session_id: "",
    reference_id: "",
    action_type: "",
    evaluation_content_type: 0,
    eval_results_set: undefined,
    company_id: 0,
    evaluation_context: "",
    text_content: "",
    message_content: [],
    request_id: "",
    sentinel_id: 0,
    fault_description: "",
    chat_completion_request: undefined,
    chat_completion_response: undefined,
    timeout: 0,
    partial: "",
    stream: false,
    apply_corrections: false,
    timing_metrics: undefined,
    tools: [],
  };
}

export const EvaluateRequest = {
  encode(message: EvaluateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.date_created !== 0) {
      writer.uint32(16).int64(message.date_created);
    }
    if (message.application_id !== 0) {
      writer.uint32(24).int64(message.application_id);
    }
    if (message.application_ref_name !== "") {
      writer.uint32(34).string(message.application_ref_name);
    }
    if (message.session_id !== "") {
      writer.uint32(42).string(message.session_id);
    }
    if (message.reference_id !== "") {
      writer.uint32(50).string(message.reference_id);
    }
    if (message.action_type !== "") {
      writer.uint32(58).string(message.action_type);
    }
    if (message.evaluation_content_type !== 0) {
      writer.uint32(64).int32(message.evaluation_content_type);
    }
    if (message.eval_results_set !== undefined) {
      EvaluateResponse.encode(message.eval_results_set, writer.uint32(74).fork()).ldelim();
    }
    if (message.company_id !== 0) {
      writer.uint32(80).int64(message.company_id);
    }
    if (message.evaluation_context !== "") {
      writer.uint32(90).string(message.evaluation_context);
    }
    if (message.text_content !== "") {
      writer.uint32(98).string(message.text_content);
    }
    for (const v of message.message_content) {
      ChatMessage.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.request_id !== "") {
      writer.uint32(114).string(message.request_id);
    }
    if (message.sentinel_id !== 0) {
      writer.uint32(120).int64(message.sentinel_id);
    }
    if (message.fault_description !== "") {
      writer.uint32(130).string(message.fault_description);
    }
    if (message.chat_completion_request !== undefined) {
      ChatCompletionRequest.encode(message.chat_completion_request, writer.uint32(138).fork()).ldelim();
    }
    if (message.chat_completion_response !== undefined) {
      ChatCompletionResponse.encode(message.chat_completion_response, writer.uint32(146).fork()).ldelim();
    }
    if (message.timeout !== 0) {
      writer.uint32(157).float(message.timeout);
    }
    if (message.partial !== "") {
      writer.uint32(162).string(message.partial);
    }
    if (message.stream !== false) {
      writer.uint32(168).bool(message.stream);
    }
    if (message.apply_corrections !== false) {
      writer.uint32(176).bool(message.apply_corrections);
    }
    if (message.timing_metrics !== undefined) {
      RequestTimingMetric.encode(message.timing_metrics, writer.uint32(186).fork()).ldelim();
    }
    for (const v of message.tools) {
      Tool.encode(v!, writer.uint32(194).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvaluateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvaluateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.application_id = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.application_ref_name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.session_id = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.reference_id = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.action_type = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.evaluation_content_type = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.eval_results_set = EvaluateResponse.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.company_id = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.evaluation_context = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.text_content = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.message_content.push(ChatMessage.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.request_id = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.sentinel_id = longToNumber(reader.int64() as Long);
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.fault_description = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.chat_completion_request = ChatCompletionRequest.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.chat_completion_response = ChatCompletionResponse.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 157) {
            break;
          }

          message.timeout = reader.float();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.partial = reader.string();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.stream = reader.bool();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.apply_corrections = reader.bool();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.timing_metrics = RequestTimingMetric.decode(reader, reader.uint32());
          continue;
        case 24:
          if (tag !== 194) {
            break;
          }

          message.tools.push(Tool.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EvaluateRequest {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      application_id: isSet(object.application_id) ? globalThis.Number(object.application_id) : 0,
      application_ref_name: isSet(object.application_ref_name) ? globalThis.String(object.application_ref_name) : "",
      session_id: isSet(object.session_id) ? globalThis.String(object.session_id) : "",
      reference_id: isSet(object.reference_id) ? globalThis.String(object.reference_id) : "",
      action_type: isSet(object.action_type) ? globalThis.String(object.action_type) : "",
      evaluation_content_type: isSet(object.evaluation_content_type)
        ? evaluationContentTypeFromJSON(object.evaluation_content_type)
        : 0,
      eval_results_set: isSet(object.eval_results_set) ? EvaluateResponse.fromJSON(object.eval_results_set) : undefined,
      company_id: isSet(object.company_id) ? globalThis.Number(object.company_id) : 0,
      evaluation_context: isSet(object.evaluation_context) ? globalThis.String(object.evaluation_context) : "",
      text_content: isSet(object.text_content) ? globalThis.String(object.text_content) : "",
      message_content: globalThis.Array.isArray(object?.message_content)
        ? object.message_content.map((e: any) => ChatMessage.fromJSON(e))
        : [],
      request_id: isSet(object.request_id) ? globalThis.String(object.request_id) : "",
      sentinel_id: isSet(object.sentinel_id) ? globalThis.Number(object.sentinel_id) : 0,
      fault_description: isSet(object.fault_description) ? globalThis.String(object.fault_description) : "",
      chat_completion_request: isSet(object.chat_completion_request)
        ? ChatCompletionRequest.fromJSON(object.chat_completion_request)
        : undefined,
      chat_completion_response: isSet(object.chat_completion_response)
        ? ChatCompletionResponse.fromJSON(object.chat_completion_response)
        : undefined,
      timeout: isSet(object.timeout) ? globalThis.Number(object.timeout) : 0,
      partial: isSet(object.partial) ? globalThis.String(object.partial) : "",
      stream: isSet(object.stream) ? globalThis.Boolean(object.stream) : false,
      apply_corrections: isSet(object.apply_corrections) ? globalThis.Boolean(object.apply_corrections) : false,
      timing_metrics: isSet(object.timing_metrics) ? RequestTimingMetric.fromJSON(object.timing_metrics) : undefined,
      tools: globalThis.Array.isArray(object?.tools) ? object.tools.map((e: any) => Tool.fromJSON(e)) : [],
    };
  },

  toJSON(message: EvaluateRequest): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.application_id !== 0) {
      obj.application_id = Math.round(message.application_id);
    }
    if (message.application_ref_name !== "") {
      obj.application_ref_name = message.application_ref_name;
    }
    if (message.session_id !== "") {
      obj.session_id = message.session_id;
    }
    if (message.reference_id !== "") {
      obj.reference_id = message.reference_id;
    }
    if (message.action_type !== "") {
      obj.action_type = message.action_type;
    }
    if (message.evaluation_content_type !== 0) {
      obj.evaluation_content_type = evaluationContentTypeToJSON(message.evaluation_content_type);
    }
    if (message.eval_results_set !== undefined) {
      obj.eval_results_set = EvaluateResponse.toJSON(message.eval_results_set);
    }
    if (message.company_id !== 0) {
      obj.company_id = Math.round(message.company_id);
    }
    if (message.evaluation_context !== "") {
      obj.evaluation_context = message.evaluation_context;
    }
    if (message.text_content !== "") {
      obj.text_content = message.text_content;
    }
    if (message.message_content?.length) {
      obj.message_content = message.message_content.map((e) => ChatMessage.toJSON(e));
    }
    if (message.request_id !== "") {
      obj.request_id = message.request_id;
    }
    if (message.sentinel_id !== 0) {
      obj.sentinel_id = Math.round(message.sentinel_id);
    }
    if (message.fault_description !== "") {
      obj.fault_description = message.fault_description;
    }
    if (message.chat_completion_request !== undefined) {
      obj.chat_completion_request = ChatCompletionRequest.toJSON(message.chat_completion_request);
    }
    if (message.chat_completion_response !== undefined) {
      obj.chat_completion_response = ChatCompletionResponse.toJSON(message.chat_completion_response);
    }
    if (message.timeout !== 0) {
      obj.timeout = message.timeout;
    }
    if (message.partial !== "") {
      obj.partial = message.partial;
    }
    if (message.stream !== false) {
      obj.stream = message.stream;
    }
    if (message.apply_corrections !== false) {
      obj.apply_corrections = message.apply_corrections;
    }
    if (message.timing_metrics !== undefined) {
      obj.timing_metrics = RequestTimingMetric.toJSON(message.timing_metrics);
    }
    if (message.tools?.length) {
      obj.tools = message.tools.map((e) => Tool.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EvaluateRequest>, I>>(base?: I): EvaluateRequest {
    return EvaluateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EvaluateRequest>, I>>(object: I): EvaluateRequest {
    const message = createBaseEvaluateRequest();
    message.id = object.id ?? 0;
    message.date_created = object.date_created ?? 0;
    message.application_id = object.application_id ?? 0;
    message.application_ref_name = object.application_ref_name ?? "";
    message.session_id = object.session_id ?? "";
    message.reference_id = object.reference_id ?? "";
    message.action_type = object.action_type ?? "";
    message.evaluation_content_type = object.evaluation_content_type ?? 0;
    message.eval_results_set = (object.eval_results_set !== undefined && object.eval_results_set !== null)
      ? EvaluateResponse.fromPartial(object.eval_results_set)
      : undefined;
    message.company_id = object.company_id ?? 0;
    message.evaluation_context = object.evaluation_context ?? "";
    message.text_content = object.text_content ?? "";
    message.message_content = object.message_content?.map((e) => ChatMessage.fromPartial(e)) || [];
    message.request_id = object.request_id ?? "";
    message.sentinel_id = object.sentinel_id ?? 0;
    message.fault_description = object.fault_description ?? "";
    message.chat_completion_request =
      (object.chat_completion_request !== undefined && object.chat_completion_request !== null)
        ? ChatCompletionRequest.fromPartial(object.chat_completion_request)
        : undefined;
    message.chat_completion_response =
      (object.chat_completion_response !== undefined && object.chat_completion_response !== null)
        ? ChatCompletionResponse.fromPartial(object.chat_completion_response)
        : undefined;
    message.timeout = object.timeout ?? 0;
    message.partial = object.partial ?? "";
    message.stream = object.stream ?? false;
    message.apply_corrections = object.apply_corrections ?? false;
    message.timing_metrics = (object.timing_metrics !== undefined && object.timing_metrics !== null)
      ? RequestTimingMetric.fromPartial(object.timing_metrics)
      : undefined;
    message.tools = object.tools?.map((e) => Tool.fromPartial(e)) || [];
    return message;
  },
};

function createBaseEvaluateResult(): EvaluateResult {
  return {
    id: 0,
    status: "",
    description: "",
    confidence: 0,
    meta: {},
    sentinel_id: 0,
    eval_time: 0,
    date_created: 0,
    usage: undefined,
    sentinel_name: "",
    severity: 0,
    correction: "",
  };
}

export const EvaluateResult = {
  encode(message: EvaluateResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.status !== "") {
      writer.uint32(18).string(message.status);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.confidence !== 0) {
      writer.uint32(33).double(message.confidence);
    }
    Object.entries(message.meta).forEach(([key, value]) => {
      EvaluateResult_MetaEntry.encode({ key: key as any, value }, writer.uint32(42).fork()).ldelim();
    });
    if (message.sentinel_id !== 0) {
      writer.uint32(48).int64(message.sentinel_id);
    }
    if (message.eval_time !== 0) {
      writer.uint32(56).int64(message.eval_time);
    }
    if (message.date_created !== 0) {
      writer.uint32(72).int64(message.date_created);
    }
    if (message.usage !== undefined) {
      ChatCompletionUsage.encode(message.usage, writer.uint32(82).fork()).ldelim();
    }
    if (message.sentinel_name !== "") {
      writer.uint32(90).string(message.sentinel_name);
    }
    if (message.severity !== 0) {
      writer.uint32(96).int64(message.severity);
    }
    if (message.correction !== "") {
      writer.uint32(106).string(message.correction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvaluateResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvaluateResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.status = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 33) {
            break;
          }

          message.confidence = reader.double();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          const entry5 = EvaluateResult_MetaEntry.decode(reader, reader.uint32());
          if (entry5.value !== undefined) {
            message.meta[entry5.key] = entry5.value;
          }
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.sentinel_id = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.eval_time = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.usage = ChatCompletionUsage.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.sentinel_name = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.severity = longToNumber(reader.int64() as Long);
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.correction = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EvaluateResult {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      status: isSet(object.status) ? globalThis.String(object.status) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
      meta: isObject(object.meta)
        ? Object.entries(object.meta).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      sentinel_id: isSet(object.sentinel_id) ? globalThis.Number(object.sentinel_id) : 0,
      eval_time: isSet(object.eval_time) ? globalThis.Number(object.eval_time) : 0,
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      usage: isSet(object.usage) ? ChatCompletionUsage.fromJSON(object.usage) : undefined,
      sentinel_name: isSet(object.sentinel_name) ? globalThis.String(object.sentinel_name) : "",
      severity: isSet(object.severity) ? globalThis.Number(object.severity) : 0,
      correction: isSet(object.correction) ? globalThis.String(object.correction) : "",
    };
  },

  toJSON(message: EvaluateResult): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.status !== "") {
      obj.status = message.status;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    if (message.meta) {
      const entries = Object.entries(message.meta);
      if (entries.length > 0) {
        obj.meta = {};
        entries.forEach(([k, v]) => {
          obj.meta[k] = v;
        });
      }
    }
    if (message.sentinel_id !== 0) {
      obj.sentinel_id = Math.round(message.sentinel_id);
    }
    if (message.eval_time !== 0) {
      obj.eval_time = Math.round(message.eval_time);
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.usage !== undefined) {
      obj.usage = ChatCompletionUsage.toJSON(message.usage);
    }
    if (message.sentinel_name !== "") {
      obj.sentinel_name = message.sentinel_name;
    }
    if (message.severity !== 0) {
      obj.severity = Math.round(message.severity);
    }
    if (message.correction !== "") {
      obj.correction = message.correction;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EvaluateResult>, I>>(base?: I): EvaluateResult {
    return EvaluateResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EvaluateResult>, I>>(object: I): EvaluateResult {
    const message = createBaseEvaluateResult();
    message.id = object.id ?? 0;
    message.status = object.status ?? "";
    message.description = object.description ?? "";
    message.confidence = object.confidence ?? 0;
    message.meta = Object.entries(object.meta ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.sentinel_id = object.sentinel_id ?? 0;
    message.eval_time = object.eval_time ?? 0;
    message.date_created = object.date_created ?? 0;
    message.usage = (object.usage !== undefined && object.usage !== null)
      ? ChatCompletionUsage.fromPartial(object.usage)
      : undefined;
    message.sentinel_name = object.sentinel_name ?? "";
    message.severity = object.severity ?? 0;
    message.correction = object.correction ?? "";
    return message;
  },
};

function createBaseEvaluateResult_MetaEntry(): EvaluateResult_MetaEntry {
  return { key: "", value: "" };
}

export const EvaluateResult_MetaEntry = {
  encode(message: EvaluateResult_MetaEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvaluateResult_MetaEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvaluateResult_MetaEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EvaluateResult_MetaEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: EvaluateResult_MetaEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EvaluateResult_MetaEntry>, I>>(base?: I): EvaluateResult_MetaEntry {
    return EvaluateResult_MetaEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EvaluateResult_MetaEntry>, I>>(object: I): EvaluateResult_MetaEntry {
    const message = createBaseEvaluateResult_MetaEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseEvaluateResponse(): EvaluateResponse {
  return {
    application_id: 0,
    session_id: "",
    request_id: 0,
    evaluation_results: [],
    evaluation_request_id: "",
    evaluate_summary: "",
    evaluation_time: 0,
    reference_id: "",
  };
}

export const EvaluateResponse = {
  encode(message: EvaluateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.application_id !== 0) {
      writer.uint32(8).int64(message.application_id);
    }
    if (message.session_id !== "") {
      writer.uint32(18).string(message.session_id);
    }
    if (message.request_id !== 0) {
      writer.uint32(24).int64(message.request_id);
    }
    for (const v of message.evaluation_results) {
      EvaluateResult.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.evaluation_request_id !== "") {
      writer.uint32(42).string(message.evaluation_request_id);
    }
    if (message.evaluate_summary !== "") {
      writer.uint32(58).string(message.evaluate_summary);
    }
    if (message.evaluation_time !== 0) {
      writer.uint32(64).int64(message.evaluation_time);
    }
    if (message.reference_id !== "") {
      writer.uint32(74).string(message.reference_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): EvaluateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvaluateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.application_id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.session_id = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.request_id = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.evaluation_results.push(EvaluateResult.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.evaluation_request_id = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.evaluate_summary = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.evaluation_time = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.reference_id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): EvaluateResponse {
    return {
      application_id: isSet(object.application_id) ? globalThis.Number(object.application_id) : 0,
      session_id: isSet(object.session_id) ? globalThis.String(object.session_id) : "",
      request_id: isSet(object.request_id) ? globalThis.Number(object.request_id) : 0,
      evaluation_results: globalThis.Array.isArray(object?.evaluation_results)
        ? object.evaluation_results.map((e: any) => EvaluateResult.fromJSON(e))
        : [],
      evaluation_request_id: isSet(object.evaluation_request_id) ? globalThis.String(object.evaluation_request_id) : "",
      evaluate_summary: isSet(object.evaluate_summary) ? globalThis.String(object.evaluate_summary) : "",
      evaluation_time: isSet(object.evaluation_time) ? globalThis.Number(object.evaluation_time) : 0,
      reference_id: isSet(object.reference_id) ? globalThis.String(object.reference_id) : "",
    };
  },

  toJSON(message: EvaluateResponse): unknown {
    const obj: any = {};
    if (message.application_id !== 0) {
      obj.application_id = Math.round(message.application_id);
    }
    if (message.session_id !== "") {
      obj.session_id = message.session_id;
    }
    if (message.request_id !== 0) {
      obj.request_id = Math.round(message.request_id);
    }
    if (message.evaluation_results?.length) {
      obj.evaluation_results = message.evaluation_results.map((e) => EvaluateResult.toJSON(e));
    }
    if (message.evaluation_request_id !== "") {
      obj.evaluation_request_id = message.evaluation_request_id;
    }
    if (message.evaluate_summary !== "") {
      obj.evaluate_summary = message.evaluate_summary;
    }
    if (message.evaluation_time !== 0) {
      obj.evaluation_time = Math.round(message.evaluation_time);
    }
    if (message.reference_id !== "") {
      obj.reference_id = message.reference_id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<EvaluateResponse>, I>>(base?: I): EvaluateResponse {
    return EvaluateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<EvaluateResponse>, I>>(object: I): EvaluateResponse {
    const message = createBaseEvaluateResponse();
    message.application_id = object.application_id ?? 0;
    message.session_id = object.session_id ?? "";
    message.request_id = object.request_id ?? 0;
    message.evaluation_results = object.evaluation_results?.map((e) => EvaluateResult.fromPartial(e)) || [];
    message.evaluation_request_id = object.evaluation_request_id ?? "";
    message.evaluate_summary = object.evaluate_summary ?? "";
    message.evaluation_time = object.evaluation_time ?? 0;
    message.reference_id = object.reference_id ?? "";
    return message;
  },
};

function createBaseTurn(): Turn {
  return {
    request: undefined,
    response: undefined,
    eval_request: undefined,
    application: undefined,
    first: false,
    last: false,
  };
}

export const Turn = {
  encode(message: Turn, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      ChatCompletionRequest.encode(message.request, writer.uint32(10).fork()).ldelim();
    }
    if (message.response !== undefined) {
      ChatCompletionResponse.encode(message.response, writer.uint32(18).fork()).ldelim();
    }
    if (message.eval_request !== undefined) {
      EvaluateRequest.encode(message.eval_request, writer.uint32(26).fork()).ldelim();
    }
    if (message.application !== undefined) {
      Application.encode(message.application, writer.uint32(34).fork()).ldelim();
    }
    if (message.first !== false) {
      writer.uint32(40).bool(message.first);
    }
    if (message.last !== false) {
      writer.uint32(48).bool(message.last);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Turn {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTurn();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = ChatCompletionRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.response = ChatCompletionResponse.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.eval_request = EvaluateRequest.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.application = Application.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.first = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.last = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Turn {
    return {
      request: isSet(object.request) ? ChatCompletionRequest.fromJSON(object.request) : undefined,
      response: isSet(object.response) ? ChatCompletionResponse.fromJSON(object.response) : undefined,
      eval_request: isSet(object.eval_request) ? EvaluateRequest.fromJSON(object.eval_request) : undefined,
      application: isSet(object.application) ? Application.fromJSON(object.application) : undefined,
      first: isSet(object.first) ? globalThis.Boolean(object.first) : false,
      last: isSet(object.last) ? globalThis.Boolean(object.last) : false,
    };
  },

  toJSON(message: Turn): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = ChatCompletionRequest.toJSON(message.request);
    }
    if (message.response !== undefined) {
      obj.response = ChatCompletionResponse.toJSON(message.response);
    }
    if (message.eval_request !== undefined) {
      obj.eval_request = EvaluateRequest.toJSON(message.eval_request);
    }
    if (message.application !== undefined) {
      obj.application = Application.toJSON(message.application);
    }
    if (message.first !== false) {
      obj.first = message.first;
    }
    if (message.last !== false) {
      obj.last = message.last;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Turn>, I>>(base?: I): Turn {
    return Turn.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Turn>, I>>(object: I): Turn {
    const message = createBaseTurn();
    message.request = (object.request !== undefined && object.request !== null)
      ? ChatCompletionRequest.fromPartial(object.request)
      : undefined;
    message.response = (object.response !== undefined && object.response !== null)
      ? ChatCompletionResponse.fromPartial(object.response)
      : undefined;
    message.eval_request = (object.eval_request !== undefined && object.eval_request !== null)
      ? EvaluateRequest.fromPartial(object.eval_request)
      : undefined;
    message.application = (object.application !== undefined && object.application !== null)
      ? Application.fromPartial(object.application)
      : undefined;
    message.first = object.first ?? false;
    message.last = object.last ?? false;
    return message;
  },
};

function createBaseSessionMessage(): SessionMessage {
  return {
    id: 0,
    application_id: 0,
    company_id: 0,
    application_action_id: 0,
    session_id: "",
    request_id: "",
    role: "",
    message: undefined,
    instructions: "",
    date_created: 0,
  };
}

export const SessionMessage = {
  encode(message: SessionMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.application_id !== 0) {
      writer.uint32(16).int64(message.application_id);
    }
    if (message.company_id !== 0) {
      writer.uint32(24).int64(message.company_id);
    }
    if (message.application_action_id !== 0) {
      writer.uint32(32).int64(message.application_action_id);
    }
    if (message.session_id !== "") {
      writer.uint32(42).string(message.session_id);
    }
    if (message.request_id !== "") {
      writer.uint32(50).string(message.request_id);
    }
    if (message.role !== "") {
      writer.uint32(58).string(message.role);
    }
    if (message.message !== undefined) {
      ChatMessage.encode(message.message, writer.uint32(66).fork()).ldelim();
    }
    if (message.instructions !== "") {
      writer.uint32(74).string(message.instructions);
    }
    if (message.date_created !== 0) {
      writer.uint32(80).int64(message.date_created);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SessionMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSessionMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.application_id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.company_id = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.application_action_id = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.session_id = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.request_id = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.role = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.message = ChatMessage.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.instructions = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SessionMessage {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      application_id: isSet(object.application_id) ? globalThis.Number(object.application_id) : 0,
      company_id: isSet(object.company_id) ? globalThis.Number(object.company_id) : 0,
      application_action_id: isSet(object.application_action_id) ? globalThis.Number(object.application_action_id) : 0,
      session_id: isSet(object.session_id) ? globalThis.String(object.session_id) : "",
      request_id: isSet(object.request_id) ? globalThis.String(object.request_id) : "",
      role: isSet(object.role) ? globalThis.String(object.role) : "",
      message: isSet(object.message) ? ChatMessage.fromJSON(object.message) : undefined,
      instructions: isSet(object.instructions) ? globalThis.String(object.instructions) : "",
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
    };
  },

  toJSON(message: SessionMessage): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.application_id !== 0) {
      obj.application_id = Math.round(message.application_id);
    }
    if (message.company_id !== 0) {
      obj.company_id = Math.round(message.company_id);
    }
    if (message.application_action_id !== 0) {
      obj.application_action_id = Math.round(message.application_action_id);
    }
    if (message.session_id !== "") {
      obj.session_id = message.session_id;
    }
    if (message.request_id !== "") {
      obj.request_id = message.request_id;
    }
    if (message.role !== "") {
      obj.role = message.role;
    }
    if (message.message !== undefined) {
      obj.message = ChatMessage.toJSON(message.message);
    }
    if (message.instructions !== "") {
      obj.instructions = message.instructions;
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SessionMessage>, I>>(base?: I): SessionMessage {
    return SessionMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SessionMessage>, I>>(object: I): SessionMessage {
    const message = createBaseSessionMessage();
    message.id = object.id ?? 0;
    message.application_id = object.application_id ?? 0;
    message.company_id = object.company_id ?? 0;
    message.application_action_id = object.application_action_id ?? 0;
    message.session_id = object.session_id ?? "";
    message.request_id = object.request_id ?? "";
    message.role = object.role ?? "";
    message.message = (object.message !== undefined && object.message !== null)
      ? ChatMessage.fromPartial(object.message)
      : undefined;
    message.instructions = object.instructions ?? "";
    message.date_created = object.date_created ?? 0;
    return message;
  },
};

function createBaseBacktestEvaluateRequest(): BacktestEvaluateRequest {
  return {
    id: 0,
    date_created: 0,
    run_id: 0,
    company_id: 0,
    application_id: 0,
    backtest_application_id: 0,
    session_id: "",
    request_id: "",
    inference_request_id: "",
    action_type: "",
    request_messages: [],
    response_message: undefined,
    sentinels: [],
    tools: [],
  };
}

export const BacktestEvaluateRequest = {
  encode(message: BacktestEvaluateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.date_created !== 0) {
      writer.uint32(16).int64(message.date_created);
    }
    if (message.run_id !== 0) {
      writer.uint32(24).int64(message.run_id);
    }
    if (message.company_id !== 0) {
      writer.uint32(32).int64(message.company_id);
    }
    if (message.application_id !== 0) {
      writer.uint32(40).int64(message.application_id);
    }
    if (message.backtest_application_id !== 0) {
      writer.uint32(48).int64(message.backtest_application_id);
    }
    if (message.session_id !== "") {
      writer.uint32(58).string(message.session_id);
    }
    if (message.request_id !== "") {
      writer.uint32(66).string(message.request_id);
    }
    if (message.inference_request_id !== "") {
      writer.uint32(74).string(message.inference_request_id);
    }
    if (message.action_type !== "") {
      writer.uint32(82).string(message.action_type);
    }
    for (const v of message.request_messages) {
      ChatMessage.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.response_message !== undefined) {
      ChatCompletionMessage.encode(message.response_message, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.sentinels) {
      Sentinel.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.tools) {
      Tool.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BacktestEvaluateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBacktestEvaluateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.run_id = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.company_id = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.application_id = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.backtest_application_id = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.session_id = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.request_id = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.inference_request_id = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.action_type = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.request_messages.push(ChatMessage.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.response_message = ChatCompletionMessage.decode(reader, reader.uint32());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.sentinels.push(Sentinel.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.tools.push(Tool.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BacktestEvaluateRequest {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      run_id: isSet(object.run_id) ? globalThis.Number(object.run_id) : 0,
      company_id: isSet(object.company_id) ? globalThis.Number(object.company_id) : 0,
      application_id: isSet(object.application_id) ? globalThis.Number(object.application_id) : 0,
      backtest_application_id: isSet(object.backtest_application_id)
        ? globalThis.Number(object.backtest_application_id)
        : 0,
      session_id: isSet(object.session_id) ? globalThis.String(object.session_id) : "",
      request_id: isSet(object.request_id) ? globalThis.String(object.request_id) : "",
      inference_request_id: isSet(object.inference_request_id) ? globalThis.String(object.inference_request_id) : "",
      action_type: isSet(object.action_type) ? globalThis.String(object.action_type) : "",
      request_messages: globalThis.Array.isArray(object?.request_messages)
        ? object.request_messages.map((e: any) => ChatMessage.fromJSON(e))
        : [],
      response_message: isSet(object.response_message)
        ? ChatCompletionMessage.fromJSON(object.response_message)
        : undefined,
      sentinels: globalThis.Array.isArray(object?.sentinels)
        ? object.sentinels.map((e: any) => Sentinel.fromJSON(e))
        : [],
      tools: globalThis.Array.isArray(object?.tools)
        ? object.tools.map((e: any) => Tool.fromJSON(e))
        : [],
    };
  },

  toJSON(message: BacktestEvaluateRequest): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.run_id !== 0) {
      obj.run_id = Math.round(message.run_id);
    }
    if (message.company_id !== 0) {
      obj.company_id = Math.round(message.company_id);
    }
    if (message.application_id !== 0) {
      obj.application_id = Math.round(message.application_id);
    }
    if (message.backtest_application_id !== 0) {
      obj.backtest_application_id = Math.round(message.backtest_application_id);
    }
    if (message.session_id !== "") {
      obj.session_id = message.session_id;
    }
    if (message.request_id !== "") {
      obj.request_id = message.request_id;
    }
    if (message.inference_request_id !== "") {
      obj.inference_request_id = message.inference_request_id;
    }
    if (message.action_type !== "") {
      obj.action_type = message.action_type;
    }
    if (message.request_messages?.length) {
      obj.request_messages = message.request_messages.map((e) => ChatMessage.toJSON(e));
    }
    if (message.response_message !== undefined) {
      obj.response_message = ChatCompletionMessage.toJSON(message.response_message);
    }
    if (message.sentinels?.length) {
      obj.sentinels = message.sentinels.map((e) => Sentinel.toJSON(e));
    }
    if (message.tools?.length) {
      obj.tools = message.tools.map((e) => Tool.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BacktestEvaluateRequest>, I>>(base?: I): BacktestEvaluateRequest {
    return BacktestEvaluateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BacktestEvaluateRequest>, I>>(object: I): BacktestEvaluateRequest {
    const message = createBaseBacktestEvaluateRequest();
    message.id = object.id ?? 0;
    message.date_created = object.date_created ?? 0;
    message.run_id = object.run_id ?? 0;
    message.company_id = object.company_id ?? 0;
    message.application_id = object.application_id ?? 0;
    message.backtest_application_id = object.backtest_application_id ?? 0;
    message.session_id = object.session_id ?? "";
    message.request_id = object.request_id ?? "";
    message.inference_request_id = object.inference_request_id ?? "";
    message.action_type = object.action_type ?? "";
    message.request_messages = object.request_messages?.map((e) => ChatMessage.fromPartial(e)) || [];
    message.response_message = (object.response_message !== undefined && object.response_message !== null)
      ? ChatCompletionMessage.fromPartial(object.response_message)
      : undefined;
    message.sentinels = object.sentinels?.map((e) => Sentinel.fromPartial(e)) || [];
    message.tools = object.tools?.map((e) => Tool.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
