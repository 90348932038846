import { Card, Col, Row, Typography } from "antd";
import DocsIcon from "../../assets/docs.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import { PrimaryButton } from "../shared/button/Buttons";

export const DocsCard = () => {
  return (
    <Card className="docs-card">
      <Row className="justify-between w-full">
        <Col>
          <Row gutter={28}>
            <Col style={{ height: "72px" }}>
              <img src={DocsIcon} alt="docs" />
            </Col>
            <Col>
              <Typography.Title
                level={2}
                className="font-400 text-typo-main"
                style={{ marginBottom: "4px", marginTop: "0" }}>
                Do you have any questions?
              </Typography.Title>
              <Typography.Paragraph
                style={{ marginBottom: "0" }}
                className="text-typo-paragraph">
                Our documentation covers SDK, API, and Portal functionality.
              </Typography.Paragraph>
            </Col>
          </Row>
        </Col>
        <Col>
          <PrimaryButton
            title="See Our Docs"
            className="buttonWrapper"
            isLeadingIcon={false}
            icon={<ArrowRightOutlined color="#255D70" />}
            style={{ width: "100%" }}
            onClick={() => window.open("https://docs.trymaitai.ai", "_blank")}
          />
        </Col>
      </Row>
    </Card>
  );
};
