import Webhook from "../models/Webhook";
import GeneralService from "./GeneralService";
import {Component} from "react";


export class WebhookService extends Component {
  public static registerWebhook(webhook: Webhook, loadingHandler: any, apiKey: string) {
    return GeneralService.post("/webhooks/", JSON.stringify(webhook), loadingHandler, JSON, GeneralService.EVENTS_ENDPOINT, apiKey);
  }

  public static getWebhooks(loadingHandler: any, apiKey: string) {
    return GeneralService.get("/webhooks/", loadingHandler, JSON, GeneralService.EVENTS_ENDPOINT, apiKey);
  }

  public static getWebhook(id: string, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/webhooks/${id}`, loadingHandler, JSON, GeneralService.EVENTS_ENDPOINT, apiKey);
  }
}

