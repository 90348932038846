export const customTheme = {
  token: {
    colorLink: "#21b892",
    colorInfo: "#21b892",
    colorPrimary: "#21b892",
    colorInfoText: "#21b892",
    colorSuccessText: "#21B892",
    colorSuccess: "#21B892",
  },
};
