import {
  Col,
  Input,
  InputRef,
  Row,
  Table,
  TableColumnsType,
  Typography,
  notification,
  Tag,
  Popover,
  Tooltip,
  TablePaginationConfig,
} from "antd";
import { useNavigate } from "react-router-dom";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment/moment";
import React, { useEffect, useRef, useState } from "react";
import { ApplicationAction } from "../../model_gen/application";
import { ApplicationService } from "../../services/ApplicationService";
import { usePermissions } from "../../PermissionsProvider";
import editIcon from "../../assets/svg/edit-icon.svg";
import ApplicationModal from "../modals/applicationModal/ApplicationModal";
import TableDropDownMenu from "../shared/tablemenus/TableDropdownMenu";
import { ApplicationActionsListProps } from "../../types/IApplication";
import { SentinelService } from "../../services/SentinelService";
import { Sentinel } from "../../model_gen/sentinel";
import { PrimaryButton } from "../shared/button/Buttons";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

export const ApplicationActionList: React.FC<ApplicationActionsListProps> = (
  props
) => {
  const { apiKey } = usePermissions();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [applicationActionNameInputValue, setApplicationActionNameInputValue] =
    useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submit button loading
  const [isLoading, setIsLoading] = useState(false); // New state for submit button loading
  const [applicationActions, setApplicationActions] = useState<
    ApplicationAction[]
  >([]);
  const [activeIntents, setActiveIntents] = useState<Set<string>>(new Set());

  const inputRef = useRef<InputRef>(null);

  const getActiveIntents = () => {
    const intents = new Set<string>();
    SentinelService.getSentinels(
      props.applicationId,
      setIsLoading,
      apiKey
    ).subscribe(
      (sents: Sentinel[]) => {
        for (const sent of sents) {
          for (const intent of sent.action_types) {
            intents.add(intent);
          }
        }
        setActiveIntents(intents);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setApplicationActionNameInputValue("");
    setIsModalOpen(false);
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      createApplicationIntent();
    }
  };

  const deleteApplicationAction = (actionId: number) => {
    if (!props.applicationId) return;
    if (
      window.confirm("Are you sure you want to delete this application action?")
    ) {
      ApplicationService.deleteApplicationAction(
        props.applicationId,
        actionId,
        setIsLoading,
        apiKey
      ).subscribe(
        () => {
          getApplicationActions();
          notification.success({
            message: "Success",
            description: "Application action deleted successfully",
          });
        },
        (error: any) => {
          console.error(error);
          notification.error({
            message: "Error",
            description: "Failed to delete application action",
          });
        }
      );
    }
  };
  const getApplicationActions = () => {
    if (!props.applicationId) return;
    ApplicationService.getApplicationActions(
      props.applicationId,
      setIsLoading,
      apiKey
    ).subscribe(
      (actions: ApplicationAction[]) => {
        // Sort actions by last_activity in descending order
        const sortedActions = actions.sort((a, b) => {
          const dateA = a.last_activity > 0 ? a.last_activity : 0;
          const dateB = b.last_activity > 0 ? b.last_activity : 0;
          return dateB - dateA;
        });
        setApplicationActions(sortedActions);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };
  const createApplicationIntent = () => {
    if (!props.applicationId) return;
    setIsSubmitting(true);
    const applicationAction: ApplicationAction = ApplicationAction.fromPartial({
      action_type: applicationActionNameInputValue,
      application_id: props.applicationId,
    });
    ApplicationService.createApplicationAction(
      props.applicationId,
      applicationAction,
      setIsLoading,
      apiKey
    ).subscribe(
      () => {
        setIsSubmitting(false);
        handleCancel();
        getApplicationActions();
      },
      (error: any) => {
        console.error(error);
        setIsSubmitting(false);
      }
    );
  };

  useEffect(() => {
    getApplicationActions();
    getActiveIntents();
  }, [props.applicationId]);

  useEffect(() => {
    if (isModalOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isModalOpen]);
  useEffect(() => {
    if (isModalOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isModalOpen]);

  const actionColumns: TableColumnsType<ApplicationAction> = [
    {
      title: "Name",
      dataIndex: "action_type",
      key: "session_id",
      sorter: (a, b) => a.action_type.localeCompare(b.action_type),
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      key: "date_created",
      render: (date: string) => moment(date).format("YYYY-MM-DD hh:mm:ss A"),
      sorter: (a, b) => a.action_type.localeCompare(b.action_type),
    },
    {
      title: "Last Activity",
      dataIndex: "last_activity",
      key: "last_activity",
      render: (date) => {
        if (date > 0) {
          return moment(date).format("YYYY-MM-DD hh:mm:ss A");
        } else {
          return "N/A";
        }
      },
      sorter: (a, b) =>
        moment(a.last_activity).unix() - moment(b.last_activity).unix(),
    },
    {
      title: "Requests",
      dataIndex: "request_count",
      key: "request_count",
      render: (count: number) => count.toString(),
      sorter: (a, b) => a.request_count - b.request_count,
    },
    {
      title: "Status",
      render: (_, action) => {
        if (action.meta?.intent_stability === "UNSTABLE") {
          return (
            <Popover
              content={
                <>
                  Your system prompt for this intent is too inconsistent.
                  <br />
                  Use a unique intent tag for each general task.
                </>
              }>
              <Tag color="error">Unstable</Tag>
            </Popover>
          );
        } else if (activeIntents.has(action.action_type)) {
          return (
            <Popover
              content={
                <>
                  This application intent is currently being
                  <br />
                  monitored and will receive evaluations.
                </>
              }>
              <Tag color="success">Monitoring</Tag>
            </Popover>
          );
        } else {
          return (
            <Popover
              content={
                <>
                  Maitai is learning about your expectations
                  <br />
                  for this application intent.
                </>
              }>
              <Tag color="warning">Learning...</Tag>
            </Popover>
          );
        }
      },
      key: "status",
    },
    {
      key: "intent",
      render: (_, action) => {
        const items = [
          {
            label: (
              <a
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/application/${action.application_id}/intent/${action.id}`
                  );
                }}>
                Edit
              </a>
            ),
            key: "0",
            icon: <img src={editIcon} width={15} />,
          },
          {
            label: (
              <a
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteApplicationAction(action.id);
                }}>
                Delete
              </a>
            ),
            key: "1",
            icon: <DeleteOutlined />,
          },
        ];
        return <TableDropDownMenu items={items} />;
      },
      align: "right",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  return (
    <div>
      <Row align="middle">
        <Col span={12}>
          <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
            <Title level={4}>Intents</Title>
            <Tooltip title="Intents are used to differentiate different request types within the same application">
              <InfoCircleOutlined
                style={{ marginLeft: 4, color: "rgba(0, 0, 0, 0.45)" }}
              />
            </Tooltip>
          </div>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <PrimaryButton
            className="buttonWrapper"
            title="New Intent"
            icon={<PlusOutlined />}
            onClick={() => showModal()}
          />
        </Col>
      </Row>
      <Table
        rowKey={"id"}
        columns={actionColumns}
        dataSource={applicationActions}
        pagination={
          applicationActions.length > 5
            ? {
                current: currentPage,
                pageSize: pageSize,
                total: applicationActions.length,
                showSizeChanger: false,
              }
            : false
        }
        loading={isLoading}
        onChange={handleTableChange}
        onRow={(record) => {
          return {
            onClick: (e) => {
              const targetId =
                ((e.target as Element).parentElement as Element).id ?? "";
              const isDropdownClick =
                targetId === "table-dropdown-space" ||
                targetId === "table-dropdown";

              if (!isDropdownClick)
                navigate(
                  `/application/${record.application_id}/intent/${record.id}`
                );
            },
          };
        }}
      />
      <ApplicationModal
        title="Add Application Intent"
        open={isModalOpen}
        onOk={createApplicationIntent}
        onCancel={handleCancel}
        submitButtonTitle="Add Intent"
        onSubmitButtonClick={createApplicationIntent}
        onCancelButtonClick={handleCancel}
        isLoading={isSubmitting}
        isContainedBtnDisabled={!applicationActionNameInputValue}>
        <p>
          Intent is used to differentiate different request types within the
          same application.
        </p>
        <Input
          ref={inputRef}
          placeholder="Description"
          value={applicationActionNameInputValue || ""}
          onChange={(e) =>
            setApplicationActionNameInputValue(e.target.value.toUpperCase())
          }
          disabled={isSubmitting}
          onKeyDown={handleKeyDown}
          style={{ padding: 10, textTransform: "capitalize" }}
        />
      </ApplicationModal>
    </div>
  );
};
