import { type FC, useState } from "react";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, Spin, Typography } from "antd";
import { BillingService } from "../../services/BillingService";
import { usePermissions } from "../../PermissionsProvider";

interface AddPaymentMethodFormProps {
    onPaymentMethodAdded: () => void;
  }

const AddPaymentMethodForm: FC<AddPaymentMethodFormProps> = ({ onPaymentMethodAdded }) => {
    const { apiKey } = usePermissions();
    const stripe: any = useStripe();
    const elements: any = useElements();
    const [loading, setLoading] = useState<boolean>(false);
    const [cardFormLoading, setCardFormLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [vaultDisabled, setVaultDisabled] = useState(true);


    const handleError = (errorMessage: string) => {
        setLoading(false);
        setErrorMessage(errorMessage);
    };

    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            handleError(submitError.message);
            return;
        }

        BillingService.getSetupIntent(null, apiKey).subscribe((data: any) => {
            const { client_secret: clientSecret } = data;
            // Use the clientSecret and Elements instance to confirm the setup

            stripe.confirmSetup({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: "https://portal.trymaitai.ai/billing",
                },
                // Uncomment below if you only want redirect for redirect-based payments
                redirect: "if_required",
            }).then(async (response: any) => {
                if (response.error) {
                    console.log(response.error);
                    handleError(response.error.message);
                } else {
                    console.log(response);
                    setLoading(false);
                    // Clear the PaymentElement
                    elements.getElement(PaymentElement).clear();
                    onPaymentMethodAdded();
                }
            }).catch((error: any) => {
                console.error(`Error calling stripe.confirmSetup: ${error}`);
                handleError("Billing gateway error. Please try again later.");
            });

        }, (error: any) => {
            console.error(`Error fetching setup intent: ${error}`);
            handleError("Billing gateway error. Please try again later.");
        });

    };
    return (
        <form onSubmit={handleSubmit} style={{ marginLeft: "10px", marginRight: "10px" }}>
            <PaymentElement onReady={() => { setVaultDisabled(false); }} onLoaderStart={() => { setCardFormLoading(false); }} />
            {cardFormLoading &&
                <div style={{ padding: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Spin size="large" />
                </div>
            }
            <Button type="primary" htmlType="submit" disabled={!stripe || loading || vaultDisabled} style={{ marginTop: "20px" }} loading={loading}>
                Vault Payment Method
            </Button>
            {errorMessage &&
                <div style={{ marginTop: "20px" }}>
                    <Typography style={{ color: "red" }}>{errorMessage}</Typography>
                </div>
            }
        </form>
    );
};

export default AddPaymentMethodForm;