import { SwitchProps, Switch } from "antd";
import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const CustomSwitch: React.FC<SwitchProps> = ({ ...props }) => {
  return (
    <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      {...props}
    />
  );
};

export default CustomSwitch;
