import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Tooltip } from "antd";
import {
  AppstoreFilled,
  AppstoreOutlined,
  CalendarFilled,
  CalendarOutlined,
  FundProjectionScreenOutlined,
  HomeFilled,
  HomeOutlined,
  LineChartOutlined,
  MoreOutlined,
  EditOutlined,
  EditFilled,
  SettingOutlined,
  MailOutlined,
} from "@ant-design/icons";
import useStore from "../../store/useStore";
import { CompanyService } from "../../services/CompanyService";
import { usePermissions } from "../../PermissionsProvider";

type MenuItem = {
  key: string;
  icon?: ((selected: boolean) => React.ReactNode) | React.ReactNode;
  text: string;
  to?: string;
  disabled: boolean;
  needsTooltip: boolean;
  children?: MenuItem[];
};

const SiderComponent: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const { dataReadyTutorial } = useStore();
  const { apiKey } = usePermissions();
  const [companyId, setCompanyId] = useState<number | null>(null);

  const tooltipTitle = "Make a request using the Maitai SDK to view this page";

  useEffect(() => {
    CompanyService.getCompany(null, apiKey).subscribe(
      (data: any) => {
        console.log("data", data);
        setCompanyId(data.id);
      },
      (error: any) => {
        console.error("Error fetching company data:", error);
      }
    );
  }, [apiKey]);

  const adminMenuItems: MenuItem[] = [
    {
      key: "annotation",
      text: "Annotation",
      to: "/admin/annotation",
      disabled: false,
      needsTooltip: false,
      icon: (selected: boolean) =>
        selected ? (
          <EditFilled className="menu-icon-size-sub" />
        ) : (
          <EditOutlined className="menu-icon-size-sub" />
        ),
    },
    {
      key: "email",
      text: "Email",
      to: "/admin/email",
      disabled: false,
      needsTooltip: false,
      icon: <MailOutlined className="menu-icon-size-sub" />,
    },
  ];

  const menuItems: MenuItem[] = [
    {
      key: "home",
      icon: (selected: boolean) =>
        selected ? (
          <HomeFilled className="menu-icon-size" />
        ) : (
          <HomeOutlined className="menu-icon-size" />
        ),
      text: "Home",
      to: "/",
      disabled: false,
      needsTooltip: false,
    },
    {
      key: "applications",
      icon: (selected: boolean) =>
        selected ? (
          <AppstoreFilled className="menu-icon-size" />
        ) : (
          <AppstoreOutlined className="menu-icon-size" />
        ),
      text: "Applications",
      to: "/applications",
      disabled: !dataReadyTutorial,
      needsTooltip: true,
    },
    {
      key: "events",
      icon: (selected: boolean) =>
        selected ? (
          <CalendarFilled className="menu-icon-size" />
        ) : (
          <CalendarOutlined className="menu-icon-size" />
        ),
      text: "Events",
      to: "/events",
      disabled: !dataReadyTutorial,
      needsTooltip: true,
    },
    {
      key: "insights",
      icon: <LineChartOutlined className="menu-icon-size" />,
      text: "Insights",
      to: "/",
      disabled: true,
      needsTooltip: false,
    },
    {
      key: "models",
      icon: <FundProjectionScreenOutlined className="menu-icon-size" />,
      text: "Models",
      to: "/",
      disabled: true,
      needsTooltip: false,
    },
    {
      key: "more",
      icon: <MoreOutlined className="menu-icon-size more-icon-rotate" />,
      text: "More",
      disabled: true,
      needsTooltip: false,
    },
  ];
  if (companyId === 2) {
    menuItems.splice(5, 0, {
      key: "admin",
      text: "Admin",
      to: "/admin",
      disabled: false,
      icon: <SettingOutlined className="menu-icon-size" />,
      needsTooltip: false,
      children: adminMenuItems,
    });
  }

  const selectedKeys = [
    menuItems.find((item) => item.to === pathname)?.key || "",
    adminMenuItems.find((item) => item.to === pathname)?.key || "",
  ];

  return (
    <Menu
      triggerSubMenuAction="hover"
      className="sidebar-cus-wrap"
      mode="inline"
      theme="light"
      selectedKeys={selectedKeys}>
      {menuItems.map((item, index) => (
        <React.Fragment key={item.key}>
          {index === menuItems.length - 1 && <Menu.Divider />}
          {item.children ? (
            <Menu.SubMenu
              key={item.key}
              className="menu-item-font-size"
              icon={
                typeof item.icon === "function"
                  ? item.icon(selectedKeys.includes(item.key))
                  : item.icon
              }
              title={item.text}>
              {item.children.map((subItem) => (
                <Menu.Item
                  style={{ fontSize: "14px" }}
                  key={subItem.key}
                  icon={
                    typeof subItem.icon === "function"
                      ? subItem.icon(selectedKeys.includes(subItem.key))
                      : subItem.icon
                  }
                  disabled={subItem.disabled}>
                  <Link
                    className={`menu-sub-item-font-size ${
                      subItem.disabled ? "cursor-not-allowed" : ""
                    }`}
                    to={subItem.to}>
                    {subItem.text}
                  </Link>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item
              key={item.key}
              icon={
                typeof item.icon === "function"
                  ? item.icon(selectedKeys.includes(item.key))
                  : item.icon
              }
              disabled={item.disabled}>
              {dataReadyTutorial || !item.needsTooltip ? (
                <Link
                  className={`menu-item-font-size ${
                    item.disabled ? "cursor-not-allowed" : ""
                  }`}
                  to={item.to}>
                  {item.text}
                </Link>
              ) : (
                <Tooltip title={tooltipTitle}>
                  <Link
                    className="menu-item-font-size cursor-not-allowed"
                    to={item.to}>
                    {item.text}
                  </Link>
                </Tooltip>
              )}
            </Menu.Item>
          )}
        </React.Fragment>
      ))}
    </Menu>
  );
};

export default SiderComponent;
