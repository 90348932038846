import React, { useState, useEffect } from "react";
import { Card, Typography, Space, Button, Row, Col, Modal, message, Spin, Empty } from "antd";
import { CreditCardOutlined } from "@ant-design/icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddCardComponent from "./AddCardComponent";
import { BillingService } from "../../services/BillingService";
import { usePermissions } from "../../PermissionsProvider";
import { PaymentMethod } from "../../model_gen/billing";

const { Text } = Typography;

// Load Stripe outside of the component to avoid recreating it on every render
const stripePromise = loadStripe("pk_live_51PfPaKIWtETaTLIE0mkYSP0hAmlpoXGA11dgiBI4QmIiSLE4RSEB7UuYPIH1lES7Tts7CYVcbc4qS24UIer30NgH003W4uNlpZ");

const BillingPaymentMethods: React.FC = () => {
  const { apiKey } = usePermissions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = async () => {
    setLoading(true);
    BillingService.getPaymentMethods(setLoading, apiKey).subscribe((paymentMethods: PaymentMethod[]) => {
      // Sort payment methods by date_created descending and put the default method first
      const sortedMethods = paymentMethods.sort((a, b) => {
        if (a.is_default) return -1;
        if (b.is_default) return 1;
        return new Date(b.date_created).getTime() - new Date(a.date_created).getTime();
      });
      setPaymentMethods(sortedMethods);
    }, (error: any) => {
      message.error("Failed to fetch payment methods");
      console.error("Error fetching payment methods:", error);
      setLoading(false);
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSetDefault = (methodId: number) => {
    setLoading(true);
    BillingService.setPaymentMethodDefault(methodId, setLoading, apiKey).subscribe(
      () => {
        message.success("Payment method set as default");
        // Now wait 2s before fetching payment methods
        setTimeout(() => {
          fetchPaymentMethods();
        }, 2000);
      },
      (error: any) => {
        message.error("Failed to set payment method as default");
        console.error("Error setting payment method as default:", error);
        setLoading(false);
      }
    );
  };

  const handleDelete = (methodId: number) => {
    Modal.confirm({
      title: "Are you sure you want to delete this payment method?",
      content: "This action cannot be undone.",
      onOk() {
        setLoading(true);
        BillingService.deletePaymentMethod(methodId, null, apiKey).subscribe(
          () => {
            message.success("Payment method deleted");
            // Now wait 2s before fetching payment methods
            setTimeout(() => {
              fetchPaymentMethods();
            }, 2000);
          },
          (error: any) => {
            message.error("Failed to delete payment method");
            console.error("Error deleting payment method:", error);
            setLoading(false);
          }
        );
      },
    });
  };

  const handlePaymentMethodAdded = () => {
    setIsModalVisible(false);
    message.success("Payment method added successfully");
    setLoading(true);
    // The setup_intent needs a couple seconds, so set a timer to kick off fetch in a couple seconds
    setTimeout(() => {
      fetchPaymentMethods();
    }, 2000);
  };

  return (
    <div>      
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Spin spinning={loading} tip="Loading payment methods...">
          {paymentMethods.length > 0 ? (
            <Row gutter={[16, 16]}>
              {paymentMethods.map((method) => (
                <Col span={8} key={method.id}>
                  <Card size="small" style={{ height: "100%" }}>
                    <Space direction="vertical" size="small" style={{ width: "100%" }}>
                      <Space size={16}>
                        <CreditCardOutlined style={{ fontSize: "20px" }} />
                        <div>
                          <Text strong>•••• {method.meta?.card?.last4}</Text>
                          <br />
                          <Text type="secondary" style={{ fontSize: "12px" }}>
                            Expires {method.meta?.card?.exp_month?.toString().padStart(2, "0")}/
                            {method.meta?.card?.exp_year?.toString().slice(-2)}
                          </Text>
                        </div>
                      </Space>
                      <div>
                        {method.is_default ? (
                          <Text type="secondary" style={{ fontSize: "12px" }}>Default</Text>
                        ) : (
                          <Button
                            type="link"
                            size="small"
                            style={{ paddingLeft: 0 }}
                            onClick={() => handleSetDefault(method.id)}
                            disabled={loading}
                          >
                            Set as default
                          </Button>
                        )}
                        <Button
                          type="link"
                          danger
                          size="small"
                          style={{ float: "right" }}
                          onClick={() => handleDelete(method.id)}
                          disabled={loading}
                        >
                          Delete
                        </Button>
                      </div>
                    </Space>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>No payment methods found.</span>
              }
            >
              <Button type="primary" icon={<CreditCardOutlined />} onClick={showModal}>
                Add payment method
              </Button>
            </Empty>
          )}
        </Spin>

        {paymentMethods.length > 0 && (
          <Button icon={<CreditCardOutlined />} onClick={showModal}>Add payment method</Button>
        )}
      </Space>

      <Modal
        title="Add Payment Method"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Elements stripe={stripePromise}>
          <AddCardComponent onPaymentMethodAdded={handlePaymentMethodAdded} />
        </Elements>
      </Modal>
    </div>
  );
};

export default BillingPaymentMethods;
