import React, { useState, useEffect, useRef } from "react";
import { Input, Avatar, Dropdown, Menu, Row, Col, Modal, Form } from "antd";
import { UserOutlined, EditOutlined, MinusOutlined } from "@ant-design/icons";
import { SandboxService } from "../../services/SandboxService"; // Import SandboxService
import ReconnectingWebSocket from "reconnecting-websocket";
import { ApplicationService } from "../../services/ApplicationService";
import { usePermissions } from "../../PermissionsProvider";
import {
  SandboxApplication,
  SandboxMessage,
  SandboxSession,
} from "../../model_gen/sandbox";
import { EvaluateRequest, EvaluateResult } from "../../model_gen/chat";
import { Application } from "../../model_gen/application";
import { OutlineButton, PrimaryButton } from "../shared/button/Buttons";

const Sandbox: React.FC = () => {
  const { apiKey } = usePermissions();
  const [messages, setMessages] = useState<SandboxMessage[]>([]);
  // evalResults not being used, kept for future implementation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [evalResults, setEvalResults] = useState<EvaluateRequest[]>([]);
  const [inputValue, setInputValue] = useState("");
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [applications, setApplications] = useState<SandboxApplication[]>([]);
  const [mtApps, setMtApps] = useState<Application[]>([]);
  const [mappedMtApp, setMappedMtApp] = useState<Application | null>(null);
  const [selectedApplication, setSelectedApplication] =
    useState<SandboxApplication | null>(null);
  const [sessions, setSessions] = useState<SandboxSession[]>([]);
  const [selectedSession, setSelectedSession] = useState<SandboxSession | null>(
    null
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [applicationName, setApplicationName] = useState("");
  const [applicationPrompt, setApplicationPrompt] = useState("");
  const [isSubmittingApplication, setIsSubmittingApplication] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const wsRef = useRef<ReconnectingWebSocket | null>(null);
  const lastMessageTimeRef = useRef<Date>(new Date());
  const wsEvalRef = useRef<ReconnectingWebSocket | null>(null);
  const lastEvalTimeRef = useRef<Date>(new Date());
  const [strobeMessage, setStrobeMessage] = useState<{
    id: string | null;
    color: string;
  }>({ id: null, color: "" });

  useEffect(() => {
    SandboxService.getApplications(null, apiKey).subscribe(
      (apps: SandboxApplication[]) => {
        setApplications(apps);
        if (apps.length > 0) {
          setSelectedApplication(apps[0]);
        }
      },
      (error: any) => {
        console.error(error);
        setSelectedApplication(null);
      }
    );
    ApplicationService.getApplications(null, apiKey).subscribe(
      (apps: Application[]) => {
        setMtApps(apps);
      },
      (error: any) => {
        console.error(error);
        setMtApps([]);
      }
    );
  }, []);

  useEffect(() => {
    if (selectedApplication && selectedApplication.id) {
      setMessages([]);
      setMappedMtApp(null);
      if (selectedApplication.sandbox_sessions) {
        setSessions(selectedApplication.sandbox_sessions);
        setSelectedSession(
          selectedApplication?.sandbox_sessions &&
            selectedApplication?.sandbox_sessions?.length > 0
            ? selectedApplication?.sandbox_sessions[0]
            : null
        );
      } else {
        SandboxService.getSessions(
          selectedApplication.id,
          null,
          apiKey
        ).subscribe(
          (sess: SandboxSession[]) => {
            setSessions(sess);
            if (sess.length > 0) {
              setSelectedSession(sess[0]);
            }
          },
          (error: any) => {
            console.error(error);
            setSelectedSession(null);
          }
        );
      }
      SandboxService.getMappedApplication(
        selectedApplication.id,
        null,
        apiKey
      ).subscribe(
        (app: Application) => {
          setMappedMtApp(app);
        },
        (error: any) => {
          console.error(error);
          setMappedMtApp(null);
        }
      );
    }
  }, [selectedApplication]);

  useEffect(() => {
    if (selectedSession && selectedSession.id) {
      SandboxService.getMessages(
        selectedSession.sandbox_application_id,
        selectedSession.id,
        null,
        apiKey
      ).subscribe(
        (msgs: SandboxMessage[]) => {
          setMessages(msgs);
        },
        (error: any) => {
          console.error(error);
          setMessages([]);
        }
      );
      setEvalResults([]);
      refreshMessageWebsocket();
      refreshEvalWebsocket();
    }
  }, [selectedSession]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const refreshMessageWebsocket = () => {
    closeMessageWsConnection();
    openMessageWebsocket();
  };

  const openMessageWebsocket = () => {
    closeMessageWsConnection();

    const ws: any = new ReconnectingWebSocket(
      `wss://09hidyy627.execute-api.us-west-2.amazonaws.com/production?key=${selectedSession?.token}&type=SANDBOX_MESSAGE`
    );
    wsRef.current = ws;

    ws.addEventListener("message", (message: any) => {
      lastMessageTimeRef.current = new Date();

      const data = JSON.parse(message.data);
      handleNewMessage(data);
    });

    // create an interval that checks the last message time and refreshes connection if needed
    const checkInterval = setInterval(() => {
      const now = new Date();
      // Check the payments WS
      const diffInMinutesWS =
        (now.getTime() - lastMessageTimeRef.current.getTime()) / (1000 * 60);

      if (diffInMinutesWS >= 1) {
        // adjust time as needed
        if (wsRef.current) {
          wsRef.current.reconnect(1000, "Manual reconnect");
          lastMessageTimeRef.current = new Date();
        }
      }
    }, 1000 * 5); // check every 5 seconds

    console.log("Opened WS connection");

    return () => {
      if (checkInterval) clearInterval(checkInterval);
      closeMessageWsConnection();
    };
  };

  const closeMessageWsConnection = () => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
      console.log("Closed WS connection");
    }
  };

  const refreshEvalWebsocket = () => {
    closeEvalWsConnection();
    openEvalWebsocket();
  };

  const openEvalWebsocket = () => {
    closeEvalWsConnection();

    const ws: any = new ReconnectingWebSocket(
      `wss://09hidyy627.execute-api.us-west-2.amazonaws.com/production?key=${selectedApplication?.id}&type=EVAL_RESULTS_PACKAGE`
    );
    wsEvalRef.current = ws;

    ws.addEventListener("message", (message: any) => {
      lastEvalTimeRef.current = new Date();
      const data = JSON.parse(message.data);
      handleEvalResults(data);
    });

    // create an interval that checks the last message time and refreshes connection if needed
    const checkInterval = setInterval(() => {
      const now = new Date();
      // Check the payments WS
      const diffInMinutesWS =
        (now.getTime() - lastEvalTimeRef.current.getTime()) / (1000 * 60);

      if (diffInMinutesWS >= 1) {
        // adjust time as needed
        if (wsRef.current) {
          wsRef.current.reconnect(1000, "Manual reconnect");
          lastEvalTimeRef.current = new Date();
        }
      }
    }, 1000 * 5); // check every 5 seconds

    console.log("Opened WS connection");

    return () => {
      if (checkInterval) clearInterval(checkInterval);
      closeEvalWsConnection();
    };
  };

  const closeEvalWsConnection = () => {
    if (wsEvalRef.current) {
      wsEvalRef.current.close();
      wsEvalRef.current = null;
      console.log("Closed Eval WS connection");
    }
  };

  const handleNewMessage = (data: any) => {
    const newMessage: SandboxMessage = data.event_data;

    setMessages((prevMessages) => {
      return [...prevMessages, newMessage];
    });
  };

  const handleEvalResults = (data: any) => {
    const newEvalResult: EvaluateRequest = data.event_data;

    console.log("Eval Result", newEvalResult);

    // Determine the color based on the eval result
    const evaluation_results: EvaluateResult[] =
      newEvalResult.eval_results_set?.evaluation_results || [];
    let hasFault = false;
    for (const eval_result of evaluation_results) {
      if (eval_result && eval_result?.status && eval_result.status == "FAULT") {
        console.log(`last message has a fault: ${eval_result.description}`);
        hasFault = true;
        break;
      }
    }
    const strobeColor = hasFault ? "red" : "green";

    // Set the message to be strobed
    setStrobeMessage({
      id: String(newEvalResult.reference_id),
      color: strobeColor,
    });

    // Reset after a brief period
    setTimeout(() => setStrobeMessage({ id: null, color: "" }), 5000); // Adjust the duration as needed

    setEvalResults((prevEvalResults) => {
      return [...prevEvalResults, newEvalResult];
    });
  };

  const handleSend = () => {
    if (!inputValue.trim()) return;

    // Create a temporary new message with a temporary ID
    const tempId = Date.now(); // Using current timestamp as a temporary ID
    const tempNewMessage: SandboxMessage = SandboxMessage.fromPartial({
      id: tempId, // Assign the temporary ID
      message: inputValue,
      sender: "USER",
      sandbox_session_id: selectedSession?.id,
    });

    // Add the temporary message to the state immediately for instant UI update
    setMessages((prevMessages) => [...prevMessages, tempNewMessage]);
    setInputValue("");

    // Send the message to the server without the temporary ID
    const messageToSend: Omit<SandboxMessage, "id"> =
      SandboxMessage.fromPartial({
        message: inputValue,
        sender: "USER",
        sandbox_session_id: selectedSession?.id,
      });

    SandboxService.newMessage(messageToSend, null, apiKey).subscribe(
      (response: SandboxMessage) => {
        // Replace the temporary message with the response message
        setMessages((prevMessages) => {
          const tempIndex = prevMessages.findIndex((msg) => msg.id === tempId);
          if (tempIndex > -1) {
            const newMessages = [...prevMessages];
            newMessages[tempIndex] = response; // Replace the temp message with the real one
            return newMessages;
          }
          return prevMessages; // In case the temp message wasn't found for some reason
        });
      },
      (error: any) => {
        console.error(error);
        // Optionally, handle the temporary message in case of an error (e.g., remove it or mark it as failed)
      }
    );
  };

  const deleteSession = (sessionId: number) => {
    SandboxService.deleteSession(sessionId, null, apiKey).subscribe(
      () => {
        // Filter out the deleted session from the local state
        const updatedSessions = sessions.filter(
          (session) => session.id !== sessionId
        );
        setSessions([...updatedSessions]);
        // If the deleted session is the currently selected one, clear the selection
        if (selectedSession && selectedSession.id === sessionId) {
          setSelectedSession(
            updatedSessions.length > 0 ? updatedSessions[0] : null
          );
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const handleApplicationSubmit = () => {
    setIsSubmittingApplication(true);
    // Ensure id and company_id are retained for the edited application
    const application = SandboxApplication.fromPartial({
      ...selectedApplication, // Spread the selectedApplication to retain its properties
      sandbox_application_name: applicationName,
      sandbox_application_prompt: applicationPrompt,
    });
    // Remove properties that should not be submitted or are undefined
    if (!isEditMode) {
      delete application.id;
      delete application.company_id;
    }

    const submitFunction = isEditMode
      ? SandboxService.updateApplication
      : SandboxService.newApplication;

    submitFunction(application, null, apiKey).subscribe(
      () => {
        setIsModalVisible(false);
        setApplicationName("");
        setApplicationPrompt("");
        setIsEditMode(false); // Reset edit mode
        // Refresh applications list
        SandboxService.getApplications(null, apiKey).subscribe(
          (apps: SandboxApplication[]) => {
            setApplications(apps);
            if (apps.length > 0) {
              // Set the selected application to the one that was just added
              setSelectedApplication(apps[apps.length - 1]);
            }
          }
        );
        setIsSubmittingApplication(false);
      },
      (error: any) => {
        console.error(error);
        setIsSubmittingApplication(false);
      }
    );
  };

  const mapSandboxApplication = (appId: number) => {
    if (
      selectedApplication?.id &&
      appId !== null &&
      appId !== mappedMtApp?.id
    ) {
      const selectedApp = mtApps.find((app) => app.id === appId);
      if (selectedApp) {
        SandboxService.mapApplication(
          selectedApplication.id,
          selectedApp,
          null,
          apiKey
        ).subscribe(
          (app: Application) => {
            console.log("Mapped Application", app);
            setMappedMtApp(app);
          },
          (error: any) => {
            console.error(error);
          }
        );
      }
    }
  };

  const openEditModal = () => {
    if (selectedApplication) {
      setApplicationName(selectedApplication.sandbox_application_name);
      setApplicationPrompt(selectedApplication.sandbox_application_prompt);
      setIsEditMode(true);
      setIsModalVisible(true);
    }
  };

  const newSession = () => {
    if (selectedApplication && selectedApplication.id) {
      const session = SandboxSession.fromPartial({
        sandbox_application_id: selectedApplication.id,
      });
      SandboxService.newSession(session, null, apiKey).subscribe(
        (response: SandboxSession) => {
          setSessions([...sessions, response]);
          setSelectedSession(response);
        }
      );
    }
  };

  return (
    <Row className="layout-container" gutter={16} style={{ height: "100%" }}>
      <Col className="sidebar" span={6}>
        <PrimaryButton
          style={{ marginBottom: "20px" }}
          disabled={selectedApplication === null}
          onClick={() => {
            newSession();
          }}>
          New Session
        </PrimaryButton>
        <Menu
          onClick={(e) =>
            setSelectedSession(
              sessions.find((sess) => sess.id === parseInt(e.key)) || null
            )
          }
          selectedKeys={
            selectedSession && selectedSession.id
              ? [selectedSession.id.toString()]
              : []
          }>
          {sessions.map((sess: SandboxSession) => (
            <Menu.Item key={sess?.id?.toString()}>
              <div className="session-menu-item">
                <div className="session-token">{sess.token}</div>
                <OutlineButton
                  size="small"
                  icon={<MinusOutlined />}
                  className="delete-session-button"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent Menu onClick from being triggered
                    deleteSession(sess.id ? sess.id : -1);
                  }}
                />
              </div>
            </Menu.Item>
          ))}
        </Menu>
      </Col>
      <Col className="main-content" span={18}>
        <Row
          className="applications-row"
          style={{
            marginBottom: "20px",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <Col>
            <PrimaryButton
              onClick={() => {
                setIsModalVisible(true);
                setIsEditMode(false);
              }}>
              New Sandbox Application
            </PrimaryButton>
            <Dropdown
              overlay={
                <Menu
                  onClick={(e) =>
                    setSelectedApplication(
                      applications.find((app) => app.id === parseInt(e.key)) ||
                        null
                    )
                  }>
                  {applications.map((app) => (
                    <Menu.Item key={app.id}>
                      {app.sandbox_application_name}
                    </Menu.Item>
                  ))}
                </Menu>
              }>
              <OutlineButton style={{ marginLeft: "10px" }}>
                {selectedApplication
                  ? selectedApplication.sandbox_application_name
                  : applications.length > 0
                  ? "Select Sandbox Application"
                  : "No Sandbox Applications"}
              </OutlineButton>
            </Dropdown>
          </Col>
          <Col>
            {selectedApplication && (
              <>
                <Dropdown
                  overlay={
                    <Menu
                      onClick={(e) => mapSandboxApplication(parseInt(e.key))}>
                      {mtApps.length > 0 ? (
                        mtApps.map((app) => (
                          <Menu.Item key={app.id.toString()}>
                            {app.application_name}
                          </Menu.Item>
                        ))
                      ) : (
                        <Menu.Item key="noApp">No Mapped Application</Menu.Item>
                      )}
                    </Menu>
                  }>
                  <OutlineButton style={{ marginRight: "10px" }}>
                    {mappedMtApp
                      ? mappedMtApp.application_name
                      : "Select Mapped Application"}
                  </OutlineButton>
                </Dropdown>
                <OutlineButton
                  onClick={openEditModal}
                  icon={<EditOutlined />}
                />
              </>
            )}
          </Col>
        </Row>
        <Row className="sandbox-container" style={{ flex: 1 }}>
          <Col span={24} style={{ display: "flex", flexDirection: "column" }}>
            <div className="chat-container">
              <div
                className="chat-messages"
                key={
                  messages.length > 0 ? messages[messages.length - 1]?.id : 0
                }>
                {messages.map((message) => (
                  <div
                    key={message?.id}
                    className={`message ${
                      strobeMessage?.id == String(message?.id)
                        ? `strobe-${strobeMessage?.color}`
                        : ""
                    }`}
                    style={{ display: "flex", alignItems: "flex-start" }}>
                    <div className="message-avatar">
                      {message.sender === "USER" ? (
                        <Avatar icon={<UserOutlined />} />
                      ) : (
                        <Avatar>MT</Avatar> // Using "MT" for Maitai Bot
                      )}
                    </div>
                    <div className="message-content">
                      <strong>
                        {message.sender === "USER" ? "You" : "Maitai Bot"}
                      </strong>
                      {message.meta && message.meta["is_correction"] && (
                        <span
                          style={{
                            marginLeft: "8px",
                            fontWeight: "normal",
                            color: "#ff4d4f",
                          }}>
                          CORRECTION
                        </span>
                      )}{" "}
                      {/* Added check for is_correction */}
                      <div>{message.message}</div>
                    </div>
                  </div>
                ))}
                <div ref={messagesEndRef} />
              </div>
            </div>
            <div className="input-area">
              <div className="input-container">
                <Input
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onPressEnter={handleSend}
                  placeholder="Type a message..."
                  disabled={
                    selectedApplication === null || selectedSession === null
                  }
                />
                <PrimaryButton
                  onClick={handleSend}
                  style={{ marginLeft: "10px" }}
                  disabled={
                    selectedApplication === null || selectedSession === null
                  }>
                  Send
                </PrimaryButton>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Modal
        title={isEditMode ? "Edit Application" : "New Application"}
        open={isModalVisible}
        onOk={handleApplicationSubmit}
        onCancel={() => {
          setIsModalVisible(false);
          setIsEditMode(false);
        }}
        okText={isEditMode ? "Update" : "Submit"}
        confirmLoading={isSubmittingApplication}
        okButtonProps={{ disabled: isSubmittingApplication }}>
        <Form layout="vertical">
          <Form.Item label="Application Name">
            <Input
              value={applicationName}
              onChange={(e) => setApplicationName(e.target.value)}
              disabled={isSubmittingApplication}
            />
          </Form.Item>
          <Form.Item label="Application Prompt">
            <Input.TextArea
              value={applicationPrompt}
              onChange={(e) => setApplicationPrompt(e.target.value)}
              rows={6}
              disabled={isSubmittingApplication}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default Sandbox;
