import { Component } from "react";
import GeneralService from "./GeneralService";

export class BillingService extends Component {

    public static getPaymentMethods(loadingHandler: any, apiKey: string) {
        return GeneralService.get("/billing/payment_methods", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
    }

    public static getPaymentMethod(payment_method_id: any, loadingHandler: any, apiKey: string) {
        return GeneralService.get(`/billing/payment_methods/${payment_method_id}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
    }

    public static setPaymentMethodDefault(payment_method_id: any, loadingHandler: any, apiKey: string) {
        const payload = {
            "payment_method_id": payment_method_id
        };
        return GeneralService.put("/billing/payment_methods/default", JSON.stringify(payload), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
    }

    public static deletePaymentMethod(payment_method_id: any, loadingHandler: any, apiKey: string) {
        const payload = {
            "payment_method_id": payment_method_id
        };
        return GeneralService.delete("/billing/payment_methods", JSON.stringify(payload), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
    }

    public static getSetupIntent(loadingHandler: any, apiKey: string) {
        return GeneralService.get("/billing/setup_intent", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
    }

    public static getPricing(loadingHandler: any, apiKey: string) {
        return GeneralService.get("/billing/pricing", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
    }

}