// @ts-nocheck
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: config.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import Long from "long";

export const protobufPackage = "config";

export enum InferenceLocations {
  CLIENT = 0,
  SERVER = 1,
  UNRECOGNIZED = -1,
}

export function inferenceLocationsFromJSON(object: any): InferenceLocations {
  switch (object) {
    case 0:
    case "CLIENT":
      return InferenceLocations.CLIENT;
    case 1:
    case "SERVER":
      return InferenceLocations.SERVER;
    case -1:
    case "UNRECOGNIZED":
    default:
      return InferenceLocations.UNRECOGNIZED;
  }
}

export function inferenceLocationsToJSON(object: InferenceLocations): string {
  switch (object) {
    case InferenceLocations.CLIENT:
      return "CLIENT";
    case InferenceLocations.SERVER:
      return "SERVER";
    case InferenceLocations.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Config {
  inference_location: InferenceLocations;
  evaluation_enabled: boolean;
  apply_corrections: boolean;
  model: string;
  temperature: number;
  streaming: boolean;
  response_format: string;
  stop?: string | undefined;
  logprobs: boolean;
  max_tokens?: number | undefined;
  n: number;
  presence_penalty: number;
  frequency_penalty: number;
  timeout: number;
  context_retrieval_enabled: boolean;
  fallback_model?: string | undefined;
  intent_stability?: string | undefined;
  safe_mode: boolean;
}

export interface ModelConfig {
  all_models: string[];
  client_models: string[];
  server_models: string[];
  default_client_model: string;
  default_server_model: string;
}

function createBaseConfig(): Config {
  return {
    inference_location: 0,
    evaluation_enabled: false,
    apply_corrections: false,
    model: "",
    temperature: 0,
    streaming: false,
    response_format: "",
    stop: undefined,
    logprobs: false,
    max_tokens: undefined,
    n: 0,
    presence_penalty: 0,
    frequency_penalty: 0,
    timeout: 0,
    context_retrieval_enabled: false,
    fallback_model: undefined,
    intent_stability: undefined,
    safe_mode: false,
  };
}

export const Config = {
  encode(message: Config, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.inference_location !== 0) {
      writer.uint32(8).int32(message.inference_location);
    }
    if (message.evaluation_enabled !== false) {
      writer.uint32(16).bool(message.evaluation_enabled);
    }
    if (message.apply_corrections !== false) {
      writer.uint32(24).bool(message.apply_corrections);
    }
    if (message.model !== "") {
      writer.uint32(34).string(message.model);
    }
    if (message.temperature !== 0) {
      writer.uint32(45).float(message.temperature);
    }
    if (message.streaming !== false) {
      writer.uint32(48).bool(message.streaming);
    }
    if (message.response_format !== "") {
      writer.uint32(58).string(message.response_format);
    }
    if (message.stop !== undefined) {
      writer.uint32(66).string(message.stop);
    }
    if (message.logprobs !== false) {
      writer.uint32(72).bool(message.logprobs);
    }
    if (message.max_tokens !== undefined) {
      writer.uint32(80).int64(message.max_tokens);
    }
    if (message.n !== 0) {
      writer.uint32(88).int64(message.n);
    }
    if (message.presence_penalty !== 0) {
      writer.uint32(121).double(message.presence_penalty);
    }
    if (message.frequency_penalty !== 0) {
      writer.uint32(129).double(message.frequency_penalty);
    }
    if (message.timeout !== 0) {
      writer.uint32(137).double(message.timeout);
    }
    if (message.context_retrieval_enabled !== false) {
      writer.uint32(144).bool(message.context_retrieval_enabled);
    }
    if (message.fallback_model !== undefined) {
      writer.uint32(154).string(message.fallback_model);
    }
    if (message.intent_stability !== undefined) {
      writer.uint32(162).string(message.intent_stability);
    }
    if (message.safe_mode !== false) {
      writer.uint32(168).bool(message.safe_mode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Config {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.inference_location = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.evaluation_enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.apply_corrections = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.model = reader.string();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.temperature = reader.float();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.streaming = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.response_format = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.stop = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.logprobs = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.max_tokens = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.n = longToNumber(reader.int64() as Long);
          continue;
        case 15:
          if (tag !== 121) {
            break;
          }

          message.presence_penalty = reader.double();
          continue;
        case 16:
          if (tag !== 129) {
            break;
          }

          message.frequency_penalty = reader.double();
          continue;
        case 17:
          if (tag !== 137) {
            break;
          }

          message.timeout = reader.double();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.context_retrieval_enabled = reader.bool();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.fallback_model = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.intent_stability = reader.string();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.safe_mode = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Config {
    return {
      inference_location: isSet(object.inference_location) ? inferenceLocationsFromJSON(object.inference_location) : 0,
      evaluation_enabled: isSet(object.evaluation_enabled) ? globalThis.Boolean(object.evaluation_enabled) : false,
      apply_corrections: isSet(object.apply_corrections) ? globalThis.Boolean(object.apply_corrections) : false,
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      temperature: isSet(object.temperature) ? globalThis.Number(object.temperature) : 0,
      streaming: isSet(object.streaming) ? globalThis.Boolean(object.streaming) : false,
      response_format: isSet(object.response_format) ? globalThis.String(object.response_format) : "",
      stop: isSet(object.stop) ? globalThis.String(object.stop) : undefined,
      logprobs: isSet(object.logprobs) ? globalThis.Boolean(object.logprobs) : false,
      max_tokens: isSet(object.max_tokens) ? globalThis.Number(object.max_tokens) : undefined,
      n: isSet(object.n) ? globalThis.Number(object.n) : 0,
      presence_penalty: isSet(object.presence_penalty) ? globalThis.Number(object.presence_penalty) : 0,
      frequency_penalty: isSet(object.frequency_penalty) ? globalThis.Number(object.frequency_penalty) : 0,
      timeout: isSet(object.timeout) ? globalThis.Number(object.timeout) : 0,
      context_retrieval_enabled: isSet(object.context_retrieval_enabled)
        ? globalThis.Boolean(object.context_retrieval_enabled)
        : false,
      fallback_model: isSet(object.fallback_model) ? globalThis.String(object.fallback_model) : undefined,
      intent_stability: isSet(object.intent_stability) ? globalThis.String(object.intent_stability) : undefined,
      safe_mode: isSet(object.safe_mode) ? globalThis.Boolean(object.safe_mode) : false,
    };
  },

  toJSON(message: Config): unknown {
    const obj: any = {};
    if (message.inference_location !== 0) {
      obj.inference_location = inferenceLocationsToJSON(message.inference_location);
    }
    if (message.evaluation_enabled !== false) {
      obj.evaluation_enabled = message.evaluation_enabled;
    }
    if (message.apply_corrections !== false) {
      obj.apply_corrections = message.apply_corrections;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.temperature !== 0) {
      obj.temperature = message.temperature;
    }
    if (message.streaming !== false) {
      obj.streaming = message.streaming;
    }
    if (message.response_format !== "") {
      obj.response_format = message.response_format;
    }
    if (message.stop !== undefined) {
      obj.stop = message.stop;
    }
    if (message.logprobs !== false) {
      obj.logprobs = message.logprobs;
    }
    if (message.max_tokens !== undefined) {
      obj.max_tokens = Math.round(message.max_tokens);
    }
    if (message.n !== 0) {
      obj.n = Math.round(message.n);
    }
    if (message.presence_penalty !== 0) {
      obj.presence_penalty = message.presence_penalty;
    }
    if (message.frequency_penalty !== 0) {
      obj.frequency_penalty = message.frequency_penalty;
    }
    if (message.timeout !== 0) {
      obj.timeout = message.timeout;
    }
    if (message.context_retrieval_enabled !== false) {
      obj.context_retrieval_enabled = message.context_retrieval_enabled;
    }
    if (message.fallback_model !== undefined) {
      obj.fallback_model = message.fallback_model;
    }
    if (message.intent_stability !== undefined) {
      obj.intent_stability = message.intent_stability;
    }
    if (message.safe_mode !== false) {
      obj.safe_mode = message.safe_mode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Config>, I>>(base?: I): Config {
    return Config.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Config>, I>>(object: I): Config {
    const message = createBaseConfig();
    message.inference_location = object.inference_location ?? 0;
    message.evaluation_enabled = object.evaluation_enabled ?? false;
    message.apply_corrections = object.apply_corrections ?? false;
    message.model = object.model ?? "";
    message.temperature = object.temperature ?? 0;
    message.streaming = object.streaming ?? false;
    message.response_format = object.response_format ?? "";
    message.stop = object.stop ?? undefined;
    message.logprobs = object.logprobs ?? false;
    message.max_tokens = object.max_tokens ?? undefined;
    message.n = object.n ?? 0;
    message.presence_penalty = object.presence_penalty ?? 0;
    message.frequency_penalty = object.frequency_penalty ?? 0;
    message.timeout = object.timeout ?? 0;
    message.context_retrieval_enabled = object.context_retrieval_enabled ?? false;
    message.fallback_model = object.fallback_model ?? undefined;
    message.intent_stability = object.intent_stability ?? undefined;
    message.safe_mode = object.safe_mode ?? false;
    return message;
  },
};

function createBaseModelConfig(): ModelConfig {
  return { all_models: [], client_models: [], server_models: [], default_client_model: "", default_server_model: "" };
}

export const ModelConfig = {
  encode(message: ModelConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.all_models) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.client_models) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.server_models) {
      writer.uint32(26).string(v!);
    }
    if (message.default_client_model !== "") {
      writer.uint32(34).string(message.default_client_model);
    }
    if (message.default_server_model !== "") {
      writer.uint32(42).string(message.default_server_model);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ModelConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModelConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.all_models.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.client_models.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.server_models.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.default_client_model = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.default_server_model = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModelConfig {
    return {
      all_models: globalThis.Array.isArray(object?.all_models)
        ? object.all_models.map((e: any) => globalThis.String(e))
        : [],
      client_models: globalThis.Array.isArray(object?.client_models)
        ? object.client_models.map((e: any) => globalThis.String(e))
        : [],
      server_models: globalThis.Array.isArray(object?.server_models)
        ? object.server_models.map((e: any) => globalThis.String(e))
        : [],
      default_client_model: isSet(object.default_client_model) ? globalThis.String(object.default_client_model) : "",
      default_server_model: isSet(object.default_server_model) ? globalThis.String(object.default_server_model) : "",
    };
  },

  toJSON(message: ModelConfig): unknown {
    const obj: any = {};
    if (message.all_models?.length) {
      obj.all_models = message.all_models;
    }
    if (message.client_models?.length) {
      obj.client_models = message.client_models;
    }
    if (message.server_models?.length) {
      obj.server_models = message.server_models;
    }
    if (message.default_client_model !== "") {
      obj.default_client_model = message.default_client_model;
    }
    if (message.default_server_model !== "") {
      obj.default_server_model = message.default_server_model;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ModelConfig>, I>>(base?: I): ModelConfig {
    return ModelConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ModelConfig>, I>>(object: I): ModelConfig {
    const message = createBaseModelConfig();
    message.all_models = object.all_models?.map((e) => e) || [];
    message.client_models = object.client_models?.map((e) => e) || [];
    message.server_models = object.server_models?.map((e) => e) || [];
    message.default_client_model = object.default_client_model ?? "";
    message.default_server_model = object.default_server_model ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
