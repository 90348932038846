// @ts-nocheck
import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext, useLocation } from "react-router-dom";

function useConfirmExit(when = true, whitelist: string[] = []) {
  const { navigator } = useContext(NavigationContext);
  const location = useLocation();

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args: any[]) => {
      const [path] = args;
      const targetPath = typeof path === "string" ? path : path.pathname;

      if (whitelist?.some(allowedPath => targetPath.startsWith(allowedPath))) {
        return push(...args);
      }
      // No prompt, just preventing the navigation
      return;
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, when, whitelist, location]);
}

export function usePreventExit(when = true, whitelist: string[] = []) {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function (event) {
        event.preventDefault();
        event.returnValue = "";
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [when]);

  useConfirmExit(when, whitelist);
}
