import { Session } from "../../model_gen/session";

const calculateScore = (session: Session): number | string=> {
  let totalResults = 0;
  let faultCount = 0;

  session.timeline.forEach((turn) => {
    totalResults += 1;
    // If the eval request has a FAULT at all, count it
    if (turn.eval_request?.eval_results_set?.evaluation_results) {
      faultCount += turn.eval_request?.eval_results_set?.evaluation_results?.some(evalResult => evalResult.status === "FAULT") ? 1 : 0;
    }
  });

  const faultRatio = faultCount / totalResults;
  const score = 100 * (1 - faultRatio);
  if (isNaN(score)) return "Pending";
  return Math.max(0, Math.round(score));
};

const getSessionScoreColor = (session: Session) => {
  const score = calculateScore(session);
  if (typeof score === "string" || isNaN(score)) return "default";
  if (score >= 90) return "success";
  if (score >= 60) return "warning";
  return "error";
};

export { calculateScore, getSessionScoreColor };
