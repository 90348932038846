import {Key} from "../model_gen/key";
import GeneralService from "./GeneralService";
import {Component} from "react";


export class PermissionsService extends Component {
  public static getKeys(loadingHandler: any, apiKey: string) {
    return GeneralService.get("/permissions/keys", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static getKeysFiltered(keyType: string, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/permissions/keys/${keyType}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static addKey(key: Partial<Key>, loadingHandler: any, apiKey: string) {
    return GeneralService.post("/permissions/keys", JSON.stringify(key), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static deleteKey(key: Key, loadingHandler: any, apiKey: string) {
    return GeneralService.delete(`/permissions/keys/${key.id}`, JSON.stringify({}), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static editKey(key: Key, loadingHandler: any, apiKey: string) {
    return GeneralService.put("/permissions/keys", JSON.stringify(key), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
}

