import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Col,
  Row,
  Space,
  Spin,
  Typography,
  Modal,
  Tooltip,
  Input,
  InputRef,
  Button,
  Dropdown,
  Menu,
} from "antd";
import { useParams } from "react-router-dom";
import { AnnotationService } from "../../services/AnnotationService";
import { usePermissions } from "../../PermissionsProvider";
import AnnotatorTool from "./AnnotatorTool";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  SearchOutlined,
  ExportOutlined,
  LoadingOutlined,
  SendOutlined,
} from "@ant-design/icons";
import {
  AnnotationMetadata,
  RequestData,
  GetSessionsResponse,
  AnnotationSession,
} from "../../model_gen/annotation";
import moment from "moment";

const { Title } = Typography;
const { confirm } = Modal;
import "../../styles/components/Search.css";
const AnnotationView: React.FC = () => {
  const { apiKey } = usePermissions();
  const { companyId, appId, intentId } = useParams<{
    companyId: string;
    appId: string;
    intentId: string;
  }>();
  const [sessionData, setSessionData] = useState<AnnotationSession[]>([]);
  const [metadata, setMetadata] = useState<AnnotationMetadata | undefined>(
    undefined
  );
  const [selectedSession, setSelectedSession] = useState<string | null>(null);
  const [requestData, setRequestData] = useState<RequestData[]>([]);
  const [selectedRequest, setSelectedRequest] = useState<RequestData | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
  const [labels, setLabels] = useState<{ [key: string]: RequestData["label"] }>(
    {}
  );
  const [completedSessions, setCompletedSessions] = useState<Set<string>>(
    new Set()
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    const fetchSessionData = () => {
      if (companyId && appId && intentId) {
        setIsLoading(true);
        AnnotationService.getSessions(
          companyId,
          appId,
          intentId,
          searchTerm,
          () => {},
          apiKey
        ).subscribe(
          (data: GetSessionsResponse) => {
            setSessionData(data.sessions);
            setMetadata(data.metadata);
            setIsLoading(false);
          },
          (error: any) => {
            console.error("Error fetching session data:", error);
            setIsLoading(false);
          }
        );
      }
    };

    fetchSessionData();
  }, [companyId, appId, intentId, apiKey, searchTerm]);

  const fetchRequestData = () => {
    if (companyId && appId && intentId && selectedSession) {
      setIsLoading(true);
      setRequestData([]);
      setSelectedRequest(null);
      setLabels({});
      AnnotationService.getRequests(
        companyId,
        appId,
        intentId,
        selectedSession,
        searchTerm,
        () => {},
        apiKey
      ).subscribe(
        (data: RequestData[]) => {
          setRequestData(data);
          const firstUnsavedRequest = data.find((request) => !request.saved);
          setSelectedRequest(firstUnsavedRequest || data[0] || null);

          const newLabels: { [key: string]: RequestData["label"] } = {};
          data.forEach((request) => {
            if (request.label) {
              newLabels[request.request_id] = request.label;
            }
          });
          setLabels(newLabels);

          const allRequestsSaved = data.every(
            (request) => request.saved || request.excluded
          );
          if (allRequestsSaved) {
            setCompletedSessions((prev) => new Set(prev).add(selectedSession));
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setCompletedSessions((prev) => {
              const newSet = new Set(prev);
              newSet.delete(selectedSession);
              return newSet;
            });
          }
        },
        (error: any) => {
          console.error("Error fetching request data:", error);
          setIsLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    fetchRequestData();
  }, [companyId, appId, intentId, selectedSession, apiKey, searchTerm]);

  const handleSessionSelect = (sessionId: string) => {
    const unsavedChanges = Object.keys(labels).length > 0;
    if (unsavedChanges) {
      confirm({
        title: "Unsaved Changes",
        content:
          "You have unsaved changes. Are you sure you want to switch sessions? Your changes will be lost.",
        onOk() {
          setSelectedSession(sessionId);
          setSelectedRequest(null);
          setLabels({});
        },
        onCancel() {},
      });
    } else {
      setSelectedSession(sessionId);
      setSelectedRequest(null);
    }
  };

  const handleRequestSelect = (request: RequestData) => {
    const unsavedChanges = Object.keys(labels).length > 0;
    if (unsavedChanges) {
      confirm({
        title: "Unsaved Changes",
        content:
          "You have unsaved changes. Are you sure you want to switch requests? Your changes will be lost.",
        onOk() {
          setSelectedRequest(request);
        },
        onCancel() {},
      });
    } else {
      setSelectedRequest(request);
    }
  };

  const handleSave = (editedContent: RequestData["label"]) => {
    if (selectedRequest && selectedSession) {
      setIsLoading(true);
      AnnotationService.updateAnnotation(
        companyId!,
        appId!,
        intentId!,
        selectedSession,
        selectedRequest.request_id,
        editedContent,
        () => {},
        apiKey
      ).subscribe(
        () => {
          fetchRequestData();
          const remainingUnsavedRequests = requestData.filter(
            (request) =>
              !request.saved &&
              request.request_id !== selectedRequest.request_id
          );
          if (remainingUnsavedRequests.length === 0) {
            const currentSessionIndex = sessionData.findIndex(
              (session) => session.session_id === selectedSession
            );
            if (currentSessionIndex < sessionData.length - 1) {
              const nextSession = sessionData[currentSessionIndex + 1];
              setSelectedSession(nextSession.session_id);
            } else {
              // If there are no more sessions, clear the selection
              setSelectedSession(null);
              setSelectedRequest(null);
            }
          }
        },
        (error: any) => {
          console.error("Error updating annotation:", error);
          setIsLoading(false);
        }
      );
    }
  };

  const handleCancel = () => {
    setSelectedRequest(null);
  };

  const handleDiscard = () => {
    if (selectedRequest && selectedSession) {
      setIsLoading(true);
      AnnotationService.excludeAnnotation(
        companyId!,
        appId!,
        intentId!,
        selectedSession,
        selectedRequest.request_id,
        () => {},
        apiKey
      ).subscribe(
        () => {
          fetchRequestData();
        },
        (error: any) => {
          console.error("Error excluding annotation:", error);
          setIsLoading(false);
        }
      );
    }
  };

  const handleSearch = () => {
    setSearchTerm(inputValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation(); // Prevent the event from bubbling up
  };

  const handleExport = (includeAll: boolean) => {
    setIsExporting(true);
    AnnotationService.exportAnnotations(
      companyId!,
      appId!,
      intentId!,
      includeAll,
      () => {},
      apiKey
    ).subscribe(
      (response: string) => {
        const blob = new Blob([response], { type: "application/x-jsonlines" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `annotations_export_${companyId}_${appId}_${intentId}_${
          includeAll ? "all" : "annotated"
        }.jsonl`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        setIsExporting(false);
      },
      (error: any) => {
        console.error("Error exporting annotations:", error);
        setIsExporting(false);
      }
    );
  };

  const handleSendEmailNotification = () => {
    confirm({
      title: "Send Email Notification",
      content: `Are you sure you want to send an email notification to ${metadata?.contact_email}?`,
      onOk() {
        setIsSendingEmail(true);
        AnnotationService.sendAnnotationEmail(
          companyId!,
          () => {},
          apiKey
        ).subscribe(
          () => {
            setIsSendingEmail(false);
            Modal.success({
              content: "Email notification sent successfully.",
            });
          },
          (error: any) => {
            console.error("Error sending email notification:", error);
            setIsSendingEmail(false);
            Modal.error({
              content: "Failed to send email notification.",
            });
          }
        );
      },
      onCancel() {},
    });
  };

  const exportMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => handleExport(false)}>
        Export Annotated
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleExport(true)}>
        Export All
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Card className="annotation-view-wrapper">
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={4}>
                {metadata
                  ? `${metadata.company_name} / ${metadata.app_name} / ${metadata.intent_name} - Annotations`
                  : "Annotate"}
              </Title>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <div className="search-container">
                <Input.Search
                  ref={inputRef}
                  placeholder="Search..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onSearch={handleSearch}
                  onKeyDown={handleKeyDown}
                  enterButton={
                    <Button icon={<SearchOutlined />} disabled={isLoading}>
                      {isLoading && <Spin size="small" />}
                    </Button>
                  }
                />
              </div>
            </Col>
            <Col span={12}>
              <div style={{ float: "right" }}>
                <Space>
                  <Dropdown
                    overlay={exportMenu}
                    trigger={["click"]}
                    placement="bottomRight">
                    <Button type="primary" disabled={isExporting}>
                      Export{" "}
                      {isExporting && (
                        <LoadingOutlined style={{ marginLeft: 8 }} />
                      )}
                      {!isExporting && <ExportOutlined />}
                    </Button>
                  </Dropdown>
                  <Button
                    onClick={handleSendEmailNotification}
                    disabled={isSendingEmail}>
                    Send Email Notification
                    {isSendingEmail ? (
                      <LoadingOutlined style={{ marginLeft: 8 }} />
                    ) : (
                      <SendOutlined style={{ marginLeft: 8 }} />
                    )}
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
          <div
            style={{
              height: "calc(100vh - 300px)",
              backgroundColor: "white",
              borderRadius: "10px",
              position: "relative",
            }}>
            {isLoading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1000,
                }}>
                <Spin size="large" />
              </div>
            )}
            <Row style={{ borderBottom: "1px solid #ccc", padding: "10px" }}>
              <Col span={5}>
                <Title level={5}>Sessions</Title>
              </Col>
              <Col span={5}>
                <Title level={5}>Requests</Title>
              </Col>
              <Col span={14}>
                <Title level={5}>Details</Title>
              </Col>
            </Row>
            <Row style={{ height: "85%" }}>
              <Col span={5} style={{ height: "100%", overflowY: "auto" }}>
                {sessionData.map((session) => (
                  <Tooltip
                    key={session.session_id}
                    title={moment(session.date_created).format(
                      "YYYY-MM-DD hh:mm:ss A"
                    )}
                    placement="top">
                    <div
                      onClick={() => handleSessionSelect(session.session_id)}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        backgroundColor:
                          selectedSession === session.session_id
                            ? "#e0e0e0"
                            : "transparent",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      <span>{session.session_id}</span>
                      {(completedSessions.has(session.session_id) ||
                        (metadata &&
                          (!metadata.incomplete_sessions.includes(
                            session.session_id
                          ) ||
                            metadata.excluded_sessions.includes(
                              session.session_id
                            )))) && (
                        <Tooltip title="This session is complete or excluded">
                          <CheckCircleFilled style={{ color: "green" }} />
                        </Tooltip>
                      )}
                    </div>
                  </Tooltip>
                ))}
              </Col>
              <Col
                span={5}
                style={{
                  borderLeft: "1px solid #ccc",
                  borderRight: "1px solid #ccc",
                  height: "100%",
                  overflowY: "auto",
                }}>
                {selectedSession ? (
                  requestData.map((request) => (
                    <Tooltip
                      key={request.request_id}
                      title={moment(request.date_created).format(
                        "YYYY-MM-DD hh:mm:ss A"
                      )}
                      placement="top">
                      <div
                        onClick={() => handleRequestSelect(request)}
                        style={{
                          cursor: "pointer",
                          padding: "10px",
                          backgroundColor:
                            selectedRequest?.request_id === request.request_id
                              ? "#e0e0e0"
                              : "transparent",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <span>{request.request_id}</span>
                        {(request.saved || request.excluded) && (
                          <Tooltip
                            title={
                              request.excluded
                                ? "This request has been excluded"
                                : "This request has been saved"
                            }>
                            {request.excluded ? (
                              <CloseCircleFilled style={{ color: "red" }} />
                            ) : (
                              <CheckCircleFilled style={{ color: "green" }} />
                            )}
                          </Tooltip>
                        )}
                      </div>
                    </Tooltip>
                  ))
                ) : (
                  <p style={{ padding: "10px" }}>
                    Select a session to view requests
                  </p>
                )}
              </Col>
              <Col span={14} style={{ height: "100%", overflowY: "auto" }}>
                {selectedRequest ? (
                  <AnnotatorTool
                    data={{
                      ...selectedRequest,
                      label: labels[selectedRequest.request_id],
                    }}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    onDiscard={handleDiscard}
                  />
                ) : (
                  <p style={{ padding: "10px" }}>
                    Select a request to view details
                  </p>
                )}
              </Col>
            </Row>
          </div>
        </Space>
      </Card>
    </div>
  );
};

export default AnnotationView;
