import React from "react";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { Col, Row } from "antd";
import AddPaymentMethodForm from "./AddPaymentMethodForm";

interface AddCardComponentProps {
  onPaymentMethodAdded: () => void;
}

const AddCardComponent: React.FC<AddCardComponentProps> = ({ onPaymentMethodAdded }) => {
  const stripe: any = useStripe();

    const appearance = {
      theme: "stripe",
    
      variables: {
        colorPrimary: "#21b892",
        colorBackground: "#ffffff",
        colorText: "#30313d",
        colorDanger: "#df1b41",
        fontFamily: "Ideal Sans, system-ui, sans-serif",
        spacingUnit: "2px",
        borderRadius: "4px",
      }
    };

    const options: any = {
        // Fully customizable with appearance API.
        mode: "setup",
        currency: "usd",
        setupFutureUsage: "off_session",
        paymentMethodTypes: ["card"],
        appearance: appearance,
    };
    

  return (
<div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center"}}>
          <Col span={24} style={{display: "flex", flexDirection: "column"}}>
              <Row style={{ flex: 1, overflow: "auto", marginTop: "20px" }}>
                <Col span={24} style={{ width: "100%", height: "100%" }}>
                    <Elements stripe={stripe} options={options}>
                      <AddPaymentMethodForm onPaymentMethodAdded={onPaymentMethodAdded} />
                    </Elements>
                </Col>
              </Row>
          </Col>
    </div>
  );
};

export default AddCardComponent;
