import { Form } from "antd";
import CustomInput from "../../shared/input/CustomInput";
import { useState } from "react";
import { IAddContextEntry } from "../../../types/Shared";
import { DeleteOutlined } from "@ant-design/icons";
import { OutlineButton, PrimaryButton } from "../../shared/button/Buttons";

const AddContextEntry: React.FC<IAddContextEntry> = ({
  reference,
  setReference,
  description,
  setDescription,
  loading,
  handleFileChange,
  selectedFile,
  handleFileRemove,
  contextText,
  handleContextTextChange,
}) => {
  const [focusedField, setFocusedField] = useState<string | null>(null);

  return (
    <div>
      <Form layout="vertical" className="register-wrap" disabled={loading}>
        <Form.Item
          required
          label="Reference"
          className={`form-list-item ${
            focusedField === "Reference" ? "focused" : ""
          }`}>
          <CustomInput
            value={reference}
            className="inputWrapper"
            onChange={(e) => setReference(e.target.value)}
            placeholder="e.g., “faq”"
            onFocus={() => setFocusedField("Reference")}
            onBlur={() => setFocusedField(null)}
          />
        </Form.Item>
        <Form.Item
          required
          label="Description"
          className={`form-list-item ${
            focusedField === "Description" ? "focused" : ""
          }`}>
          <CustomInput
            value={description}
            className="inputWrapper"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="e.g., “Frequently asked questions when ordering”"
            onFocus={() => setFocusedField("Description")}
            onBlur={() => setFocusedField(null)}
          />
        </Form.Item>

        <Form.Item>
          <div className="upload-wrap">
            <div className="upload-title-wrap">
              <label htmlFor="fileInput">
                Upload a file - Select a file containing the context for this
                entry
              </label>
            </div>
            <div>
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="fileInput"
                accept=".pdf, .md, .txt, .json"
                disabled={loading}
              />
              <PrimaryButton
                title={selectedFile ? selectedFile.name : "Choose File"}
                loading={loading}
                className="buttonWrapper btn-radius-8"
                disabled={loading}
                onClick={() => {
                  document.getElementById("fileInput")?.click();
                }}
              />
              {selectedFile && (
                <OutlineButton
                  danger
                  icon={<DeleteOutlined />}
                  onClick={handleFileRemove}
                  style={{ marginLeft: 10 }}
                  disabled={loading}
                />
              )}
            </div>
          </div>
        </Form.Item>
        <Form.Item>
          <div className="line-wrap">
            <div className="divider-left"></div>
            <div className="title-or"> or </div>
            <div className="divider-right"></div>
          </div>
        </Form.Item>
        <Form.Item
          label="Paste text"
          className={`form-list-item ${
            focusedField === "Paste-text" ? "focused" : ""
          }`}>
          <p style={{ marginBottom: "6px", marginTop: "0", fontSize: "12px" }}>
            Copy and paste your context in the input field below
          </p>
          <CustomInput
            inputType="textarea"
            className="inputTextAreaWrapper"
            value={contextText}
            onChange={(e) => handleContextTextChange(e.target.value)}
            rows={4}
            placeholder="Paste context here"
            onFocus={() => setFocusedField("Paste-text")}
            onBlur={() => setFocusedField(null)}
            disabled={!!selectedFile || loading}
          />
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddContextEntry;
