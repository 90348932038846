import React, { useState, useEffect } from "react";
import { Card, Col, Row, Space, Spin, Select, Form, Input, Button, Modal, message } from "antd";
import { usePermissions } from "../../PermissionsProvider";
import { CompanyService } from "../../services/CompanyService";
import { EmailService } from "../../services/EmailService";

const { Option } = Select;

interface Company {
  id: string;
  company_name: string;
}

const templates = [
    { id: "dynamic", name: "Dynamic" },
    { id: "welcome", name: "Welcome" },
    { id: "get_started", name: "Get Started" },
    { id: "sentinels_building", name: "Sentinels Building" },
    { id: "sentinels_finished", name: "Sentinels Finished" },
    { id: "fine_tune_scheduled", name: "Fine Tune Scheduled" },
    { id: "fine_tune_started", name: "Fine Tune Started" },
    { id: "fine_tune_finished", name: "Fine Tune Finished" },
];

const EmailOverview: React.FC = () => {
  const { apiKey } = usePermissions();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    refreshCompanies();
  }, [apiKey]);

  const refreshCompanies = () => {
    setIsLoading(true);
    console.log("Fetching companies...");
    CompanyService.getAllCompanies(setIsLoading, apiKey).subscribe(
      (response: any) => {
        setCompanies(response || []);
        setIsLoading(false);
      },
      (error: any) => {
        console.error("Error fetching companies:", error);
        setCompanies([]);
        setIsLoading(false);
      }
    );
  };

  const handleTemplateChange = (value: string) => {
    setSelectedTemplate(value);
    form.setFieldsValue({ template: value });
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      setIsModalVisible(true);
    }).catch((error) => {
      console.error("Validation failed:", error);
    });
  };

  const handleConfirm = () => {
    const formData = form.getFieldsValue();
    const companyId = formData.recipient;
    const templateName = formData.template;

    setIsLoading(true);

    if (templateName === "dynamic") {
      const payload = {
        subject: formData.subject,
        header: formData.header,
        body: formData.body,
        buttonText: formData.buttonText,
        buttonLink: formData.buttonLink,
      };
      console.log(`Sending dynamic email to ${companyId} with payload:`, payload);

      EmailService.sendDynamicEmail(companyId, payload, setIsLoading, apiKey).subscribe(
        () => {
          setIsLoading(false);
          setIsModalVisible(false);
          message.success("Dynamic email sent successfully");
          form.resetFields();
        },
        (error: any) => {
          setIsLoading(false);
          setIsModalVisible(false);
          message.error("Failed to send dynamic email");
          console.error("Error sending dynamic email:", error);
        }
      );
    } else {
        console.log(`Sending template email to ${companyId} with template:`, templateName);
      EmailService.sendTemplateEmail(companyId, templateName, setIsLoading, apiKey).subscribe(
        () => {
          setIsLoading(false);
          setIsModalVisible(false);
          message.success("Template email sent successfully");
          form.resetFields();
        },
        (error: any) => {
          setIsLoading(false);
          setIsModalVisible(false);
          message.error("Failed to send template email");
          console.error("Error sending template email:", error);
        }
      );
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlePreview = () => {
    form.validateFields().then(() => {
      setIsPreviewModalVisible(true);
    }).catch((error) => {
      console.error("Validation failed:", error);
    });
  };

  const renderEmailPreview = () => {
    const { subject, header, body, buttonText, buttonLink } = form.getFieldsValue();
    return (
      <div style={{ border: "1px solid #d9d9d9", padding: "20px", borderRadius: "4px" }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
          <strong style={{ marginRight: "10px" }}>Subject:</strong>
          <span>{subject}</span>
        </div>
        <div style={{ textAlign: "center" }}>
          <h2 style={{ marginBottom: "20px" }}>{header}</h2>
          <p style={{ whiteSpace: "pre-wrap", marginBottom: "20px" }}>{body}</p>
          {buttonText && buttonLink && (
            <Button type="primary" href={buttonLink} target="_blank">
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    );
  };

  const isFormValid = () => {
    const requiredFields = ["recipient", "template"];
    if (selectedTemplate === "dynamic") {
      requiredFields.push("subject", "header", "body");
    }
    
    const formValues = form.getFieldsValue();
    return requiredFields.every(field => formValues[field]);
  };

  if (isLoading) {
    return (
      <Card className="email-wrapper">
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Row justify="space-between" align="middle">
            <Col>
              <h1>Email</h1>
            </Col>
          </Row>
          <Spin size="large" style={{ display: "flex", justifyContent: "center" }} />
        </Space>
      </Card>
    );
  }

  return (
    <Card className="email-wrapper">
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Row justify="space-between" align="middle">
          <Col>
            <h1>Email</h1>
          </Col>
        </Row>
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Recipient"
                name="recipient"
                rules={[{ required: true, message: "Please select a recipient" }]}
              >
                <Select
                  placeholder="Select a company"
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                  loading={isLoading}
                  options={companies.map((company) => ({
                    value: company.id,
                    label: company.company_name,
                  }))}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Template"
                name="template"
                rules={[{ required: true, message: "Please select a template" }]}
              >
                <Select placeholder="Select a template" onChange={handleTemplateChange}>
                  {templates.map((template) => (
                    <Option key={template.id} value={template.id}>
                      {template.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          
          {selectedTemplate === "dynamic" && (
            <div style={{ marginTop: "24px" }}>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[{ required: true, message: "Please enter a subject" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Header"
                name="header"
                rules={[{ required: true, message: "Please enter a header" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Body"
                name="body"
                rules={[{ required: true, message: "Please enter a body" }]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Button Text (Optional)" name="buttonText">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Button Link (Optional)" name="buttonLink">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          )}
          
          <Form.Item shouldUpdate>
            {() => (
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isFormValid()}
                >
                  Send Email
                </Button>
                {selectedTemplate === "dynamic" && (
                  <Button onClick={handlePreview}>Preview</Button>
                )}
              </Space>
            )}
          </Form.Item>
        </Form>
      </Space>
      <Modal
        title="Confirm Email Send"
        visible={isModalVisible}
        onOk={handleConfirm}
        onCancel={handleCancel}
        confirmLoading={isLoading}
      >
        <p>Are you sure you want to send this email?</p>
      </Modal>
      <Modal
        title="Email Preview"
        visible={isPreviewModalVisible}
        onOk={() => setIsPreviewModalVisible(false)}
        onCancel={() => setIsPreviewModalVisible(false)}
        width={600}
      >
        {renderEmailPreview()}
      </Modal>
    </Card>
  );
};

export default EmailOverview;

