import React, { useEffect, useState } from "react";
import { Sentinel, SeverityLevel } from "../../model_gen/sentinel";
import { SentinelService } from "../../services/SentinelService";
import {
  Col,
  notification,
  Popover,
  Row,
  Table,
  Tag,
  Typography,
  Empty,
} from "antd";
import moment from "moment/moment";
import {
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { usePermissions } from "../../PermissionsProvider";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { Application } from "../../model_gen/application";
import { ApplicationService } from "../../services/ApplicationService";
import TableDropDownMenu from "../shared/tablemenus/TableDropdownMenu";
import { SentinelListProps } from "../../types/ISentinels";
import { PrimaryButton } from "../shared/button/Buttons";

const { Title } = Typography;

type SentinelColumns = ColumnsType<Sentinel>;

const SentinelList: React.FC<SentinelListProps> = ({
  applicationId,
  actionType,
  emptyText,
}) => {
  const { apiKey } = usePermissions();

  const [applications, setApplications] = useState<Application[]>([]);
  const [sentinels, setSentinels] = useState<Sentinel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  const severityColorMap: Record<SeverityLevel, string> = {
    [SeverityLevel.UNRECOGNIZED]: "default",
    [SeverityLevel.INFO]: "default",
    [SeverityLevel.WARN]: "warning",
    [SeverityLevel.ERROR]: "warning",
    [SeverityLevel.FATAL]: "error",
  };

  const severityPopovers: Record<SeverityLevel, string> = {
    [SeverityLevel.UNRECOGNIZED]: "This Sentinel is NOT evaluating requests.",
    [SeverityLevel.INFO]: "This Sentinel is NOT evaluating requests.",
    [SeverityLevel.WARN]: "This Sentinel is NOT evaluating requests.",
    [SeverityLevel.ERROR]: "This Sentinel is NOT evaluating requests.",
    [SeverityLevel.FATAL]: "This Sentinel is evaluating requests.",
  };

  const navigate = useNavigate();
  const refreshApplications = () => {
    ApplicationService.getApplications(null, apiKey).subscribe(
      (apps: Application[]) => {
        setApplications(apps);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };
  useEffect(() => {
    refreshApplications();
  }, [apiKey]);

  const getSentinels = (appId: number) => {
    SentinelService.getSentinels(appId, setIsLoading, apiKey).subscribe(
      (sents: Sentinel[]) => {
        // Only add Sentinels that support the action_type passed in
        let tmp = sents;
        if (actionType) {
          tmp = sents.filter(
            (sent) =>
              sent.action_types.includes(actionType) ||
              sent.action_types.includes("ALL")
          );
        }
        const sortedSentinels = tmp.sort((a, b) => b.severity - a.severity);
        setSentinels(sortedSentinels);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const deleteSentinel = (sentinelId: number) => {
    SentinelService.deleteSentinel(sentinelId, setIsLoading, apiKey).subscribe(
      () => {
        // Remove the deleted sentinel from the list
        const updatedSentinels = sentinels.filter(
          (sent) => sent.id !== sentinelId
        );
        setSentinels(updatedSentinels);
        notification.success({
          message: "Success",
          description: "Sentinel deleted successfully",
        });
      },
      (error: any) => {
        console.error(error);
        notification.error({
          message: "Error",
          description: "Failed to delete sentinel",
        });
      }
    );
  };

  // const disableSentinel = (sentinelId: number) => {
  //   SentinelService.disableSentinel(sentinelId, setIsLoading, apiKey).subscribe(
  //     (sentinel: Sentinel) => {
  //       const updatedSentinels = sentinels.map((sent) =>
  //         sent.id === sentinel.id ? sentinel : sent
  //       );
  //       setSentinels(updatedSentinels);
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   );
  // };

  // const enableSentinel = (sentinelId: number) => {
  //   SentinelService.enableSentinel(sentinelId, setIsLoading, apiKey).subscribe(
  //     (sentinel: Sentinel) => {
  //       const updatedSentinels = sentinels.map((sent) =>
  //         sent.id === sentinel.id ? sentinel : sent
  //       );
  //       setSentinels(updatedSentinels);
  //     },
  //     (error: any) => {
  //       console.error(error);
  //     }
  //   );
  // };

  const cloneSentinel = (sentinelId: number, applicationId: number) => {
    SentinelService.cloneSentinel(
      sentinelId,
      applicationId,
      setIsLoading,
      apiKey
    ).subscribe(
      (sentinel: Sentinel) => {
        const updatedSentinels = sentinels.map((sent) =>
          sent.id === sentinel.id ? sentinel : sent
        );
        setSentinels(updatedSentinels);
        notification.success({
          message: "Success",
          description: "Sentinel cloned successfully",
        });
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const sentinelColumns: SentinelColumns = [
    {
      title: "Name",
      dataIndex: "sentinel_name",
      key: "sentinel_name",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (state) => (
        <Tag
          color={
            state === "ACTIVE"
              ? "success"
              : state === "DISABLED"
              ? "error"
              : "default"
          }>
          {state}
        </Tag>
      ),
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      render: (severity: SeverityLevel) => (
        <Popover content={severityPopovers[severity]}>
          <Tag color={severityColorMap[severity]}>
            {SeverityLevel[severity]}
          </Tag>
        </Popover>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      key: "date_created",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "",
      key: "action",
      align: "right",
      render: (_, record) => {
        const items = [
          {
            label: (
              <a
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/application/${record.application_id}/sentinel/${record.id}`
                  );
                }}>
                Edit Sentinel
              </a>
            ),
            key: "0",
            icon: <EditOutlined />,
          },
          {
            label: "Clone Sentinel",
            children: applications
              .filter((app) => app.id !== record.application_id)
              .map((app) => ({
                key: app.id.toString(),
                label: app.application_name,
                onClick: () => cloneSentinel(record.id, app.id),
              })),
            // label: (
            //   <Dropdown
            //     placement="bottomRight"
            //     overlay={
            //       <Menu
            //         onClick={(e) => {
            //           // Handle menu item click, e.key is the applicationId
            //           console.log(
            //             `Clone sentinel ${record.id} to application ${e.key}`
            //           );
            //           cloneSentinel(record.id, Number(e.key));
            //         }}
            //       >
            //         {applications
            //           .filter((app) => app.id !== record.application_id)
            //           .map((app) => (
            //             <Menu.Item key={app.id}>
            //               {app.application_name}
            //             </Menu.Item>
            //           ))}
            //       </Menu>
            //     }
            //     trigger={["hover"]}
            //   >
            //     <a
            //       rel="noopener noreferrer"
            //       onClick={(e) => {
            //         e.stopPropagation();
            //         //   deleteApplicationAction(action.id);
            //       }}
            //     >
            //       Clone Sentinel
            //     </a>
            //   </Dropdown>
            // ),
            key: "1",
            icon: <CopyOutlined />,
          },
          {
            label: (
              <a
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  //   deleteApplicationAction(action.id);
                }}>
                Disable Sentinel
              </a>
            ),
            key: "3",
            icon: <CheckCircleOutlined />,
          },
          {
            label: (
              <a
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteSentinel(record.id);
                }}>
                Delete Sentinel
              </a>
            ),
            key: "4",
            icon: <DeleteOutlined />,
          },
        ];
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <TableDropDownMenu items={items} placement="bottomLeft" />
          </div>
        );
      },
      //   render: (_, record) => (
      //     <Button.Group>
      //       <Tooltip title="Edit Sentinel">
      //         <Button
      //           icon={<EditOutlined />}
      //           onClick={(e) => {
      //             e.stopPropagation(); // Prevent row onClick from being triggered
      //             navigate(
      //               `/application/${record.application_id}/sentinel/${record.id}`
      //             );
      //           }}
      //         />
      //       </Tooltip>
      //       <Tooltip title="Clone Sentinel">
      //         <Dropdown
      //           overlay={
      //             <Menu
      //               onClick={(e) => {
      //                 // Handle menu item click, e.key is the applicationId
      //                 console.log(
      //                   `Clone sentinel ${record.id} to application ${e.key}`
      //                 );
      //                 cloneSentinel(record.id, Number(e.key));
      //               }}
      //             >
      //               {applications
      //                 .filter((app) => app.id !== record.application_id)
      //                 .map((app) => (
      //                   <Menu.Item key={app.id}>{app.application_name}</Menu.Item>
      //                 ))}
      //             </Menu>
      //           }
      //           trigger={["click"]}
      //         >
      //           <Button
      //             icon={<CopyOutlined />}
      //             onClick={(e) => e.stopPropagation()}
      //           />
      //         </Dropdown>
      //       </Tooltip>
      //       {record.state === "ACTIVE" || record.state === "ENABLED" ? (
      //         <Tooltip title="Disable Sentinel">
      //           <Button
      //             icon={<StopOutlined />}
      //             onClick={(e) => {
      //               e.stopPropagation(); // Prevent row onClick from being triggered
      //               disableSentinel(record.id);
      //             }}
      //           />
      //         </Tooltip>
      //       ) : (
      //         <Tooltip title="Enable Sentinel">
      //           <Button
      //             icon={<CheckCircleOutlined />}
      //             onClick={(e) => {
      //               e.stopPropagation(); // Prevent row onClick from being triggered
      //               enableSentinel(record.id);
      //             }}
      //           />
      //         </Tooltip>
      //       )}
      //       <Tooltip title="Delete Sentinel">
      //         <Button
      //           icon={<DeleteOutlined />}
      //           onClick={(e) => {
      //             e.stopPropagation(); // Prevent row onClick from being triggered
      //             deleteSentinel(record.id);
      //           }}
      //         />
      //       </Tooltip>
      //     </Button.Group>
      //   ),
    },
  ];

  useEffect(() => {
    if (!applicationId) return;
    getSentinels(applicationId);
  }, [applicationId]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }
  };

  const paginatedSentinels = sentinels.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const defaultEmptyContent = (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
  );

  return (
    <div>
      <Row align="middle">
        <Col span={12}>
          <Title level={4}>Sentinels</Title>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <PrimaryButton
            className="buttonWrapper"
            title="New Sentinel"
            icon={<PlusOutlined />}
            onClick={() =>
              navigate(`/application/${applicationId}/sentinel/new`)
            }
          />
        </Col>
      </Row>
      <Table
        columns={sentinelColumns}
        dataSource={paginatedSentinels}
        rowKey="id"
        pagination={
          sentinels.length > pageSize
            ? {
                current: currentPage,
                pageSize: pageSize,
                total: sentinels.length,
                showSizeChanger: false,
              }
            : false
        }
        loading={isLoading}
        onChange={handleTableChange}
        locale={{
          emptyText: emptyText || defaultEmptyContent,
        }}
        onRow={(record) => {
          return {
            onClick: () =>
              navigate(`/application/${applicationId}/sentinel/${record.id}`),
          };
        }}
      />
    </div>
  );
};

export default SentinelList;
