import { Component } from "react";
import { GeneralService } from "./GeneralService";
import { Dict } from "styled-components/dist/types";

export class EmailService extends Component {
    public static sendTemplateEmail(companyId: string, templateName: string, loadingHandler: any, apiKey: string) {
        return GeneralService.post(`/email/company/${companyId}/send_template/${templateName}`,
          null,
          loadingHandler,
          JSON,
          GeneralService.PROD_ENDPOINT,
          apiKey
        );
      }

      public static sendDynamicEmail(companyId: string, payload: Dict, loadingHandler: any, apiKey: string) {
        return GeneralService.post(`/email/company/${companyId}/send_dynamic`,
          JSON.stringify(payload),
          loadingHandler,
          JSON,
          GeneralService.PROD_ENDPOINT,
          apiKey
        );
      }

}