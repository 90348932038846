// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-sentinel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}

.edit-sentinel-card {
  width: 100%;
  max-width: 900px;
}

.register-sentinel-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-sentinel-card {
  width: 100%;
  max-width: 900px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/Sentinel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".edit-sentinel-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 2rem 0;\n}\n\n.edit-sentinel-card {\n  width: 100%;\n  max-width: 900px;\n}\n\n.register-sentinel-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.register-sentinel-card {\n  width: 100%;\n  max-width: 900px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
