import React, { useCallback, useEffect, useState } from "react";
import { Card, Form, notification, Table } from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  FileOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ApplicationService } from "../../services/ApplicationService";
import { usePermissions } from "../../PermissionsProvider";
import {
  Application,
  ApplicationContext,
  ApplicationObjective,
} from "../../model_gen/application";
import type { ColumnsType } from "antd/es/table";
import CustomInput from "../shared/input/CustomInput";
import { ApplicationFormProps } from "../../types/IApplication";
import ApplicationModal from "../modals/applicationModal/ApplicationModal";
import AddContextEntry from "../modals/addcontext/AddContextEntry";
import {
  DashedButton,
  OutlineButton,
  PrimaryButton,
} from "../shared/button/Buttons";

const ApplicationForm: React.FC<ApplicationFormProps> = ({ applicationId }) => {
  const navigate = useNavigate();
  const { apiKey } = usePermissions();
  const [contextEntries, setContextEntries] = useState<ApplicationContext[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [applicationName, setApplicationName] = useState("");
  const [applicationRefName, setApplicationRefName] = useState("");
  const [objective, setObjective] = useState("");
  const [application, setApplication] = useState<Application | undefined>();
  const [isOpenContextEntry, setIsOpenContextEntry] = useState(false);
  const [isContextSubmitting, setIsContextSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [contextText, setContextText] = useState<string>("");
  const [reference, setReference] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setContextText(""); // Clear context text when a file is selected
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  const handleContextTextChange = (v: string) => {
    setContextText(v);
    if (v) {
      setSelectedFile(null); // Clear selected file when text is entered
    }
  };

  const isAddContextEntryDisabled =
    !reference || !description || (!selectedFile && !contextText);

  const [focusedField, setFocusedField] = useState<string | null>(null);

  useEffect(() => {
    if (applicationId) {
      setLoading(true);
      ApplicationService.getApplication(
        Number(applicationId),
        setLoading,
        apiKey
      ).subscribe(
        (application: Application) => {
          setApplication(application);
          setApplicationName(application.application_name);
          setApplicationRefName(application.application_ref_name);
          setObjective(
            application.application_objective?.objective_body["main"] || ""
          );
          setLoading(false);
        },
        (error: any) => {
          console.error(error);
          notification.error({
            message: "Error",
            description: "Failed to fetch application",
          });
          setLoading(false);
        }
      );
      ApplicationService.getApplicationContext(
        Number(applicationId),
        null,
        apiKey
      ).subscribe(
        (contexts: any) => {
          if (!contexts) return;
          setContextEntries(contexts);
        },
        (error: any) => {
          console.error(error);
          notification.error({
            message: "Error",
            description: "Failed to fetch application context",
          });
        }
      );
    }
  }, [applicationId]);
  const handleRegisterApplication = useCallback(() => {
    setLoading(true);
    const application: Application = Application.fromPartial({
      application_name: applicationName,
      application_ref_name: applicationRefName,
      application_objective: ApplicationObjective.fromPartial({
        objective_body: { main: objective },
      }),
    });
    ApplicationService.registerApplication(application, null, apiKey).subscribe(
      (app: Application) => {
        const payload = { application_id: app.id, context: contextEntries };
        setLoading(true);
        ApplicationService.updateApplicationContexts(
          payload,
          null,
          apiKey
        ).subscribe(
          () => {
            notification.success({
              message: "Success",
              description: "Application created successfully",
            });
            navigate(`/application/${app.id}`);
          },
          (error: any) => {
            setLoading(false);
            console.error(error);
            notification.error({
              message: "Error",
              description: "Created application, but failed to set context",
            });
          }
        );
      },
      (error: any) => {
        console.error(error);
        notification.error({
          message: "Error",
          description: "Failed to create application",
        });
        setLoading(false);
      }
    );
  }, [
    applicationName,
    applicationRefName,
    objective,
    navigate,
    contextEntries,
  ]);

  const handleUpdate = useCallback(() => {
    setLoading(true);
    if (application) {
      application.application_name = applicationName;
      application.application_ref_name = applicationRefName;
      if (!application.application_objective) {
        application.application_objective = ApplicationObjective.fromPartial(
          {}
        );
      }
      application.application_objective.objective_body = { main: objective };
    }
    setLoading(true);
    ApplicationService.updateApplication(application, null, apiKey).subscribe(
      () => {
        notification.success({
          message: "Success",
          description: "Application updated successfully",
        });
        navigate(`/application/${applicationId}`);
      },
      (error: any) => {
        setLoading(false);
        console.error(error);
        notification.error({
          message: "Error",
          description: "Failed to update application",
        });
        setLoading(false);
      }
    );

    const payload = { application_id: applicationId, context: contextEntries };
    setLoading(true);
    ApplicationService.updateApplicationContexts(
      payload,
      null,
      apiKey
    ).subscribe(
      () => {
        notification.success({
          message: "Success",
          description: "Application context updated successfully",
        });
      },
      (error: any) => {
        setLoading(false);
        console.error(error);
        notification.error({
          message: "Error",
          description: "Failed to update Application context",
        });
      }
    );
  }, [
    application,
    applicationName,
    applicationRefName,
    objective,
    contextEntries,
    applicationId,
    navigate,
  ]);

  const handleSubmit = () => {
    if (applicationId) {
      handleUpdate();
    } else {
      handleRegisterApplication();
    }
  };
  const handleRemoveContextEntry = useCallback((itemId: number) => {
    setContextEntries((prev) => prev.filter((entry) => entry.id !== itemId));
  }, []);

  const showContextModal = () => {
    setIsOpenContextEntry(true);
  };

  const clearContextModal = () => {
    setIsOpenContextEntry(false);
    setReference("");
    setDescription("");
    setSelectedFile(null);
    setContextText("");
    setIsContextSubmitting(false);
  };

  const handleContextSubmit = () => {
    setIsContextSubmitting(true); // Start loading when submit is initiated

    const applicationContext = ApplicationContext.fromPartial({
      application_id: Number(applicationId),
      reference: reference,
      description: description,
      date_created: Date.now(),
      context_type: selectedFile ? "file" : "text",
      context_path: "",
      context_body: contextText,
    });

    // Upload the file to S3, insert the ApplicationContext, then refresh the ApplicationContext list

    if (selectedFile) {
      ApplicationService.uploadApplicationContextFile(
        selectedFile,
        null,
        apiKey
      ).subscribe(
        (response: any) => {
          console.log(`Uploaded file: ${response}`);
          const s3_path = response["s3_path"];
          applicationContext.context_path = s3_path;
          setContextEntries([...contextEntries, applicationContext]);
          clearContextModal();
        },
        (error: any) => {
          console.error(error);
          notification.error({
            message: "Error",
            description: "Failed to upload file",
          });
        }
      );
    } else {
      setContextEntries([...contextEntries, applicationContext]);
      clearContextModal();
    }
    // Disable all inputs
    setIsOpenContextEntry(false);
  };

  const handleCancel = () => {
    setIsOpenContextEntry(false);
  };

  const columns: ColumnsType<ApplicationContext> = [
    {
      title: "",
      key: "icon",
      render: (_: any, record: ApplicationContext) =>
        record.context_type === "text" ? (
          <FileTextOutlined />
        ) : (
          <FileOutlined />
        ),
    },
    {
      title: "Reference",
      dataIndex: "reference" as keyof ApplicationContext,
      key: "reference",
    },
    {
      title: "Description",
      dataIndex: "description" as keyof ApplicationContext,
      key: "description",
    },
    {
      title: "Added",
      dataIndex: "date_created" as keyof ApplicationContext,
      key: "date_created",
      render: (millis: number) => new Date(millis).toLocaleDateString(),
    },
    {
      title: "",
      key: "action",
      render: (_: any, record: ApplicationContext) => (
        <OutlineButton
          danger
          icon={<DeleteOutlined />}
          onClick={() => handleRemoveContextEntry(record.id)}
        />
      ),
    },
  ];

  return (
    <div className="register-application-container">
      <Card
        title={applicationId ? "Edit Application" : "Register Application"}
        bordered={false}
        className="register-application-card"
        headStyle={{ backgroundColor: "#E9F8F4" }}
        extra={
          <CloseOutlined
            style={{ cursor: "pointer", color: "#9DA6A4" }}
            onClick={() => navigate(-1)}
          />
        }>
        <Form layout="vertical" className="register-wrap" disabled={loading}>
          <Form.Item
            label="Name"
            required
            tooltip="The name of the application"
            className={`form-list-item ${
              focusedField === "name" ? "focused" : ""
            }`}>
            <CustomInput
              className="inputWrapper"
              onFocus={() => setFocusedField("name")}
              onBlur={() => setFocusedField(null)}
              value={applicationName}
              onChange={(e) => setApplicationName(e.target.value)}
              placeholder="My Application"
            />
          </Form.Item>
          <Form.Item
            label="Application Reference"
            required
            tooltip="The reference name of the application"
            className={`form-list-item ${
              focusedField === "applicationRefName" ? "focused" : ""
            }`}>
            <CustomInput
              className="inputWrapper"
              onFocus={() => setFocusedField("applicationRefName")}
              onBlur={() => setFocusedField(null)}
              value={applicationRefName}
              onChange={(e) => setApplicationRefName(e.target.value)}
              placeholder="my_application"
            />
          </Form.Item>
          <Form.Item
            label="Objective"
            required
            tooltip="The objective of the application"
            className={`form-list-item ${
              focusedField === "objective" ? "focused" : ""
            }`}>
            <CustomInput
              inputType="textarea"
              className="inputTextAreaWrapper"
              placeholder="The purpose of this application is to do this thing."
              onFocus={() => setFocusedField("objective")}
              onBlur={() => setFocusedField(null)}
              value={objective}
              onChange={(e) => setObjective(e.target.value)}
              rows={4}
            />
          </Form.Item>
          <Form.Item label="Application Context" required>
            <Table
              dataSource={contextEntries}
              columns={columns}
              rowKey="id"
              pagination={false}
              loading={loading}
            />
            <DashedButton onClick={showContextModal} block>
              + Add Context Entry
            </DashedButton>
          </Form.Item>
          <Form.Item>
            <PrimaryButton
              title={
                applicationId ? "Update Application" : "Register Application"
              }
              className="buttonWrapper w-100 btn-radius-8"
              onClick={handleSubmit}
              block
              loading={loading}
              disabled={loading}
            />
          </Form.Item>
        </Form>
      </Card>
      <ApplicationModal
        title="Add Context Entry"
        open={isOpenContextEntry}
        submitButtonTitle="Add Entry"
        onCancel={handleCancel}
        onSubmitButtonClick={handleContextSubmit}
        onCancelButtonClick={handleCancel}
        isLoading={isContextSubmitting}
        isContainedBtnDisabled={isAddContextEntryDisabled}>
        <AddContextEntry
          reference={reference}
          setReference={setReference}
          description={description}
          setDescription={setDescription}
          loading={isContextSubmitting}
          handleFileChange={handleFileChange}
          selectedFile={selectedFile}
          handleFileRemove={handleFileRemove}
          contextText={contextText}
          handleContextTextChange={handleContextTextChange}
        />
      </ApplicationModal>
    </div>
  );
};

export default ApplicationForm;
