import GeneralService from "./GeneralService";
import {Component} from "react";


export class SearchService extends Component {
  public static search(key: string, searchTerm: string, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/search/${key}/${searchTerm}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
}

