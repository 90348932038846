import React, { useEffect, useState } from "react";
import { Card, Space, Tabs } from "antd";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { BellOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { IntegrationsService } from "../../services/IntegrationsService";
import { usePermissions } from "../../PermissionsProvider";
import DevelopersIntegrations from "./DevelopersIntegrations";
import NotificationSettings from "./NotificationSettings";
import DevelopersAPIKeys from "./DevelopersAPIKeys";

const { TabPane } = Tabs;

const DevelopersBase: React.FC = () => {
  const { apiKey } = usePermissions();
  const location = useLocation(); // Access location object
  const [defaultActiveKey, setDefaultActiveKey] = useState<string>("1");
  const [slackCode, setSlackCode] = useState<string | null>(null);

  // Map hash to tab keys
  const tabKeys: any = {
    overview: "1",
    apikeys: "2",
    webhooks: "3",
    integrations: "4",
    logs: "5",
    notifications: "6",
  };

  useEffect(() => {
    const parsedQuery = queryString.parse(location.search); // Parse query parameters
    const hash = location.hash.replace("#", "");

    setDefaultActiveKey(tabKeys[hash] || "1"); // Default to 'overview' if hash is not recognized

    // Extract Slack OAuth code if present
    if (parsedQuery.code) {
      setSlackCode(parsedQuery.code as string);
      // You can now send this code to your backend for further processing
    }
  }, [location]);

  useEffect(() => {
    if (slackCode) {
      // Process the Slack code, e.g., send it to your backend
      console.log("Slack OAuth code:", slackCode);
      IntegrationsService.handleSlackAuthSuccess(
        slackCode,
        null,
        apiKey
      ).subscribe(
        (res: any) => {
          console.log(`Success authenticating with Slack! ${res}`);
        },
        (error: any) => {
          console.log(`Error authenticating with Slack! ${error}`);
        }
      );
    }
  }, [slackCode]);

  return (
    <Card className="applications-wrapper">
      <h1 style={{ margin: 0, paddingBottom: 12 }}>Settings</h1>
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        <Tabs defaultActiveKey={defaultActiveKey} className="common-tab-wrap">
          {/*<TabPane tab="Overview" icon={<EyeOutlined />} key="1">*/}
          {/*  <DevelopersOverview />*/}
          {/*</TabPane>*/}
          <TabPane tab="API Keys" icon={<SearchOutlined />} key="2">
            <DevelopersAPIKeys />
          </TabPane>
          <TabPane tab="Integrations" icon={<SyncOutlined />} key="4">
            <DevelopersIntegrations />
          </TabPane>
          {/*<TabPane tab="Logs" icon={<LoginOutlined />} key="5">*/}
          {/*  <DevelopersLogs />*/}
          {/*</TabPane>*/}
          <TabPane tab="Notifications" icon={<BellOutlined />} key="6">
            <NotificationSettings />
          </TabPane>
        </Tabs>
      </Space>
    </Card>
  );
};

export default DevelopersBase;
