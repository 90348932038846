import React, { useEffect, useState } from "react";
import { Card, Space, Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { CreditCardOutlined, DollarOutlined, HistoryOutlined, SettingOutlined } from "@ant-design/icons";
import BillingOverview from "./BillingOverview";
import BillingPaymentMethods from "./BillingPaymentMethods";
import BillingHistory from "./BillingHistory";
import BillingPreferences from "./BillingPreferences";

const { TabPane } = Tabs;

const BillingBase: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState<string>("1");

  // Map hash to tab keys
  const tabKeys: any = {
    overview: "1",
    paymentMethods: "2",
    history: "3",
    preferences: "4",
  };

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    setActiveKey(tabKeys[hash] || "1");
  }, [location]);

  const handleTabChange = (key: string) => {
    setActiveKey(key);
    navigate(`#${Object.keys(tabKeys).find(k => tabKeys[k] === key) || "overview"}`);
  };

  return (
    <Card className="billing-wrapper">
      <h1 style={{ margin: 0, paddingBottom: 12 }}>Billing</h1>
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        <Tabs activeKey={activeKey} onChange={handleTabChange} className="common-tab-wrap">
          <TabPane tab="Overview" icon={<DollarOutlined />} key="1">
            <BillingOverview onTabChange={handleTabChange} />
          </TabPane>
          <TabPane tab="Payment Methods" icon={<CreditCardOutlined />} key="2">
            <BillingPaymentMethods />
          </TabPane>
          <TabPane tab="Billing History" icon={<HistoryOutlined />} key="3">
            <BillingHistory />
          </TabPane>
          <TabPane tab="Preferences" icon={<SettingOutlined />} key="4">
            <BillingPreferences />
          </TabPane>
        </Tabs>
      </Space>
    </Card>
  );
};

export default BillingBase;
