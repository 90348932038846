import React, { useEffect, useState } from "react";
import { Col, Row, Space, Spin, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import { Application } from "../../model_gen/application";
import { ApplicationService } from "../../services/ApplicationService";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../PermissionsProvider";
import Card from "antd/es/card/Card";
import editIcon from "../../assets/svg/edit-icon.svg";
import copyIcon from "../../assets/svg/copy-icon.svg";
import TableDropDownMenu from "../shared/tablemenus/TableDropdownMenu";
import { PrimaryButton } from "../shared/button/Buttons";
import { notification } from "antd";

type ApplicationColumns = ColumnsType<Application>;

const Applications: React.FC = () => {
  const { apiKey } = usePermissions();
  const [applications, setApplications] = useState<Application[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const navigate = useNavigate();

  const deleteApplication = (applicationId: number) => {
    setIsLoading(true);
    ApplicationService.deleteApplication(
      applicationId,
      setIsLoading,
      apiKey
    ).subscribe(
      () => {
        setApplications((prevApplications) =>
          prevApplications.filter((app) => app.id !== applicationId)
        );
        setIsLoading(false);
        notification.success({
          message: "Success",
          description: "Application deleted successfully",
        });
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
        notification.error({
          message: "Error",
          description: "Failed to delete application",
        });
      }
    );
  };

  const applicationColumns: ApplicationColumns = [
    {
      title: "Name",
      dataIndex: "application_name",
      key: "application_name",
      sorter: (a, b) => a.application_name.localeCompare(b.application_name),
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      key: "date_created",
      render: (date) => moment(date).format("YYYY-MM-DD hh:mm:ss A"),
      sorter: (a, b) =>
        moment(a.date_created).unix() - moment(b.date_created).unix(),
    },
    {
      title: "Last Activity",
      dataIndex: "last_activity",
      key: "last_activity",
      render: (date) => {
        if (date > 0) {
          return moment(date).format("YYYY-MM-DD hh:mm:ss A");
        } else {
          return "N/A";
        }
      },
      sorter: (a, b) =>
        moment(a.last_activity).unix() - moment(b.last_activity).unix(),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => {
        const items = [
          {
            label: (
              <a
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/application/${record.id}/edit`);
                }}>
                Edit
              </a>
            ),
            key: "0",
            icon: <img src={editIcon} width={15} />,
          },
          {
            label: (
              <a
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/application/${record.id}/clone`);
                }}>
                Clone
              </a>
            ),
            key: "1",
            icon: <img src={copyIcon} width={15} />,
          },
          {
            label: (
              <a
                rel="noopener noreferrer"
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    window.confirm(
                      "Are you sure you want to delete this application?"
                    )
                  ) {
                    deleteApplication(record.id);
                  }
                }}>
                Delete
              </a>
            ),
            key: "2",
            icon: <DeleteOutlined />,
          },
        ];
        return <TableDropDownMenu items={items} />;
      },
      align: "right",
    },
  ];

  useEffect(() => {
    refreshApplications();
  }, [apiKey]);

  const refreshApplications = () => {
    setIsLoading(true);
    ApplicationService.getApplications(setIsLoading, apiKey).subscribe(
      (apps: Application[]) => {
        // Sort applications by last_activity in descending order
        const sortedApps = apps.sort((a, b) => {
          const dateA = a.last_activity > 0 ? a.last_activity : 0;
          const dateB = b.last_activity > 0 ? b.last_activity : 0;
          return dateB - dateA;
        });
        setApplications(sortedApps);
        setIsLoading(false);
      },
      (error: any) => {
        console.error(error);
        setIsLoading(false);
      }
    );
  };

  // const disableApplication = (applicationId: number) => {
  //   setIsLoading(true);
  //   ApplicationService.disableApplication(
  //     applicationId,
  //     setIsLoading,
  //     apiKey
  //   ).subscribe(
  //     (application: Application) => {
  //       const updatedApplications = applications.map((app) =>
  //         app.id === application.id ? application : app
  //       );
  //       setApplications(updatedApplications);
  //     },
  //     (error: any) => {
  //       console.error(error);
  //       setIsLoading(false);
  //     }
  //   );
  // };

  // const enableApplication = (applicationId: number) => {
  //   setIsLoading(true);
  //   ApplicationService.enableApplication(
  //     applicationId,
  //     setIsLoading,
  //     apiKey
  //   ).subscribe(
  //     (application: Application) => {
  //       const updatedApplications = applications.map((app) =>
  //         app.id === application.id ? application : app
  //       );
  //       setApplications(updatedApplications);
  //     },
  //     (error: any) => {
  //       console.error(error);
  //       setIsLoading(false);
  //     }
  //   );
  // };

  // Define a separate component for the expanded row content

  const handleTableChange = (pagination: TablePaginationConfig) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }
  };

  const paginatedApplications = applications.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <Card className="applications-wrapper">
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        <Row justify="space-between" align="middle">
          <Col>
            <h1>Applications</h1>
          </Col>
          <Col>
            <PrimaryButton
              title="New Application"
              className="buttonWrapper"
              icon={<PlusOutlined />}
              onClick={() => navigate("/application/new")}
            />
          </Col>
        </Row>
        {isLoading ? (
          <Spin
            size="large"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
            }}
          />
        ) : (
          <Table
            rowKey={(record) => record.id.toString()}
            columns={applicationColumns}
            dataSource={paginatedApplications}
            pagination={
              applications.length > pageSize
                ? {
                    current: currentPage,
                    pageSize: pageSize,
                    total: applications.length,
                    showSizeChanger: false,
                  }
                : false
            }
            onChange={handleTableChange}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  const targetId =
                    ((e.target as Element).parentElement as Element).id ?? "";
                  const isDropdownClick =
                    targetId === "table-dropdown-space" ||
                    targetId === "table-dropdown";

                  if (!isDropdownClick) navigate(`/application/${record.id}`);
                },
              };
            }}
          />
        )}
      </Space>
    </Card>
  );
};

export default Applications;
