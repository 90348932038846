import React, { useState, useEffect } from "react"; // Import useEffect
import { Card, notification, Button, Spin } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { SentinelService } from "../../services/SentinelService";
import { Sentinel } from "../../model_gen/sentinel";
import { useNavigate, useParams } from "react-router-dom";
import { usePermissions } from "../../PermissionsProvider";
import { ApplicationService } from "../../services/ApplicationService";
import type { SelectProps } from "antd";
import SentinelForm from "./SentinelForm";
import { ApplicationAction } from "../../model_gen/application";

const EditSentinel: React.FC = () => {
  const navigate = useNavigate();
  const { apiKey } = usePermissions();
  const { sentinelId, applicationId } = useParams();
  const [loading, setLoading] = useState(false);
  const [sentinel, setSentinel] = useState<Sentinel | undefined>();
  const [allActionTypes, setAllActionTypes] = useState<SelectProps["options"]>(
    []
  );
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (sentinelId) {
      setLoading(true);
      SentinelService.getSentinel(
        Number(sentinelId),
        setLoading,
        apiKey
      ).subscribe(
        (sentinel: Sentinel) => {
          setSentinel(sentinel);
          setLoading(false);
          setIsDataLoaded(true);
        },
        (error: any) => {
          console.error(error);
          notification.error({
            message: "Error",
            description: "Failed to fetch sentinel",
          });
          setLoading(false);
        }
      );
    }
  }, [sentinelId, apiKey]);

  const handleSubmit = (formData: any) => {
    setLoading(true);
    if (sentinel) {
      const updatedSentinel = {
        ...sentinel,
        sentinel_name: formData.sentinelName,
        sentinel_directive: {
          ...sentinel.sentinel_directive,
          directive_body: { main: formData.directive, override: "" },
          examples: formData.examples.map((example: any) => ({
            example_type: example.exampleType,
            example_body: example.inputExample,
          })),
        },
        action_types: formData.actionTypes,
        severity: formData.severity,
        meta: {
          ...sentinel.meta,
          include_input: formData.includeInput,
          input_include_context: formData.includeContext,
          input_include_system: formData.includeSystemPrompt,
          include_tool_calls: formData.includeToolCalls,
        },
      };

      SentinelService.updateSentinel(
        updatedSentinel,
        setLoading,
        apiKey
      ).subscribe(
        () => {
          notification.success({
            message: "Success",
            description: "Sentinel updated successfully",
          });
          navigate(-1);
        },
        (error: any) => {
          console.error(error);
          notification.error({
            message: "Error",
            description: "Failed to update sentinel",
          });
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    if (applicationId) {
      ApplicationService.getApplicationActions(
        Number(applicationId),
        () => {},
        apiKey
      ).subscribe(
        (actions: ApplicationAction[]) => {
          setAllActionTypes(
            actions.map(({ action_type }) => ({ value: action_type }))
          );
        },
        (error: any) => {
          console.error(error);
        }
      );
    }
  }, [applicationId, apiKey]);

  return (
    <div className="register-sentinel-container">
      <Card
        title="Edit Sentinel"
        bordered={false}
        className="register-sentinel-card"
        extra={
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={() => navigate(-1)}
            aria-label="Close"
          />
        }>
        {isDataLoaded ? (
          <SentinelForm
            initialSentinel={sentinel}
            allActionTypes={allActionTypes}
            loading={loading}
            onSubmit={handleSubmit}
            submitButtonText="Update Sentinel"
          />
        ) : (
          <Spin size="large" />
        )}
      </Card>
    </div>
  );
};

export default EditSentinel;
