import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Collapse,
  Input,
  Tooltip,
  Button,
  Select,
  Form,
  Typography,
} from "antd";
import { ChatMessage, ToolCall } from "../../model_gen/chat";
import { RequestData } from "../../model_gen/annotation";
import {
  PlusOutlined,
  MinusCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { TextAreaRef } from "antd/es/input/TextArea";

interface AnnotatorToolProps {
  data: RequestData;
  onSave: (label: ChatMessage) => void;
  onCancel: () => void;
  onDiscard: () => void;
}

const { Panel } = Collapse;
const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const AnnotatorTool: React.FC<AnnotatorToolProps> = ({
  data,
  onSave,
  onCancel,
  onDiscard,
}) => {
  const [label, setLabel] = useState<ChatMessage>(
    data.label || data.messages[data.messages.length - 1]
  );
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const bottomRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<TextAreaRef>(null);

  useEffect(() => {
    const keysToExpand = [];
    for (let i = data.messages.length - 1; i >= 0; i--) {
      if (i === data.messages.length - 1 || data.messages[i].role === "user") {
        keysToExpand.push(i.toString());
      } else {
        break;
      }
    }
    setActiveKeys(keysToExpand.reverse());

    // Delay scrolling to allow for expansion
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }, [data.messages]);

  useEffect(() => {
    if (data.messages.length > 0) {
      setLabel(data.label || data.messages[data.messages.length - 1]);
    }
  }, [data]);

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLabel((prev) => ({ ...prev, content: e.target.value }));
  };

  const handleRevert = () => {
    const lastMessage = data.messages[data.messages.length - 1];
    setLabel(lastMessage);
  };

  const handleSave = () => {
    onSave(label);
    setLabel(ChatMessage.fromPartial({}));
    setActiveKeys([]);
  };

  const handleCancel = () => {
    setLabel(ChatMessage.fromPartial({}));
    setActiveKeys([]);
    onCancel();
  };

  const handleToolSelect = (value: string, index: number) => {
    const newToolCalls = [...(label.tool_calls || [])];
    newToolCalls[index] = {
      ...newToolCalls[index],
      function: { name: value, arguments: "{}" },
    };
    setLabel((prev) => ({ ...prev, tool_calls: newToolCalls }));
  };

  const handleParamChange = (index: number, params: string) => {
    const newToolCalls = [...(label.tool_calls || [])];
    newToolCalls[index] = {
      ...newToolCalls[index],
      function: { ...newToolCalls[index].function, arguments: params },
    };
    setLabel((prev) => ({ ...prev, tool_calls: newToolCalls }));
  };

  const addToolCall = () => {
    setLabel((prev) => ({
      ...prev,
      tool_calls: [
        ...(prev.tool_calls || []),
        ToolCall.fromPartial({ function: { name: "", arguments: "{}" } }),
      ],
    }));
  };

  const removeToolCall = (index: number) => {
    setLabel((prev) => ({
      ...prev,
      tool_calls: (prev.tool_calls || []).filter((_, i) => i !== index),
    }));
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const isTextAreaFocused =
        document.activeElement ===
        textAreaRef.current?.resizableTextArea?.textArea;
      const isToolCallArgumentFocused =
        document.activeElement?.tagName === "INPUT" &&
        (document.activeElement as HTMLInputElement).placeholder ===
          "Enter JSON arguments";

      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        handleSave();
      } else if (!isTextAreaFocused && !isToolCallArgumentFocused) {
        if (event.key === "s") {
          event.preventDefault();
          handleSave();
        } else if (event.key === "e") {
          event.preventDefault();
          textAreaRef.current?.focus();
        } else if (event.key === "x") {
          event.preventDefault();
          onDiscard();
        } else if (event.key === "a") {
          event.preventDefault();
          addToolCall();
        }
      }
    },
    [handleSave, onDiscard]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div style={{ padding: "10px" }}>
      <div style={{ marginBottom: "10px" }}>
        <Text strong>Keyboard Shortcuts:</Text>
        <Text>
          {" "}
          s = save, e = edit, x = exclude, a = add tool call, Enter = save
        </Text>
      </div>
      {data.messages.map((message, index) => (
        <div key={index} style={{ marginBottom: "10px" }}>
          <Collapse
            activeKey={activeKeys}
            onChange={(keys) => setActiveKeys(keys as string[])}>
            <Panel header={message.role} key={index.toString()}>
              {index !== data.messages.length - 1 ? (
                <>
                  <div>{message.content}</div>
                  {message.tool_calls && message.tool_calls.length > 0 && (
                    <div style={{ marginTop: "10px" }}>
                      <strong>Tool Calls:</strong>
                      {message.tool_calls.map((tool, toolIndex) => (
                        <div key={toolIndex}>
                          {tool.function?.name}: {tool.function?.arguments}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <div>
                  <TextArea
                    ref={textAreaRef}
                    value={label.content}
                    onChange={handleContentChange}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                  {(label.content !== message.content ||
                    JSON.stringify(label.tool_calls) !==
                      JSON.stringify(message.tool_calls)) && (
                    <Tooltip
                      title={
                        <div>
                          <strong>Original:</strong>{" "}
                          {message.content || "No content"}
                          {data.tools && (
                            <div style={{ marginTop: "10px" }}>
                              <strong>Original Tool Calls:</strong>
                              {message.tool_calls &&
                              message.tool_calls.length > 0 ? (
                                message.tool_calls.map((tool, toolIndex) => (
                                  <div key={toolIndex}>
                                    {tool.function?.name}:{" "}
                                    {tool.function?.arguments}
                                  </div>
                                ))
                              ) : (
                                <div>No tool calls</div>
                              )}
                            </div>
                          )}
                        </div>
                      }
                      overlayStyle={{
                        maxWidth: "800px",
                        backgroundColor: "white",
                      }}>
                      View Original
                      <Button
                        onClick={handleRevert}
                        size="small"
                        style={{ marginLeft: 8 }}>
                        Undo
                      </Button>
                    </Tooltip>
                  )}
                  <div style={{ marginTop: "10px" }}>
                    <Button onClick={addToolCall} icon={<PlusOutlined />}>
                      Add Tool Call
                    </Button>
                  </div>
                  {label.tool_calls && label.tool_calls.length > 0 && (
                    <div style={{ marginTop: "10px" }}>
                      <strong>Tool Calls:</strong>
                      {label.tool_calls.map((toolCall, toolIndex) => (
                        <div key={toolIndex} style={{ marginTop: "10px" }}>
                          <Select
                            style={{ width: 200, marginBottom: 10 }}
                            placeholder="Select a tool"
                            onChange={(value) =>
                              handleToolSelect(value, toolIndex)
                            }
                            value={toolCall.function?.name}>
                            {data.tools.map((tool, index) => (
                              <Option key={index} value={tool.function?.name}>
                                {tool.function?.name}
                              </Option>
                            ))}
                          </Select>
                          <Button
                            icon={<MinusCircleOutlined />}
                            onClick={() => removeToolCall(toolIndex)}
                            style={{ marginLeft: 8 }}
                          />
                          {toolCall.function?.name && (
                            <Form layout="vertical">
                              <Form.Item
                                label={
                                  <span>
                                    Arguments
                                    <Tooltip
                                      title={
                                        <pre>
                                          {JSON.stringify(
                                            data.tools.find(
                                              (tool) =>
                                                tool.function?.name ===
                                                toolCall.function?.name
                                            )?.function?.parameters?.properties,
                                            null,
                                            2
                                          )}
                                        </pre>
                                      }>
                                      <InfoCircleOutlined
                                        style={{ marginLeft: "5px" }}
                                      />
                                    </Tooltip>
                                  </span>
                                }>
                                <Input
                                  value={toolCall.function?.arguments}
                                  onChange={(e) =>
                                    handleParamChange(toolIndex, e.target.value)
                                  }
                                  placeholder="Enter JSON arguments"
                                />
                              </Form.Item>
                            </Form>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </Panel>
          </Collapse>
        </div>
      ))}
      <div style={{ marginBottom: "10px" }}>
        <Button
          type="primary"
          onClick={handleSave}
          style={{ marginRight: "10px" }}>
          Save
        </Button>
        <Button onClick={handleCancel} style={{ marginRight: "10px" }}>
          Cancel
        </Button>
        <Button type="primary" danger onClick={onDiscard}>
          Exclude
        </Button>
      </div>
      <div ref={bottomRef} />
    </div>
  );
};

export default AnnotatorTool;
