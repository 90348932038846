import React from "react";
import { Card, Row, Space } from "antd";
import Faults from "./Faults";
import Fallbacks from "./Fallbacks";

const Events: React.FC = () => {
  return (
    <Card className="applications-wrapper">
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        <Row justify="space-between" align="middle">
          <h1>Fallbacks</h1>
        </Row>
        <Fallbacks />
        <Row justify="space-between" align="middle">
          <h1>Faults</h1>
        </Row>
        <Faults />
      </Space>
    </Card>
  );
};

export default Events;

