// @ts-nocheck
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: key.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import Long from "long";

export const protobufPackage = "key";

export interface Key {
  id: number;
  date_created: number;
  key_value?: string | undefined;
  company_id: number;
  description: string;
  owner: string;
  type: string;
  obfuscated: boolean;
  key_map: KeyIdMap | undefined;
}

export interface KeyIdMap {
  openai_api_key: number;
  groq_api_key: number;
  anthropic_api_key: number;
  cerebras_api_key: number;
}

export interface KeyMap {
  openai_api_key: Key | undefined;
  groq_api_key: Key | undefined;
  anthropic_api_key: Key | undefined;
  cerebras_api_key: Key | undefined;
}

function createBaseKey(): Key {
  return {
    id: 0,
    date_created: 0,
    key_value: undefined,
    company_id: 0,
    description: "",
    owner: "",
    type: "",
    obfuscated: false,
    key_map: undefined,
  };
}

export const Key = {
  encode(message: Key, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.date_created !== 0) {
      writer.uint32(16).int64(message.date_created);
    }
    if (message.key_value !== undefined) {
      writer.uint32(26).string(message.key_value);
    }
    if (message.company_id !== 0) {
      writer.uint32(32).int64(message.company_id);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.owner !== "") {
      writer.uint32(50).string(message.owner);
    }
    if (message.type !== "") {
      writer.uint32(58).string(message.type);
    }
    if (message.obfuscated !== false) {
      writer.uint32(64).bool(message.obfuscated);
    }
    if (message.key_map !== undefined) {
      KeyIdMap.encode(message.key_map, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Key {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.key_value = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.company_id = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.owner = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.type = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.obfuscated = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.key_map = KeyIdMap.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Key {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      key_value: isSet(object.key_value) ? globalThis.String(object.key_value) : undefined,
      company_id: isSet(object.company_id) ? globalThis.Number(object.company_id) : 0,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      owner: isSet(object.owner) ? globalThis.String(object.owner) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      obfuscated: isSet(object.obfuscated) ? globalThis.Boolean(object.obfuscated) : false,
      key_map: isSet(object.key_map) ? KeyIdMap.fromJSON(object.key_map) : undefined,
    };
  },

  toJSON(message: Key): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.key_value !== undefined) {
      obj.key_value = message.key_value;
    }
    if (message.company_id !== 0) {
      obj.company_id = Math.round(message.company_id);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.owner !== "") {
      obj.owner = message.owner;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.obfuscated !== false) {
      obj.obfuscated = message.obfuscated;
    }
    if (message.key_map !== undefined) {
      obj.key_map = KeyIdMap.toJSON(message.key_map);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Key>, I>>(base?: I): Key {
    return Key.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Key>, I>>(object: I): Key {
    const message = createBaseKey();
    message.id = object.id ?? 0;
    message.date_created = object.date_created ?? 0;
    message.key_value = object.key_value ?? undefined;
    message.company_id = object.company_id ?? 0;
    message.description = object.description ?? "";
    message.owner = object.owner ?? "";
    message.type = object.type ?? "";
    message.obfuscated = object.obfuscated ?? false;
    message.key_map = (object.key_map !== undefined && object.key_map !== null)
      ? KeyIdMap.fromPartial(object.key_map)
      : undefined;
    return message;
  },
};

function createBaseKeyIdMap(): KeyIdMap {
  return { openai_api_key: 0, groq_api_key: 0, anthropic_api_key: 0, cerebras_api_key: 0 };
}

export const KeyIdMap = {
  encode(message: KeyIdMap, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.openai_api_key !== 0) {
      writer.uint32(8).int64(message.openai_api_key);
    }
    if (message.groq_api_key !== 0) {
      writer.uint32(16).int64(message.groq_api_key);
    }
    if (message.anthropic_api_key !== 0) {
      writer.uint32(24).int64(message.anthropic_api_key);
    }
    if (message.cerebras_api_key !== 0) {
      writer.uint32(32).int64(message.cerebras_api_key);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KeyIdMap {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKeyIdMap();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.openai_api_key = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.groq_api_key = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.anthropic_api_key = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.cerebras_api_key = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KeyIdMap {
    return {
      openai_api_key: isSet(object.openai_api_key) ? globalThis.Number(object.openai_api_key) : 0,
      groq_api_key: isSet(object.groq_api_key) ? globalThis.Number(object.groq_api_key) : 0,
      anthropic_api_key: isSet(object.anthropic_api_key) ? globalThis.Number(object.anthropic_api_key) : 0,
      cerebras_api_key: isSet(object.cerebras_api_key) ? globalThis.Number(object.cerebras_api_key) : 0,
    };
  },

  toJSON(message: KeyIdMap): unknown {
    const obj: any = {};
    if (message.openai_api_key !== 0) {
      obj.openai_api_key = Math.round(message.openai_api_key);
    }
    if (message.groq_api_key !== 0) {
      obj.groq_api_key = Math.round(message.groq_api_key);
    }
    if (message.anthropic_api_key !== 0) {
      obj.anthropic_api_key = Math.round(message.anthropic_api_key);
    }
    if (message.cerebras_api_key !== 0) {
      obj.cerebras_api_key = Math.round(message.cerebras_api_key);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KeyIdMap>, I>>(base?: I): KeyIdMap {
    return KeyIdMap.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<KeyIdMap>, I>>(object: I): KeyIdMap {
    const message = createBaseKeyIdMap();
    message.openai_api_key = object.openai_api_key ?? 0;
    message.groq_api_key = object.groq_api_key ?? 0;
    message.anthropic_api_key = object.anthropic_api_key ?? 0;
    message.cerebras_api_key = object.cerebras_api_key ?? 0;
    return message;
  },
};

function createBaseKeyMap(): KeyMap {
  return {
    openai_api_key: undefined,
    groq_api_key: undefined,
    anthropic_api_key: undefined,
    cerebras_api_key: undefined,
  };
}

export const KeyMap = {
  encode(message: KeyMap, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.openai_api_key !== undefined) {
      Key.encode(message.openai_api_key, writer.uint32(10).fork()).ldelim();
    }
    if (message.groq_api_key !== undefined) {
      Key.encode(message.groq_api_key, writer.uint32(18).fork()).ldelim();
    }
    if (message.anthropic_api_key !== undefined) {
      Key.encode(message.anthropic_api_key, writer.uint32(26).fork()).ldelim();
    }
    if (message.cerebras_api_key !== undefined) {
      Key.encode(message.cerebras_api_key, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): KeyMap {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKeyMap();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.openai_api_key = Key.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.groq_api_key = Key.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.anthropic_api_key = Key.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cerebras_api_key = Key.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KeyMap {
    return {
      openai_api_key: isSet(object.openai_api_key) ? Key.fromJSON(object.openai_api_key) : undefined,
      groq_api_key: isSet(object.groq_api_key) ? Key.fromJSON(object.groq_api_key) : undefined,
      anthropic_api_key: isSet(object.anthropic_api_key) ? Key.fromJSON(object.anthropic_api_key) : undefined,
      cerebras_api_key: isSet(object.cerebras_api_key) ? Key.fromJSON(object.cerebras_api_key) : undefined,
    };
  },

  toJSON(message: KeyMap): unknown {
    const obj: any = {};
    if (message.openai_api_key !== undefined) {
      obj.openai_api_key = Key.toJSON(message.openai_api_key);
    }
    if (message.groq_api_key !== undefined) {
      obj.groq_api_key = Key.toJSON(message.groq_api_key);
    }
    if (message.anthropic_api_key !== undefined) {
      obj.anthropic_api_key = Key.toJSON(message.anthropic_api_key);
    }
    if (message.cerebras_api_key !== undefined) {
      obj.cerebras_api_key = Key.toJSON(message.cerebras_api_key);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<KeyMap>, I>>(base?: I): KeyMap {
    return KeyMap.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<KeyMap>, I>>(object: I): KeyMap {
    const message = createBaseKeyMap();
    message.openai_api_key = (object.openai_api_key !== undefined && object.openai_api_key !== null)
      ? Key.fromPartial(object.openai_api_key)
      : undefined;
    message.groq_api_key = (object.groq_api_key !== undefined && object.groq_api_key !== null)
      ? Key.fromPartial(object.groq_api_key)
      : undefined;
    message.anthropic_api_key = (object.anthropic_api_key !== undefined && object.anthropic_api_key !== null)
      ? Key.fromPartial(object.anthropic_api_key)
      : undefined;
    message.cerebras_api_key = (object.cerebras_api_key !== undefined && object.cerebras_api_key !== null)
      ? Key.fromPartial(object.cerebras_api_key)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
