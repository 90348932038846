import React, { useState, useEffect } from "react";
import { Card, notification, SelectProps, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { SentinelService } from "../../services/SentinelService";
import {
  Sentinel,
  SentinelDirective,
  SentinelExample,
  DirectiveBody,
} from "../../model_gen/sentinel";
import { useNavigate, useParams } from "react-router-dom";
import { usePermissions } from "../../PermissionsProvider";
import { ApplicationService } from "../../services/ApplicationService";
import { ApplicationAction } from "../../model_gen/application";
import SentinelForm from "./SentinelForm";

const RegisterSentinel: React.FC = () => {
  const navigate = useNavigate();
  const { apiKey } = usePermissions();
  const { applicationId } = useParams();
  const [loading, setLoading] = useState(false);
  const [allActionTypes, setAllActionTypes] = useState<SelectProps["options"]>(
    []
  );

  const handleSubmit = (formData: any) => {
    setLoading(true);
    const sentinel: Sentinel = Sentinel.fromPartial({
      application_id: Number(applicationId),
      sentinel_name: formData.sentinelName,
      action_types: formData.actionTypes,
      severity: formData.severity,
    });
    const sentinel_directive = SentinelDirective.fromPartial({
      directive_body: DirectiveBody.fromPartial({ main: formData.directive }),
      examples: formData.examples.map(({ exampleType, inputExample }: any) =>
        SentinelExample.fromPartial({
          example_type: exampleType,
          example_body: inputExample,
        })
      ),
    });
    sentinel.sentinel_directive = sentinel_directive;

    console.log(sentinel);

    SentinelService.registerSentinel(sentinel, setLoading, apiKey).subscribe(
      () => {
        notification.success({
          message: "Success",
          description: "Sentinel registered successfully",
        });
        navigate("/applications"); // Redirecting to the Applications page
      },
      (error: any) => {
        console.error(error);
        notification.error({
          message: "Error",
          description: "Failed to register sentinel",
        });
        setLoading(false);
      }
    );
  };

  const getActionTypes = () => {
    if (!applicationId) return;
    ApplicationService.getApplicationActions(
      Number(applicationId),
      () => {},
      apiKey
    ).subscribe(
      (actions: ApplicationAction[]) => {
        setAllActionTypes(
          actions.map(({ action_type }) => ({ value: action_type }))
        );
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    getActionTypes();
  }, [applicationId]);
  return (
    <div className="register-sentinel-container">
      <Card
        title="Register Sentinel"
        bordered={false}
        className="register-sentinel-card"
        extra={
          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={() => navigate(-1)}
            aria-label="Close"
          />
        }>
        <SentinelForm
          allActionTypes={allActionTypes}
          loading={loading}
          onSubmit={handleSubmit}
          submitButtonText="Create Sentinel"
        />
      </Card>
    </div>
  );
};

export default RegisterSentinel;
