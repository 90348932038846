// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configuration-panel-wrapper {
  display: grid;
  grid-template-columns: 1.1fr 1px 1fr;
  gap: 25px;
}
.configuration-panel-wrapper-modal {
  display: flex;
  flex-direction: column;
  border: 1px solid #ECEDEC;
  padding: 2rem;
  border-radius: 12px;
}
.configuration-panel-wrapper-modal .ant-descriptions-item {
  padding: 0 !important;
}

.configuration-panel-label {
  color: #616665;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/ConfigurationPanel.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oCAAoC;EACpC,SAAS;AACX;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".configuration-panel-wrapper {\n  display: grid;\n  grid-template-columns: 1.1fr 1px 1fr;\n  gap: 25px;\n}\n.configuration-panel-wrapper-modal {\n  display: flex;\n  flex-direction: column;\n  border: 1px solid #ECEDEC;\n  padding: 2rem;\n  border-radius: 12px;\n}\n.configuration-panel-wrapper-modal .ant-descriptions-item {\n  padding: 0 !important;\n}\n\n.configuration-panel-label {\n  color: #616665;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
