import React from "react";
import { Row, Col, Typography, Button, Card } from "antd";
import { useNavigate } from "react-router-dom";

interface OnboardingCompleteCardProps {

}

export const OnboardingCompleteCard: React.FC<OnboardingCompleteCardProps> = () => {
  const navigate = useNavigate();

  const goToApplications = () => {
    navigate("/applications");
  };

  return (
    <Card style={{ background: "white" }} bordered={false}>
      <Row gutter={32} style={{ padding: "8px 8px" }}>
        <Col span={12} style={{ display: "flex", alignItems: "center", gap: "24px" }}>
          <div>
              <Typography.Title
              level={5}
              style={{ marginBottom: "4px", marginTop: "0" }}
              >
              Onboarding Complete
              </Typography.Title>
              <Typography.Paragraph
              style={{ marginBottom: "0" }}
              className="text-typo-paragraph"
              >
              You're all set! Let's explore.
              </Typography.Paragraph>
          </div>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
            <Button type="primary" style={{ marginTop: "8px" }} onClick={goToApplications}>
              View My Applications
            </Button>
        </Col>
      </Row>
    </Card>
  );
};
