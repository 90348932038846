// @ts-nocheck
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: metric.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "metric";

export interface RequestTimingMetric {
  time_request_start: number;
  time_pre_request: number;
  time_first_chunk?: number | undefined;
  time_request_end: number;
  request_id: string;
}

function createBaseRequestTimingMetric(): RequestTimingMetric {
  return {
    time_request_start: 0,
    time_pre_request: 0,
    time_first_chunk: undefined,
    time_request_end: 0,
    request_id: "",
  };
}

export const RequestTimingMetric = {
  encode(message: RequestTimingMetric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.time_request_start !== 0) {
      writer.uint32(13).float(message.time_request_start);
    }
    if (message.time_pre_request !== 0) {
      writer.uint32(21).float(message.time_pre_request);
    }
    if (message.time_first_chunk !== undefined) {
      writer.uint32(29).float(message.time_first_chunk);
    }
    if (message.time_request_end !== 0) {
      writer.uint32(37).float(message.time_request_end);
    }
    if (message.request_id !== "") {
      writer.uint32(42).string(message.request_id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RequestTimingMetric {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRequestTimingMetric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.time_request_start = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.time_pre_request = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.time_first_chunk = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.time_request_end = reader.float();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.request_id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RequestTimingMetric {
    return {
      time_request_start: isSet(object.time_request_start) ? globalThis.Number(object.time_request_start) : 0,
      time_pre_request: isSet(object.time_pre_request) ? globalThis.Number(object.time_pre_request) : 0,
      time_first_chunk: isSet(object.time_first_chunk) ? globalThis.Number(object.time_first_chunk) : undefined,
      time_request_end: isSet(object.time_request_end) ? globalThis.Number(object.time_request_end) : 0,
      request_id: isSet(object.request_id) ? globalThis.String(object.request_id) : "",
    };
  },

  toJSON(message: RequestTimingMetric): unknown {
    const obj: any = {};
    if (message.time_request_start !== 0) {
      obj.time_request_start = message.time_request_start;
    }
    if (message.time_pre_request !== 0) {
      obj.time_pre_request = message.time_pre_request;
    }
    if (message.time_first_chunk !== undefined) {
      obj.time_first_chunk = message.time_first_chunk;
    }
    if (message.time_request_end !== 0) {
      obj.time_request_end = message.time_request_end;
    }
    if (message.request_id !== "") {
      obj.request_id = message.request_id;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RequestTimingMetric>, I>>(base?: I): RequestTimingMetric {
    return RequestTimingMetric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RequestTimingMetric>, I>>(object: I): RequestTimingMetric {
    const message = createBaseRequestTimingMetric();
    message.time_request_start = object.time_request_start ?? 0;
    message.time_pre_request = object.time_pre_request ?? 0;
    message.time_first_chunk = object.time_first_chunk ?? undefined;
    message.time_request_end = object.time_request_end ?? 0;
    message.request_id = object.request_id ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
