import { Col, Collapse, Row, Typography } from "antd";
import CodeBlock from "../shared/codeblock/CodeBlock";
import { IInstallCard } from "../../types/ITutorial";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { isDimmed } from "../../utils/FormatUtils";

const { Panel } = Collapse;

export const InstallCard = ({ isReady }: IInstallCard) => {
  const tabs = [
    {
      key: "1",
      label: "Python",
      language: "bash",
      children: "pip install maitai-python",
    },
    {
      key: "2",
      label: "Javascript",
      language: "bash",
      children: "npm install maitai",
    },
  ];

  return (
    <Collapse
      expandIconPosition="end"
      style={{ background: "white" }}
      bordered={false}
    >
      <Panel
        header={
          <Row gutter={32} style={{ padding: "8px 8px", ...isDimmed(isReady) }}>
            <Col span={24} style={{ display: "flex", alignItems: "center", gap: "24px" }}>
              <div>
                {isReady ? (
                  <CheckCircleOutlined style={{ color: "#21b892", fontSize: "24px" }} />
                ) : (
                  <MinusCircleOutlined style={{ color: "#bfbfbf", fontSize: "24px" }} />
                )}
              </div>
              <div style={{ flex: 1 }}>
                <Typography.Title
                  level={5}
                  style={{ marginBottom: "4px", marginTop: "0" }}
                >
                  Install
                </Typography.Title>
                <Typography.Paragraph
                  style={{ marginBottom: "0" }}
                  className="text-typo-paragraph"
                >
                  Install the Maitai SDK
                </Typography.Paragraph>
              </div>
              <Typography.Text style={{ marginLeft: "auto" }}>
                View Terminal Command
              </Typography.Text>
            </Col>
          </Row>
        }
        key="1"
      >
        <Row>
          <Col span={12}></Col>
          <Col span={12}>
            <CodeBlock items={tabs} />
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};
