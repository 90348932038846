import GeneralService from "./GeneralService";
import { Component } from "react";

export class ReportsService extends Component {
  public static getFaults(
    offset: number,
    limit: number,
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.get(
      `/reports/faults/${offset}/${limit}`,
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }
  public static getFaultsByIntent(
    intent_id: number,
    offset: number,
    limit: number,
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.get(
      `/reports/faults/${intent_id}/${offset}/${limit}`,
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }

  public static getFallbacks(
    offset: number,
    limit: number,
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.get(
      `/reports/fallbacks/${offset}/${limit}`,
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }
  public static getFallbacksByIntent(
    intent_id: number,
    offset: number,
    limit: number,
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.get(
      `/reports/fallbacks/${intent_id}/${offset}/${limit}`,
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }
}
