// @ts-nocheck
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: application.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Config } from "./config";
import Long from "long";

export const protobufPackage = "application";

export interface ApplicationObjective {
  id: number;
  date_created: number;
  application_id: number;
  objective_body: { [key: string]: string };
}

export interface ApplicationObjective_ObjectiveBodyEntry {
  key: string;
  value: string;
}

export interface ApplicationContext {
  id: number;
  date_created: number;
  application_id: number;
  context_body: string;
  context_type: string;
  context_path: string;
  reference: string;
  description: string;
}

export interface Application {
  id: number;
  date_created: number;
  application_name: string;
  application_ref_name: string;
  company_id: number;
  application_objective: ApplicationObjective | undefined;
  state: string;
  meta: Config | undefined;
  action_types: ApplicationAction[];
  last_activity: number;
  fault_notifications: boolean;
  session_summaries: boolean;
}

export interface ApplicationAction {
  id: number;
  action_type: string;
  date_created: number;
  application_id: number;
  meta: Config | undefined;
  last_activity: number;
  notifications_enabled: boolean;
  prompt: string;
  request_count: number;
}

function createBaseApplicationObjective(): ApplicationObjective {
  return { id: 0, date_created: 0, application_id: 0, objective_body: {} };
}

export const ApplicationObjective = {
  encode(message: ApplicationObjective, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.date_created !== 0) {
      writer.uint32(16).int64(message.date_created);
    }
    if (message.application_id !== 0) {
      writer.uint32(24).int64(message.application_id);
    }
    Object.entries(message.objective_body).forEach(([key, value]) => {
      ApplicationObjective_ObjectiveBodyEntry.encode({ key: key as any, value }, writer.uint32(34).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApplicationObjective {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplicationObjective();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.application_id = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          const entry4 = ApplicationObjective_ObjectiveBodyEntry.decode(reader, reader.uint32());
          if (entry4.value !== undefined) {
            message.objective_body[entry4.key] = entry4.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApplicationObjective {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      application_id: isSet(object.application_id) ? globalThis.Number(object.application_id) : 0,
      objective_body: isObject(object.objective_body)
        ? Object.entries(object.objective_body).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: ApplicationObjective): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.application_id !== 0) {
      obj.application_id = Math.round(message.application_id);
    }
    if (message.objective_body) {
      const entries = Object.entries(message.objective_body);
      if (entries.length > 0) {
        obj.objective_body = {};
        entries.forEach(([k, v]) => {
          obj.objective_body[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApplicationObjective>, I>>(base?: I): ApplicationObjective {
    return ApplicationObjective.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApplicationObjective>, I>>(object: I): ApplicationObjective {
    const message = createBaseApplicationObjective();
    message.id = object.id ?? 0;
    message.date_created = object.date_created ?? 0;
    message.application_id = object.application_id ?? 0;
    message.objective_body = Object.entries(object.objective_body ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseApplicationObjective_ObjectiveBodyEntry(): ApplicationObjective_ObjectiveBodyEntry {
  return { key: "", value: "" };
}

export const ApplicationObjective_ObjectiveBodyEntry = {
  encode(message: ApplicationObjective_ObjectiveBodyEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApplicationObjective_ObjectiveBodyEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplicationObjective_ObjectiveBodyEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApplicationObjective_ObjectiveBodyEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: ApplicationObjective_ObjectiveBodyEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApplicationObjective_ObjectiveBodyEntry>, I>>(
    base?: I,
  ): ApplicationObjective_ObjectiveBodyEntry {
    return ApplicationObjective_ObjectiveBodyEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApplicationObjective_ObjectiveBodyEntry>, I>>(
    object: I,
  ): ApplicationObjective_ObjectiveBodyEntry {
    const message = createBaseApplicationObjective_ObjectiveBodyEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseApplicationContext(): ApplicationContext {
  return {
    id: 0,
    date_created: 0,
    application_id: 0,
    context_body: "",
    context_type: "",
    context_path: "",
    reference: "",
    description: "",
  };
}

export const ApplicationContext = {
  encode(message: ApplicationContext, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.date_created !== 0) {
      writer.uint32(16).int64(message.date_created);
    }
    if (message.application_id !== 0) {
      writer.uint32(24).int64(message.application_id);
    }
    if (message.context_body !== "") {
      writer.uint32(34).string(message.context_body);
    }
    if (message.context_type !== "") {
      writer.uint32(42).string(message.context_type);
    }
    if (message.context_path !== "") {
      writer.uint32(50).string(message.context_path);
    }
    if (message.reference !== "") {
      writer.uint32(58).string(message.reference);
    }
    if (message.description !== "") {
      writer.uint32(66).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApplicationContext {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplicationContext();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.application_id = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.context_body = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.context_type = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.context_path = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.reference = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApplicationContext {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      application_id: isSet(object.application_id) ? globalThis.Number(object.application_id) : 0,
      context_body: isSet(object.context_body) ? globalThis.String(object.context_body) : "",
      context_type: isSet(object.context_type) ? globalThis.String(object.context_type) : "",
      context_path: isSet(object.context_path) ? globalThis.String(object.context_path) : "",
      reference: isSet(object.reference) ? globalThis.String(object.reference) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
    };
  },

  toJSON(message: ApplicationContext): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.application_id !== 0) {
      obj.application_id = Math.round(message.application_id);
    }
    if (message.context_body !== "") {
      obj.context_body = message.context_body;
    }
    if (message.context_type !== "") {
      obj.context_type = message.context_type;
    }
    if (message.context_path !== "") {
      obj.context_path = message.context_path;
    }
    if (message.reference !== "") {
      obj.reference = message.reference;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApplicationContext>, I>>(base?: I): ApplicationContext {
    return ApplicationContext.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApplicationContext>, I>>(object: I): ApplicationContext {
    const message = createBaseApplicationContext();
    message.id = object.id ?? 0;
    message.date_created = object.date_created ?? 0;
    message.application_id = object.application_id ?? 0;
    message.context_body = object.context_body ?? "";
    message.context_type = object.context_type ?? "";
    message.context_path = object.context_path ?? "";
    message.reference = object.reference ?? "";
    message.description = object.description ?? "";
    return message;
  },
};

function createBaseApplication(): Application {
  return {
    id: 0,
    date_created: 0,
    application_name: "",
    application_ref_name: "",
    company_id: 0,
    application_objective: undefined,
    state: "",
    meta: undefined,
    action_types: [],
    last_activity: 0,
    fault_notifications: false,
    session_summaries: false,
  };
}

export const Application = {
  encode(message: Application, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.date_created !== 0) {
      writer.uint32(16).int64(message.date_created);
    }
    if (message.application_name !== "") {
      writer.uint32(26).string(message.application_name);
    }
    if (message.application_ref_name !== "") {
      writer.uint32(34).string(message.application_ref_name);
    }
    if (message.company_id !== 0) {
      writer.uint32(40).int64(message.company_id);
    }
    if (message.application_objective !== undefined) {
      ApplicationObjective.encode(message.application_objective, writer.uint32(50).fork()).ldelim();
    }
    if (message.state !== "") {
      writer.uint32(58).string(message.state);
    }
    if (message.meta !== undefined) {
      Config.encode(message.meta, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.action_types) {
      ApplicationAction.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    if (message.last_activity !== 0) {
      writer.uint32(80).int64(message.last_activity);
    }
    if (message.fault_notifications !== false) {
      writer.uint32(88).bool(message.fault_notifications);
    }
    if (message.session_summaries !== false) {
      writer.uint32(96).bool(message.session_summaries);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Application {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplication();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.application_name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.application_ref_name = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.company_id = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.application_objective = ApplicationObjective.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.state = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.meta = Config.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.action_types.push(ApplicationAction.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.last_activity = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.fault_notifications = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.session_summaries = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Application {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      application_name: isSet(object.application_name) ? globalThis.String(object.application_name) : "",
      application_ref_name: isSet(object.application_ref_name) ? globalThis.String(object.application_ref_name) : "",
      company_id: isSet(object.company_id) ? globalThis.Number(object.company_id) : 0,
      application_objective: isSet(object.application_objective)
        ? ApplicationObjective.fromJSON(object.application_objective)
        : undefined,
      state: isSet(object.state) ? globalThis.String(object.state) : "",
      meta: isSet(object.meta) ? Config.fromJSON(object.meta) : undefined,
      action_types: globalThis.Array.isArray(object?.action_types)
        ? object.action_types.map((e: any) => ApplicationAction.fromJSON(e))
        : [],
      last_activity: isSet(object.last_activity) ? globalThis.Number(object.last_activity) : 0,
      fault_notifications: isSet(object.fault_notifications) ? globalThis.Boolean(object.fault_notifications) : false,
      session_summaries: isSet(object.session_summaries) ? globalThis.Boolean(object.session_summaries) : false,
    };
  },

  toJSON(message: Application): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.application_name !== "") {
      obj.application_name = message.application_name;
    }
    if (message.application_ref_name !== "") {
      obj.application_ref_name = message.application_ref_name;
    }
    if (message.company_id !== 0) {
      obj.company_id = Math.round(message.company_id);
    }
    if (message.application_objective !== undefined) {
      obj.application_objective = ApplicationObjective.toJSON(message.application_objective);
    }
    if (message.state !== "") {
      obj.state = message.state;
    }
    if (message.meta !== undefined) {
      obj.meta = Config.toJSON(message.meta);
    }
    if (message.action_types?.length) {
      obj.action_types = message.action_types.map((e) => ApplicationAction.toJSON(e));
    }
    if (message.last_activity !== 0) {
      obj.last_activity = Math.round(message.last_activity);
    }
    if (message.fault_notifications !== false) {
      obj.fault_notifications = message.fault_notifications;
    }
    if (message.session_summaries !== false) {
      obj.session_summaries = message.session_summaries;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Application>, I>>(base?: I): Application {
    return Application.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Application>, I>>(object: I): Application {
    const message = createBaseApplication();
    message.id = object.id ?? 0;
    message.date_created = object.date_created ?? 0;
    message.application_name = object.application_name ?? "";
    message.application_ref_name = object.application_ref_name ?? "";
    message.company_id = object.company_id ?? 0;
    message.application_objective =
      (object.application_objective !== undefined && object.application_objective !== null)
        ? ApplicationObjective.fromPartial(object.application_objective)
        : undefined;
    message.state = object.state ?? "";
    message.meta = (object.meta !== undefined && object.meta !== null) ? Config.fromPartial(object.meta) : undefined;
    message.action_types = object.action_types?.map((e) => ApplicationAction.fromPartial(e)) || [];
    message.last_activity = object.last_activity ?? 0;
    message.fault_notifications = object.fault_notifications ?? false;
    message.session_summaries = object.session_summaries ?? false;
    return message;
  },
};

function createBaseApplicationAction(): ApplicationAction {
  return {
    id: 0,
    action_type: "",
    date_created: 0,
    application_id: 0,
    meta: undefined,
    last_activity: 0,
    notifications_enabled: false,
    prompt: "",
    request_count: 0,
  };
}

export const ApplicationAction = {
  encode(message: ApplicationAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.action_type !== "") {
      writer.uint32(18).string(message.action_type);
    }
    if (message.date_created !== 0) {
      writer.uint32(24).int64(message.date_created);
    }
    if (message.application_id !== 0) {
      writer.uint32(32).int64(message.application_id);
    }
    if (message.meta !== undefined) {
      Config.encode(message.meta, writer.uint32(42).fork()).ldelim();
    }
    if (message.last_activity !== 0) {
      writer.uint32(48).int64(message.last_activity);
    }
    if (message.notifications_enabled !== false) {
      writer.uint32(56).bool(message.notifications_enabled);
    }
    if (message.prompt !== "") {
      writer.uint32(66).string(message.prompt);
    }
    if (message.request_count !== 0) {
      writer.uint32(72).int64(message.request_count);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApplicationAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplicationAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.action_type = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.application_id = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.meta = Config.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.last_activity = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.notifications_enabled = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.prompt = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.request_count = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApplicationAction {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      action_type: isSet(object.action_type) ? globalThis.String(object.action_type) : "",
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      application_id: isSet(object.application_id) ? globalThis.Number(object.application_id) : 0,
      meta: isSet(object.meta) ? Config.fromJSON(object.meta) : undefined,
      last_activity: isSet(object.last_activity) ? globalThis.Number(object.last_activity) : 0,
      notifications_enabled: isSet(object.notifications_enabled)
        ? globalThis.Boolean(object.notifications_enabled)
        : false,
      prompt: isSet(object.prompt) ? globalThis.String(object.prompt) : "",
      request_count: isSet(object.request_count) ? globalThis.Number(object.request_count) : 0,
    };
  },

  toJSON(message: ApplicationAction): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.action_type !== "") {
      obj.action_type = message.action_type;
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.application_id !== 0) {
      obj.application_id = Math.round(message.application_id);
    }
    if (message.meta !== undefined) {
      obj.meta = Config.toJSON(message.meta);
    }
    if (message.last_activity !== 0) {
      obj.last_activity = Math.round(message.last_activity);
    }
    if (message.notifications_enabled !== false) {
      obj.notifications_enabled = message.notifications_enabled;
    }
    if (message.prompt !== "") {
      obj.prompt = message.prompt;
    }
    if (message.request_count !== 0) {
      obj.request_count = Math.round(message.request_count);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApplicationAction>, I>>(base?: I): ApplicationAction {
    return ApplicationAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApplicationAction>, I>>(object: I): ApplicationAction {
    const message = createBaseApplicationAction();
    message.id = object.id ?? 0;
    message.action_type = object.action_type ?? "";
    message.date_created = object.date_created ?? 0;
    message.application_id = object.application_id ?? 0;
    message.meta = (object.meta !== undefined && object.meta !== null) ? Config.fromPartial(object.meta) : undefined;
    message.last_activity = object.last_activity ?? 0;
    message.notifications_enabled = object.notifications_enabled ?? false;
    message.prompt = object.prompt ?? "";
    message.request_count = object.request_count ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
