// @ts-nocheck
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: session.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Turn } from "./chat";
import Long from "long";

export const protobufPackage = "session";

export enum SessionFeedback {
  PENDING = 0,
  CORRECT = 1,
  INCORRECT = 2,
  UNRECOGNIZED = -1,
}

export function sessionFeedbackFromJSON(object: any): SessionFeedback {
  switch (object) {
    case 0:
    case "PENDING":
      return SessionFeedback.PENDING;
    case 1:
    case "CORRECT":
      return SessionFeedback.CORRECT;
    case 2:
    case "INCORRECT":
      return SessionFeedback.INCORRECT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SessionFeedback.UNRECOGNIZED;
  }
}

export function sessionFeedbackToJSON(object: SessionFeedback): string {
  switch (object) {
    case SessionFeedback.PENDING:
      return "PENDING";
    case SessionFeedback.CORRECT:
      return "CORRECT";
    case SessionFeedback.INCORRECT:
      return "INCORRECT";
    case SessionFeedback.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Session {
  session_id: string;
  date_created: number;
  application_id: number;
  application_name?: string | undefined;
  summary: string;
  feedback: SessionFeedback;
  reviewer: { [key: string]: string };
  date_ended: number;
  company_id: number;
  meta: { [key: string]: string };
  timeline: Turn[];
  cost: number;
}

export interface Session_ReviewerEntry {
  key: string;
  value: string;
}

export interface Session_MetaEntry {
  key: string;
  value: string;
}

function createBaseSession(): Session {
  return {
    session_id: "",
    date_created: 0,
    application_id: 0,
    application_name: undefined,
    summary: "",
    feedback: 0,
    reviewer: {},
    date_ended: 0,
    company_id: 0,
    meta: {},
    timeline: [],
    cost: 0,
  };
}

export const Session = {
  encode(message: Session, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.session_id !== "") {
      writer.uint32(10).string(message.session_id);
    }
    if (message.date_created !== 0) {
      writer.uint32(16).int64(message.date_created);
    }
    if (message.application_id !== 0) {
      writer.uint32(24).int64(message.application_id);
    }
    if (message.application_name !== undefined) {
      writer.uint32(34).string(message.application_name);
    }
    if (message.summary !== "") {
      writer.uint32(50).string(message.summary);
    }
    if (message.feedback !== 0) {
      writer.uint32(56).int32(message.feedback);
    }
    Object.entries(message.reviewer).forEach(([key, value]) => {
      Session_ReviewerEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).ldelim();
    });
    if (message.date_ended !== 0) {
      writer.uint32(72).int64(message.date_ended);
    }
    if (message.company_id !== 0) {
      writer.uint32(80).int64(message.company_id);
    }
    Object.entries(message.meta).forEach(([key, value]) => {
      Session_MetaEntry.encode({ key: key as any, value }, writer.uint32(90).fork()).ldelim();
    });
    for (const v of message.timeline) {
      Turn.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.cost !== 0) {
      writer.uint32(104).int64(message.cost);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Session {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.session_id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.application_id = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.application_name = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.summary = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.feedback = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          const entry8 = Session_ReviewerEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.reviewer[entry8.key] = entry8.value;
          }
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.date_ended = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.company_id = longToNumber(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          const entry11 = Session_MetaEntry.decode(reader, reader.uint32());
          if (entry11.value !== undefined) {
            message.meta[entry11.key] = entry11.value;
          }
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.timeline.push(Turn.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.cost = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Session {
    return {
      session_id: isSet(object.session_id) ? globalThis.String(object.session_id) : "",
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      application_id: isSet(object.application_id) ? globalThis.Number(object.application_id) : 0,
      application_name: isSet(object.application_name) ? globalThis.String(object.application_name) : undefined,
      summary: isSet(object.summary) ? globalThis.String(object.summary) : "",
      feedback: isSet(object.feedback) ? sessionFeedbackFromJSON(object.feedback) : 0,
      reviewer: isObject(object.reviewer)
        ? Object.entries(object.reviewer).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      date_ended: isSet(object.date_ended) ? globalThis.Number(object.date_ended) : 0,
      company_id: isSet(object.company_id) ? globalThis.Number(object.company_id) : 0,
      meta: isObject(object.meta)
        ? Object.entries(object.meta).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      timeline: globalThis.Array.isArray(object?.timeline) ? object.timeline.map((e: any) => Turn.fromJSON(e)) : [],
      cost: isSet(object.cost) ? globalThis.Number(object.cost) : 0,
    };
  },

  toJSON(message: Session): unknown {
    const obj: any = {};
    if (message.session_id !== "") {
      obj.session_id = message.session_id;
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.application_id !== 0) {
      obj.application_id = Math.round(message.application_id);
    }
    if (message.application_name !== undefined) {
      obj.application_name = message.application_name;
    }
    if (message.summary !== "") {
      obj.summary = message.summary;
    }
    if (message.feedback !== 0) {
      obj.feedback = sessionFeedbackToJSON(message.feedback);
    }
    if (message.reviewer) {
      const entries = Object.entries(message.reviewer);
      if (entries.length > 0) {
        obj.reviewer = {};
        entries.forEach(([k, v]) => {
          obj.reviewer[k] = v;
        });
      }
    }
    if (message.date_ended !== 0) {
      obj.date_ended = Math.round(message.date_ended);
    }
    if (message.company_id !== 0) {
      obj.company_id = Math.round(message.company_id);
    }
    if (message.meta) {
      const entries = Object.entries(message.meta);
      if (entries.length > 0) {
        obj.meta = {};
        entries.forEach(([k, v]) => {
          obj.meta[k] = v;
        });
      }
    }
    if (message.timeline?.length) {
      obj.timeline = message.timeline.map((e) => Turn.toJSON(e));
    }
    if (message.cost !== 0) {
      obj.cost = Math.round(message.cost);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Session>, I>>(base?: I): Session {
    return Session.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Session>, I>>(object: I): Session {
    const message = createBaseSession();
    message.session_id = object.session_id ?? "";
    message.date_created = object.date_created ?? 0;
    message.application_id = object.application_id ?? 0;
    message.application_name = object.application_name ?? undefined;
    message.summary = object.summary ?? "";
    message.feedback = object.feedback ?? 0;
    message.reviewer = Object.entries(object.reviewer ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.date_ended = object.date_ended ?? 0;
    message.company_id = object.company_id ?? 0;
    message.meta = Object.entries(object.meta ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.timeline = object.timeline?.map((e) => Turn.fromPartial(e)) || [];
    message.cost = object.cost ?? 0;
    return message;
  },
};

function createBaseSession_ReviewerEntry(): Session_ReviewerEntry {
  return { key: "", value: "" };
}

export const Session_ReviewerEntry = {
  encode(message: Session_ReviewerEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Session_ReviewerEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSession_ReviewerEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Session_ReviewerEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: Session_ReviewerEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Session_ReviewerEntry>, I>>(base?: I): Session_ReviewerEntry {
    return Session_ReviewerEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Session_ReviewerEntry>, I>>(object: I): Session_ReviewerEntry {
    const message = createBaseSession_ReviewerEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseSession_MetaEntry(): Session_MetaEntry {
  return { key: "", value: "" };
}

export const Session_MetaEntry = {
  encode(message: Session_MetaEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Session_MetaEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSession_MetaEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Session_MetaEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: Session_MetaEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Session_MetaEntry>, I>>(base?: I): Session_MetaEntry {
    return Session_MetaEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Session_MetaEntry>, I>>(object: I): Session_MetaEntry {
    const message = createBaseSession_MetaEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
