import { Component } from "react";
import { Observable } from "rxjs";

export class GeneralService extends Component {
  public static readonly LOCAL_HOST = "http://0.0.0.0:5000";
  public static readonly PROD_HOST = "api.test.trymaitai.ai";
  // public static readonly PROD_ENDPOINT = GeneralService.LOCAL_HOST;
  public static readonly PROD_ENDPOINT = `https://${GeneralService.PROD_HOST}`;
  public static readonly EVENTS_ENDPOINT = "https://events.trymaitai.ai";
  public static readonly options = {
    method: "GET",
    mode: "cors",
    headers: {
      "User-Agent": "Mozilla/5.0",
      "Content-Type": "application/json",
      "Accept-Charset": "UTF-8",
      "X-Device-Id": "",
      Authorization: "",
    },
  };

  static async handleResponse(response: Response, expectedType: any) {
    if (expectedType === JSON) {
      try {
        return await response.json();
      } catch (e) {
        console.error("Error getting JSON from response, getting TEXT instead");
        return await response.text();
      }
    } else if (expectedType === "blob") {
      return await response.blob();
    } else {
      return await response.text();
    }
  }

  static fetchHandler(
    path: string,
    options: any,
    loadingHandler: (val: boolean) => void,
    expectedType: any,
    endpoint: any,
    apiKey: string
  ) {
    return Observable.create((observer: any) => {
      const fullPath = `${endpoint}${path}`;
      if (loadingHandler != null) loadingHandler(true);
      options["headers"]["x-api-key"] = apiKey;
      fetch(fullPath, options)
        .then(async (response) => {
          if (response.ok) {
            return await this.handleResponse(response, expectedType);
          } else if (response.status == 401) {
            return await Promise.reject(response);
          } else {
            return await Promise.reject(response);
          }
        }) // or text() or blob() etc.
        .then((data) => {
          observer.next(data);
          observer.complete();
          if (loadingHandler != null) loadingHandler(false);
        })
        .catch((response) => {
          if (loadingHandler != null) loadingHandler(false);
          try {
            //Sometimes it's this way, not sure why
            response.text().then((text: string) => {
              observer.error(text);
            });
          } catch (err) {
            observer.error(response.text);
          }
        });
    });
  }

  public static get(
    path: string,
    loadingHandler: (val: boolean) => void = null,
    expectedType: any = JSON,
    endpoint: any = GeneralService.PROD_ENDPOINT,
    apiKey: string
  ) {
    const getOptions: any = structuredClone(GeneralService.options);
    return GeneralService.fetchHandler(
      path,
      getOptions,
      loadingHandler,
      expectedType,
      endpoint,
      apiKey
    );
  }

  public static getAsync(
    path: string,
    loadingHandler: (val: boolean) => void = null,
    expectedType: any = JSON,
    endpoint: any = GeneralService.PROD_ENDPOINT,
    apiKey: string
  ) {
    const getOptions: any = structuredClone(GeneralService.options);
    return GeneralService.fetchHandler(
      path,
      getOptions,
      loadingHandler,
      expectedType,
      endpoint,
      apiKey
    );
  }

  public static post(
    path: string,
    body: any,
    loadingHandler: (val: boolean) => void = null,
    expectedType: any = JSON,
    endpoint: any = GeneralService.PROD_ENDPOINT,
    apiKey: string,
    contentTypeOveride: any = null
  ) {
    console.log(`apiKey is ${apiKey}`);
    const postOptions: any = structuredClone(GeneralService.options);
    postOptions.method = "POST";
    postOptions.body = body;
    if (contentTypeOveride) {
      delete postOptions.headers["Content-Type"];
    }
    return this.fetchHandler(
      path,
      postOptions,
      loadingHandler,
      expectedType,
      endpoint,
      apiKey
    );
  }

  public static put(
    path: string,
    body: string,
    loadingHandler: (val: boolean) => void = null,
    expectedType: any = JSON,
    endpoint: any = GeneralService.PROD_ENDPOINT,
    apiKey: string
  ) {
    const putOptions: any = structuredClone(GeneralService.options);
    putOptions.method = "PUT";
    putOptions.body = body;
    return this.fetchHandler(
      path,
      putOptions,
      loadingHandler,
      expectedType,
      endpoint,
      apiKey
    );
  }

  public static delete(
    path: string,
    body: string,
    loadingHandler: (val: boolean) => void = null,
    expectedType: any = JSON,
    endpoint: any = GeneralService.PROD_ENDPOINT,
    apiKey: string
  ) {
    const deleteOptions: any = structuredClone(GeneralService.options);
    deleteOptions.method = "DELETE";
    deleteOptions.body = body;
    return this.fetchHandler(
      path,
      deleteOptions,
      loadingHandler,
      expectedType,
      endpoint,
      apiKey
    );
  }
}

export default GeneralService;
