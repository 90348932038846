import React from "react";
import { Card, Typography, Space, Row, Col } from "antd";
import {
  CreditCardOutlined,
  FileTextOutlined,
  SettingOutlined,
  BarChartOutlined,
  DollarOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import ModelPricing from "./ModelPricing";

const { Title, Text } = Typography;

interface BillingOverviewProps {
  onTabChange: (key: string) => void;
}

const BillingOverview: React.FC<BillingOverviewProps> = ({ onTabChange }) => {
  return (
    <div>
      <Title level={2}>Pay as you go</Title>

      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div>
          <Text type="secondary">Credit balance</Text>
          <Title level={2} style={{ margin: 0 }}>
            $0.00
          </Title>
        </div>

        <Card style={{ background: "#f0f0f0", border: "1px solid #d9d9d9" }}>
          <Row>
            <Col span={24} style={{ display: "flex", alignItems: "start" }}>
              <div>
                <CheckCircleOutlined
                  style={{
                    color: "#52c41a",
                    marginRight: "8px",
                    marginTop: "4px",
                  }}
                />
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}>
                <Text strong>Invoicing is on</Text>
                <Text type="secondary">
                  You will receive an invoice for each billing period. The card
                  on file will automatically be charged for the invoice amount
                  on the 1st of each month.
                </Text>
                {/* <Button type="link" style={{ paddingLeft: 0 }}>Modify</Button> */}
              </div>
            </Col>
          </Row>
        </Card>

        {/* <Card style={{ background: "#f0f0f0", border: "1px solid #d9d9d9" }}>
          <Row>
            <Col span={24} style={{ display: "flex", alignItems: "start" }}>
                <div>
                    <CheckCircleOutlined style={{ color: "#52c41a", marginRight: "8px", marginTop: "4px" }} />
                </div>
                <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    <Text strong>Auto recharge is on</Text>
                    <Text type="secondary">When your credit balance reaches $100.00, your payment method will be charged to bring the balance up to $1,000.00.</Text>
                    <Button type="link" style={{ paddingLeft: 0 }}>Modify</Button>
                </div>
            </Col>
          </Row>
        </Card> */}

        {/* <Space>
          <Button type="primary">Add to credit balance</Button>
          <Button>Cancel plan</Button>
        </Space> */}

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card hoverable onClick={() => onTabChange("2")}>
              <Space size={16}>
                <CreditCardOutlined style={{ fontSize: "24px" }} />
                <div>
                  <Text strong>Payment methods</Text>
                  <br />
                  <Text type="secondary">Add or change payment method</Text>
                </div>
              </Space>
            </Card>
          </Col>
          <Col span={12}>
            <Card hoverable onClick={() => onTabChange("3")}>
              <Space size={16}>
                <FileTextOutlined style={{ fontSize: "24px" }} />
                <div>
                  <Text strong>Billing history</Text>
                  <br />
                  <Text type="secondary">View past and current invoices</Text>
                </div>
              </Space>
            </Card>
          </Col>
          <Col span={12}>
            <Card hoverable onClick={() => onTabChange("4")}>
              <Space size={16}>
                <SettingOutlined style={{ fontSize: "24px" }} />
                <div>
                  <Text strong>Preferences</Text>
                  <br />
                  <Text type="secondary">Manage billing information</Text>
                </div>
              </Space>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Space size={16}>
                <BarChartOutlined
                  style={{ fontSize: "24px", color: "#d9d9d9" }}
                />
                <div>
                  <Text strong style={{ color: "#d9d9d9" }}>
                    Usage limits
                  </Text>
                  <br />
                  <Text type="secondary" style={{ color: "#d9d9d9" }}>
                    Set monthly spend limits (coming soon)
                  </Text>
                </div>
              </Space>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              hoverable
              onClick={() =>
                window.open("https://trymaitai.ai/pricing", "_blank")
              }>
              <Space size={16}>
                <DollarOutlined style={{ fontSize: "24px" }} />
                <div>
                  <Text strong>Pricing</Text>
                  <br />
                  <Text type="secondary">View pricing and FAQs</Text>
                </div>
              </Space>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={4}>Model Pricing</Title>
            <ModelPricing />
          </Col>
        </Row>
      </Space>
    </div>
  );
};

export default BillingOverview;
