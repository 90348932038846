import React, { useState, useEffect } from "react";
import { Card, Col, Collapse, Row, Space, Spin, Table } from "antd";
import { AnnotationService } from "../../services/AnnotationService";
import { usePermissions } from "../../PermissionsProvider";
import { useNavigate } from "react-router-dom";

const { Panel } = Collapse;

interface Intent {
  intent_id: string;
  intent_name: string;
  request_count: number;
  session_count: number;
}

interface Application {
  app_id: string;
  app_name: string;
  intents: Intent[];
}

interface AnnotationData {
  company_id: string;
  company_name: string;
  applications: Application[];
}

const AnnotationOverview: React.FC = () => {
  const { apiKey } = usePermissions();
  const [annotationData, setAnnotationData] = useState<AnnotationData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = () => {
      AnnotationService.getAvailableData(setIsLoading, apiKey).subscribe(
        (data: AnnotationData[]) => {
          setAnnotationData(data);
        },
        (error: any) => {
          console.error("Error fetching annotation data:", error);
        }
      );
    };

    fetchData();
  }, [apiKey]);

  return (
    <Card className="applications-wrapper">
      <Space direction="vertical" size="small" style={{ width: "100%" }}>
        <Row justify="space-between" align="middle">
          <Col>
            <h1>Annotation</h1>
          </Col>
        </Row>
        {isLoading ? (
          <Spin
            size="large"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
            }}
          />
        ) : (
          <Table
            dataSource={annotationData}
            showHeader={false}
            rowKey="company_id"
            columns={[
              {
                dataIndex: "company_name",
                key: "company_name",
                render: (text) => <span>{text}</span>,
              },
            ]}
            pagination={false}
            expandable={{
              expandedRowRender: (company) => (
                <Collapse>
                  {company.applications.map((app) => (
                    <Panel header={app.app_name} key={app.app_id}>
                      <Table
                        dataSource={app.intents}
                        showHeader={false}
                        pagination={false}
                        rowKey="intent_id"
                        columns={[
                          {
                            key: "intent",
                            render: (_, intent) => (
                              <>
                                <span
                                  onClick={() =>
                                    navigate(
                                      `/admin/annotation/company/${company.company_id}/application/${app.app_id}/intent/${intent.intent_id}/`
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                    color: "#1890ff",
                                  }}>
                                  {intent.intent_name}
                                </span>
                                <span style={{ marginLeft: "10px" }}>
                                  ({intent.request_count} requests,{" "}
                                  {intent.session_count} sessions)
                                </span>
                              </>
                            ),
                          },
                        ]}
                      />
                    </Panel>
                  ))}
                </Collapse>
              ),
            }}
          />
        )}
      </Space>
    </Card>
  );
};

export default AnnotationOverview;
