import React, { useState, useEffect } from "react";
import { Typography, Table, Tag, Empty } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";

const { Text } = Typography;

interface Invoice {
  id: string;
  status: "Paid" | "Pending" | "Failed";
  amount: number;
  created: string;
}

const BillingHistory: React.FC = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    // Simulating API call to fetch invoices
    setTimeout(() => {
      setInvoices([]);
      setIsLoading(false);
    }, 1000);
  }, []);

  const columns: ColumnsType<Invoice> = [
    {
      title: "INVOICE",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "Paid" ? "success" : status === "Pending" ? "warning" : "error"}>
          {status}
        </Tag>
      ),
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${amount.toFixed(2)}`,
    },
    {
      title: "CREATED",
      dataIndex: "created",
      key: "created",
      render: (date) => moment(date).format("MMM DD, YYYY, HH:mm A"),
    },
    {
      title: "",
      key: "action",
      render: () => (
        <a href="#" style={{ color: "#21b892" }}>View</a>
      ),
    },
  ];

  return (
    <div>
      <Text type="secondary">Showing invoices within the past 12 months</Text>
      
      <Table
        columns={columns}
        dataSource={invoices}
        rowKey="id"
        pagination={false}
        loading={isLoading}
        style={{ marginTop: "16px" }}
        locale={{
          emptyText: <Empty description="No billing history" />
        }}
      />
    </div>
  );
};

export default BillingHistory;
