import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { BillingService } from "../../services/BillingService";
import { usePermissions } from "../../PermissionsProvider";
import { ModelCost } from "../../model_gen/billing";
import { formatCurrency } from "../../utils/FormatUtils";

const ModelPricingTable: React.FC = () => {
  const [modelPricing, setModelPricing] = useState<ModelCost[]>([]);
  const [loading, setLoading] = useState(true);
  const { apiKey } = usePermissions();

  useEffect(() => {
    BillingService.getPricing(setLoading, apiKey).subscribe(
      (data: ModelCost[]) => {
        setModelPricing(data);
        setLoading(false);
      },
      (error: any) => {
        console.error("Error fetching model pricing:", error);
        setLoading(false);
      }
    );
  }, [apiKey]);

  const columns = [
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: "Model Name",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Input Cost",
      dataIndex: "prompt_cpm",
      key: "prompt_cpm",
      render: (cost: number) => formatCurrency(cost) + " / 1M tokens",
    },
    {
      title: "Output Cost",
      dataIndex: "completion_cpm",
      key: "completion_cpm",
      render: (cost: number) => formatCurrency(cost) + " / 1M tokens",
    },
  ];

  return (
    <Table
      dataSource={modelPricing}
      columns={columns}
      rowKey={(row: ModelCost) => row.provider + row.model}
      loading={loading}
      pagination={false}
      onRow={() => ({
        style: {
          cursor: "default",
        },
      })}
    />
  );
};

export default ModelPricingTable;
