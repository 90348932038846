import React from "react";
import { useParams } from "react-router-dom";
import ApplicationForm from "./ApplicationForm";

const EditApplication: React.FC = () => {
  const { applicationId } = useParams();

  return <ApplicationForm applicationId={applicationId} />;
};
export default EditApplication;
