import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Descriptions,
  Empty,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { Link, useParams } from "react-router-dom";
import { Application, ApplicationAction } from "../../model_gen/application";
import { usePermissions } from "../../PermissionsProvider";
import { ApplicationService } from "../../services/ApplicationService";
import moment from "moment/moment";
import { Config } from "../../model_gen/config";
import { SentinelService } from "../../services/SentinelService";
import { Sentinel } from "../../model_gen/sentinel";
import ConfigurationPanel from "./configuration/ConfigurationPanel";
import CustomSwitch from "../shared/switch/CustomSwitch";
import SentinelList from "../Sentinel/SentinelList";
import Fallbacks from "../Event/Fallbacks";
import Faults from "../Event/Faults";

const ApplicationActionOverview: React.FC = () => {
  const { apiKey } = usePermissions();
  const { applicationId, actionId } = useParams<{
    applicationId: string;
    actionId: string;
  }>();

  const [application, setApplication] = useState<Application | null>(null);
  const [config, setConfig] = useState<Config>();
  const [applicationAction, setApplicationAction] =
    useState<ApplicationAction | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeIntents, setActiveIntents] = useState<Set<string>>(new Set());
  const emptySentinelList = () => {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <span>
            We're still learning this intent.
            <br />
            Please check back later.
          </span>
        }
      />
    );
  };
  const getActiveIntents = () => {
    const intents = new Set<string>();
    SentinelService.getSentinels(
      Number(applicationId),
      setIsLoading,
      apiKey
    ).subscribe(
      (sents: Sentinel[]) => {
        for (const sent of sents) {
          for (const intent of sent.action_types) {
            intents.add(intent);
          }
        }
        setActiveIntents(intents);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };
  const getConfig = () => {
    ApplicationService.getApplicationActionConfig(
      Number(applicationId),
      Number(actionId),
      null,
      apiKey
    ).subscribe(
      (config: Config) => {
        setConfig(config);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };
  const updateConfig = (
    config: Partial<Config>,
    loadingHandler: (loading: boolean) => void | null,
    save: boolean = true
  ) => {
    setConfig((prevConfig: Config | undefined) => {
      if (!prevConfig) {
        return Config.fromPartial(config);
      }
      return {
        ...prevConfig,
        ...config,
      };
    });
    if (!save) {
      return;
    }
    ApplicationService.setApplicationActionConfig(
      Number(applicationId),
      Number(actionId),
      config,
      loadingHandler,
      apiKey
    ).subscribe(
      (config: Config) => {
        setConfig(config);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const resetConfig = () => {
    setConfig(undefined);
    ApplicationService.resetApplicationActionConfig(
      Number(applicationId),
      Number(actionId),
      null,
      apiKey
    ).subscribe(
      () => {
        getConfig();
      },
      (error: any) => {
        console.error(error);
      }
    );
  };
  useEffect(() => {
    getActiveIntents();
  }, [applicationId]);
  useEffect(() => {
    if (applicationId) {
      ApplicationService.getApplication(
        Number(applicationId),
        setIsLoading,
        apiKey
      ).subscribe(
        (app: Application) => {
          setApplication(app);
          ApplicationService.getApplicationAction(
            Number(applicationId),
            Number(actionId),
            null,
            apiKey
          ).subscribe((action: ApplicationAction) => {
            setApplicationAction(action);
            getConfig();
          });
        },
        (error: any) => {
          console.error(error);
        }
      );
    }
  }, [applicationId, actionId]);
  if (isLoading) {
    return (
      <Spin
        size="large"
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      />
    );
  }
  return (
    <Card className="applications-container applications-wrapper">
      <Row justify="space-between" align="middle" style={{ marginBottom: 24 }}>
        <Col>
          <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
            <Link to="/" style={{ color: "#828282" }}>
              Applications {" / "}{" "}
            </Link>
            <Link
              to={`/application/${applicationId}`}
              style={{ color: "#828282" }}>
              {application?.application_name}
              {" / "}{" "}
            </Link>
            {applicationAction?.action_type}
          </Typography>
        </Col>
      </Row>
      <Card className="overview-wrapper" style={{ marginBottom: 24 }} bordered>
        <Descriptions colon={false} column={12} layout="vertical">
          <Descriptions.Item label="Intent Name" span={4}>
            {applicationAction?.action_type}
          </Descriptions.Item>
          <Descriptions.Item label="Notifications" span={2}>
            <CustomSwitch
              className="custom-switch"
              checked={applicationAction?.notifications_enabled}
              onChange={(checked) => {
                const updateAction = () => {
                  setApplicationAction((prevState) => {
                    if (!prevState) return prevState;
                    return {
                      ...prevState,
                      notifications_enabled: checked,
                    };
                  });
                };
                if (applicationAction) {
                  if (checked) {
                    ApplicationService.enableApplicationActionNotifications(
                      applicationAction.application_id,
                      applicationAction.id,
                      null,
                      apiKey
                    ).subscribe(
                      () => {
                        updateAction();
                      },
                      (error: any) => {
                        console.error(error);
                      }
                    );
                  } else {
                    ApplicationService.disableApplicationActionNotifications(
                      applicationAction.application_id,
                      applicationAction.id,
                      null,
                      apiKey
                    ).subscribe(
                      () => {
                        updateAction();
                      },
                      (error: any) => {
                        console.error(error);
                      }
                    );
                  }
                }
              }}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Date Created" span={2}>
            {moment(applicationAction?.date_created).format(
              "YYYY-MM-DD hh:mm:ss A"
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Last Activity" span={2}>
            {moment(applicationAction?.last_activity).format(
              "YYYY-MM-DD hh:mm:ss A"
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Requests" span={1}>
            {applicationAction?.request_count}
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={1}>
            {activeIntents?.has(applicationAction?.action_type)
              ? "Ready"
              : "Learning..."}
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Card className="overview-wrapper-panel" bordered>
        <ConfigurationPanel
          config={config}
          updateConfig={updateConfig}
          resetConfig={resetConfig}
        />
      </Card>
      {applicationAction && (
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          <Typography.Title level={4}>Faults</Typography.Title>
          <Faults intentId={Number(actionId)} />
          <Typography.Title level={4}>Fallbacks</Typography.Title>
          <Fallbacks intentId={Number(actionId)} />
          <SentinelList
            applicationId={application?.id}
            actionType={applicationAction?.action_type}
            emptyText={emptySentinelList()}
          />
        </Space>
      )}
    </Card>
  );
};

export default ApplicationActionOverview;
