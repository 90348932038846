import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { Form, Input, List, Select, Tag, SelectProps } from "antd";
import { SeverityLevel } from "../../model_gen/sentinel";
import {
  DashedButton,
  OutlineButton,
  PrimaryButton,
} from "../shared/button/Buttons";
import CustomSwitch from "../shared/switch/CustomSwitch";
import { Sentinel } from "../../model_gen/sentinel";

interface SentinelFormProps {
  initialSentinel?: Sentinel;
  allActionTypes: SelectProps["options"];
  loading: boolean;
  onSubmit: (formData: any) => void;
  submitButtonText: string;
}

const SentinelForm: React.FC<SentinelFormProps> = ({
  initialSentinel,
  allActionTypes,
  loading,
  onSubmit,
  submitButtonText,
}) => {
  const [sentinelName, setSentinelName] = useState(
    initialSentinel?.sentinel_name || ""
  );
  const [directive, setDirective] = useState(
    initialSentinel?.sentinel_directive?.directive_body?.main || ""
  );
  const [examples, setExamples] = useState(
    initialSentinel?.sentinel_directive?.examples?.map((example, index) => ({
      id: index,
      exampleType: example.example_type,
      inputExample: example.example_body,
    })) || []
  );
  const [actionTypes, setActionTypes] = useState(
    initialSentinel?.action_types || []
  );
  const [severity, setSeverity] = useState(
    initialSentinel?.severity || SeverityLevel.INFO
  );
  const [includeInput, setIncludeInput] = useState(
    initialSentinel?.meta?.include_input || ""
  );
  const [includeContext, setIncludeContext] = useState(
    initialSentinel?.meta?.input_include_context || ""
  );
  const [includeSystemPrompt, setIncludeSystemPrompt] = useState(
    initialSentinel?.meta?.input_include_system || ""
  );
  const [includeToolCalls, setIncludeToolCalls] = useState(
    initialSentinel?.meta?.include_tool_calls || ""
  );

  const exampleIdCounter = useRef(
    initialSentinel?.sentinel_directive?.examples?.length || 0
  );
  const [selectWidth, setSelectWidth] = useState<number>(0);

  const calculateWidestOption = useMemo(() => {
    const options = ["PASS", "FAULT", "NA"];
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    if (context) {
      context.font = "14px Arial";
      return (
        Math.max(
          ...options.map((option) => context.measureText(option).width)
        ) + 40
      );
    }
    return 80;
  }, []);

  useEffect(() => {
    setSelectWidth(calculateWidestOption);
  }, [calculateWidestOption]);

  const handleAddExample = useCallback(() => {
    setExamples((prev) => [
      ...prev,
      { id: exampleIdCounter.current++, exampleType: "", inputExample: "" },
    ]);
  }, []);

  const handleRemoveExample = useCallback((itemId: number) => {
    setExamples((prev) => prev.filter((entry) => entry.id !== itemId));
  }, []);

  const handleExampleTypeChange = useCallback(
    (itemId: number, newType: string) => {
      setExamples((prev) =>
        prev.map((entry) =>
          entry.id === itemId ? { ...entry, exampleType: newType } : entry
        )
      );
    },
    []
  );

  const handleInputExampleChange = useCallback(
    (itemId: number, newInput: string) => {
      setExamples((prev) =>
        prev.map((entry) =>
          entry.id === itemId ? { ...entry, inputExample: newInput } : entry
        )
      );
    },
    []
  );

  const handleActionTypeChange = (value: string[]) => {
    const uppercaseActionTypes = value.map((actionType) =>
      actionType.toUpperCase()
    );
    setActionTypes(uppercaseActionTypes);
  };

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;
    return (
      <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  };

  const handleSubmit = () => {
    onSubmit({
      sentinelName,
      directive,
      examples,
      actionTypes,
      severity,
      includeInput,
      includeContext,
      includeSystemPrompt,
      includeToolCalls,
    });
  };
  console.log("sentinelName", sentinelName);
  return (
    <Form layout="vertical" disabled={loading}>
      <Form.Item label="Name" required tooltip="Your name for this Sentinel">
        <Input
          value={sentinelName}
          onChange={(e) => setSentinelName(e.target.value)}
          placeholder="Menu Monitor"
        />
      </Form.Item>
      <Form.Item
        label="Directive"
        required
        tooltip="Tells the sentinel what to monitor for">
        <Input.TextArea
          style={{ whiteSpace: "pre-wrap" }}
          value={directive}
          onChange={(e) => setDirective(e.target.value)}
          rows={4}
          placeholder="Make sure the customer doesn't order items that aren't on the menu"
        />
      </Form.Item>
      <Form.Item label="Examples" required>
        <List
          dataSource={examples}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}>
                <div style={{ width: `${selectWidth}px`, marginRight: "1em" }}>
                  <Select
                    style={{ width: "100%" }}
                    value={item.exampleType}
                    onChange={(value) =>
                      handleExampleTypeChange(item.id, value)
                    }
                    placeholder="Select type">
                    <Select.Option value="PASS" style={{ color: "green" }}>
                      <span style={{ color: "green" }}>PASS</span>
                    </Select.Option>
                    <Select.Option value="FAULT" style={{ color: "red" }}>
                      <span style={{ color: "red" }}>FAULT</span>
                    </Select.Option>
                    <Select.Option value="NA" style={{ color: "grey" }}>
                      <span style={{ color: "grey" }}>NA</span>
                    </Select.Option>
                  </Select>
                </div>
                <Input.TextArea
                  style={{
                    flex: 1,
                    marginRight: "1em",
                    whiteSpace: "pre-wrap",
                  }}
                  value={item.inputExample}
                  onChange={(e) =>
                    handleInputExampleChange(item.id, e.target.value)
                  }
                  placeholder="Example"
                  rows={4}
                />
                <OutlineButton onClick={() => handleRemoveExample(item.id)}>
                  -
                </OutlineButton>
              </div>
            </List.Item>
          )}
        />
        <List.Item>
          <DashedButton onClick={handleAddExample} block>
            + Add Example
          </DashedButton>
        </List.Item>
      </Form.Item>
      <Form.Item
        label="Action Types"
        required
        tooltip="Defines the types of actions this Sentinel can take">
        <Select
          mode="tags"
          style={{ width: "100%" }}
          placeholder="Add Action Types"
          value={actionTypes}
          onChange={handleActionTypeChange}
          tagRender={tagRender}
          options={allActionTypes}
          maxLength={20}
        />
      </Form.Item>
      <Form.Item
        label="Severity"
        required
        tooltip="Select the severity level for this Sentinel">
        <Select value={severity} onChange={(value) => setSeverity(value)}>
          {Object.entries(SeverityLevel)
            .filter(
              ([key]) =>
                !isNaN(Number(SeverityLevel[key as keyof typeof SeverityLevel]))
            )
            .map(([key]) => (
              <Select.Option
                key={SeverityLevel[key as keyof typeof SeverityLevel]}
                value={SeverityLevel[key as keyof typeof SeverityLevel]}>
                {key}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="Include System Prompt">
        <CustomSwitch
          checked={includeSystemPrompt === "true"}
          onChange={(checked) => setIncludeSystemPrompt(checked ? "true" : "")}
        />
      </Form.Item>
      <Form.Item label="Include Context">
        <CustomSwitch
          checked={includeContext === "true"}
          onChange={(checked) => setIncludeContext(checked ? "true" : "")}
        />
      </Form.Item>
      <Form.Item label="Include Input">
        <CustomSwitch
          checked={includeInput === "true"}
          onChange={(checked) => setIncludeInput(checked ? "true" : "")}
        />
      </Form.Item>
      <Form.Item label="Include Tool Calls">
        <CustomSwitch
          checked={includeToolCalls === "true"}
          onChange={(checked) => setIncludeToolCalls(checked ? "true" : "")}
        />
      </Form.Item>
      <Form.Item>
        <PrimaryButton
          onClick={handleSubmit}
          block
          loading={loading}
          disabled={loading}>
          {submitButtonText}
        </PrimaryButton>
      </Form.Item>
    </Form>
  );
};

export default SentinelForm;
