// @ts-nocheck
// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.180.0
//   protoc               v5.27.1
// source: sandbox.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import Long from "long";

export const protobufPackage = "sandbox";

export interface SandboxApplication {
  id?: number | undefined;
  company_id?: number | undefined;
  sandbox_application_name: string;
  date_created: number;
  sandbox_application_prompt: string;
  sandbox_sessions: SandboxSession[];
}

export interface SandboxSession {
  id: number;
  token: string;
  sandbox_application_id: number;
  date_created: number;
  sandbox_messages: SandboxMessage[];
}

export interface SandboxMessage {
  id?: number | undefined;
  sandbox_session_id: number;
  date_created: number;
  sender: string;
  message: string;
  meta: { [key: string]: string };
}

export interface SandboxMessage_MetaEntry {
  key: string;
  value: string;
}

function createBaseSandboxApplication(): SandboxApplication {
  return {
    id: undefined,
    company_id: undefined,
    sandbox_application_name: "",
    date_created: 0,
    sandbox_application_prompt: "",
    sandbox_sessions: [],
  };
}

export const SandboxApplication = {
  encode(message: SandboxApplication, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).int64(message.id);
    }
    if (message.company_id !== undefined) {
      writer.uint32(16).int64(message.company_id);
    }
    if (message.sandbox_application_name !== "") {
      writer.uint32(26).string(message.sandbox_application_name);
    }
    if (message.date_created !== 0) {
      writer.uint32(32).int64(message.date_created);
    }
    if (message.sandbox_application_prompt !== "") {
      writer.uint32(42).string(message.sandbox_application_prompt);
    }
    for (const v of message.sandbox_sessions) {
      SandboxSession.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SandboxApplication {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSandboxApplication();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.company_id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sandbox_application_name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sandbox_application_prompt = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sandbox_sessions.push(SandboxSession.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SandboxApplication {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : undefined,
      company_id: isSet(object.company_id) ? globalThis.Number(object.company_id) : undefined,
      sandbox_application_name: isSet(object.sandbox_application_name)
        ? globalThis.String(object.sandbox_application_name)
        : "",
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      sandbox_application_prompt: isSet(object.sandbox_application_prompt)
        ? globalThis.String(object.sandbox_application_prompt)
        : "",
      sandbox_sessions: globalThis.Array.isArray(object?.sandbox_sessions)
        ? object.sandbox_sessions.map((e: any) => SandboxSession.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SandboxApplication): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = Math.round(message.id);
    }
    if (message.company_id !== undefined) {
      obj.company_id = Math.round(message.company_id);
    }
    if (message.sandbox_application_name !== "") {
      obj.sandbox_application_name = message.sandbox_application_name;
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.sandbox_application_prompt !== "") {
      obj.sandbox_application_prompt = message.sandbox_application_prompt;
    }
    if (message.sandbox_sessions?.length) {
      obj.sandbox_sessions = message.sandbox_sessions.map((e) => SandboxSession.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SandboxApplication>, I>>(base?: I): SandboxApplication {
    return SandboxApplication.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SandboxApplication>, I>>(object: I): SandboxApplication {
    const message = createBaseSandboxApplication();
    message.id = object.id ?? undefined;
    message.company_id = object.company_id ?? undefined;
    message.sandbox_application_name = object.sandbox_application_name ?? "";
    message.date_created = object.date_created ?? 0;
    message.sandbox_application_prompt = object.sandbox_application_prompt ?? "";
    message.sandbox_sessions = object.sandbox_sessions?.map((e) => SandboxSession.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSandboxSession(): SandboxSession {
  return { id: 0, token: "", sandbox_application_id: 0, date_created: 0, sandbox_messages: [] };
}

export const SandboxSession = {
  encode(message: SandboxSession, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== 0) {
      writer.uint32(8).int64(message.id);
    }
    if (message.token !== "") {
      writer.uint32(18).string(message.token);
    }
    if (message.sandbox_application_id !== 0) {
      writer.uint32(24).int64(message.sandbox_application_id);
    }
    if (message.date_created !== 0) {
      writer.uint32(32).int64(message.date_created);
    }
    for (const v of message.sandbox_messages) {
      SandboxMessage.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SandboxSession {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSandboxSession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.token = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.sandbox_application_id = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sandbox_messages.push(SandboxMessage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SandboxSession {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      token: isSet(object.token) ? globalThis.String(object.token) : "",
      sandbox_application_id: isSet(object.sandbox_application_id)
        ? globalThis.Number(object.sandbox_application_id)
        : 0,
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      sandbox_messages: globalThis.Array.isArray(object?.sandbox_messages)
        ? object.sandbox_messages.map((e: any) => SandboxMessage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SandboxSession): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.token !== "") {
      obj.token = message.token;
    }
    if (message.sandbox_application_id !== 0) {
      obj.sandbox_application_id = Math.round(message.sandbox_application_id);
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.sandbox_messages?.length) {
      obj.sandbox_messages = message.sandbox_messages.map((e) => SandboxMessage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SandboxSession>, I>>(base?: I): SandboxSession {
    return SandboxSession.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SandboxSession>, I>>(object: I): SandboxSession {
    const message = createBaseSandboxSession();
    message.id = object.id ?? 0;
    message.token = object.token ?? "";
    message.sandbox_application_id = object.sandbox_application_id ?? 0;
    message.date_created = object.date_created ?? 0;
    message.sandbox_messages = object.sandbox_messages?.map((e) => SandboxMessage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSandboxMessage(): SandboxMessage {
  return { id: undefined, sandbox_session_id: 0, date_created: 0, sender: "", message: "", meta: {} };
}

export const SandboxMessage = {
  encode(message: SandboxMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      writer.uint32(8).int64(message.id);
    }
    if (message.sandbox_session_id !== 0) {
      writer.uint32(16).int64(message.sandbox_session_id);
    }
    if (message.date_created !== 0) {
      writer.uint32(24).int64(message.date_created);
    }
    if (message.sender !== "") {
      writer.uint32(34).string(message.sender);
    }
    if (message.message !== "") {
      writer.uint32(42).string(message.message);
    }
    Object.entries(message.meta).forEach(([key, value]) => {
      SandboxMessage_MetaEntry.encode({ key: key as any, value }, writer.uint32(50).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SandboxMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSandboxMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.id = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sandbox_session_id = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.date_created = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.sender = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.message = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          const entry6 = SandboxMessage_MetaEntry.decode(reader, reader.uint32());
          if (entry6.value !== undefined) {
            message.meta[entry6.key] = entry6.value;
          }
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SandboxMessage {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : undefined,
      sandbox_session_id: isSet(object.sandbox_session_id) ? globalThis.Number(object.sandbox_session_id) : 0,
      date_created: isSet(object.date_created) ? globalThis.Number(object.date_created) : 0,
      sender: isSet(object.sender) ? globalThis.String(object.sender) : "",
      message: isSet(object.message) ? globalThis.String(object.message) : "",
      meta: isObject(object.meta)
        ? Object.entries(object.meta).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
    };
  },

  toJSON(message: SandboxMessage): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = Math.round(message.id);
    }
    if (message.sandbox_session_id !== 0) {
      obj.sandbox_session_id = Math.round(message.sandbox_session_id);
    }
    if (message.date_created !== 0) {
      obj.date_created = Math.round(message.date_created);
    }
    if (message.sender !== "") {
      obj.sender = message.sender;
    }
    if (message.message !== "") {
      obj.message = message.message;
    }
    if (message.meta) {
      const entries = Object.entries(message.meta);
      if (entries.length > 0) {
        obj.meta = {};
        entries.forEach(([k, v]) => {
          obj.meta[k] = v;
        });
      }
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SandboxMessage>, I>>(base?: I): SandboxMessage {
    return SandboxMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SandboxMessage>, I>>(object: I): SandboxMessage {
    const message = createBaseSandboxMessage();
    message.id = object.id ?? undefined;
    message.sandbox_session_id = object.sandbox_session_id ?? 0;
    message.date_created = object.date_created ?? 0;
    message.sender = object.sender ?? "";
    message.message = object.message ?? "";
    message.meta = Object.entries(object.meta ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseSandboxMessage_MetaEntry(): SandboxMessage_MetaEntry {
  return { key: "", value: "" };
}

export const SandboxMessage_MetaEntry = {
  encode(message: SandboxMessage_MetaEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SandboxMessage_MetaEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSandboxMessage_MetaEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SandboxMessage_MetaEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: SandboxMessage_MetaEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SandboxMessage_MetaEntry>, I>>(base?: I): SandboxMessage_MetaEntry {
    return SandboxMessage_MetaEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SandboxMessage_MetaEntry>, I>>(object: I): SandboxMessage_MetaEntry {
    const message = createBaseSandboxMessage_MetaEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  if (long.lt(globalThis.Number.MIN_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is smaller than Number.MIN_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
