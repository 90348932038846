import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import "./styles/index.css";
import DevelopersWebhooksCreate from "./components/Developers/DevelopersWebhooksCreate";
import {
  Col,
  ConfigProvider,
  Dropdown,
  Layout,
  MenuProps,
  Row,
  Space,
  Typography,
} from "antd";
import { Content, Header } from "antd/es/layout/layout";
import HeaderComponent from "./components/shared/HeaderComponent";
import Sider from "antd/es/layout/Sider";
import SiderComponent from "./components/shared/SiderComponent";
import DevelopersBase from "./components/Developers/DevelopersBase";
import { useAuth0 } from "@auth0/auth0-react";
import { PermissionsProvider } from "./PermissionsProvider";
import MaitaiLogo from "./assets/maitai_logo_full.svg";
import { customTheme } from "./config/customTheme";
import Tutorial from "./components/Tutorial/Tutorial";
import Events from "./components/Event/Events";
import Applications from "./components/Application/Applications";
import ApplicationOverview from "./components/Application/ApplicationOverview";
import RegisterApplication from "./components/Application/RegisterApplication";
import EditApplication from "./components/Application/EditApplication";
import CloneApplication from "./components/Application/CloneApplication";
import RegisterSentinel from "./components/Sentinel/RegisterSentinel";
import EditSentinel from "./components/Sentinel/EditSentinel";
import ApplicationActionOverview from "./components/Application/ApplicationActionOverview";
import Sandbox from "./components/Sandbox/Sandbox";
import { CompanyService } from "./services/CompanyService";
import { DownOutlined } from "@ant-design/icons";
import { PrimaryButton } from "./components/shared/button/Buttons";
import SessionOverview from "./components/Session/SessionOverview";
import BillingBase from "./components/Billing/BillingBase";
import Intercom from "@intercom/messenger-js-sdk";
import AnnotationOverview from "./components/Annotation/AnnotationOverview";
import AnnotationView from "./components/Annotation/AnnotationView";
import EmailOverview from "./components/Email/EmailOverview";

const { Text, Title } = Typography;

declare global {
  interface Window {
    heap: any;
  }
}
const AppLayout: React.FC<{
  children: React.ReactNode;
  companies?: { company_name: string; key_value: string }[];
  setMeta?: (meta: any) => void;
}> = ({ children, companies, setMeta }) => {
  const [companyName, setCompanyName] = useState<string>("Change Environment");
  if (!companies) {
    companies = [];
  }
  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (setMeta) {
      setMeta({ api_key: key });
    }
  };
  const items = companies.map((company) => {
    return {
      key: company.key_value,
      label: company.company_name,
      onClick: () => {
        setCompanyName(company.company_name);
      },
    };
  });
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={256}
        className="Sider sticky-side-bar"
        style={{ borderRight: "1px solid #C5C6C5" }}>
        <img
          src={MaitaiLogo}
          style={{
            width: "150px",
            paddingLeft: "28px",
            paddingTop: "20px",
            paddingBottom: "15px",
          }}
        />
        <SiderComponent />
        {companies.length > 0 && (
          <center>
            <Dropdown menu={{ items, onClick }} trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {companyName}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </center>
        )}
      </Sider>
      <Layout>
        <Header className="Header sticky-header-wrap">
          <HeaderComponent />
        </Header>
        <Content className="main-container">{children}</Content>
      </Layout>
    </Layout>
  );
};

const App: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  const domain = "dev-ociu84lf8keizm5i.us.auth0.com";
  const [userMeta, setUserMeta] = useState<any>([]);
  const [affiliated, setAffiliated] = useState<
    { company_name: string; key_value: string }[]
  >([]);

  const loadCompanies = (apiKey: string) => {
    CompanyService.getAffiliatedCompanies(null, apiKey).subscribe(
      (data: any) => {
        setAffiliated(data);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    const invitation = searchParams.get("invitation");
    const organization = searchParams.get("organization");
    if (location.pathname === "/login" && invitation && organization) {
      loginWithRedirect({
        authorizationParams: {
          organization,
          invitation,
        },
      });
    }
  }, [location, searchParams, loginWithRedirect]);

  useEffect(() => {
    if (searchParams.get("logout")) {
      console.log("logout");
      searchParams.delete("logout");
      setSearchParams(searchParams);
      logout({ logoutParams: { returnTo: window.location.origin } }).then(
        () => {}
      );
    }
    if (isAuthenticated) {
      if (searchParams.get("logout")) {
        logout({ logoutParams: { returnTo: window.location.origin } }).then(
          () => {
            loginWithRedirect();
          }
        );
      }
      Intercom({
        app_id: "lgh3y6pe",
        user_id: user?.email,
        name: user?.name,
        email: user?.email,
        created_at: user?.createdAt,
      });
      window.heap.identify(user?.email);
      getAccessTokenSilently({
        authorizationParams: {
          audience: `https://${domain}/api/v2/`,
          scope: "openid profile email read:current_user",
        },
      })
        .then((accessToken) => {
          console.log(`AccessToken: ${accessToken}`);
          if (!accessToken) {
            console.error("No access token returned");
            return;
          }
          // Use the access token to fetch user profile from the /userinfo endpoint
          fetch(`https://${domain}/api/v2/users/${user?.sub}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => response.json())
            .then((profile) => {
              console.log("User Profile:", profile);
              // Access user_metadata from the profile
              console.log("User Metadata:", profile["user_metadata"]);
              setUserMeta(profile["user_metadata"]);
              loadCompanies(profile["user_metadata"].api_key);
              // You can now set this information in your state or use it as needed
            })
            .catch((err) => {
              console.error(
                "Error fetching user profile, redirecting to login",
                err
              );
              logout({
                logoutParams: { returnTo: window.location.origin },
              }).then(() => {
                loginWithRedirect();
              });
            });
        })
        .catch((err) => {
          console.error("Error getting access token", err);
          if (err.error === "consent_required") {
            console.log("consent_required, redirecting to login");
            logout({ logoutParams: { returnTo: window.location.origin } }).then(
              () => {
                loginWithRedirect();
              }
            );
          }
        });
    } else if (!isLoading) {
      if (
        !searchParams.get("error_description") &&
        location.pathname !== "/login"
      ) {
        loginWithRedirect();
      }
    }
  }, [
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
    logout,
  ]);

  useEffect(() => {
    if (!userMeta || !userMeta.api_key) {
      // logout({logoutParams: {returnTo: window.location.origin }}).then(() => {
      //     loginWithRedirect();
      // });
    } else {
      console.log("userMeta", userMeta);
    }
  }, [userMeta, logout]);
  const handleRefresh = () => {
    setSearchParams({});
    window.location.reload();
  };
  const organizationError = searchParams
    ?.get("error_description")
    ?.includes("organization");
  if (searchParams.get("error_description")) {
    return (
      <Row
        justify="center"
        align="middle"
        style={{ minHeight: "100vh", textAlign: "center" }}>
        <Col>
          <Title>
            {organizationError
              ? "Organization not found"
              : "Email Verification"}
          </Title>
          <Text>
            {searchParams.get("error_description")}
            <br />
            <br />
          </Text>
          <Text type="secondary">
            {organizationError
              ? "Please contact support@trymaitai.ai if you believe this is an error."
              : "Click continue after you've verified your email."}
          </Text>
          <br />
          <br />
          {organizationError ? (
            <PrimaryButton href="mailto:support@trymaitai.ai">
              Contact Support
            </PrimaryButton>
          ) : (
            <PrimaryButton onClick={handleRefresh}>Continue</PrimaryButton>
          )}
        </Col>
      </Row>
    );
  }
  return (
    <>
      {userMeta && userMeta.api_key !== undefined ? (
        <PermissionsProvider apiKey={userMeta.api_key}>
          <Routes>
            <Route
              path="/"
              element={
                <AppLayout companies={affiliated} setMeta={setUserMeta}>
                  <Tutorial />
                </AppLayout>
              }
            />
            <Route
              path="/applications"
              element={
                <AppLayout companies={affiliated} setMeta={setUserMeta}>
                  <Applications />
                </AppLayout>
              }
            />
            <Route
              path="/application/:applicationId"
              element={
                <AppLayout>
                  <ApplicationOverview />
                </AppLayout>
              }
            />
            <Route
              path="/application/new"
              element={
                <AppLayout>
                  <RegisterApplication />
                </AppLayout>
              }
            />
            <Route
              path="/application/:applicationId/edit"
              element={
                <AppLayout>
                  <EditApplication />
                </AppLayout>
              }
            />
            <Route
              path="/application/:applicationId/clone"
              element={
                <AppLayout>
                  <CloneApplication />
                </AppLayout>
              }
            />
            <Route
              path="/application/:applicationId/sentinel/new"
              element={
                <AppLayout>
                  <RegisterSentinel />
                </AppLayout>
              }
            />
            <Route
              path="/application/:applicationId/sentinel/:sentinelId"
              element={
                <AppLayout>
                  <EditSentinel />
                </AppLayout>
              }
            />
            <Route
              path="/application/:applicationId/intent/:actionId"
              element={
                <AppLayout>
                  <ApplicationActionOverview />
                </AppLayout>
              }
            />
            <Route
              path="/application/:applicationId/session/:sessionId"
              element={
                <AppLayout>
                  <SessionOverview />
                </AppLayout>
              }
            />
            <Route
              path="/settings"
              element={
                <AppLayout companies={affiliated} setMeta={setUserMeta}>
                  <DevelopersBase />
                </AppLayout>
              }
            />
            <Route
              path="/settings/webhooks/create"
              element={<DevelopersWebhooksCreate />}
            />
            <Route
              path="/sandbox"
              element={
                <AppLayout companies={affiliated} setMeta={setUserMeta}>
                  <Sandbox />
                </AppLayout>
              }
            />
            <Route
              path="/events"
              element={
                <AppLayout companies={affiliated} setMeta={setUserMeta}>
                  <Events />
                </AppLayout>
              }
            />
            <Route
              path="/billing"
              element={
                <AppLayout companies={affiliated} setMeta={setUserMeta}>
                  <BillingBase />
                </AppLayout>
              }
            />
            <Route
              path="/admin/annotation"
              element={
                <AppLayout companies={affiliated} setMeta={setUserMeta}>
                  <AnnotationOverview />
                </AppLayout>
              }
            />
            <Route
              path="/admin/annotation/company/:companyId/application/:appId/intent/:intentId"
              element={
                <AppLayout companies={affiliated} setMeta={setUserMeta}>
                  <AnnotationView />
                </AppLayout>
              }
            />
            <Route
              path="/admin/email"
              element={
                <AppLayout companies={affiliated} setMeta={setUserMeta}>
                  <EmailOverview />
                </AppLayout>
              }
            />
          </Routes>
        </PermissionsProvider>
      ) : null}
    </>
  );
};

export default () => (
  <ConfigProvider theme={customTheme}>
    <Router>
      <App />
    </Router>
  </ConfigProvider>
);
