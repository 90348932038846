import { Modal } from "antd";
import React from "react";
import { ModalCustomProps } from "../../../types/IApplication";
import { OutlineButton, PrimaryButton } from "../../shared/button/Buttons";

const ApplicationModal: React.FC<ModalCustomProps> = ({
  title = "Set Title here",
  open = false,
  onOk,
  onCancel,
  isLoading,
  onSubmitButtonClick,
  onCancelButtonClick,
  children,
  isContainedBtnDisabled,
  submitButtonTitle,
  footerClassName = "common-footer-modal-wrap-right",
  footerLeadingButton,
  ...props
}) => {
  return (
    <Modal
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      className="cus-modal-new-wrap"
      footer={[
        <div className={footerClassName}>
          {footerLeadingButton}
          <OutlineButton
            key="cancel"
            onClick={onCancelButtonClick}
            disabled={isLoading}
            className="cancel-newbtn-wrap">
            Cancel
          </OutlineButton>
          <PrimaryButton
            key="submit"
            loading={isLoading}
            disabled={isLoading || isContainedBtnDisabled}
            className="buttonWrapper"
            onClick={onSubmitButtonClick}
            style={{ borderRadius: "8px" }}>
            {submitButtonTitle ? submitButtonTitle : "Add Action"}
          </PrimaryButton>
        </div>,
      ]}
      {...props}>
      {children}
    </Modal>
  );
};

export default ApplicationModal;
