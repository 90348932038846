import GeneralService from "./GeneralService";
import {Component} from "react";


export class IntegrationsService extends Component {
  public static getSlackIntegration(loadingHandler: any, apiKey: string) {
    return GeneralService.get("/notification/slack/integration", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
  public static handleSlackAuthSuccess(auth_code: string, loadingHandler: any, apiKey: string) {
    const payload = {"code": auth_code};
    return GeneralService.post("/notification/slack/auth_success", JSON.stringify(payload), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
}

