import React from "react";
import { Row, Col, Typography, Button, Card, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { PaymentMethod } from "../../model_gen/billing";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { isDimmed } from "../../utils/FormatUtils";

interface PaymentMethodCardProps {
  paymentMethods: PaymentMethod[];
  loading: boolean;
  isReady: boolean;
}

export const PaymentMethodCard: React.FC<PaymentMethodCardProps> = ({
  paymentMethods,
  loading,
}) => {
  const navigate = useNavigate();

  const handleAddCard = () => {
    console.log("handleAddCard");
    navigate("/billing#paymentMethods");
  };

  return (
    <Card style={{ background: "white" }} bordered={false}>
      <Spin spinning={loading}>
        <Row gutter={32} style={{ padding: "8px 8px", ...isDimmed(paymentMethods.length > 0)}}>
          <Col span={12} style={{ display: "flex", alignItems: "center", gap: "24px" }}>
            {paymentMethods.length > 0 ? (
                <div>
                    <CheckCircleOutlined style={{ color: "#21b892", fontSize: "24px" }} />
                </div>
            ) : (
                <div>
                    <MinusCircleOutlined style={{ color: "#bfbfbf", fontSize: "24px" }} />
                </div>
            )}
            <div>
                <Typography.Title
                level={5}
                style={{ marginBottom: "4px", marginTop: "0" }}
                >
                Payment Method
                </Typography.Title>
                <Typography.Paragraph
                style={{ marginBottom: "0" }}
                className="text-typo-paragraph"
                >
                {paymentMethods.length > 0
                    ? "You have a valid payment method on file"
                    : "A valid payment method is required to use Maitai services"}
                </Typography.Paragraph>
            </div>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            {paymentMethods.length === 0 && (
                <Button type="primary" style={{ marginTop: "8px" }} onClick={handleAddCard}>
                  Add a card
              </Button>
            )}
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};
