import {Application, ApplicationAction} from "../model_gen/application";
import GeneralService from "./GeneralService";
import {Component} from "react";
import {Config} from "../model_gen/config";


export class ApplicationService extends Component {
  public static registerApplication(application: Application, loadingHandler: any, apiKey: string) {
    return GeneralService.post("/application/", JSON.stringify(application), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static cloneApplication(clone_application_id: any, application: any, loadingHandler: any, apiKey: string) {
    const payload = {"clone_application_id": clone_application_id, "application": application};
    return GeneralService.post("/application/clone", JSON.stringify(payload), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static getApplications(loadingHandler: any, apiKey: string) {
    return GeneralService.get("/application/", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static enableApplication(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.put(`/application/${applicationId}/enable`, "", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static disableApplication(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.put(`/application/${applicationId}/disable`, "", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static deleteApplication(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.delete(`/application/${applicationId}`, "", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static getApplication(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/application/${applicationId}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static updateApplication(application: Application | undefined, loadingHandler: any, apiKey: string) {
    return GeneralService.put("/application/", JSON.stringify(application), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static setApplicationConfig(applicationId: number, config: Partial<Config>, loadingHandler: any, apiKey: string) {
    return GeneralService.put(`/application/${applicationId}/config`, JSON.stringify(config), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static getApplicationContext(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/context/application/${applicationId}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static updateApplicationContext(payload: any, loadingHandler: any, apiKey: string) {
    return GeneralService.put("/context/application", JSON.stringify(payload), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static updateApplicationContexts(payload: any, loadingHandler: any, apiKey: string) {
    return GeneralService.put("/context/application/all", JSON.stringify(payload), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static uploadApplicationContextFile(contextFile: File, loadingHandler: any, apiKey: string) {
    const formData = new FormData();
    formData.append("file", contextFile);

    return GeneralService.post("/context/application/file", formData, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey, true);
  }

  public static createApplicationAction(applicationId: number, applicationAction: ApplicationAction, loadingHandler: any, apiKey: string) {
    return GeneralService.post(`/application/${applicationId}/action`, JSON.stringify(applicationAction), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static getApplicationAction(applicationId: number, applicationActionId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/application/${applicationId}/action/${applicationActionId}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static getApplicationActions(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/application/${applicationId}/action`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static deleteApplicationAction(applicationId: number, applicationActionId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.delete(`/application/${applicationId}/action/${applicationActionId}`, "", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static getApplicationActionConfig(applicationId: number, applicationActionId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/application/${applicationId}/action/${applicationActionId}/config`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
  public static setApplicationActionConfig(applicationId: number, applicationActionId: number, config: Partial<Config>, loadingHandler: any, apiKey: string) {
    return GeneralService.post(`/application/${applicationId}/action/${applicationActionId}/config`, JSON.stringify(config), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
  public static resetApplicationActionConfig(applicationId: number, applicationActionId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.post(`/application/${applicationId}/action/${applicationActionId}/config/reset`, "", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
  public static enableApplicationActionNotifications(applicationId: number, applicationActionId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.put(`/application/${applicationId}/action/${applicationActionId}/notifications/enable`, "", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
  public static disableApplicationActionNotifications(applicationId: number, applicationActionId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.put(`/application/${applicationId}/action/${applicationActionId}/notifications/disable`, "", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
  public static setFaultNotifications(applicationIDs: number[], loadingHandler: any, apiKey: string) {
    return GeneralService.post("/application/notification/fault/enable", JSON.stringify({application_ids: applicationIDs}), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
  public static setSessionSummaries(applicationIDs: number[], loadingHandler: any, apiKey: string) {
    return GeneralService.post("/application/notification/session/enable", JSON.stringify({application_ids: applicationIDs}), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
}
