import React, { useEffect, useState, useRef } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import {
  Spin,
  Descriptions,
  Timeline,
  Collapse,
  Row,
  Tag,
  Typography,
  Button,
  Popover,
  Card,
  message,
  Tooltip,
} from "antd";
import { CopyOutlined, UpOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { useParams } from "react-router-dom";
import { usePermissions } from "../../PermissionsProvider";
import { SessionService } from "../../services/SessionService";
import { Session } from "../../model_gen/session";
import { Turn, ChatMessage, EvaluateResult } from "../../model_gen/chat";
import { formatCurrency, formatTextOrJson } from "../../utils/FormatUtils";
import { calculateScore } from "./SessionUtils";
import styled, { keyframes } from "styled-components";

const { Text, Paragraph } = Typography;
const { Panel } = Collapse;

interface SessionOverviewProps {
  sessionId?: string;
  applicationId?: string;
  refreshTrigger?: number;
  requestId?: string;
}

const pulseAnimation = keyframes`
  0%, 100% {
    background-color: rgba(0, 0, 0, 0.02);
  }
  50% {
    background-color: rgba(255, 165, 0, 0.3);
  }
`;

const HighlightedCollapse = styled(Collapse)<{ highlight: boolean }>`
  transition: background-color 0.3s ease-in-out;

  &.highlight {
    animation: ${pulseAnimation} 1s ease-in-out 2;
  }
`;

const SessionOverview: React.FC<SessionOverviewProps> = ({
  sessionId: propSessionId,
  applicationId: propApplicationId,
  refreshTrigger,
  requestId,
}) => {
  const { apiKey } = usePermissions();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [session, setSession] = useState<Session | null>(null);
  const wsRef = useRef<ReconnectingWebSocket | null>(null);
  const lastMessageTimeRef = useRef<Date>(new Date());
  const timelineRef = useRef<HTMLDivElement>(null);
  const [highlightedId, setHighlightedId] = useState<string | null>(null);
  const [page, setPage] = useState<number>(0);
  const [isLoadingOlder, setIsLoadingOlder] = useState<boolean>(false);

  // Get parameters from URL if not provided as props
  const { sessionId: paramSessionId, applicationId: paramApplicationId } =
    useParams<{ sessionId: string; applicationId: string }>();

  // Use props if available, otherwise use URL parameters
  const sessionId = propSessionId || paramSessionId;
  const applicationId = propApplicationId || paramApplicationId;

  useEffect(() => {
    if (applicationId && sessionId) {
      loadSession();
    }
    return openWebsocket();
  }, [sessionId, applicationId, apiKey, refreshTrigger]);

  const loadSession = () => {
    SessionService.getSession(
      applicationId,
      sessionId,
      0,
      25,
      setIsLoading,
      apiKey
    ).subscribe(
      (fetchedSession: Session) => {
        setSession(fetchedSession);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const loadOlderRequests = () => {
    setIsLoadingOlder(true);
    const nextPage = page + 1;
    SessionService.getSession(
      applicationId,
      sessionId,
      nextPage,
      25,
      () => {},
      apiKey
    ).subscribe(
      (fetchedSession: Session) => {
        setSession((prevSession) => {
          if (!prevSession) return fetchedSession;
          const existingRequestIds = new Set(
            prevSession.timeline.map((turn) => turn.request?.request_id)
          );
          const newTurns = fetchedSession.timeline.filter(
            (turn) => !existingRequestIds.has(turn.request?.request_id)
          );
          return {
            ...fetchedSession,
            timeline: [...newTurns, ...prevSession.timeline],
          };
        });
        setPage(nextPage);
        setIsLoadingOlder(false);
      },
      (error: any) => {
        console.error(error);
        setIsLoadingOlder(false);
      }
    );
  };

  const openWebsocket = () => {
    closeWsConnection();

    const ws: any = new ReconnectingWebSocket(
      `wss://09hidyy627.execute-api.us-west-2.amazonaws.com/production?key=${sessionId}&type=SESSION_UPDATE`
    );
    wsRef.current = ws;

    ws.addEventListener("message", (message: any) => {
      lastMessageTimeRef.current = new Date();
      const data = JSON.parse(message.data);
      if (data.event_type === "SESSION_UPDATE") {
        handleNewSessionTurn(data);
      }
    });

    const checkInterval = setInterval(() => {
      const now = new Date();
      const diffInMinutesWS =
        (now.getTime() - lastMessageTimeRef.current.getTime()) / (1000 * 60);

      if (diffInMinutesWS >= 1) {
        if (wsRef.current) {
          wsRef.current.reconnect(1000, "Manual reconnect");
          lastMessageTimeRef.current = new Date();
        }
      }
    }, 1000 * 5);

    console.log("Opened WS connection");

    return () => {
      if (checkInterval) clearInterval(checkInterval);
      closeWsConnection();
    };
  };

  const closeWsConnection = () => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
      console.log("Closed WS connection");
    }
  };

  const handleNewSessionTurn = (data: any) => {
    const turn: Turn = JSON.parse(data.event_data);
    setSession((prevSession) => {
      if (!prevSession) return prevSession;
      const updatedTimeline = [...prevSession.timeline];
      const turnIndex = updatedTimeline.findIndex(
        (t) => t.request?.request_id === turn.request?.request_id
      );
      if (turnIndex !== -1) {
        // Update existing turn
        updatedTimeline[turnIndex] = {
          ...updatedTimeline[turnIndex],
          ...turn,
        };
      } else {
        // Add new turn
        updatedTimeline.unshift(turn);
      }
      return { ...prevSession, timeline: updatedTimeline };
    });
  };

  const removeDuplicateTurns = (timeline: Turn[]): Turn[] => {
    const uniqueTurns = new Map<string, Turn>();
    timeline.forEach((turn) => {
      if (turn.request?.request_id) {
        uniqueTurns.set(turn.request.request_id, turn);
      }
    });
    return Array.from(uniqueTurns.values());
  };

  const sortedTimeline = React.useMemo(() => {
    if (!session?.timeline) return [];
    const uniqueTimeline = removeDuplicateTurns(session.timeline);
    return uniqueTimeline.sort((a, b) => {
      const dateA = new Date(a.request?.date_created || 0);
      const dateB = new Date(b.request?.date_created || 0);
      return dateA.getTime() - dateB.getTime(); // Changed to ascending order
    });
  }, [session?.timeline]);

  const copyRequestId = (requestId: string) => {
    navigator.clipboard.writeText(requestId);
    message.success("Request ID copied to clipboard");
  };

  useEffect(() => {
    if (requestId && session) {
      setHighlightedId(requestId);
      setTimeout(() => {
        const requestElement = document.querySelector(
          `[data-request-id="${requestId}"]`
        );
        if (requestElement) {
          requestElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 100);

      // Remove highlight after animation completes
      const timer = setTimeout(() => {
        setHighlightedId(null);
      }, 2000); // 2 seconds for 2 pulses

      return () => clearTimeout(timer);
    }
  }, [requestId, session]);

  const formatDateTime = (
    date: number | undefined,
    prevDate: number | undefined,
    nextDate: number | undefined
  ) => {
    if (!date) return { display: "", full: "" };
    const currentMoment = moment(date);
    const prevMoment = prevDate ? moment(prevDate) : null;
    const nextMoment = nextDate ? moment(nextDate) : null;

    const showFullDate =
      (prevMoment && !currentMoment.isSame(prevMoment, "day")) ||
      (nextMoment && !currentMoment.isSame(nextMoment, "day"));

    const fullDateTime = currentMoment.format("MMM D, YYYY HH:mm:ss");
    const displayDateTime = showFullDate
      ? fullDateTime
      : currentMoment.format("HH:mm:ss");

    return { display: displayDateTime, full: fullDateTime };
  };

  if (isLoading) {
    return (
      <Spin
        size="large"
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      />
    );
  }
  const getFaultSummary = (turn: Turn) => {
    let faultSummary = turn.eval_request?.eval_results_set?.evaluate_summary;
    let suggestedCorrection = "";
    turn?.eval_request?.eval_results_set?.evaluation_results?.forEach(
      (val: EvaluateResult) => {
        if (!faultSummary && val.status == "FAULT") {
          faultSummary = val.description;
        }
        if (!suggestedCorrection && val.status == "FAULT") {
          suggestedCorrection = val.meta.correction;
        }
      }
    );
    const correctionField = formatTextOrJson(
      turn.response?.correction_applied
        ? turn.eval_request?.text_content
        : suggestedCorrection
    );
    return (
      <>
        <Descriptions colon={false} column={1}>
          <Descriptions.Item label="Reason">{faultSummary}</Descriptions.Item>
          <Descriptions.Item
            label={
              turn.response?.correction_applied
                ? "Original"
                : "Suggested Correction"
            }>
            {correctionField}
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  const content = (
    <div className="session-container" ref={timelineRef}>
      {session ? (
        <div>
          <Descriptions colon={false} column={12} layout="vertical">
            <Descriptions.Item label="Session ID" span={4}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {session?.session_id}
                <Button
                  icon={<CopyOutlined />}
                  onClick={() => {
                    navigator.clipboard.writeText(session?.session_id || "");
                    message.success("Session ID copied to clipboard");
                  }}
                  size="small"
                  style={{ marginLeft: "8px" }}
                />
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Date Created" span={3}>
              {moment(session?.date_created).format("MM/DD/YYYY hh:mm:ss A")}
            </Descriptions.Item>
            <Descriptions.Item label="Cost" span={1}>
              {session.cost > 0 ? formatCurrency(session.cost) : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Health" span={1}>
              100
            </Descriptions.Item>
            <Descriptions.Item label="Score" span={3}>
              {calculateScore(session)}
            </Descriptions.Item>
          </Descriptions>
        </div>
      ) : (
        <Paragraph>Session still processing; please try again later</Paragraph>
      )}

      <Timeline
        pending="Listening..."
        key={session?.session_id}
        style={{ marginTop: 96 }}>
        {sortedTimeline.length > 0 && !sortedTimeline[0].first && (
          <Timeline.Item
            dot={
              <Button
                icon={
                  isLoadingOlder ? (
                    <LoadingOutlined style={{ fontSize: "16px" }} />
                  ) : (
                    <UpOutlined style={{ fontSize: "16px" }} />
                  )
                }
                onClick={loadOlderRequests}
                disabled={isLoadingOlder}
                style={{ marginBottom: 16 }}
              />
            }>
            More requests available
          </Timeline.Item>
        )}
        {sortedTimeline.map((turn: Turn, index: number) => {
          const prevTurn = index > 0 ? sortedTimeline[index - 1] : null;
          const nextTurn =
            index < sortedTimeline.length - 1
              ? sortedTimeline[index + 1]
              : null;
          const { display: dateTimeString, full: fullDateTimeString } =
            formatDateTime(
              turn.request?.date_created,
              prevTurn?.request?.date_created,
              nextTurn?.request?.date_created
            );
          let faultySentinel = "";
          let faultCount = 0;
          turn.eval_request?.eval_results_set?.evaluation_results?.forEach(
            (evalResult: EvaluateResult) => {
              if (evalResult.status == "FAULT" && faultySentinel == "") {
                faultySentinel = evalResult.sentinel_name || "";
              }
              if (evalResult.status == "FAULT") {
                faultCount += 1;
              }
            }
          );
          const toolCalls = turn.response?.choices[0]?.message?.tool_calls?.map(
            (toolCall) => {
              return (
                <Row key={toolCall.id}>
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    <code>
                      {toolCall.function.name}({toolCall.function.arguments})
                    </code>
                  </pre>
                </Row>
              );
            }
          );
          return (
            <Timeline.Item
              key={turn.request?.request_id}
              data-request-id={turn.request?.request_id}
              dot={
                <Tooltip title={fullDateTimeString}>
                  <Text style={{ fontSize: 12 }}>{dateTimeString}</Text>
                </Tooltip>
              }
              style={{ marginTop: 48 }}
              color="black">
              <HighlightedCollapse
                style={{
                  width: "auto",
                  padding: "20px 30px",
                  borderRadius: 16,
                }}
                bordered={false}
                defaultActiveKey={[]}
                expandIconPosition="end"
                highlight={turn.request?.request_id === highlightedId}
                className={
                  turn.request?.request_id === highlightedId ? "highlight" : ""
                }>
                <Panel
                  header={
                    <div style={{ width: "100%" }}>
                      <Row align="middle">
                        <Tag style={{ marginRight: 20 }}>
                          {turn.request?.action_type}
                        </Tag>
                        <Text
                          style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: "#00000080",
                          }}>
                          {turn.response?.response_time &&
                          turn.response.response_time > 0 ? (
                            <span style={{ marginRight: 20 }}>
                              {turn.response?.response_time}ms
                            </span>
                          ) : null}
                          <span style={{ marginRight: 20 }}>
                            Tokens: {turn.response?.usage?.prompt_tokens}
                            {" > "}
                            {turn.response?.usage?.completion_tokens}
                          </span>
                          {turn.response?.usage?.cost &&
                          turn.response.usage.cost > 0 ? (
                            <span style={{ marginRight: 20 }}>
                              Cost: {formatCurrency(turn.response.usage.cost)}
                            </span>
                          ) : null}
                          <Popover
                            content={
                              <div style={{ maxWidth: 800 }}>
                                {getFaultSummary(turn)}
                              </div>
                            }
                            title={
                              faultySentinel
                                ? `${faultySentinel} Fault`
                                : "Fault"
                            }
                            trigger="hover"
                            placement={"top"}>
                            {turn.response?.correction_applied ? (
                              <Tag color="success">Corrected</Tag>
                            ) : (
                              faultCount > 0 && (
                                <Tag color="error">
                                  {faultCount > 1 ? "FAULTS" : "FAULT"}
                                </Tag>
                              )
                            )}
                          </Popover>
                        </Text>
                        {toolCalls?.length > 0 && (
                          <Tag style={{ marginRight: 20 }}>Tool Call</Tag>
                        )}
                        <Button
                          icon={<CopyOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            copyRequestId(turn.request?.request_id || "");
                          }}
                          size="small">
                          Request ID
                        </Button>
                      </Row>
                      <Row
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          whiteSpace: "pre-wrap",
                        }}>
                        <span style={{ marginTop: 8 }}>
                          {formatTextOrJson(
                            turn.response?.choices[0]?.message?.content
                          )}
                          {turn.request?.error && (
                            <Text style={{ color: "red" }}>
                              {turn.request?.error}
                            </Text>
                          )}
                        </span>
                      </Row>
                      {toolCalls}
                    </div>
                  }
                  key="1">
                  <Row style={{ marginTop: 20 }}>
                    <Descriptions colon={false} column={12} layout="vertical">
                      <Descriptions.Item label="Inference" span={1}>
                        {turn.request?.inference_location == "SERVER"
                          ? "Server"
                          : "Client"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Model" span={1}>
                        {turn.request?.params?.model}
                      </Descriptions.Item>
                      <Descriptions.Item label="Temperature" span={1}>
                        {turn.request?.params?.temperature}
                      </Descriptions.Item>
                      <Descriptions.Item label="Streaming" span={1}>
                        {turn.request?.params?.stream ? "On" : "Off"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Evaluations" span={1}>
                        {turn.eval_request?.id ? "On" : "Off"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Corrections" span={1}>
                        {turn.request?.apply_corrections ? "On" : "Off"}
                      </Descriptions.Item>
                    </Descriptions>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Text
                      style={{
                        color: "#828282",
                        fontSize: 14,
                        fontWeight: 600,
                      }}>
                      Messages
                    </Text>
                  </Row>
                  <Collapse
                    style={{ marginTop: 6, backgroundColor: "transparent" }}
                    bordered={false}
                    defaultActiveKey={turn.request?.params?.messages.length}
                    expandIconPosition={"end"}>
                    {turn.request?.params?.messages.map(
                      (message: ChatMessage, index) => {
                        return (
                          <Panel
                            key={index + 1}
                            header={
                              <Text
                                style={{
                                  color: "#828282",
                                  fontSize: 14,
                                  fontWeight: 600,
                                  textTransform: "capitalize",
                                }}>
                                {message.role}
                              </Text>
                            }
                            style={{
                              marginTop: 10,
                              backgroundColor: "white",
                              border: "none",
                            }}>
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: 600,
                                whiteSpace: "pre-wrap",
                              }}>
                              {formatTextOrJson(message.content)}
                            </span>
                          </Panel>
                        );
                      }
                    )}
                  </Collapse>
                </Panel>
              </HighlightedCollapse>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </div>
  );

  // If both sessionId and applicationId are provided as props, render without Card
  if (propSessionId && propApplicationId) {
    return content;
  }

  // Otherwise, wrap in a Card for standalone view
  return (
    <Card className="session-overview-wrapper">
      <h1>Session Overview</h1>
      {content}
    </Card>
  );
};

export default SessionOverview;
