import React, { useState, useEffect } from "react";
import { Typography, Input, Select, Space, Button, Row, Col, message, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { CompanyService } from "../../services/CompanyService";
import { Company } from "../../model_gen/company";
import { usePermissions } from "../../PermissionsProvider";

const { Text } = Typography;

const BillingPreferences: React.FC = () => {
  const {apiKey} = usePermissions();
  const [company, setCompany] = useState<Company | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCompany();
  }, [apiKey]);

  const fetchCompany = async () => {
    setLoading(true);
    CompanyService.getCompany(setLoading, apiKey).subscribe((company: Company) => {
      setCompany(company);
    }, (error: any) => {
      message.error(`Failed to fetch company data: ${error}`);
      setLoading(false);
    });
  };

  const renderLabel = (label: string, tooltip: string) => (
    <Space>
      <Text strong>{label}</Text>
      <Tooltip title={tooltip}>
        <InfoCircleOutlined style={{ color: "rgba(0, 0, 0, 0.45)" }} />
      </Tooltip>
    </Space>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ maxWidth: "600px" }}>
      <Text type="secondary" style={{ display: "block", marginBottom: "24px" }}>
        Changes to these preferences will apply to future invoices only. If you need a past invoice reissued, please contact ar@openai.com.
      </Text>

      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div>
          {renderLabel("Company name", "If specified, this name will appear on invoices instead of your organization name.")}
          <Input
            value={company?.company_name || ""}
            onChange={(e) => setCompany(prev => prev ? { ...prev, company_name: e.target.value } : null)}
            style={{ marginTop: "8px" }}
          />
        </div>

        <div>
          {renderLabel("Purchase order (PO) number", "Your PO number will be displayed on future invoices.")}
          <Input
            value={company?.meta?.["po_number"] || ""}
            onChange={(e) => setCompany(prev => prev ? { ...prev, meta: { ...prev.meta, po_number: e.target.value } } : null)}
            style={{ marginTop: "8px" }}
          />
        </div>

        <div>
          {renderLabel("Billing email", "Invoices and other billing notifications will be sent here (in addition to being sent to the owners of your organization).")}
          <Input
            value={company?.meta?.["contact_email"] || ""}
            onChange={(e) => setCompany(prev => prev ? { ...prev, meta: { ...prev.meta, contact_email: e.target.value } } : null)}
            style={{ marginTop: "8px" }}
          />
        </div>

        <div>
          {renderLabel("Primary business address", "This is the physical address of the company purchasing OpenAI services and is used to calculate any applicable sales tax.")}
          <Space direction="vertical" size="middle" style={{ width: "100%", marginTop: "8px" }}>
            <Input
              value={company?.meta?.["address_line1"] || ""}
              onChange={(e) => setCompany(prev => prev ? { ...prev, meta: { ...prev.meta, address_line1: e.target.value } } : null)}
              placeholder="Address line 1"
            />
            <Input
              value={company?.meta?.["address_line2"] || ""}
              onChange={(e) => setCompany(prev => prev ? { ...prev, meta: { ...prev.meta, address_line2: e.target.value } } : null)}
              placeholder="Address line 2"
            />
            <Row gutter={8}>
              <Col span={19}>
                <Input
                  value={company?.meta?.["city"] || ""}
                  onChange={(e) => setCompany(prev => prev ? { ...prev, meta: { ...prev.meta, city: e.target.value } } : null)}
                  placeholder="City"
                />
              </Col>
              <Col span={5}>
                <Input
                  value={company?.meta?.["zip_code"] || ""}
                  onChange={(e) => setCompany(prev => prev ? { ...prev, meta: { ...prev.meta, zip_code: e.target.value } } : null)}
                  placeholder="ZIP code"
                />
              </Col>
            </Row>
            <Select
              value={company?.meta?.["state"] || ""}
              onChange={value => setCompany(prev => prev ? { ...prev, meta: { ...prev.meta, state: value } } : null)}
              style={{ width: "100%" }}
              options={[{ value: "California", label: "California" }]}
            />
          </Space>
        </div>

        <Button type="primary" disabled>Save</Button>
      </Space>
    </div>
  );
};

export default BillingPreferences;
