import { Typography } from "antd";

export function formatCurrency(amount: number) {
  let decimalPlaces = 2;

  if (amount < 0.01) {
    const match = amount.toString().match(/0\.0*([1-9])/);
    if (match && match.index != undefined) {
      decimalPlaces = match.index + match[0].length - 2;
    }
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(amount);
}

export function isDimmed(condition: boolean) {
  return {
    opacity: condition ? 0.5 : 1,
  };
}

export function formatTextOrJson(text?: string): JSX.Element {
  if (!text) {
    return;
  }

  let formattedText = text;

  // Check if the text contains [ or {
  if (text.includes("[") || text.includes("{")) {
    try {
      // Attempt to parse the text as JSON
      const parsedJson = JSON.parse(text);
      formattedText = JSON.stringify(parsedJson, null, 2);
    } catch (e) {
      // If parsing fails, return the original text
      return <Typography.Text>{text}</Typography.Text>;
    }

    return (
      <pre style={{ whiteSpace: "pre-wrap" }}>
        <code>{formattedText}</code>
      </pre>
    );
  } else {
    // If the text doesn't contain [ or {, return it as is
    return <Typography.Text>{text}</Typography.Text>;
  }
}
