import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Table, Typography, message } from "antd";
import { usePermissions } from "../../PermissionsProvider";
import { PermissionsService } from "../../services/PermissionsService";
import {
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  CopyOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Key } from "../../model_gen/key";
const { Text } = Typography;
import CustomSelect from "../shared/select/CustomSelect";
import CustomInput from "../shared/input/CustomInput";
import ApplicationModal from "../modals/applicationModal/ApplicationModal";
import { ProviderKeyMap } from "../../types/IDevelopers";
import { LinkButton, PrimaryButton } from "../shared/button/Buttons";

const DevelopersAPIKeys: React.FC = () => {
  const defaultKeyMap = {
    openai_api_key: 0,
    groq_api_key: 0,
    anthropic_api_key: 0,
  };
  const defaultKey: Key = Key.fromPartial({
    key_map: defaultKeyMap,
    owner: "USER",
    type: "Maitai",
    description: "",
  });

  const { apiKey } = usePermissions();
  const [keys, setKeys] = useState<any[]>([]);
  const [visibleKey, setVisibleKey] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentKey, setCurrentKey] = useState<Key>(defaultKey);
  const [prevKey, setPrevKey] = useState<Key | null>(null);
  const [editKey, setEditKey] = useState(true); // New state for submit button loading
  const [prevEditKey, setPrevEditKey] = useState(true); // New state for submit button loading
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submit button loading
  const [providerKeyMap, setProviderKeyMap] = useState<ProviderKeyMap>({
    openai_api_keys: [],
    groq_api_keys: [],
    anthropic_api_keys: [],
  });
  const keyTypeOptions = [
    { label: "Maitai", value: "Maitai" },
    { label: "OpenAI", value: "OpenAI" },
    { label: "Groq", value: "Groq" },
    { label: "Anthropic", value: "Anthropic" },
  ];
  useEffect(() => {
    refreshKeys();
  }, []);
  const backtrack = (response: Key) => {
    setIsModalVisible(!!prevKey);
    if (prevKey) {
      const newKey = {
        ...prevKey,
        key_map: {
          openai_api_key: prevKey.key_map?.openai_api_key || 0,
          groq_api_key: prevKey.key_map?.groq_api_key || 0,
          anthropic_api_key: prevKey.key_map?.anthropic_api_key || 0,
        },
      };
      switch (response.type) {
        case "OpenAI":
          // @ts-ignore
          newKey.key_map.openai_api_key = response.id;
          break;
        case "Groq":
          // @ts-ignore
          newKey.key_map.groq_api_key = response.id;
          break;
        case "Anthropic":
          // @ts-ignore
          newKey.key_map.anthropic_api_key = response.id;
          break;
      }
      setCurrentKey(newKey);
      setEditKey(prevEditKey);
    }
    setPrevKey(null);
  };

  const refreshKeys = () => {
    setIsLoading(true);
    PermissionsService.getKeys(setIsLoading, apiKey).subscribe(
      (data: Key[]) => {
        console.log("header-main-wrap ", data);
        setKeys(data);
        const tmpProviderKeyMap: ProviderKeyMap = {
          openai_api_keys: [],
          groq_api_keys: [],
          anthropic_api_keys: [],
        };
        data.forEach((key) => {
          switch (key.type.toLowerCase()) {
            case "anthropic":
              tmpProviderKeyMap.anthropic_api_keys.push(key);
              break;
            case "groq":
              tmpProviderKeyMap.groq_api_keys.push(key);
              break;
            case "openai":
              tmpProviderKeyMap.openai_api_keys.push(key);
              break;
          }
          setProviderKeyMap(tmpProviderKeyMap);
        });
      },
      (error: any) => {
        console.error("Error fetching keys:", error);
        setIsLoading(false);
      }
    );
  };

  const toggleVisibility = (key: string) => {
    setVisibleKey(visibleKey === key ? null : key);
  };

  const showModal = (key?: Key) => {
    console.log("showModal", key);
    setCurrentKey(key || defaultKey);
    setIsModalVisible(true);
  };

  const buildKeyOptionList = (keys: Key[]) => {
    const options: [{ label: any; value: any }] = [
      { label: "Maitai Provisioned", value: 0 },
    ];
    keys.forEach((key) => {
      options.push({
        label: key.description,
        value: key.id,
      });
    });
    options.push({
      label: "Add New Provider Key",
      value: -1,
    });
    return options;
  };

  const handleOk = () => {
    setIsSubmitting(true); // Start loading when submit is initiated
    if (editKey) {
      console.log("Editing key:", currentKey);
      PermissionsService.editKey(currentKey, null, apiKey)
        .subscribe(
          (response: Key) => {
            console.log("Key edited successfully:", response);
            refreshKeys();
            backtrack(response);
          },
          (error: any) => {
            console.error("Error editing key:", error);
          }
        )
        .add(() => setIsSubmitting(false)); // Stop loading regardless of the result
    } else {
      if (!currentKey) return;
      PermissionsService.addKey(currentKey, null, apiKey)
        .subscribe(
          (response: Key) => {
            console.log("Key added successfully:", response);
            refreshKeys();
            backtrack(response);
          },
          (error: any) => {
            console.error("Error adding key:", error);
          }
        )
        .add(() => setIsSubmitting(false)); // Stop loading regardless of the result
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const copyKeyValue = (keyValue: string) => {
    navigator.clipboard.writeText(keyValue);
    message.success("Key copied to clipboard");
  };

  const columns = [
    {
      title: "Key Type",
      dataIndex: "type",
      key: "type",
      width: 250,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
    },
    {
      title: "Key Value",
      dataIndex: "key_value",
      key: "key_value",
      render: (text: string, record: Key) => {
        if (record.obfuscated) {
          return (
            <div className="table-item-input-wrap">{record.key_value}</div>
          );
        }
        return (
          <div className="table-item-input-wrap">
            <span>
              {visibleKey === record.key_value ? text : "•".repeat(32)}
            </span>
            <div>
              <LinkButton
                className="icon-btn"
                icon={<CopyOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  copyKeyValue(record.key_value);
                }}
              />
              <LinkButton
                className="icon-btn"
                icon={
                  visibleKey === record.key_value ? (
                    <EyeInvisibleOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                onClick={() => toggleVisibility(record.key_value)}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Date Created",
      dataIndex: "date_created",
      key: "date_created",
      render: (text: number) => new Date(text).toISOString().split("T")[0],
      width: 200,
    },
    {
      title: "Actions",
      key: "actions",
      align: "left" as const,
      width: 100,
      render: (_: any, record: any) => (
        <div className="table-event-wrap">
          <LinkButton
            className="icon-btn table-icon-btn"
            icon={<EditOutlined />}
            onClick={() => {
              setEditKey(true);
              showModal(record);
            }}
            style={{ marginRight: 8 }}>
            Edit
          </LinkButton>
          <LinkButton
            className="table-icon-btn"
            icon={<DeleteOutlined />}
            onClick={() => removeKey(record)}
            title="Remove API Key"
            danger>
            Delete
          </LinkButton>
        </div>
      ),
    },
  ];

  const removeKey = (key: Key) => {
    Modal.confirm({
      title: "Are you sure you want to delete this API key?",
      content: "This action cannot be undone.",
      okText: "Yes, delete it",
      okType: "danger",
      cancelText: "No",
      onOk() {
        console.log("Removing key:", key);
        PermissionsService.deleteKey(key, null, apiKey).subscribe(
          (response: any) => {
            console.log("Key removed successfully:", response);
            refreshKeys();
          },
          (error: any) => {
            console.error("Error removing key:", error);
          }
        );
      },
    });
  };

  useEffect(() => {
    if (
      currentKey.key_map?.anthropic_api_key != -1 &&
      currentKey.key_map?.groq_api_key != -1 &&
      currentKey.key_map?.openai_api_key != -1
    ) {
      return;
    }
    setPrevKey(currentKey);
    setPrevEditKey(editKey);
    setEditKey(false);
    const newKey = {
      ...defaultKey,
    };
    if (currentKey.key_map?.openai_api_key == -1) {
      newKey.type = "OpenAI";
    } else if (currentKey.key_map?.groq_api_key == -1) {
      newKey.type = "Groq";
    } else if (currentKey.key_map?.anthropic_api_key == -1) {
      newKey.type = "Anthropic";
    }
    setCurrentKey(newKey);
  }, [currentKey.key_map]);
  const maitaiKeySelection = (
    <div>
      <Row style={{ marginTop: 20 }}>
        <Col span={24} className="mb-1">
          <Text style={{ fontSize: 14, fontWeight: 500 }}>OpenAI API Key</Text>
        </Col>
        <Col span={24} style={{ alignItems: "right" }}>
          <CustomSelect
            value={currentKey?.key_map?.openai_api_key}
            style={{ width: "100%" }}
            options={buildKeyOptionList(providerKeyMap.openai_api_keys)}
            onChange={(value) =>
              setCurrentKey(
                currentKey
                  ? {
                      ...currentKey,
                      key_map: {
                        ...(currentKey.key_map || defaultKeyMap),
                        openai_api_key: value,
                      },
                    }
                  : currentKey
              )
            }
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={24} className="mb-1">
          <Text style={{ fontSize: 14, fontWeight: 500 }}>Groq API Key</Text>
        </Col>
        <Col span={24} style={{ alignItems: "right" }}>
          <CustomSelect
            value={currentKey?.key_map?.groq_api_key}
            style={{ width: "100%" }}
            options={buildKeyOptionList(providerKeyMap.groq_api_keys)}
            onChange={(value) =>
              setCurrentKey(
                currentKey
                  ? {
                      ...currentKey,
                      key_map: {
                        ...(currentKey.key_map || defaultKeyMap),
                        groq_api_key: value,
                      },
                    }
                  : currentKey
              )
            }
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col span={24} className="mb-1">
          <Text style={{ fontSize: 14, fontWeight: 500 }}>
            Anthropic API Key
          </Text>
        </Col>
        <Col span={24} style={{ alignItems: "right" }}>
          <CustomSelect
            value={currentKey?.key_map?.anthropic_api_key}
            style={{ width: "100%" }}
            options={buildKeyOptionList(providerKeyMap.anthropic_api_keys)}
            onChange={(value) =>
              setCurrentKey(
                currentKey
                  ? {
                      ...currentKey,
                      key_map: {
                        ...(currentKey.key_map || defaultKeyMap),
                        anthropic_api_key: value,
                      },
                    }
                  : currentKey
              )
            }
          />
        </Col>
      </Row>
    </div>
  );

  const keyValueInput = (
    <Row style={{ marginTop: 20 }}>
      <Col span={24}>
        <Text style={{ fontSize: 14, fontWeight: 500 }}>Key Value</Text>
        <CustomInput
          className="inputWrapper"
          placeholder="Key Value"
          disabled={isSubmitting}
          width={"100%"}
          value={currentKey?.key_value || ""}
          onChange={(e) =>
            setCurrentKey(
              currentKey
                ? { ...currentKey, key_value: e.target.value }
                : currentKey
            )
          }
        />
      </Col>
    </Row>
  );

  return (
    <div>
      <div className="header-main-wrap">
        <h2 style={{ marginBottom: 0 }}>API Keys</h2>
        <PrimaryButton
          title="New API Key"
          icon={<PlusOutlined />}
          className="buttonWrapper"
          onClick={() => {
            setEditKey(false);
            showModal();
          }}
        />
      </div>
      <Table
        size="small"
        columns={columns}
        dataSource={keys}
        bordered={false}
        pagination={false}
        loading={isLoading}
      />
      <ApplicationModal
        title={editKey ? "Edit API Key" : "Add API Key"}
        open={isModalVisible}
        submitButtonTitle={editKey ? "Edit Key" : "Add Key"}
        onOk={handleOk}
        onCancel={handleCancel}
        onSubmitButtonClick={handleOk}
        onCancelButtonClick={handleCancel}
        isLoading={isSubmitting}>
        <Row>
          <Col span={24} className="mb-1">
            <Text style={{ fontSize: 14, fontWeight: 500 }}>Key Type</Text>
          </Col>
          <Col span={24} className="mb-2">
            <CustomSelect
              disabled={editKey}
              options={keyTypeOptions}
              className="cus-select-wrap w-100"
              value={currentKey?.type}
              onChange={(value) =>
                setCurrentKey(
                  currentKey ? { ...currentKey, type: value } : currentKey
                )
              }
            />
          </Col>
          <Col span={24} className="mb-1">
            <Text style={{ fontSize: 14, fontWeight: 500 }}>Description</Text>
          </Col>
          <Col span={24}>
            <CustomInput
              inputType="textarea"
              className="inputTextAreaWrapper"
              placeholder="Description"
              rows={4}
              value={currentKey?.description}
              onChange={(e) =>
                setCurrentKey(
                  currentKey
                    ? { ...currentKey, description: e.target.value }
                    : currentKey
                )
              }
              disabled={isSubmitting}
            />
          </Col>
        </Row>
        {(currentKey?.type || "Maitai") == "Maitai"
          ? maitaiKeySelection
          : keyValueInput}
      </ApplicationModal>
    </div>
  );
};

export default DevelopersAPIKeys;
