import { Dropdown, Space } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { TableDropDownMenuProps } from "../../../types/Shared";

const TableDropDownMenu: React.FC<TableDropDownMenuProps> = ({
  items,
  placement = "bottom",
}) => {
  return (
    <Space wrap>
      <Dropdown
        autoAdjustOverflow={true}
        overlayClassName="cus-drop-hov-wrap"
        placement={placement}
        menu={{ items }}
        trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <Space id="table-dropdown-space">
            <MoreOutlined
              style={{ transform: "rotate(90deg)" }}
              id="table-dropdown"
            />
          </Space>
        </a>
      </Dropdown>
    </Space>
  );
};

export default TableDropDownMenu;
