import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Space, Typography } from "antd";
import { usePermissions } from "../../PermissionsProvider";
import { ApplicationService } from "../../services/ApplicationService";
import { Application } from "../../model_gen/application";
import { PermissionsService } from "../../services/PermissionsService";
import { Key } from "../../model_gen/key";
import ReconnectingWebSocket from "reconnecting-websocket";
import { useAuth0 } from "@auth0/auth0-react";
import { InstallCard } from "./InstallCard";
import { ImplementationCard } from "./ImplementationCard";
import { DocsCard } from "./DocsCard";
import { usePreventExit } from "../../utils/usePrevent";
import useStore from "../../store/useStore";
import { BillingService } from "../../services/BillingService";
import { PaymentMethod } from "../../model_gen/billing";
import { PaymentMethodCard } from "./PaymentMethodCard";
import { OnboardingCompleteCard } from "./OnboardingCompleteCard";

const Tutorial: React.FC = () => {
  const { apiKey } = usePermissions();
  const { dataReadyTutorial, setDataReadyTutorial } = useStore();

  const [apiToken, setApiToken] = useState<string>("");
  const [companyId, setCompanyId] = useState<number>(0);
  const [websocketRequired, setWebsocketRequired] = useState<boolean>(false);
  const wsRef = useRef<ReconnectingWebSocket | null>(null);
  const { user } = useAuth0();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true);

  useEffect(() => {
    setDataReadyTutorial(false);
    PermissionsService.getKeysFiltered("maitai", null, apiKey).subscribe(
      (keys: Key[]) => {
        setCompanyId(keys[0].company_id);
        setApiToken(keys[0].key_value);
      },
      (error: any) => {
        console.error(error);
      }
    );
    ApplicationService.getApplications(null, apiKey).subscribe(
      (apps: Application[]) => {
        if (apps && apps.length > 0) {
          setDataReadyTutorial(true);
        } else {
          setWebsocketRequired(true);
        }
      },
      (error: any) => {
        setWebsocketRequired(true);
        console.error(error);
      }
    );
    fetchPaymentMethods();
  }, [apiKey]);

  const fetchPaymentMethods = () => {
    BillingService.getPaymentMethods(
      setLoadingPaymentMethods,
      apiKey
    ).subscribe(
      (methods: PaymentMethod[]) => {
        setPaymentMethods(methods);
        setLoadingPaymentMethods(false);
      },
      (error: any) => {
        console.error("Error fetching payment methods:", error);
        setLoadingPaymentMethods(false);
      }
    );
  };

  useEffect(() => {
    if (companyId > 0 && websocketRequired) {
      refreshWebsocket();
    }
  }, [companyId, websocketRequired]);

  const refreshWebsocket = () => {
    closeWsConnection();
    openWebsocket();
  };

  const openWebsocket = () => {
    closeWsConnection();

    const ws: any = new ReconnectingWebSocket(
      `wss://09hidyy627.execute-api.us-west-2.amazonaws.com/production?key=${companyId}&type=APPLICATION_CONFIG_CHANGE`
    );
    wsRef.current = ws;

    ws.addEventListener("message", () => {
      // App
      setDataReadyTutorial(true);
      closeWsConnection();
    });

    console.log("Opened WS connection");
    // Pull applications every minute just in case
    const checkInterval = setInterval(() => {
      ApplicationService.getApplications(null, apiKey).subscribe(
        (apps: Application[]) => {
          if (apps && apps.length > 0) {
            setDataReadyTutorial(true);
          }
        },
        (error: any) => {
          console.error(error);
        }
      );
    }, 1000 * 60); // check every minute
    return () => {
      clearInterval(checkInterval);
      closeWsConnection();
    };
  };

  const closeWsConnection = () => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
      console.log("Closed WS connection");
    }
  };
  usePreventExit(!dataReadyTutorial, ["/billing"]);

  return (
    <Card className="applications-wrapper">
      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Col>
          <Typography.Title
            className="font-400 text-typo-main"
            style={{ marginBottom: "6px" }}>
            Welcome to Maitai
            <span className="capitalize">, {user?.nickname}</span>
          </Typography.Title>
          <Typography.Paragraph
            style={{ marginBottom: "0" }}
            className="text-typo-paragraph">
            You're just a couple of steps away from reliable LLM output.
          </Typography.Paragraph>
        </Col>
        <InstallCard isReady={dataReadyTutorial} />
        <ImplementationCard apiKey={apiToken} isReady={dataReadyTutorial} />
        <PaymentMethodCard
          isReady={dataReadyTutorial}
          paymentMethods={paymentMethods}
          loading={loadingPaymentMethods}
        />
        {dataReadyTutorial && paymentMethods.length > 0 && (
          <OnboardingCompleteCard />
        )}
        <DocsCard />
      </Space>
    </Card>
  );
};

export default Tutorial;
