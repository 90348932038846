import React from "react";
import { Col, Collapse, Row, Typography } from "antd";
import CodeBlock from "../shared/codeblock/CodeBlock";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { IImplementingCard } from "../../types/ITutorial";
import { isDimmed } from "../../utils/FormatUtils";

const { Panel } = Collapse;

export const ImplementationCard: React.FC<IImplementingCard> = ({
  apiKey,
  isReady,
}) => {
  const tabs = [
    {
      key: "1",
      label: "Python",
      language: "python",
      children: `import maitai

client = maitai.Maitai(
    maitai_api_key="${apiKey}"
)

messages = [
    {"role": "system", "content": "You are a helpful assistant"},
    {"role": "user", "content": "Generate numbers 1-10"},
]

response = client.chat.completions.create(
    messages=messages,
    model="llama3-70b-8192", # Remove this line to set model in Portal
    application="YOUR_APP_PROD", # Unique identifier for your application
    intent="CONVERSATION", # Purpose of the request. Used to learn your prompt and power auto corrections
    session_id="CONVERSATION_ID", # Unique ID that you generate for each session. Used to group requests
)`,
    },
    {
      key: "2",
      label: "Javascript",
      language: "javascript",
      children: `import Maitai from "maitai";

const maitai = new Maitai({
  maitaiApiKey: "${apiKey}"
});

const messages = [
    {"role": "system", "content": "You are a helpful assistant"},
    {"role": "user", "content": "Generate numbers 1-10"},
];

const response = await maitai.chat.completions.create({
    messages: messages,
    model: "llama3-70b-8192",  // Remove this line to set model in Portal
    application: "YOUR_APP_PROD", // Unique identifier for your application
    intent: "CONVERSATION", // Purpose of the request. Used to learn your prompt and power auto corrections
    session_id: "CONVERSATION_ID", // Unique ID that you generate for each session. Used to group requests
});`,
    },
  ];

  return (
    <Collapse
      expandIconPosition="end"
      style={{ background: "white" }}
      bordered={false}>
      <Panel
        header={
          <Row gutter={32} style={{ padding: "8px 8px", ...isDimmed(isReady) }}>
            <Col
              span={24}
              style={{ display: "flex", alignItems: "center", gap: "24px" }}>
              <div>
                {isReady ? (
                  <CheckCircleOutlined
                    style={{ color: "#21b892", fontSize: "24px" }}
                  />
                ) : (
                  <MinusCircleOutlined
                    style={{ color: "#bfbfbf", fontSize: "24px" }}
                  />
                )}
              </div>
              <div style={{ flex: 1 }}>
                <Typography.Title
                  level={5}
                  style={{ marginBottom: "4px", marginTop: "0" }}>
                  Implementation
                </Typography.Title>
                <Typography.Paragraph
                  style={{ marginBottom: "0" }}
                  className="text-typo-paragraph">
                  Import Maitai, then make a request.
                </Typography.Paragraph>
              </div>
              <Typography.Text style={{ marginLeft: "auto" }}>
                View Code Example
              </Typography.Text>
            </Col>
          </Row>
        }
        key="1">
        <Row>
          <Col span={8}></Col>
          <Col span={16}>
            <CodeBlock items={tabs} />
            <Typography.Paragraph
              style={{ marginBottom: "0", textAlign: "right", marginTop: "16px" }}
              className="text-typo-paragraph">
              Need help? Check out our{" "}
              <a href="https://docs.trymaitai.ai/get_started/quickstart" target="_blank">
                Quickstart Guide
              </a>
            </Typography.Paragraph>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};
