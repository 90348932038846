import React, { useEffect, useState } from "react";
import "highlight.js/styles/monokai.css";
import hljs from "highlight.js";
import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tabs, Tooltip } from "antd";
import { CodeBlockProps } from "../../../types/Shared";
import { GhostButton } from "../button/Buttons";

const CodeBlock: React.FC<CodeBlockProps> = ({ items }) => {
  const [copied, setCopied] = useState<boolean>(false);
  const [copyText, setCopyText] = useState<string>(items[0]?.children ?? "");

  useEffect(() => {
    hljs.highlightAll();
  }, []);

  useEffect(() => {
    if (!copied) {
      return;
    }
    const interval = setInterval(() => setCopied(false), 3000);

    return () => clearInterval(interval);
  }, [copied]);

  const onChange = (key: string) => {
    setCopyText(items.find(({ key: k }) => k === key).children);
  };

  return (
    <>
      <Tooltip title={copied ? "Copied!" : "Copy"}>
        <CopyToClipboard text={copyText} onCopy={() => setCopied(true)}>
          <GhostButton
            icon={
              copied ? (
                <CheckOutlined />
              ) : (
                <CopyOutlined className="copy-icon" />
              )
            }
            style={{
              position: "absolute",
              top: 4,
              right: 20,
              zIndex: 200,
            }}
          />
        </CopyToClipboard>
      </Tooltip>
      <Tabs defaultActiveKey="1" onChange={onChange} className="tab-codeblock">
        {items?.map(({ key, label, children, language }) => {
          return (
            <Tabs.TabPane
              tab={label}
              key={key}
              className="tab-pane-wrapper"
              forceRender={true}>
              <pre>
                <code className={`language-${language} hljs`}>{children}</code>
              </pre>
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </>
  );
};

export default CodeBlock;
