import React, { useState, useCallback } from "react";
import { Form, Input, Card, notification, List } from "antd";
import { ApplicationService } from "../../services/ApplicationService";
import { useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { Application, ApplicationObjective } from "../../model_gen/application";
import { usePermissions } from "../../PermissionsProvider";
import ApplicationModal from "../modals/applicationModal/ApplicationModal";
import CustomInput from "../shared/input/CustomInput";
import AddContextEntry from "../modals/addcontext/AddContextEntry";
import { DashedButton, OutlineButton, PrimaryButton } from "../shared/button/Buttons";
// import AddContextEntry from "../modals/addcontext/AddContextEntry";

const RegisterApplication: React.FC = () => {
  const navigate = useNavigate();
  const { apiKey } = usePermissions();
  const [contextEntries, setContextEntries] = useState<
    { id: number; context_key: string; context_value: string }[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [applicationName, setApplicationName] = useState("");
  const [applicationRefName, setApplicationRefName] = useState("");
  const [objective, setObjective] = useState("");
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [isOpenContextEntry, setIsOpenContextEntry] = useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    const application: Application = Application.fromPartial({
      application_name: applicationName,
      application_ref_name: applicationRefName,
      application_objective: ApplicationObjective.fromPartial({
        objective_body: { main: objective },
      }),
    });

    ApplicationService.registerApplication(application, null, apiKey).subscribe(
      (app: Application) => {
        const updatedContextBody = contextEntries.reduce(
          (acc, { context_key, context_value }) => ({
            ...acc,
            [context_key]: context_value,
          }),
          {}
        );
        const payload = { application_id: app.id, context: updatedContextBody };
        ApplicationService.updateApplicationContext(
          payload,
          setLoading,
          apiKey
        ).subscribe(
          () => {
            notification.success({
              message: "Success",
              description: "Application created successfully",
            });
            navigate(`/application/${app.id}`);
          },
          (error: any) => {
            console.error(error);
            notification.error({
              message: "Error",
              description: "Created application, but failed to set context",
            });
          }
        );
      },
      (error: any) => {
        console.error(error);
        notification.error({
          message: "Error",
          description: "Failed to create application",
        });
        setLoading(false);
      }
    );
  }, [applicationName, applicationRefName, objective, navigate]);

  const handleContextKeyChange = useCallback(
    (itemId: number, newContextKey: string) => {
      setContextEntries((prev) =>
        prev.map((entry) =>
          entry.id === itemId ? { ...entry, context_key: newContextKey } : entry
        )
      );
    },
    []
  );

  const handleContextValueChange = useCallback(
    (itemId: number, newContextValue: string) => {
      setContextEntries((prev) =>
        prev.map((entry) =>
          entry.id === itemId
            ? { ...entry, context_value: newContextValue }
            : entry
        )
      );
    },
    []
  );

  const handleRemoveContextEntry = useCallback((itemId: number) => {
    setContextEntries((prev) => prev.filter((entry) => entry.id !== itemId));
  }, []);

  const handleAddContextEntry = useCallback(() => {
    setIsOpenContextEntry(true);

    return;
    setContextEntries((prev) => [
      ...prev,
      { id: Date.now() + Math.random(), context_key: "", context_value: "" },
    ]);
  }, []);

  const handleCancel = () => {
    setIsOpenContextEntry(false);
  };

  // Add Context Entry
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [contextText, setContextText] = useState<string>("");
  const [reference, setReference] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isContextSubmitting, setIsContextSubmitting] = useState(false);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
      setContextText(""); // Clear context text when a file is selected
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);
  };

  const handleContextTextChange = (v: string) => {
    setContextText(v);
    if (v) {
      setSelectedFile(null); // Clear selected file when text is entered
    }
  };
  return (
    <div className="register-application-container">
      <Card
        title="Register Application"
        bordered={false}
        className="register-application-card"
        headStyle={{ backgroundColor: "#E9F8F4" }}
        extra={
          <CloseOutlined style={{ cursor: "pointer", color: "#9DA6A4" }} />
        }>
        <Form layout="vertical" className="register-wrap" disabled={loading}>
          <Form.Item
            label="Name"
            required
            tooltip="The name of the application"
            className={`form-list-item ${
              focusedField === "name" ? "focused" : ""
            }`}>
            <CustomInput
              value={applicationName}
              className="inputWrapper"
              onChange={(e) => setApplicationName(e.target.value)}
              placeholder="My Application"
              onFocus={() => setFocusedField("name")}
              onBlur={() => setFocusedField(null)}
            />
          </Form.Item>
          <Form.Item
            label="Application Reference"
            required
            tooltip="The reference name of the application"
            className={`form-list-item ${
              focusedField === "applicationRefName" ? "focused" : ""
            }`}>
            <CustomInput
              value={applicationRefName}
              className="inputWrapper"
              onChange={(e) => setApplicationRefName(e.target.value)}
              placeholder="my_application"
              onFocus={() => setFocusedField("applicationRefName")}
              onBlur={() => setFocusedField(null)}
            />
          </Form.Item>
          <Form.Item
            label="Objective"
            required
            tooltip="The objective of the application"
            className={`form-list-item ${
              focusedField === "objective" ? "focused" : ""
            }`}>
            <CustomInput
              inputType="textarea"
              className="inputTextAreaWrapper"
              value={objective}
              onChange={(e) => setObjective(e.target.value)}
              rows={4}
              placeholder="The purpose of this application is to do this thing."
              onFocus={() => setFocusedField("objective")}
              onBlur={() => setFocusedField(null)}
            />
          </Form.Item>

          <Form.Item label="Application Context" required>
            <List
              dataSource={contextEntries}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Input
                    style={{ width: "30%", marginRight: "1em" }}
                    value={item.context_key}
                    onChange={(e) =>
                      handleContextKeyChange(item.id, e.target.value)
                    }
                    onFocus={() => setFocusedField(`context_key_${item.id}`)}
                    onBlur={() => setFocusedField(null)}
                  />
                  <Input.TextArea
                    style={{ width: "65%", marginRight: "1em" }}
                    value={item.context_value}
                    onChange={(e) =>
                      handleContextValueChange(item.id, e.target.value)
                    }
                    rows={1}
                    onFocus={() => setFocusedField(`context_value_${item.id}`)}
                    onBlur={() => setFocusedField(null)}
                  />
                  <OutlineButton
                    onClick={() => handleRemoveContextEntry(item.id)}>
                    -
                  </OutlineButton>
                </List.Item>
              )}
            />
            <List.Item>
              <DashedButton onClick={handleAddContextEntry} block>
                + Add Context Entry
              </DashedButton>
            </List.Item>
          </Form.Item>
          <Form.Item className="w-100 mb-0">
            <PrimaryButton
              title="New Application"
              loading={loading}
              className="buttonWrapper w-100 btn-radius-8"
              disabled={loading}
              onClick={handleSubmit}
            />
          </Form.Item>
        </Form>
      </Card>
      <ApplicationModal
        title="Add Context Entry"
        open={isOpenContextEntry}
        onOk={handleCancel}
        onCancel={handleCancel}
        submitButtonTitle="Add Entry"
        onSubmitButtonClick={handleCancel}
        onCancelButtonClick={handleCancel}
        isLoading={false}>
        <AddContextEntry
          reference={reference}
          setReference={setReference}
          description={description}
          setDescription={setDescription}
          loading={isContextSubmitting}
          handleFileChange={handleFileChange}
          selectedFile={selectedFile}
          handleFileRemove={handleFileRemove}
          contextText={contextText}
          handleContextTextChange={handleContextTextChange}
        />
      </ApplicationModal>
    </div>
  );
};

export default RegisterApplication;
