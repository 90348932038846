import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Space,
  Spin,
  Typography,
  Modal,
  Tooltip,
  Table,
  TableColumnsType,
  Tag,
  TablePaginationConfig,
} from "antd";
import {
  EditOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { ApplicationService } from "../../services/ApplicationService";
import moment from "moment";
import ReconnectingWebSocket from "reconnecting-websocket";
import { usePermissions } from "../../PermissionsProvider";
import { Session } from "../../model_gen/session";
import { Application } from "../../model_gen/application";
import { ApplicationActionList } from "./ApplicationActionList";
import ConfigurationPanel from "./configuration/ConfigurationPanel";
import { Config } from "../../model_gen/config";
import { SessionService } from "../../services/SessionService";
import ApplicationModal from "../modals/applicationModal/ApplicationModal";
import { calculateScore, getSessionScoreColor } from "../Session/SessionUtils";
import { LinkButton, OutlineButton } from "../shared/button/Buttons";
import SessionOverview from "../Session/SessionOverview";
import { Turn } from "../../model_gen/chat";

const { Title, Paragraph } = Typography;

const ApplicationOverview: React.FC = () => {
  const { apiKey } = usePermissions();
  const { applicationId } = useParams<{ applicationId: string }>();
  const [application, setApplication] = useState<Application | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sessionsLoading, setSessionsLoading] = useState<boolean>(true);
  const [sessions, setSessions] = useState<Session[]>([]);
  // setCurrentPage not being used, kept for future implementation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSessions, setTotalSessions] = useState(0);
  const pageSize = 10; // Adjust based on your preference
  const wsRef = useRef<ReconnectingWebSocket | null>(null);
  const lastMessageTimeRef = useRef<Date>(new Date());
  const [configurationModalOpen, setConfigurationModalOpen] =
    useState<boolean>(false);
  const [originalConfig, setOriginalConfig] = useState<Config>();
  const childRef = useRef(null);
  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(
    null
  );
  const [sessionModalVisible, setSessionModalVisible] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const updateConfig = (
    config: Partial<Config>,
    loadingHandler: (loading: boolean) => void | null,
    save: boolean = true
  ) => {
    let newConfig = application?.meta;
    if (!newConfig) {
      newConfig = Config.fromPartial(config);
    }
    if (!application) {
      return;
    }
    const newApplication = {
      ...application,
      meta: {
        ...newConfig,
        ...config,
      },
    };
    setApplication(newApplication);
    if (!save) {
      return;
    }
    ApplicationService.setApplicationConfig(
      application.id,
      config,
      loadingHandler,
      apiKey
    ).subscribe(
      (updatedConfig: Config) => {
        setApplication((prevState) => {
          if (!prevState) return prevState;
          return {
            ...prevState,
            meta: updatedConfig,
          };
        });
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const resetConfig = () => {
    if (originalConfig) {
      updateConfig(originalConfig, () => {});
    }
  };

  const handleCancel = () => {
    if (childRef.current) {
      childRef.current.closeToolTipEvent();
    } else {
      console.error("childRef.current is undefined");
    }
    setConfigurationModalOpen(false);
  };

  const showModal = () => {
    if (childRef.current) {
      childRef.current.openToolTipEvent();
    } else {
      console.error("childRef.current is undefined");
    }
    setConfigurationModalOpen(true);
  };

  useEffect(() => {
    if (applicationId) {
      ApplicationService.getApplication(
        Number(applicationId),
        setIsLoading,
        apiKey
      ).subscribe(
        (app: Application) => {
          setApplication(app);
          setOriginalConfig(app.meta);
        },
        (error: any) => {
          console.error(error);
        }
      );
    }
    return openWebsocket();
  }, [applicationId]);

  useEffect(() => {
    if (applicationId) {
      SessionService.getApplicationSessions(
        applicationId,
        (currentPage - 1) * pageSize,
        pageSize,
        setSessionsLoading,
        apiKey
      ).subscribe(
        ({ sessions, total }: { sessions: Session[]; total: number }) => {
          setSessions(sessions);
          setTotalSessions(total);
        },
        (error: any) => {
          console.error(error);
        }
      );
    }
  }, [applicationId, currentPage, pageSize]);

  const openWebsocket = () => {
    closeWsConnection();

    const ws: any = new ReconnectingWebSocket(
      `wss://09hidyy627.execute-api.us-west-2.amazonaws.com/production?key=${applicationId}&type=NEW_SESSION,SESSION_UPDATE`
    );
    wsRef.current = ws;

    ws.addEventListener("message", (message: any) => {
      lastMessageTimeRef.current = new Date();
      const data = JSON.parse(message.data);
      switch (data.event_type) {
        case "NEW_SESSION":
          handleNewSession(data);
          break;
        case "SESSION_UPDATE":
          handleNewSessionTurn(data);
          break;
      }
    });

    // create an interval that checks the last message time and refreshes connection if needed
    const checkInterval = setInterval(() => {
      const now = new Date();
      // Check the payments WS
      const diffInMinutesWS =
        (now.getTime() - lastMessageTimeRef.current.getTime()) / (1000 * 60);

      if (diffInMinutesWS >= 1) {
        // adjust time as needed
        if (wsRef.current) {
          wsRef.current.reconnect(1000, "Manual reconnect");
          lastMessageTimeRef.current = new Date();
        }
      }
    }, 1000 * 5); // check every 5 seconds

    console.log("Opened WS connection");

    return () => {
      if (checkInterval) clearInterval(checkInterval);
      closeWsConnection();
    };
  };

  const closeWsConnection = () => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
      console.log("Closed WS connection");
    }
  };

  const handleNewSession = (data: any) => {
    const newSession: Session = JSON.parse(data.event_data);
    if (newSession.timeline == undefined) {
      newSession.timeline = [];
    }
    if (!newSession.cost) {
      newSession.cost = -1;
    }
    const sessionId = newSession.session_id;

    // Set the date_created to the current time in seconds
    newSession.date_created = Date.now();

    setSessions((prevSessions) => {
      const updatedSessions = [...prevSessions];
      const sessionExists = updatedSessions?.some(
        (session) => session.session_id === sessionId
      );

      if (!sessionExists && currentPage == 1) {
        updatedSessions.unshift(newSession); // Prepend the new session to the beginning of the sessions array
      }

      return updatedSessions;
    });
  };

  const handleNewSessionTurn = (data: any) => {
    const turn: Turn = JSON.parse(data.event_data);
    const sessionId = turn.request?.session_id || turn.eval_request?.session_id;

    if (sessionId) {
      setSessions((prevSessions) => {
        const updatedSessions = [...prevSessions];
        const sessionIndex = updatedSessions.findIndex(
          (session) => session.session_id === sessionId
        );

        if (sessionIndex !== -1) {
          const session = updatedSessions[sessionIndex];
          const turnIndex = session.timeline.findIndex(
            (t) =>
              (t.request?.request_id &&
                turn.request?.request_id &&
                t.request.request_id === turn.request.request_id) ||
              (t.response?.request_id &&
                turn.response?.request_id &&
                t.response.request_id === turn.response.request_id) ||
              (t.eval_request?.request_id &&
                turn.eval_request?.request_id &&
                t.eval_request.request_id === turn.eval_request.request_id)
          );
          if (turnIndex !== -1) {
            const existingTurn = session.timeline[turnIndex];
            session.timeline[turnIndex] = {
              ...existingTurn,
            };
            if (turn.request?.request_id) {
              session.timeline[turnIndex].request = turn.request;
            }
            if (turn.response?.request_id) {
              session.timeline[turnIndex].response = turn.response;
            }
            if (turn.eval_request?.request_id) {
              session.timeline[turnIndex].eval_request = turn.eval_request;
            }
          } else if (turn.request || turn.response) {
            session.timeline.push(turn);
          }

          updatedSessions[sessionIndex] = session;
        }

        return updatedSessions;
      });
    }
  };
  const sessionColumns: TableColumnsType<Session> = [
    {
      title: "Session ID",
      dataIndex: "session_id",
      key: "session_id",
    },
    {
      title: "Score",
      key: "score",
      render: (_, session: Session) => (
        <Tag color={getSessionScoreColor(session)}>
          {calculateScore(session)}
        </Tag>
      ),
    },
    {
      title: "Date Started",
      dataIndex: "date_created",
      key: "date_created",
      render: (text: string) => moment(text).format("YYYY-MM-DD hh:mm:ss A"),
    },
    {
      title: "Last Activity",
      key: "last_activity",
      render: (_, session: Session) => {
        const firstRequestTimeMoment = session.date_created
          ? moment(session.date_created)
          : null;
        const lastRequestTimeMoment = session.date_ended
          ? moment(session.date_ended)
          : null;
        return lastRequestTimeMoment
          ? lastRequestTimeMoment.format("YYYY-MM-DD hh:mm:ss A")
          : firstRequestTimeMoment?.format("YYYY-MM-DD hh:mm:ss A");
      },
    },
    {
      title: "Requests",
      key: "requests",
      render: (_, session: Session) => session.timeline.length,
    },
    {
      title: "Faults",
      key: "faults",
      render: (_, session: Session) => {
        const faultsCount = session.timeline.reduce((acc, turn) => {
          const hasFault =
            turn.eval_request?.eval_results_set?.evaluation_results?.some(
              (evalResult) => evalResult.status === "FAULT"
            );
          return acc + (hasFault ? 1 : 0);
        }, 0);
        return faultsCount;
      },
    },
    {
      title: "Cost",
      key: "cost",
      render: (_, session: Session) => {
        // Format the cost to 3 decimal points like '$0.000'
        if (session.cost < 0) {
          return "Calculating...";
        }
        return `$${session.cost.toFixed(3)}`;
      },
    },
    // Add more columns as needed
  ];

  const navigate = useNavigate();

  const handleSessionClick = (session: Session) => {
    setSelectedSessionId(session.session_id);
    setSessionModalVisible(true);
    setRefreshTrigger((prev) => prev + 1);
  };

  const handleSessionModalClose = () => {
    setSelectedSessionId(null);
    setSessionModalVisible(false);
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  if (isLoading) {
    return (
      <Spin
        size="large"
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      />
    );
  }

  const modalTitle = (
    <div style={{ display: "flex", alignItems: "flex-end", gap: "10px" }}>
      <span>Configuration</span>
    </div>
  );

  return (
    <Card className="applications-wrapper">
      <Row justify="space-between" align="middle" style={{ marginBottom: 30 }}>
        <Col>
          <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
            <Link to="/applications" style={{ color: "#828282" }}>
              Applications {" / "}{" "}
            </Link>
            {application?.application_name}
          </Typography>
        </Col>
        <Col className="setting-buttons-wrap">
          <LinkButton
            className="icon-btn p-0 gap-12"
            shape="default"
            icon={<SettingOutlined />}
            onClick={() => showModal()}
          />
          <LinkButton
            className="icon-btn p-0"
            shape="default"
            icon={<EditOutlined />}
            onClick={() => navigate(`/application/${applicationId}/edit`)}
          />
        </Col>
      </Row>
      <Space direction="vertical" size={30} style={{ width: "100%" }}>
        {application ? (
          <Card>
            <Row>
              <Col span={12}>
                <h3 style={{ padding: 0, margin: 0, color: "#616665" }}>
                  Application Reference
                </h3>
                <p
                  className="gc-reg"
                  style={{ padding: 0, margin: 0, color: "#090F0D" }}>
                  {application.application_ref_name}
                </p>
              </Col>
              <Col
                span={12}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  borderLeft: "1px solid #C5C6C5",
                }}>
                <h3 style={{ padding: 0, margin: 0, color: "#616665" }}>
                  Date Created
                </h3>
                <p
                  className="gc-reg"
                  style={{ padding: 0, margin: 0, color: "#090F0D" }}>
                  {moment(application.date_created).format(
                    "YYYY-MM-DD hh:mm:ss A"
                  )}
                </p>
              </Col>
            </Row>
            {/* <Descriptions colon={false} column={12} layout='vertical' >
              <Descriptions.Item label="Application Reference" span={5}>{application.application_ref_name}</Descriptions.Item>
              <Descriptions.Item label="Date Created" span={5}>{moment(application.date_created).format('YYYY-MM-DD hh:mm:ss A')}</Descriptions.Item>
            </Descriptions> */}
          </Card>
        ) : (
          <Paragraph>Application not found</Paragraph>
        )}
        <Space direction="vertical" size={0} style={{ width: "100%" }}>
          <Title level={4} style={{ marginTop: 0 }}>
            Health
          </Title>
          <Row gutter={16}>
            <Col span={6}>
              <Card
                className="cus-card-health-wrap"
                style={{ position: "relative" }}>
                <Tooltip
                  title={
                    <span>
                      Measures how well the application adheres to its defined
                      responsibilities and tasks.<br></br>NOTE: This is a
                      placeholder value and will be updated with actual data in
                      the future.
                    </span>
                  }>
                  <InfoCircleOutlined
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "12px",
                      color: "rgba(0, 0, 0, 0.45)",
                    }}
                  />
                </Tooltip>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    color: "#090F0D",
                    fontSize: "18px",
                  }}
                  className="gc-demi-bold">
                  Responsibility
                </p>
                <h3
                  className="gc-bold"
                  style={{
                    padding: 0,
                    margin: 0,
                    color: "#255D70",
                    fontSize: "32px",
                    lineHeight: "1.1em",
                  }}>
                  99
                </h3>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                className="cus-card-health-wrap"
                style={{ position: "relative" }}>
                <Tooltip
                  title={
                    <span>
                      Indicates the overall quality of the application's
                      responses and performance.<br></br>NOTE: This is a
                      placeholder value and will be updated with actual data in
                      the future.
                    </span>
                  }>
                  <InfoCircleOutlined
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "12px",
                      color: "rgba(0, 0, 0, 0.45)",
                    }}
                  />
                </Tooltip>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    color: "#090F0D",
                    fontSize: "18px",
                  }}
                  className="gc-demi-bold">
                  Quality
                </p>
                <h3
                  className="gc-bold"
                  style={{
                    padding: 0,
                    margin: 0,
                    color: "#255D70",
                    fontSize: "32px",
                    lineHeight: "1.1em",
                  }}>
                  94
                </h3>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                className="cus-card-health-wrap"
                style={{ position: "relative" }}>
                <Tooltip
                  title={
                    <span>
                      Represents the percentage of expectations being monitored
                      for.<br></br>NOTE: This is a placeholder value and will be
                      updated with actual data in the future.
                    </span>
                  }>
                  <InfoCircleOutlined
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "12px",
                      color: "rgba(0, 0, 0, 0.45)",
                    }}
                  />
                </Tooltip>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    color: "#090F0D",
                    fontSize: "18px",
                  }}
                  className="gc-demi-bold">
                  Coverage
                </p>
                <h3
                  className="gc-bold"
                  style={{
                    padding: 0,
                    margin: 0,
                    color: "#255D70",
                    fontSize: "32px",
                    lineHeight: "1.1em",
                  }}>
                  89%
                </h3>
              </Card>
            </Col>
            <Col span={6}>
              <Card
                className="cus-card-health-wrap"
                style={{ position: "relative" }}>
                <Tooltip
                  title={
                    <span>
                      The total number of sessions within this application.
                    </span>
                  }>
                  <InfoCircleOutlined
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "12px",
                      color: "rgba(0, 0, 0, 0.45)",
                    }}
                  />
                </Tooltip>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    color: "#090F0D",
                    fontSize: "18px",
                  }}
                  className="gc-demi-bold">
                  Sessions
                </p>
                <h3
                  className="gc-bold"
                  style={{
                    padding: 0,
                    margin: 0,
                    color: "#255D70",
                    fontSize: "32px",
                    lineHeight: "1.1em",
                  }}>
                  {totalSessions}
                </h3>
              </Card>
            </Col>
          </Row>
        </Space>
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          <ApplicationActionList applicationId={application?.id} />
          <Title
            level={4}
            style={{
              marginTop: application.action_types.length > 5 ? 0 : "48px",
            }}>
            Sessions
          </Title>
          <Table
            rowKey={"session_id"}
            columns={sessionColumns}
            dataSource={sessions}
            pagination={
              totalSessions > pageSize
                ? {
                    current: currentPage,
                    pageSize: pageSize,
                    total: totalSessions,
                    showSizeChanger: false,
                  }
                : false
            }
            loading={sessionsLoading}
            onChange={handleTableChange}
            onRow={(record) => ({
              onClick: () => handleSessionClick(record),
            })}
          />
        </Space>
      </Space>
      <ApplicationModal
        title={modalTitle}
        submitButtonTitle="Save"
        open={configurationModalOpen}
        onOk={() => {}}
        onSubmitButtonClick={handleCancel}
        onCancel={handleCancel}
        onCancelButtonClick={handleCancel}
        isLoading={false}
        footerLeadingButton={
          <OutlineButton
            key="reset"
            icon={<ReloadOutlined />}
            onClick={resetConfig}
            className="cancel-newbtn-wrap">
            Reset
          </OutlineButton>
          // <LinkButton
          //   icon={<ReloadOutlined />}
          //   style={{
          //     padding: 0,
          //     color: "black",
          //     display: "flex",
          //     alignItems: "center",
          //   }}
          //   onClick={resetConfig}>
          //   Reset
          // </LinkButton>
        }>
        <ConfigurationPanel
          ref={childRef}
          config={application?.meta}
          isModalOpen={true}
          updateConfig={updateConfig}
          resetConfig={resetConfig}
        />
      </ApplicationModal>
      <Modal
        title="Session Details"
        open={sessionModalVisible}
        onCancel={handleSessionModalClose}
        footer={null}
        width={1000}>
        {selectedSessionId && applicationId && (
          <SessionOverview
            sessionId={selectedSessionId}
            applicationId={applicationId}
            refreshTrigger={refreshTrigger}
          />
        )}
      </Modal>
    </Card>
  );
};

export default ApplicationOverview;
