import { useEffect, useRef } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";

interface UseWebSocketProps {
  key: any;
  eventType: string;
  onMessage: (data: any) => void;
}

export const useWebSocket = ({
  key,
  eventType,
  onMessage,
}: UseWebSocketProps) => {
  const wsRef = useRef<ReconnectingWebSocket | null>(null);
  const lastMessageTimeRef = useRef<Date>(new Date());

  useEffect(() => {
    return openWebsocket();
  }, [key]);

  const openWebsocket = () => {
    closeWsConnection();
    if (!key) {
      return;
    }

    const ws: any = new ReconnectingWebSocket(
      `wss://09hidyy627.execute-api.us-west-2.amazonaws.com/production?key=${key}&type=${eventType}`
    );
    wsRef.current = ws;

    ws.addEventListener("message", (message: any) => {
      lastMessageTimeRef.current = new Date();
      const data = JSON.parse(message.data);
      onMessage(data);
    });

    const checkInterval = setInterval(() => {
      const now = new Date();
      const diffInMinutesWS =
        (now.getTime() - lastMessageTimeRef.current.getTime()) / (1000 * 60);

      if (diffInMinutesWS >= 1) {
        if (wsRef.current) {
          wsRef.current.reconnect(1000, "Manual reconnect");
          lastMessageTimeRef.current = new Date();
        }
      }
    }, 1000 * 60);

    console.log("Opened WS connection");

    return () => {
      if (checkInterval) clearInterval(checkInterval);
      closeWsConnection();
    };
  };

  const closeWsConnection = () => {
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
      console.log("Closed WS connection");
    }
  };

  return { closeWsConnection };
};
