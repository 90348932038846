import GeneralService from "./GeneralService";
import { Component } from "react";

export class AnnotationService extends Component {
  public static getAvailableData(loadingHandler: any, apiKey: string) {
    return GeneralService.get(
      "/annotation/data",
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }
  public static getSessions(
    companyId: string,
    appId: string,
    intentId: string,
    searchTerm: string, // Add searchTerm parameter
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.get(
      `/annotation/company/${companyId}/application/${appId}/intent/${intentId}?search=${encodeURIComponent(
        searchTerm
      )}`, // Include search term in URL
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }

  public static getRequests(
    companyId: string,
    appId: string,
    intentId: string,
    sessionId: string,
    searchTerm: string, // Add searchTerm parameter
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.get(
      `/annotation/company/${companyId}/application/${appId}/intent/${intentId}/session/${sessionId}?search=${encodeURIComponent(
        searchTerm
      )}`, // Include search term in URL
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }
  public static updateAnnotation(
    companyId: string,
    appId: string,
    intentId: string,
    sessionId: string,
    requestId: string,
    label: any,
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.put(
      `/annotation/company/${companyId}/application/${appId}/intent/${intentId}/session/${sessionId}/request/${requestId}`,
      JSON.stringify(label),
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }
  public static excludeAnnotation(
    companyId: string,
    appId: string,
    intentId: string,
    sessionId: string,
    requestId: string,
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.put(
      `/annotation/company/${companyId}/application/${appId}/intent/${intentId}/session/${sessionId}/request/${requestId}/exclude`,
      null,
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }
  public static exportAnnotations(
    companyId: string,
    appId: string,
    intentId: string,
    includeAll: boolean,
    loadingHandler: any,
    apiKey: string
  ) {
    const payload = { include_all: includeAll };
    return GeneralService.post(
      `/annotation/company/${companyId}/application/${appId}/intent/${intentId}/export`,
      JSON.stringify(payload),
      loadingHandler,
      "blob",
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }
  public static sendAnnotationEmail(
    companyId: string,
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.post(
      `/annotation/company/${companyId}/send-annotation-email`,
      null,
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }
}
