import GeneralService from "./GeneralService";
import { Component } from "react";
import { Session } from "../model_gen/session";

export class SessionService extends Component {
  public static getApplicationSessions(
    applicationId: any,
    offset: number,
    limit: number,
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.get(
      `/session/application/${applicationId}/${offset}/${limit}`,
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }

  public static getCompanySessions(
    offset: number,
    limit: number,
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.get(
      `/session/${offset}/${limit}`,
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }

  public static setSessionFeedback(
    applicationId: number,
    sessionId: string,
    session: Partial<Session>,
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.post(
      `/session/application/${applicationId}/session/${sessionId}/feedback`,
      JSON.stringify(session),
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }

  public static getSession(
    applicationId: any,
    sessionId: string,
    offset: number,
    limit: number,
    loadingHandler: any,
    apiKey: string
  ) {
    return GeneralService.get(
      `/session/${sessionId}/application/${applicationId}/${offset}/${limit}`,
      loadingHandler,
      JSON,
      GeneralService.PROD_ENDPOINT,
      apiKey
    );
  }
}
