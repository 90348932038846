import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { IntegrationsService } from "../../services/IntegrationsService";
import { usePermissions } from "../../PermissionsProvider";
import SlackIntegration from "../../models/SlackIntegration";
import { SyncOutlined, DeleteTwoTone, ApiOutlined } from "@ant-design/icons";
import { OutlineButton } from "../shared/button/Buttons";

const DevelopersIntegrations: React.FC = () => {
  const { apiKey } = usePermissions();
  // loading not being used, kept for future implementation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [slackIntegration, setSlackIntegration] =
    useState<SlackIntegration | null>(null);

  // slackInstallUrl not being used, kept for future implementation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const slackInstallUrl =
    "https://slack.com/oauth/v2/authorize?client_id=7076808695920.7108175715302&scope=app_mentions:read,channels:join,channels:manage,channels:read,chat:write,commands,groups:read,groups:write,im:write,mpim:write,team:read,channels:history,groups:history,mpim:history,im:history&user_scope=";

  useEffect(() => {
    IntegrationsService.getSlackIntegration(setLoading, apiKey).subscribe(
      (slackIntegration: SlackIntegration) => {
        console.log(
          "Success fetching Slack integration!",
          JSON.stringify(slackIntegration)
        );
        setSlackIntegration(slackIntegration);
      },
      (error: any) => {
        console.log(error);
        setLoading(false);
      }
    );
  }, []);

  return (
    <Row>
      <Col span={24}>
        <h2 className="gc-reg" style={{ margin: 0, paddingBottom: 10 }}>
          {" "}
          Slack{" "}
        </h2>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={8}>
            <Card>
              <Row>
                <div
                  style={{
                    backgroundColor: "#4A154B",
                    border: "0px",
                    borderRadius: "8px",
                    padding: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ height: "24px", width: "24px" }}
                    viewBox="0 0 122.8 122.8">
                    <path
                      d="M25.8 77.6c0 7.1-5.8 12.9-12.9 12.9S0 84.7 0 77.6s5.8-12.9 12.9-12.9h12.9v12.9zm6.5 0c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9v32.3c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V77.6z"
                      fill="#e01e5a"></path>
                    <path
                      d="M45.2 25.8c-7.1 0-12.9-5.8-12.9-12.9S38.1 0 45.2 0s12.9 5.8 12.9 12.9v12.9H45.2zm0 6.5c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H12.9C5.8 58.1 0 52.3 0 45.2s5.8-12.9 12.9-12.9h32.3z"
                      fill="#36c5f0"></path>
                    <path
                      d="M97 45.2c0-7.1 5.8-12.9 12.9-12.9s12.9 5.8 12.9 12.9-5.8 12.9-12.9 12.9H97V45.2zm-6.5 0c0 7.1-5.8 12.9-12.9 12.9s-12.9-5.8-12.9-12.9V12.9C64.7 5.8 70.5 0 77.6 0s12.9 5.8 12.9 12.9v32.3z"
                      fill="#2eb67d"></path>
                    <path
                      d="M77.6 97c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9-12.9-5.8-12.9-12.9V97h12.9zm0-6.5c-7.1 0-12.9-5.8-12.9-12.9s5.8-12.9 12.9-12.9h32.3c7.1 0 12.9 5.8 12.9 12.9s-5.8 12.9-12.9 12.9H77.6z"
                      fill="#ecb22e"></path>
                  </svg>
                </div>
              </Row>
              <Row>
                <h3 className="gc-reg" style={{ margin: 0, marginTop: 10 }}>
                  Slack
                </h3>
              </Row>
              <Row>
                <p className="gc-reg" style={{ margin: 0, paddingBottom: 10 }}>
                  Connect your Slack workspace to Maitai to receive
                  notifications and start engaging with Maitai in your Slack
                  channels.
                </p>
              </Row>
              {slackIntegration ? (
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}>
                  <OutlineButton
                    style={{ flex: 1 }}
                    onClick={() => {
                      window.location.href = slackInstallUrl;
                    }}>
                    <SyncOutlined />
                    Reload Slack App
                  </OutlineButton>
                  <OutlineButton style={{ flex: 1 }} danger>
                    <DeleteTwoTone twoToneColor="#ff4d4f" />
                    Disconnect Slack App
                  </OutlineButton>
                </Row>
              ) : (
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}>
                  <OutlineButton
                    style={{ flex: 1 }}
                    onClick={() => {
                      window.location.href = slackInstallUrl;
                    }}>
                    <ApiOutlined />
                    Install Slack App
                  </OutlineButton>
                </Row>
              )}
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DevelopersIntegrations;
