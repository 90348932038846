import React, { useEffect, useState } from "react";
import { Col, Row, Select, SelectProps, Spin, Typography } from "antd";
import { usePermissions } from "../../PermissionsProvider";
import { Application } from "../../model_gen/application";
import { ApplicationService } from "../../services/ApplicationService";

const { Title } = Typography;

const NotificationSettings: React.FC = () => {
  const { apiKey } = usePermissions();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<SelectProps["options"]>([]);
  const [faultApplications, setFaultApplications] = useState<number[]>([]);
  const [summaryApplications, setSummaryApplications] = useState<number[]>([]);
  const handleFaultApplicationChange = (applicationIDs: number[]) => {
    ApplicationService.setFaultNotifications(
      applicationIDs,
      null,
      apiKey
    ).subscribe(
      () => {
        setFaultApplications(applicationIDs);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };
  const handleSummaryApplicationChange = (applicationIDs: number[]) => {
    ApplicationService.setSessionSummaries(
      applicationIDs,
      null,
      apiKey
    ).subscribe(
      () => {
        setSummaryApplications(applicationIDs);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    setIsLoading(true);
    ApplicationService.getApplications(setIsLoading, apiKey).subscribe(
      (applications: Application[]) => {
        const tmpOptions: SelectProps["options"] = [];
        const tmpEnabledFaultApplications: number[] = [];
        const tmpEnabledSummaryApplications: number[] = [];

        applications.forEach((app: Application) => {
          tmpOptions.push({
            label: app.application_name,
            value: app.id,
          });
          if (app.fault_notifications) {
            tmpEnabledFaultApplications.push(app.id);
          }
          if (app.session_summaries) {
            tmpEnabledSummaryApplications.push(app.id);
          }
        });
        setOptions(tmpOptions);
        setSummaryApplications(tmpEnabledSummaryApplications);
        setFaultApplications(tmpEnabledFaultApplications);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }, []);

  return (
    <div className="overlay-container">
      {isLoading && (
        <div className="overlay">
          <Spin size="large" />
        </div>
      )}
      <Title level={4}>Notifications</Title>
      <Row>
        <Col span={12}>
          <div className="notification-item-wrap">
            <div className="notification-item-title">Real-Time Faults</div>
            <Select
              className="h-56"
              mode="multiple"
              allowClear
              style={{ width: "70%" }}
              placeholder="Disabled"
              options={options}
              value={faultApplications}
              onChange={handleFaultApplicationChange}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div className="notification-item-wrap">
            <div className="notification-item-title">Session End Summaries</div>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "70%" }}
              placeholder="Disabled"
              options={options}
              value={summaryApplications}
              onChange={handleSummaryApplicationChange}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default NotificationSettings;
