import GeneralService from "./GeneralService";
import { Component } from "react";


export class CompanyService extends Component {
  public static getCompany(loadingHandler: any, apiKey: string) {
    return GeneralService.get("/company/", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static getAffiliatedCompanies(loadingHandler: any, apiKey: string) {
    return GeneralService.get("/company/affiliated", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static getAllCompanies(loadingHandler: any, apiKey: string) {
    return GeneralService.get("/company/all", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
}

