import {Sentinel} from "../model_gen/sentinel";
import GeneralService from "./GeneralService";
import {Component} from "react";


export class SentinelService extends Component {

  public static getSentinel(sentinelId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/sentinel/${sentinelId}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static getSentinels(applicationId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.get(`/sentinel/application/${applicationId}`, loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static disableSentinel(sentinelId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.put(`/sentinel/${sentinelId}/state`, JSON.stringify({state: "DISABLED"}), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static enableSentinel(sentinelId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.put(`/sentinel/${sentinelId}/state`, JSON.stringify({state: "ENABLED"}), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static registerSentinel(sentinel: Sentinel, loadingHandler: any, apiKey: string) {
    return GeneralService.post("/sentinel/", JSON.stringify(sentinel), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static updateSentinel(sentinel: Sentinel | undefined, loadingHandler: any, apiKey: string) {
    return GeneralService.put("/sentinel/", JSON.stringify(sentinel), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static deleteSentinel(sentinelId: number, loadingHandler: any, apiKey: string) {
    return GeneralService.delete(`/sentinel/${sentinelId}`, "", loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }

  public static cloneSentinel(sentinelId: number, applicationId: number, loadingHandler: any, apiKey: string) {
    const payload = {
      "application_id": applicationId
    };
    return GeneralService.post(`/sentinel/${sentinelId}/clone`, JSON.stringify(payload), loadingHandler, JSON, GeneralService.PROD_ENDPOINT, apiKey);
  }
}

