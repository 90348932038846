import React, { useState, useEffect, useRef } from "react";
import { Input, InputRef, Typography, Modal, Spin, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "../../styles/components/Search.css";
import { SearchService } from "../../services/SearchService";
import { usePermissions } from "../../PermissionsProvider";
import SessionOverview from "../Session/SessionOverview";
import { Session } from "../../model_gen/session";

const { Text } = Typography;
interface Category {
  name: string;
  key: string;
}

interface SearchProps {
  disabled?: boolean;
}

const Search: React.FC<SearchProps> = ({ disabled = false }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const { apiKey } = usePermissions();
  const categories: Category[] = [
    { name: "Sessions", key: "sessions" },
    { name: "Requests", key: "requests" },
  ];
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<Category | null>(null);
  const suggestionsRef = useRef<HTMLUListElement>(null);
  const [selectedSession, setSelectedSession] = useState<Session | null>(null);
  const [sessionModalVisible, setSessionModalVisible] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [selectedRequestId, setSelectedRequestId] = useState<string | null>(
    null
  );

  useEffect(() => {
    setShowSuggestions(searchTerm.length > 0);
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current?.input &&
        !inputRef.current.input.contains(event.target as Node) &&
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node)
      ) {
        setCurrentCategory(null);
        setSearchResults([]);
        setSearchTerm("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchTerm, currentCategory, inputRef.current?.input]);

  const handleSearch = (category: Category) => {
    setCurrentCategory(category);
    SearchService.search(
      category.key,
      searchTerm,
      setIsSearching,
      apiKey
    ).subscribe(
      (data: any) => {
        if (!data || data.length === 0) {
          setSearchResults([{ isEmpty: true, message: "No results found" }]);
        } else {
          setSearchResults(data);
        }
        setShowSuggestions(true);
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  const handleResultClick = (result: any) => {
    setSelectedRequestId(result.request_id || null);
    setSelectedSession(
      Session.fromPartial({
        application_id: result.application_id,
        session_id: result.session_id,
      })
    );
    setSessionModalVisible(true);
    setRefreshTrigger((prev) => prev + 1);
    setShowSuggestions(false);
    setSearchTerm("");
    setCurrentCategory(null);
    setSearchResults([]);
  };

  const handleSessionModalClose = () => {
    setSelectedSession(null);
    setSessionModalVisible(false);
  };

  const renderSearchResult = (result: any, index: number) => {
    const commonProps = {
      key: index,
      className: "result-item",
      onClick: (e: React.MouseEvent) => {
        if (result.isEmpty) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        handleResultClick(result);
      },
    };

    if (result.isEmpty) {
      return (
        <li {...commonProps} className="result-item-no-hover" style={{ cursor: "default" }}>
          <Text>{result.message}</Text>
        </li>
      );
    }

    if (result.request_id) {
      return (
        <li {...commonProps}>
          <Text>
            <span className="app-session" style={{ color: "grey" }}>
              {result.application_name} / {result.session_id} /{" "}
            </span>
            <span>{result.request_id}</span>
          </Text>
        </li>
      );
    } else if (result.session_id) {
      return (
        <li {...commonProps}>
          <Text>
            <span className="app-name" style={{ color: "grey" }}>
              {result.application_name} /{" "}
            </span>
            <span>{result.session_id}</span>
          </Text>
        </li>
      );
    }
    return null;
  };

  const searchInput = (
    <Input
      ref={inputRef}
      prefix={<SearchOutlined />}
      suffix={isSearching && <Spin size="small" />}
      className="search-input"
      placeholder="Search..."
      value={searchTerm}
      onChange={(e) => {
        setSearchTerm(e.target.value);
        setCurrentCategory(null);
        setSearchResults([]);
      }}
      disabled={disabled}
    />
  );

  return (
    <div className="search-container">
      {disabled ? (
        <Tooltip title="Make a request using the Maitai SDK to use this feature">
          {searchInput}
        </Tooltip>
      ) : (
        searchInput
      )}
      {showSuggestions && !disabled && (
        <ul ref={suggestionsRef} className="suggestions-list scrollable">
          {currentCategory
            ? searchResults.map((result, index) =>
                renderSearchResult(result, index)
              )
            : categories.map((category, index) => (
                <li
                  key={index}
                  className="suggestion-item"
                  onClick={() => handleSearch(category)}>
                  <span>{searchTerm}</span>
                  <span className="category-label">in {category.name}</span>
                </li>
              ))}
        </ul>
      )}
      <Modal
        title="Session Details"
        open={sessionModalVisible}
        onCancel={handleSessionModalClose}
        footer={null}
        width={1000}>
        {selectedSession && (
          <SessionOverview
            sessionId={selectedSession.session_id}
            applicationId={selectedSession.application_id.toString()}
            refreshTrigger={refreshTrigger}
            requestId={selectedRequestId || undefined}
          />
        )}
      </Modal>
    </div>
  );
};

export default Search;
