import React from "react";
import { Input } from "antd";
import { TextAreaProps } from "antd/es/input";
import { CustomInputProps } from "../../../types/Shared";

const CustomInput: React.FC<CustomInputProps> = ({
  inputType = "text",
  onFocusChange,
  ...props
}) => {
  const handleFocus = () => {
    if (onFocusChange) onFocusChange(true);
  };
  const handleBlur = () => {
    if (onFocusChange) onFocusChange(false);
  };
  if (inputType === "textarea") {
    const textareaProps = props as unknown as TextAreaProps;
    return (
      <Input.TextArea
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...textareaProps}
      />
    );
  }
  return <Input onFocus={handleFocus} onBlur={handleBlur} {...props} />;
};

export default CustomInput;
