import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";

interface PermissionsContextType {
  apiKey: string;
  setApiKey: (apiKey: string) => void;
}

const PermissionsContext = createContext<PermissionsContextType | undefined>(undefined);

export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (context === undefined) {
    throw new Error("usePermissions must be used within a PermissionsProvider");
  }
  return context;
};

export const PermissionsProvider: React.FC<{ children: ReactNode, apiKey: string }> = ({ children, apiKey }) => {
  const [key, setKey] = useState<string>(apiKey);
  useEffect(() => {
    if (apiKey) {
      setKey(apiKey);
    }
  }, [apiKey]);
  return (
    <PermissionsContext.Provider value={{ apiKey: key, setApiKey: setKey }}>
      {children}
    </PermissionsContext.Provider>
  );
};
